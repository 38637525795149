import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import { stripHtmlTags } from 'src/app/shared/utils/date-transforms/string-transform';
import {
  addUserIdentity,
  initMixpanel,
  optOutTrackingWhenUserIsTester,
  sendMetricEvent,
} from 'src/environments/mix-panel/mix-panel';
import { RoutingService } from '../../services/routing.service';
import {
  createMetricPayload,
  extractMetricEvent,
  getQueryProperties,
} from '../../utils/mixpanel';
import {
  DayNamesDto,
  dayVideoMap,
  ThermalNamesDto,
  thermalVideoMap,
} from '../../view-models/event.view.model';
import { CoreState } from '../state/core.state';
import {
  AuthenticationActions,
  AuthenticationSelectors,
  CommentsActions,
  CommentsSelectors,
  EventsActions,
  EventsSelectors,
  FiltersActions,
  OverviewActions,
  ShipsActions,
  ShipsSelectors,
} from '../types';

@Injectable()
export class MixpanelEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private routingService: RoutingService,
    private store: Store<CoreState>
  ) {}

  onAppNavigation$ = createEffect(
    () => {
      return this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => event as NavigationEnd),
        withLatestFrom(
          this.routingService.getCurrentPage$(),
          this.routingService.getCurrentPageQueryParams$()
        ),
        switchMap(([event, currentPageName, currentPageQueryParams]) => {
          const mixpanelQueryProps = getQueryProperties(currentPageQueryParams);
          return of(
            sendMetricEvent('InAppNavigation', {
              ...mixpanelQueryProps,
              currentPageName: currentPageName,
            })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onAuthentication$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.saveAuthUser),
        filter(action => action.authUser != null),
        concatMap(action => {
          initMixpanel(); // effect can run before app initializor, so we must call init again, has no effect if it was called twice though
          addUserIdentity(action.authUser!);
          optOutTrackingWhenUserIsTester(action.authUser!);
          return of(sendMetricEvent('LoggedIn'));
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onLogout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.logout),
        concatMap(action => {
          return of(sendMetricEvent('LoggedOut'));
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onTimeframeChanged$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FiltersActions.saveSelectedDatesFilter),
        concatMap(action => {
          return of(
            sendMetricEvent('TimeframeFilterChanged', { ...action.dateFilter })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onSetSelectedEventOnOverviewPageList$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OverviewActions.setSelectedOverviewEventList),
        concatMap(action => {
          return of(
            sendMetricEvent('EventsListSelectedEvent', {
              ...action.selectedEvent,
            })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onSetSelectedEventOnOverviewPageMap$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OverviewActions.setSelectedOverviewMapEvent),
        concatMap(action => {
          return of(
            sendMetricEvent('MapSelectedEvent', { ...action.selectedEvent })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onExploreEvent$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.onExploreEventResolved),
        concatMap(action => {
          return of(
            sendMetricEvent('EventExploration', {
              ...action.event,
              eventURL: action.currentPageUrl,
            })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onSelectedEventOnEventsPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.setSelectedFilteredEvent),
        concatMap(action => {
          return of(
            sendMetricEvent('EventsListSelectedEvent', { ...action.event })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onSelectedEventOnEventPageMap$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.setSelectedEventsMapEvent),
        concatMap(action =>
          of(action).pipe(
            withLatestFrom(
              this.store.select(EventsSelectors.selectSelectedEvent)
            )
          )
        ),
        concatMap(([_, selectedEvent]) => {
          return of(sendMetricEvent('MapSelectedEvent', selectedEvent));
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onEventsDayFilter$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.updateEventsDateFilterOnUserClick),
        concatMap(action => {
          return of(
            sendMetricEvent('UpdateEventsDayFilter', {
              ...action.eventDayFilter,
            })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onResetEventsDayFilter$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.resetEventsDateFilterOnClear),
        concatMap(action => {
          return of(sendMetricEvent('ResetEventsDayFilter'));
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onResetSelectedMapEvent$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.clearEventsListSubFilters),
        concatMap(action => {
          return of(sendMetricEvent('ResetEventsSelectedMapEvent'));
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onSortByEvents$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.saveSortByOptionOnUserChange),
        concatMap(action => {
          return of(sendMetricEvent('SortByEvents'));
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onResetToDefualtAppliedFilters$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.resetAllFiltersOnUserPress),
        concatMap(action => {
          return of(sendMetricEvent('ResetToDefaultAppliedFilters'));
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onFilterChanged$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.filterChanged),
        map(action => {
          return of(
            sendMetricEvent(extractMetricEvent(action.filterChanges), {
              ...action.filterChanges.delta[
                Object.keys(action.filterChanges.delta)[0]
              ],
            })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onSignup$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.signup),
        concatMap(action => {
          return of(
            sendMetricEvent('Signup', {
              firstName: action.signupForm.firstName,
              lastName: action.signupForm.lastName,
              email: action.signupForm.email,
              companyName: action.signupForm.companyName,
            })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onResetPassword$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.passwordResetEmailChecked),
        concatMap(action => {
          return of(
            sendMetricEvent('PasswordRecovery', {
              ...action.passwordReset,
            })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onDownloadKepler$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.downloadKeplerFile),
        concatMap(action => {
          return of(sendMetricEvent('DownloadKepler', { ...action.event }));
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onShipListSelected$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ShipsActions.fetchAndSaveShipDataOnSelectListShip),
        concatMap(action =>
          of(action).pipe(
            withLatestFrom(this.store.select(ShipsSelectors.selectShips))
          )
        ),
        concatMap(([action, ships]) => {
          const ship = ships.find(ship => action.shipId === ship.shipId);
          return of(
            sendMetricEvent('ShipListSelectedShip', {
              shipId: action.shipId,
              shipName: ship?.shipName,
            })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onMapShipSelected$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ShipsActions.fetchAndSaveShipDataOnSelectMapShip,
          ShipsActions.setSelectedShipIdOnShipMapClick
        ),
        concatMap(action =>
          of(action).pipe(
            withLatestFrom(this.store.select(ShipsSelectors.selectShips))
          )
        ),
        concatMap(([action, ships]) => {
          const ship = ships.find(ship => action.shipId === ship.shipId);
          return of(
            sendMetricEvent('MapSelectedShip', {
              shipId: action.shipId,
              shipName: ship?.shipName,
            })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onViewShipProfile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ShipsActions.setSelectedShipIdOnViewShipProfile),
        concatMap(action =>
          of(action).pipe(
            withLatestFrom(this.store.select(ShipsSelectors.selectShips))
          )
        ),
        concatMap(([action, ships]) => {
          const ship = ships.find(ship => action.shipId === ship.shipId);
          return of(
            sendMetricEvent('ShipListViewedShip', {
              shipId: action.shipId,
              shipName: ship?.shipName,
            })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onSavePresetFilters$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.savePresetFilters),
        concatLatestFrom(() =>
          this.store.select(EventsSelectors.selectAppliedEventFilters)
        ),
        concatMap(([action, appliedFilters]) =>
          of(
            sendMetricEvent(
              'PresetCreated',
              createMetricPayload({
                filters: appliedFilters,
                presetName: action.presetName,
              })
            )
          )
        )
      );
    },
    { dispatch: false }
  );

  onSetPresetFilters$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          EventsActions.setPresetFilterInEvents,
          EventsActions.setPresetFilterInOverview
        ),
        concatMap(action =>
          of(
            sendMetricEvent(
              'PresetSelected',
              createMetricPayload(action.selectedPresetFilter)
            )
          )
        )
      );
    },
    { dispatch: false }
  );

  onShipProfileGraphPeriodChange$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ShipsActions.getShipSailData),
        concatMap(action => {
          const period =
            action.periodInDays === 90
              ? '3 Months'
              : action.periodInDays === 180
              ? '6 Months'
              : 'Year';
          return of(
            sendMetricEvent('SailedPeriodSelected', {
              periodSelected: period,
            })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onLivestreamStart$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ShipsActions.getShipStreamMaster),
        concatLatestFrom(_ => [
          this.store.select(ShipsSelectors.selectShips),
          this.store.select(ShipsSelectors.selectShipStreamingId),
        ]),
        concatMap(([_, ships, selectedShipId]) => {
          const ship = ships.find(ship => ship.shipId === selectedShipId);
          return of(
            sendMetricEvent('LiveStreamClicked', { shipName: ship?.shipName })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onLiveStreamCameraChange$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ShipsActions.getShipStream),
        switchMap(action =>
          of(action).pipe(
            withLatestFrom(
              this.store.select(ShipsSelectors.selectShipStreamingId),
              this.store.select(ShipsSelectors.selectShips)
            )
          )
        ),
        switchMap(([action, selectedShipId, ships]) => {
          const ship = ships.find(ship => ship.shipId === selectedShipId);
          const cameraName = dayVideoMap[action.camera_id as DayNamesDto]
            ? `Day ${dayVideoMap[action.camera_id as DayNamesDto]}`
            : `Thermal ${thermalVideoMap[action.camera_id as ThermalNamesDto]}`;
          return of(
            sendMetricEvent('LiveStreamCameraSelected', {
              shipName: ship?.shipName,
              selectedCamera: cameraName,
            })
          );
        })
      );
    },
    {
      dispatch: false,
    }
  );

  onShipNameClicked$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ShipsActions.shipNameClicked),
        concatMap(action => {
          return of(
            sendMetricEvent('ShipListSelectedShip', {
              shipId: action.shipId,
              shipName: action.shipName,
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  onAddComment$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CommentsActions.addCommentToThread),
        withLatestFrom(
          this.store.select(AuthenticationSelectors.selectSelectedUserId),
          this.store.select(CommentsSelectors.selectCurrentThreadComments),
          this.store.select(CommentsSelectors.selectUsersToMentionOnThread)
        ),
        concatMap(([action, userId, comments, usersToMention]) => {
          const eventUrl = `${window.location.origin}/private/events/event-exploration/${action.threadEvent.eventId}`;
          const mentionedUsers = usersToMention.filter(user =>
            action.mentions.includes(user.id)
          );
          const eventProperties = {
            user_id: userId,
            eventId: action.threadEvent.eventId,
            eventURL: eventUrl,
            type: action.threadEvent.type,
            severity: action.threadEvent.severity,
            numberOfComments: comments.length + 1,
            mentionedUser: mentionedUsers.map(user => user.email),
          };
          return of(sendMetricEvent('CommentAdded', eventProperties));
        })
      );
    },
    { dispatch: false }
  );

  onEditComment$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CommentsActions.editCommentOnThread),
        withLatestFrom(
          this.store.select(AuthenticationSelectors.selectSelectedUserId),
          this.store.select(CommentsSelectors.selectCurrentThreadComments),
          this.store.select(CommentsSelectors.selectUsersToMentionOnThread)
        ),
        concatMap(([action, userId, comments, usersToMention]) => {
          const eventUrl = `${window.location.origin}/private/events/event-exploration/${action.threadEvent.eventId}`;
          const commentToEdit = comments.find(
            comment => comment.id == action.commentId
          );
          const mentionedUsers = usersToMention.filter(user =>
            commentToEdit?.mentions?.includes(user.id)
          );
          const eventProperties = {
            user_id: userId,
            eventId: action.threadEvent.eventId,
            eventURL: eventUrl,
            type: action.threadEvent.type,
            severity: action.threadEvent.severity,
            numberOfComments: comments.length,
            mentionedUser: mentionedUsers.map(user => user.email),
          };
          return of(sendMetricEvent('CommentEdited', eventProperties));
        })
      );
    },
    { dispatch: false }
  );

  onDeleteComment$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CommentsActions.deleteCommentOnThread),
        withLatestFrom(
          this.store.select(AuthenticationSelectors.selectSelectedUserId),
          this.store.select(CommentsSelectors.selectCurrentThreadComments),
          this.store.select(CommentsSelectors.selectUsersToMentionOnThread)
        ),
        concatMap(([action, userId, comments, usersToMention]) => {
          const eventUrl = `${window.location.origin}/private/events/event-exploration/${action.threadEvent.eventId}`;
          const commentToDelete = comments.find(
            comment => comment.id == action.commentId
          );
          const mentionedUsers = usersToMention.filter(user =>
            commentToDelete?.mentions?.includes(user.id)
          );
          const eventProperties = {
            user_id: userId,
            eventId: action.threadEvent.eventId,
            eventURL: eventUrl,
            type: action.threadEvent.type,
            severity: action.threadEvent.severity,
            numberOfComments: comments.length - 1,
            mentionedUser: mentionedUsers.map(user => user.email),
          };
          return of(sendMetricEvent('CommentDeleted', eventProperties));
        })
      );
    },
    { dispatch: false }
  );

  onVoteForInsight$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OverviewActions.updateVoteForInsight),
        concatMap(action => {
          return of(
            sendMetricEvent('InsightRated', {
              InsightTypeName: action.insight.title,
              InsightFeedback: action.insightVote.likeVote
                ? 'Helpful'
                : 'Unhelpful',
              InsightTimestamp: action.insight.dateUpdated.toString(),
              InsightText: stripHtmlTags(action.insight.content),
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  onReadInsight$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OverviewActions.insightArrowClicked),
        concatMap(action => {
          return of(
            sendMetricEvent('InsightSwitched', {
              InsightTypeName: action.insight.title,
              InsightTimestamp: action.insight.dateUpdated.toString(),
              InsightText: stripHtmlTags(action.insight.content),
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  onExploreInsight$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OverviewActions.linkOfInsightWasClicked),
        concatMap(action => {
          return of(
            sendMetricEvent('EventsExploredFromInsights', {
              InsightTimestamp: action.insight.dateUpdated.toString(),
              InsightText: stripHtmlTags(action.insight.content),
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  onReportsClicked$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ShipsActions.reportsClicked),
        concatMap(action => {
          return of(
            sendMetricEvent('ReportClicked', {
              DisplayedReports: action.displayedReports,
            })
          );
        })
      );
    },
    { dispatch: false }
  );
  onReportDownloaded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ShipsActions.reportDownloaded),
        concatMap(action => {
          return of(
            sendMetricEvent('ReportDownloaded', {
              ReportName: action.reportName,
            })
          );
        })
      );
    },
    { dispatch: false }
  );
  onReportViewed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ShipsActions.reportViewed),
        concatMap(action => {
          return of(
            sendMetricEvent('ReportViewed', {
              ReportName: action.reportName,
            })
          );
        })
      );
    },
    { dispatch: false }
  );
}
