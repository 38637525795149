import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-like-unlike',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './like-unlike.component.html',
  styleUrls: ['./like-unlike.component.scss'],
})
export class LikeUnlikeComponent {
  @Output() statusLikeChanged = new EventEmitter<boolean>();

  @Input() isLikeActive: boolean | null = null;
  @Input()
  unlikeText: string = 'Like';
  @Input()
  likeText: string = 'Unlike';

  updateLikeStatus(likeVote: boolean): void {
    this.isLikeActive = likeVote;
    this.statusLikeChanged.emit(likeVote);
  }
}
