import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import _ from 'lodash';
import { EasingOptions, MapOptions } from 'mapbox-gl';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ShipPosition } from 'src/app/core/models/map.models';
import { ShipSafteyScore } from 'src/app/core/models/ship.model';
import { VoyageModel } from 'src/app/core/pages/voyages/models/voyages.types';
import { FleetService } from 'src/app/core/services/fleet.service';
import { MapboxModule } from 'src/app/mapbox/mapbox.module';
import { MapboxLegendConfigurations } from 'src/app/mapbox/models/mapbox-legend.models';
import {
  MapboxMenuOption,
  MapboxMenuOptionGroup,
  MenuBotton,
} from 'src/app/mapbox/models/mapbox-menu.models';
import {
  EventMapEntity,
  MapboxEntityClickEvent,
  MapboxEntityConfiguration,
  MapboxSettingOption,
  MapLabel,
  PolygonEntity,
  ShipMapEntity,
} from 'src/app/mapbox/models/mapbox.models';
import { OutsideClickDetectorComponent } from 'src/app/shared/components/outside-click-detector/outside-click-detector.component';
import { COMPLIANCE_SETTING_ID } from '../../map-settings/compliance.setting';
import { CONTOUR_SETTING_ID } from '../../map-settings/countries-contour.setting';
import { EVENT_FOCUS_SETTING_ID } from '../../map-settings/event-focus.setting';
import { EVENT_SETTING_ID } from '../../map-settings/event.setting';
import { HUMIDITY_SETTING_ID } from '../../map-settings/humidity.setting';
import { LAND_SETTING_ID } from '../../map-settings/land.setting';
import { NO_GO_SETTING_ID } from '../../map-settings/no-go.setting';
import { PERCIPITATION_SETTING_ID } from '../../map-settings/percipitation.setting';
import { RT_EVENT_SETTING_ID } from '../../map-settings/rt-event.setting';
import { SHIP_FOCUS_SETTING_ID } from '../../map-settings/ship-focus.setting';
import { SHIP_LABEL_SETTING_ID } from '../../map-settings/ship-label.setting';
import { SHIP_TRAIL_SETTING_ID } from '../../map-settings/ship-trail.setting';
import { SHIP_SETTING_ID } from '../../map-settings/ship.setting';
import { TEMPERATURE_SETTING_ID } from '../../map-settings/temperature.setting';
import { WIND_SETTING_ID } from '../../map-settings/wind.setting';
import {
  MAP_ENTITIES_CONFIGURATIONS,
  MARITIME_MAP_OPTIONS,
} from '../../models/maritime-configurations.models';
import {
  ShipCardOuputs,
  ShipLastLocation,
  ShipScreenshot,
} from '../../models/ship.models';
import {
  MaritimeSettingsService,
  MENU_IDS,
} from '../../services/maritime-settings.service';
import { LayersMenuFormComponent } from '../layers-menu-form/layers-menu-form.component';
import { LayersMenuTitleComponent } from '../layers-menu-title/layers-menu-title.component';
import { ShipProfileCardComponent } from '../ship-profile-card/ship-profile-card.component';
import { WeatherMenuFormComponent } from '../weather-menu-form/weather-menu-form.component';
import { WeatherMenuTitleComponent } from '../weather-menu-title/weather-menu-title.component';

const DEFAULT_LEGEND_CONFIG: MapboxLegendConfigurations = {
  id: 'default',
  unitsName: '',
  legendTicks: [],
};

const DEFAULT_FOCUS_HEIGHT = 25000000;
const CARD_LATITUDE_BUFFER = 0.5;

@Component({
  selector: 'app-maritime-map',
  standalone: true,
  templateUrl: './maritime-map.component.html',
  styleUrls: ['./maritime-map.component.scss'],
  imports: [
    CommonModule,
    MapboxModule,
    OutsideClickDetectorComponent,
    WeatherMenuFormComponent,
    WeatherMenuTitleComponent,
    LayersMenuFormComponent,
    LayersMenuTitleComponent,
    ShipProfileCardComponent,
  ],
})
export class MaritimeMapComponent implements OnInit, OnDestroy {
  @Input() ships$: BehaviorSubject<ShipMapEntity[]> = new BehaviorSubject<
    ShipMapEntity[]
  >([]);
  @Input() events$: BehaviorSubject<EventMapEntity[]> = new BehaviorSubject<
    EventMapEntity[]
  >([]);
  @Input() rtEvents$: BehaviorSubject<MapLabel[]> = new BehaviorSubject<
    MapLabel[]
  >([]);
  @Input() noGoZones$: BehaviorSubject<PolygonEntity[]> = new BehaviorSubject<
    PolygonEntity[]
  >([]);
  @Input() eventZones$: BehaviorSubject<PolygonEntity[]> = new BehaviorSubject<
    PolygonEntity[]
  >([]);
  @Input() complianceZones$: BehaviorSubject<PolygonEntity[]> =
    new BehaviorSubject<PolygonEntity[]>([]);
  @Input() showShipLocationsByShipId$: BehaviorSubject<number | null> =
    new BehaviorSubject<number | null>(null);
  @Input() shipTrail$: BehaviorSubject<ShipLastLocation[] | null> =
    new BehaviorSubject<ShipLastLocation[] | null>(null);
  @Input() screenshots$: BehaviorSubject<ShipScreenshot[]> =
    new BehaviorSubject<ShipScreenshot[]>([]);
  @Input() shipsSafetyScores$: BehaviorSubject<ShipSafteyScore[]> =
    new BehaviorSubject<ShipSafteyScore[]>([]);
  @Input() token: string | undefined | null = undefined;

  @Input() selectedEvent$: BehaviorSubject<EventMapEntity | undefined> =
    new BehaviorSubject<EventMapEntity | undefined>(undefined);
  @Input() changePositon$: BehaviorSubject<ShipPosition | undefined> =
    new BehaviorSubject<ShipPosition | undefined>(undefined);
  @Input() minimalZoomLevel: number = MARITIME_MAP_OPTIONS.minZoom ?? 0;
  @Input() showLayersMenu = false;
  @Input() showWeatherMenu = true;
  @Input() showAreasMenu = false;
  @Input() hideControls = false;
  @Input() settingToShow?: string[];
  @Input() isCaptain = false;
  @Input() shipFocusLatitudeBuffer: number = CARD_LATITUDE_BUFFER;
  @Input() shipVoyages$: BehaviorSubject<VoyageModel[] | null> =
    new BehaviorSubject<VoyageModel[] | null>(null);
  @Output() selectedMapEvent = new EventEmitter<
    [entityId: string, entityType: string]
  >();
  @Output() deselectedMapEvent = new EventEmitter<void>();
  @Output() shipTooltipButtonClicked = new EventEmitter<number>();
  @Output() shipTooltipDismissButtonClicked = new EventEmitter<void>();

  // map settings
  mapSettings: MapboxSettingOption[] = [];
  entitiesConfiguration: MapboxEntityConfiguration[] =
    MAP_ENTITIES_CONFIGURATIONS;
  mapOptions: MapOptions = { ...MARITIME_MAP_OPTIONS };
  weatherSamplingTimeString$ = new BehaviorSubject<string>('');
  shipLastLocations$ = new BehaviorSubject<ShipLastLocation[]>([]);
  focusedShipScreenshot$ = new BehaviorSubject<ShipScreenshot | null>(null);
  focusedShip$ = new BehaviorSubject<ShipMapEntity | null>(null);
  focusedShipSafetyScores$ = new BehaviorSubject<ShipSafteyScore | null>(null);
  MENU_IDS = MENU_IDS;

  // map triggers
  flyTo$: BehaviorSubject<EasingOptions> = new BehaviorSubject<EasingOptions>({
    duration: 0,
    center: MARITIME_MAP_OPTIONS.center,
  });

  // legend settings
  legendConfig$: BehaviorSubject<MapboxLegendConfigurations> =
    new BehaviorSubject<MapboxLegendConfigurations>(DEFAULT_LEGEND_CONFIG);
  showLegend$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // menu settings
  menuToShow$: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);
  menuButtons: MenuBotton[] = [];
  lastUpdateTime$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    new Date(0)
  );
  weatherMenuToggle$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  layersFormOptionGroups$: BehaviorSubject<MapboxMenuOptionGroup[]> =
    new BehaviorSubject<MapboxMenuOptionGroup[]>([]);
  weatherMenuOptions: MapboxMenuOption[] = [];
  weatherSelection$: BehaviorSubject<MapboxMenuOption | undefined> =
    new BehaviorSubject<MapboxMenuOption | undefined>(undefined);

  // lifecycle resources
  private subscriptions: Subscription[] = [];
  private shipCardOuputs: ShipCardOuputs = {
    onButtonClicked$: new Subject<{ nagivationUrl: string; shipId: number }>(),
    onClose$: new Subject<void>(),
  };

  constructor(
    private settingService: MaritimeSettingsService,
    private router: Router,
    private fleetService: FleetService
  ) {
    this.weatherSamplingTimeString$.next(
      settingService.GetWeatherSamplingTimeString()
    );
    this.subscriptions.push(
      ...[
        this.legendConfig$.subscribe(config =>
          this.showLegend$.next(
            !_.isEmpty(config.legendTicks) && !_.isEmpty(config.unitsName)
          )
        ),
        this.shipCardOuputs.onButtonClicked$.subscribe(
          ({ nagivationUrl, shipId }) => {
            this.router.navigateByUrl(nagivationUrl);
            this.shipTooltipButtonClicked.emit(shipId);
          }
        ),
        this.shipCardOuputs.onClose$.subscribe(() => {
          this.shipTooltipDismissButtonClicked.emit();
          this.mapSettings
            .find(setting => setting.id === SHIP_FOCUS_SETTING_ID)
            ?.show$?.next(false);
          this.mapSettings
            .find(setting => setting.id === SHIP_TRAIL_SETTING_ID)
            ?.show$?.next(false);
          this.mapSettings
            .find(setting => setting.id === SHIP_LABEL_SETTING_ID)
            ?.show$?.next(true);
          this.focusedShip$.next(null);

          this.SelectGroupLayerSetting(
            this.layersFormOptionGroups$.getValue(),
            this.mapSettings
          );
          this.deselectedMapEvent.emit();
          this.mapSettings
            .find(setting => setting.id === SHIP_SETTING_ID)
            ?.show$.next(true);
        }),
      ]
    );
    setInterval(() => {
      this.weatherSamplingTimeString$.next(
        settingService.GetWeatherSamplingTimeString()
      );
    }, dayjs.duration(3, 'hour').asMilliseconds());
  }

  async ngOnInit(): Promise<void> {
    const changePositionDebounced = _.debounce(
      (position: ShipPosition | undefined) => {
        if (position) {
          if (position.shipId) {
            this.SetFocusedShip(position.shipId).then(() => {
              this.FocusOnShip();
            });
          } else {
            this.flyTo$.next(this.getFlightDestination(position));
          }
        } else {
          this.flyTo$.next({
            duration: 0,
            center: MARITIME_MAP_OPTIONS.center,
          });
        }
      },
      dayjs.duration(400, 'millisecond').asMilliseconds()
    );

    this.mapOptions.minZoom = this.minimalZoomLevel;
    const weatherSettingIds = this.settingService.GetWeatherSettingIds();
    const menusConfigurations = this.settingService.GetMenuSettings(
      this.showLayersMenu,
      this.showWeatherMenu,
      this.showAreasMenu
    );
    this.mapSettings = (
      await this.settingService.GetAllMapSettings(
        this.noGoZones$,
        this.complianceZones$,
        this.events$,
        this.rtEvents$,
        this.ships$,
        this.weatherSamplingTimeString$,
        this.selectedEvent$,
        this.shipLastLocations$,
        this.isCaptain,
        this.shipCardOuputs,
        this.focusedShipScreenshot$,
        this.focusedShip$,
        this.focusedShipSafetyScores$,
        this.eventZones$,
        this.shipVoyages$
      )
    ).filter(
      setting =>
        _.isEmpty(this.settingToShow) ||
        this.settingToShow?.includes(setting.id) ||
        weatherSettingIds.includes(setting.id)
    );
    if (this.settingToShow) {
      this.mapSettings.forEach(settingOption =>
        settingOption.show$.next(this.settingToShow!.includes(settingOption.id))
      );
    }
    this.lastUpdateTime$.next(await this.settingService.GetMapLastUpdateTime());
    this.menuButtons = menusConfigurations.menuButtons;
    this.layersFormOptionGroups$.next(
      menusConfigurations.layersFormOptionGroups
    );
    this.weatherMenuOptions = menusConfigurations.weatherMenuOptions;
    this.subscriptions.push(
      ...[
        this.weatherSelection$.subscribe(selection => {
          if (selection) {
            this.SelectWeatherSetting(selection.id);

            if (!this.weatherMenuToggle$.getValue()) {
              this.weatherMenuToggle$.next(true);
            }
          } else {
            this.SelectWeatherSetting(undefined);
          }
        }),
        this.layersFormOptionGroups$.subscribe(optionGroups =>
          this.SelectGroupLayerSetting(optionGroups, this.mapSettings)
        ),
        this.weatherMenuToggle$.subscribe(showWeather => {
          if (!showWeather) {
            this.weatherSelection$.next(undefined);
            return;
          }

          if (this.weatherSelection$.getValue()) {
            return;
          }

          this.weatherSelection$.next(_.first(this.weatherMenuOptions));
        }),
        this.changePositon$.subscribe(position =>
          changePositionDebounced(position)
        ),
        this.showShipLocationsByShipId$.subscribe(async id => {
          const shipTrailSetting = this.mapSettings.find(
            setting => setting.id === SHIP_TRAIL_SETTING_ID
          );
          if (id && shipTrailSetting) {
            await this.updateFocusedShip(id);
            shipTrailSetting?.show$.next(true);
          }
        }),
        this.shipTrail$.subscribe(trail => {
          const shipTrailSetting = this.mapSettings.find(
            setting => setting.id === SHIP_TRAIL_SETTING_ID
          );
          if (shipTrailSetting) {
            this.shipLastLocations$.next(trail ?? []);
            shipTrailSetting?.show$.next(true);
          }
        }),
      ]
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // click events handling
  async HandleEntityClicked(event: MapboxEntityClickEvent): Promise<void> {
    const entity = _.first(event.entities);
    switch (entity?.properties?.['category']) {
      case 'ship':
        const id = Number(entity.properties!['id']);
        await this.SetFocusedShip(id);
        this.FocusOnShip();
        this.selectedMapEvent.emit([id.toString(), 'ship']);
        break;
      case 'event':
        const eventId = entity.properties!['id'];
        const eventEntity = this.settingService.GetEventEntity(
          this.events$.getValue(),
          eventId
        );
        if (eventEntity) {
          await this.SetFocusedShip(eventEntity.shipId);
          this.FocusOnEvent(eventEntity);
          this.selectedMapEvent.emit([eventId, 'event']);
        }
        break;
      default:
        break;
    }
  }

  // menu events handling
  OnOutOfMenuClick(): void {
    this.menuToShow$.next(undefined);
  }

  OnMenuButtonClick(menuId: string): void {
    this.enableDefaultWeatherOptionOnToggle(menuId);

    // Toggle Menu
    this.menuToShow$.next(
      this.menuToShow$.getValue() === menuId ? undefined : menuId
    );
  }

  private enableDefaultWeatherOptionOnToggle(menuId: string) {
    if (menuId !== 'weather') {
      return;
    }

    // When toggling off just close the menu
    if (this.menuToShow$.getValue()) {
      return;
    }

    // If the user has already selected a value do not override
    if (this.weatherSelection$.getValue()) {
      return;
    }

    this.weatherMenuToggle$.next(true);
    this.weatherSelection$.next(_.first(this.weatherMenuOptions));
  }

  private SelectWeatherSetting(weatherOptionId: string | undefined): void {
    const weatherSettingOptionIds = this.weatherMenuOptions.map(
      option => option.id
    );
    this.mapSettings.forEach(setting => {
      if (
        setting.id !== weatherOptionId &&
        weatherSettingOptionIds.includes(setting.id)
      ) {
        setting.show$.next(false);
      }
      if (setting.id === weatherOptionId) {
        setting.show$.next(true);
      }
      if (setting.id === CONTOUR_SETTING_ID) {
        setting.show$.next(
          [
            WIND_SETTING_ID,
            HUMIDITY_SETTING_ID,
            TEMPERATURE_SETTING_ID,
            PERCIPITATION_SETTING_ID,
          ].includes(weatherOptionId!)
        );
      }
      if (setting.id === LAND_SETTING_ID) {
        setting.show$.next(!!weatherOptionId);
      }
    });
    weatherOptionId
      ? this.legendConfig$.next(
          this.settingService.GetLegendSettings(weatherOptionId)
        )
      : this.legendConfig$.next(DEFAULT_LEGEND_CONFIG);
  }
  private SelectGroupLayerSetting(
    optionGroups: MapboxMenuOptionGroup[],
    settings: MapboxSettingOption[]
  ): void {
    optionGroups
      .flatMap(group => group.options)
      .forEach(option => {
        settings
          .find(setting => setting.id === option.settingsOptionId)
          ?.show$.next(option.isChecked);
      });
  }
  private async SetFocusedShip(shipId: number) {
    const shipFocusSetting = this.mapSettings.find(
      setting => setting.id === SHIP_FOCUS_SETTING_ID
    );
    const shipTrailSetting = this.mapSettings.find(
      setting => setting.id === SHIP_TRAIL_SETTING_ID
    );
    if (shipTrailSetting || shipFocusSetting) {
      await this.updateFocusedShip(shipId);

      shipFocusSetting?.show$.next(true);
      shipTrailSetting?.show$.next(true);
      if (!this.settingToShow) {
        this.mapSettings
          .filter(setting =>
            [
              SHIP_LABEL_SETTING_ID,
              SHIP_SETTING_ID,
              EVENT_SETTING_ID,
              RT_EVENT_SETTING_ID,
              NO_GO_SETTING_ID,
              COMPLIANCE_SETTING_ID,
            ].includes(setting.id)
          )
          .forEach(setting => setting.show$.next(false));
      }
    }
  }
  private FocusOnShip() {
    const ship = this.focusedShip$.getValue();
    if (ship) {
      let position: ShipPosition = {
        long: ship.long,
        lat: ship.lat + this.shipFocusLatitudeBuffer,
      };
      this.mapSettings
        .find(setting => setting.id === SHIP_SETTING_ID)
        ?.show$.next(false);
      if (
        !this.settingToShow ||
        !this.settingToShow.includes(SHIP_FOCUS_SETTING_ID)
      ) {
        position = { ...position, height: DEFAULT_FOCUS_HEIGHT };
      }
      this.flyTo$.next(this.getFlightDestination(position));
    }
  }
  private FocusOnEvent(eventEntity: EventMapEntity) {
    const eventFocusSetting = this.mapSettings.find(
      setting => setting.id === EVENT_FOCUS_SETTING_ID
    );
    if (!eventFocusSetting) {
      return;
    }
    this.selectedEvent$.next(eventEntity);
    eventFocusSetting.show$.next(true);
    const position = {
      long: eventEntity.long,
      lat: eventEntity.lat,
      height: DEFAULT_FOCUS_HEIGHT,
    };
    this.flyTo$.next(this.getFlightDestination(position));
  }
  private getFlightDestination(position: ShipPosition): EasingOptions {
    return {
      duration: dayjs.duration(1, 'second').asMilliseconds(),
      center: [position?.long, position?.lat],
      zoom: (40000000 - (position?.height ?? DEFAULT_FOCUS_HEIGHT)) / 2000000,
    };
  }
  private async updateFocusedShip(shipId: number) {
    const ship = this.ships$.getValue().find(ship => ship.shipId === shipId);
    const locations =
      this.shipTrail$.getValue() ??
      (await this.fleetService.getShipLastLocations$(shipId, new Date()));
    const score = this.shipsSafetyScores$
      .getValue()
      .find(score => score.shipId === shipId);

    const screenshot = _.first(
      this.screenshots$
        .getValue()
        .filter(screenShot => screenShot.shipId === shipId)
        .map(screenShot => ({
          ...screenShot,
          cdnUrl: `${screenShot.cdnUrl}?jwt=${this.token}`,
        }))
        .sort(
          (screenShotA, screenShotB) =>
            new Date(screenShotA.timestamp).getTime() -
            new Date(screenShotB.timestamp).getTime()
        )
    );
    this.shipLastLocations$.next(locations);
    this.focusedShipSafetyScores$.next(score ?? null);
    this.focusedShipScreenshot$.next(screenshot ?? null);
    this.focusedShip$.next(ship ?? null);
  }
}
