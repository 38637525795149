import _ from 'lodash';
import { GeoJSONFeature } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import {
  EventMapEntity,
  MapboxCustomSetting,
  MapboxSettingType,
  ShipMapEntity,
} from 'src/app/mapbox/models/mapbox.models';

export const EVENT_FOCUS_SETTING_ID = 'EventFocus';

export async function CreateEventFocusSetting(
  events$: BehaviorSubject<EventMapEntity[]>,
  selectedEvent$: BehaviorSubject<EventMapEntity | undefined>,
  focusedShip$: BehaviorSubject<ShipMapEntity | null>
): Promise<MapboxCustomSetting> {
  const getEventFeatures = (): GeoJSONFeature[] => {
    const selectedEventId = selectedEvent$.getValue()?.id;
    const selectedEvent = selectedEventId
      ? events$
          .getValue()
          .find(
            event =>
              event.id === selectedEventId &&
              (focusedShip$.getValue()
                ? event.shipId === focusedShip$.getValue()?.shipId
                : true)
          )
      : undefined;
    return !selectedEvent
      ? []
      : [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [selectedEvent.long, selectedEvent.lat],
            },
            source: 'event-focus',
            properties: {
              id: selectedEvent.id,
              icon: `/assets/map/events/${_.snakeCase(
                selectedEvent.eventType
              )}/${_.snakeCase(selectedEvent.severity)}.png`,
              category: 'event',
            },
          },
        ];
  };

  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'event-focus',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getEventFeatures(),
          },
        },
        updateFeatures: () => getEventFeatures(),
      },
    ],
    layers: [
      {
        id: 'event-focus',
        type: 'symbol',
        source: 'event-focus',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      },
    ],
    popups: [],
  };
}
