import { BehaviorSubject } from 'rxjs';
import {
  MapboxLegendConfigurations,
  MapboxLegendTick,
} from 'src/app/mapbox/models/mapbox-legend.models';
import {
  MapboxCustomSetting,
  MapboxSettingType,
} from 'src/app/mapbox/models/mapbox.models';
import { environment } from 'src/environments/environment';

export const WAVE_LEGEND_TICKS: MapboxLegendTick[] = [
  { value: 0, color: 'transparent' },
  { value: 0.3, color: '#dde3ed' },
  { value: 0.5, color: '#c4d7e9' },
  { value: 1, color: '#a5c2e2' },
  { value: 1.5, color: '#88dcc6' },
  { value: 2, color: '#a5f5ae' },
  { value: 2.5, color: '#efe976' },
  { value: 3, color: '#efc177' },
  { value: 4, color: '#e8a07d' },
  { value: 5, color: '#ed7579' },
  { value: 6, color: '#d2777c' },
  { value: 7, color: '#d776e1' },
  { value: 8, color: '#b276c5' },
  { value: 9, color: '#d9dfe3' },
  { value: 10, color: '#bcc0c3' },
  { value: 11, color: '#969b9f' },
];

export const WAVE_LEGEND_CONFIG: MapboxLegendConfigurations = {
  id: 'wave-swell',
  unitsName: 'm',
  legendTicks: WAVE_LEGEND_TICKS,
};

export const WAVE_SWELL_SETTING_ID = 'Waves (Swell)';

export const WAVE_SIGNIFICANT_SETTING_ID = 'Waves (Significant)';

export const GetWavesSwellSetting = (
  sampleDateString$: BehaviorSubject<string>
): MapboxCustomSetting => {
  const getTileSource = () => [
    `${
      environment.meteoblue.tilesUrl
    }/vector/MFWAM/${sampleDateString$.getValue()}/waveColortable~109~sfc~hourly~none~contourSteps~-0.1~0.3~0.5~1.0~1.5~2.0~2.5~3.0~4.0~5.0~6.0~7.0~8.0~9.0~10.0~11.0_arrowsswell~102~sfc~hourly~none~windArrows/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=km%2Fh&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${
      environment.meteoblue.apiKey
    }`,
  ];
  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'wave-swell-vector-tile-source',
        source: {
          type: 'vector',
          tiles: getTileSource(),
        },
        updateTilesSource: getTileSource,
      },
    ],
    layers: [
      {
        id: 'wave-swell-height-fill',
        source: 'wave-swell-vector-tile-source',
        'source-layer': 'waveColortable',
        type: 'fill',
        paint: {
          'fill-antialias': false,
          'fill-opacity': 0.75,
          'fill-color': [
            'interpolate',
            ['linear'],
            ['get', 'minValue'],
            ...WAVE_LEGEND_TICKS.flatMap(item => [item.value, item.color]),
          ],
        },
        filter: ['all', ['==', '$type', 'Polygon']],
      },
      {
        id: 'wave-swell-height-line',
        type: 'line',
        source: 'wave-swell-vector-tile-source',
        'source-layer': 'arrowsswell',
        paint: {
          'line-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            'black',
            '#848884',
          ],
          'line-width': {
            stops: [
              [0, 1.5],
              [22, 2.5],
            ],
          },
          'line-opacity': 0.8,
        },
        filter: ['all', ['==', '$type', 'LineString']],
      },
    ],
    popups: [],
  };
};

export const GetWavesSignificantSetting = (
  sampleDateString$: BehaviorSubject<string>
): MapboxCustomSetting => {
  const getTileSource = () => [
    `${
      environment.meteoblue.tilesUrl
    }/vector/MFWAM/${sampleDateString$.getValue()}/waveColortable~100~sfc~hourly~none~contourSteps~-0.1~0.3~0.5~1.0~1.5~2.0~2.5~3.0~4.0~5.0~6.0~7.0~8.0~9.0~10.0~11.0_arrowssignificant~105~sfc~hourly~none~windArrows/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=kn&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${
      environment.meteoblue.apiKey
    }`,
  ];

  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'wave-significant-vector-tile-source',
        source: {
          type: 'vector',
          tiles: getTileSource(),
        },
        updateTilesSource: getTileSource,
      },
    ],
    layers: [
      {
        id: 'wave-significant-height-fill',
        source: 'wave-significant-vector-tile-source',
        'source-layer': 'waveColortable',
        type: 'fill',
        paint: {
          'fill-antialias': false,
          'fill-opacity': 0.75,
          'fill-color': [
            'interpolate',
            ['linear'],
            ['get', 'minValue'],
            ...WAVE_LEGEND_TICKS.flatMap(tick => [tick.value, tick.color]),
          ],
        },
        filter: ['all', ['==', '$type', 'Polygon']],
      },
      {
        id: 'wave-significant-height-line',
        type: 'line',
        source: 'wave-significant-vector-tile-source',
        'source-layer': 'arrowssignificant',
        paint: {
          'line-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            'black',
            '#848884',
          ],
          'line-width': {
            stops: [
              [0, 1.5],
              [22, 2.5],
            ],
          },
          'line-opacity': 0.8,
        },
        filter: ['all', ['==', '$type', 'LineString']],
      },
    ],
    popups: [],
  };
};
