import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-module-feature-expired-dialog',
  templateUrl: './module-feature-expired-dialog.component.html',
  styleUrls: ['./module-feature-expired-dialog.component.scss'],
  standalone: true,
})
export class ModuleFeatureExpiredDialogComponent {
  constructor(
    private location: Location,
    public dialogRef: MatDialogRef<ModuleFeatureExpiredDialogComponent>
  ) {}

  goBack(): void {
    this.location.back();
  }
}
