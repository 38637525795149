import { Expose, plainToClass, Transform } from 'class-transformer';
import 'reflect-metadata';
import { formatNumber } from 'src/app/shared/utils/calculators/general';
import {
  InsightDataType,
  InsightTypeClassificationViewNames,
  InsightTypeFromatFromDto,
} from 'src/app/shared/view-models/insight.view.model';

export type InsightType =
  | 'ShipWithMostEventsInsight'
  | 'HighDuringLastWeekInsight';

export class InsightDataDto {
  @Expose({ name: 'insight_id' })
  insightId!: string;

  @Expose({ name: 'fleet_id' })
  fleetId!: number;

  @Expose({ name: 'insight_type' })
  @Transform(
    ({ value }) =>
      InsightTypeFromatFromDto[value as InsightTypeClassificationViewNames]
  )
  insightType!: InsightType;

  @Expose({ name: 'last_updated_at' })
  lastUpdatedAt!: string;

  @Expose({ name: 'insight_data' })
  @Transform(({ value, obj }) => {
    const insightType =
      InsightTypeFromatFromDto[
        obj.insight_type as InsightTypeClassificationViewNames
      ];
    switch (insightType) {
      case InsightTypeFromatFromDto.ship_wmost_events:
        return plainToClass(ShipWithMostEventsInsight, value);
      case InsightTypeFromatFromDto.high_during_lst_week:
        return plainToClass(HighDuringLastWeekInsight, value);
      default:
        return value;
    }
  })
  insightData!: InsightDataType;

  @Expose({ name: 'read_status' })
  readStatus!: boolean;

  @Expose({ name: 'like_vote' })
  likeVote!: boolean | null;
}

export class ShipWithMostEventsInsight {
  @Expose({ name: 'fleet_name' })
  fleetName!: string;

  @Expose({ name: 'outstand_ship_name' })
  outsandShipName!: string;

  @Expose({ name: 'outstand_ship_id' })
  outsandShipId!: number;

  @Expose({ name: 'outstand_ship_num_of_events_per_1000_nm' })
  outstandShipNumOfEventsPer1000Nm!: number;

  @Expose({ name: 'outstand_ship_total_num_of_events' })
  outstandShipTotalNumOfEvents!: number;

  @Expose({ name: 'fleet_avg_num_of_event_per_1000_nm' })
  fleetAvgNumOfEventPer1000Nm!: number;

  get formattedOutstandShipNumOfEventsPer1000Nm(): string {
    return formatNumber(this.outstandShipNumOfEventsPer1000Nm, 2);
  }

  get formattedOutstandShipTotalNumOfEvents(): string {
    return formatNumber(this.outstandShipTotalNumOfEvents, 2);
  }

  get formattedFleetAvgNumOfEventPer1000Nm(): string {
    return formatNumber(this.fleetAvgNumOfEventPer1000Nm, 2);
  }
}

export class HighDuringLastWeekInsight {
  @Expose({ name: 'num_of_commissioned_ships' })
  numOfCommissionedShips!: number;

  @Expose({ name: 'num_of_severity_events' })
  numOfSeverityEvents!: number;

  @Expose({ name: 'avg_num_of_high_events_per_ship' })
  avgNumOfHighEventsPerShip!: number;

  @Expose({ name: 'benchmark_avg_num_of_high_events_per_ship' })
  benchmarkAvgNumOfHighEventsPerShip!: number;

  get formattedAvgNumOfHighEventsPerShip(): string {
    return formatNumber(this.avgNumOfHighEventsPerShip, 2);
  }

  get formattedBenchmarkAvgNumOfHighEventsPerShip(): string {
    return formatNumber(this.benchmarkAvgNumOfHighEventsPerShip, 2);
  }
}

export class InsightReadDto {
  @Expose({ name: 'read_id' })
  readId!: string;

  @Expose({ name: 'insight_id' })
  insightId!: string;

  @Expose({ name: 'user_id' })
  userId!: string;

  @Expose({ name: 'last_read_time' })
  lastReadTime!: Date;
}

export class InsightVoteDto {
  @Expose({ name: 'vote_id' })
  voteId!: string;

  @Expose({ name: 'insight_id' })
  insightId!: string;

  @Expose({ name: 'user_id' })
  userId!: string;

  @Expose({ name: 'last_vote_time' })
  lastVoteTime!: Date;

  @Expose({ name: 'like_vote' })
  likeVote!: boolean;
}
