import { createSelector } from '@ngrx/store';
import _ from 'lodash';
import { DateGraphFilterKeys } from 'src/app/shared/view-models/dates.view.model';
import { CoreState } from '../state/core.state';
import { FuelOptimizationState } from '../state/fuel-optimization.state';

const selectFuelOptimizationState = (state: CoreState): FuelOptimizationState =>
  state.fuelOptimization;

export const selectPotentialSavingsGraphSelectedDate = createSelector(
  selectFuelOptimizationState,
  state => {
    const filter = state.potentialSavingsGraphSelectedDate;

    const dateFilterKey = _.first(Object.keys(filter)) as DateGraphFilterKeys;
    const dates = filter[dateFilterKey]!;
    return {
      ...dates,
      startDate: new Date(dates.startDate.setUTCHours(0, 0, 0, 0)),
    };
  }
);

export const selectPotentialSavingsGraphSelectedDateKey = createSelector(
  selectFuelOptimizationState,
  state =>
    _.first(
      Object.keys(state.potentialSavingsGraphSelectedDate)
    ) as DateGraphFilterKeys
);

export const selectAccelerationTimeGraphSelectedDate = createSelector(
  selectFuelOptimizationState,
  state => {
    const filter = state.accelerationTimeGraphSelectedDate;

    const dateFilterKey = _.first(Object.keys(filter)) as DateGraphFilterKeys;
    const dates = filter[dateFilterKey]!;
    return {
      ...dates,
      startDate: new Date(dates.startDate.setUTCHours(0, 0, 0, 0)),
    };
  }
);
export const selectAccelerationTimeGraphSelectedDateKey = createSelector(
  selectFuelOptimizationState,
  state =>
    _.first(
      Object.keys(state.accelerationTimeGraphSelectedDate)
    ) as DateGraphFilterKeys
);
