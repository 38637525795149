import { GeoJSONFeature } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { RtEvent } from 'src/app/core/view-models/event.view.model';
import {
  MapboxCustomSetting,
  MapboxSettingType,
} from 'src/app/mapbox/models/mapbox.models';
import { ShipLastLocation } from '../models/ship.models';

export const SHIP_TRAIL_SETTING_ID = 'ShipTrail';

export async function CreateShipTrailSetting(
  locations$: BehaviorSubject<ShipLastLocation[]>,
  shipCurrentLocation$: BehaviorSubject<ShipLastLocation | null>,
  itsRtEvent: RtEvent | null
): Promise<MapboxCustomSetting> {
  const getTrailPointsFeatures = () => {
    const locations = locations$.getValue();
    const shipLocation = shipCurrentLocation$.getValue();
    if (shipLocation) {
      if (itsRtEvent === null) {
        locations.push(shipLocation);
      } else {
        locations.push({
          latitude: itsRtEvent.lat,
          longitude: itsRtEvent.long,
          sog: itsRtEvent.sog,
          time: itsRtEvent.start.toISOString(),
        });
      }
    }

    return locations.map(
      (location: ShipLastLocation): GeoJSONFeature => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [location.longitude, location.latitude],
        },
        source: 'point',
        properties: {
          latitude: location.latitude,
          longitude: location.longitude,
          sog: location.sog,
          time: location.time,
          textLabel: `${location.time}\n${location.sog} Knts`,
          labelType: 'white',
        },
      })
    );
  };

  const getTrailLineFeatures = (): GeoJSONFeature[] => {
    const locations = locations$.getValue();
    const shipLocation = shipCurrentLocation$.getValue();

    if (shipLocation) {
      if (itsRtEvent === null) {
        locations.push(shipLocation);
      } else {
        locations.push({
          latitude: itsRtEvent.lat,
          longitude: itsRtEvent.long,
          sog: itsRtEvent.sog,
          time: itsRtEvent.start.toISOString(),
        });
      }
    }

    return [
      {
        type: 'Feature',
        source: 'dashed_line',
        geometry: {
          type: 'LineString',
          coordinates: locations.map((location: ShipLastLocation) => [
            location.longitude,
            location.latitude,
          ]),
        },
        properties: {},
      },
    ];
  };

  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'point',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getTrailPointsFeatures(),
          },
        },
        updateFeatures: getTrailPointsFeatures,
      },
      {
        sourceId: 'dashed_line',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getTrailLineFeatures(),
          },
        },
        updateFeatures: getTrailLineFeatures,
      },
    ],
    layers: [
      {
        id: 'point',
        type: 'circle',
        source: 'point',
        paint: {
          'circle-radius': 4,
          'circle-color': '#5B588C',
        },
      },
      {
        id: 'dashed_line',
        type: 'line',
        source: 'dashed_line',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#5B588C',
          'line-width': 1,
          'line-dasharray': [2, 2],
        },
      },
    ],
    popups: [],
  };
}
