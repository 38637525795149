import { createAction, props } from '@ngrx/store';
import { InsightCard } from 'src/app/shared/view-models/insight.view.model';
import { Event, EventLocation, EventsData } from '../../models/events.model';
import { InsightReadDto, InsightVoteDto } from '../../models/insights.models';
import { ShipPosition } from '../../models/map.models';
import { Ship, ShipSafetyScores } from '../../models/ship.model';
import {
  Events,
  Polygon,
  ShipLastLocation,
} from '../../view-models/event.view.model';

export const saveOverviewEventsLocationOnChange = createAction(
  '[Overview Effects] Save overview events location on changes',
  props<{ eventsLocation: EventLocation[] }>()
);

export const saveOverviewInsightsOnChange = createAction(
  '[Overview Effects] Save overview insights on changes',
  props<{ insights: InsightCard[] }>()
);

export const resetSelectedShipDataOnShipsChange = createAction(
  '[Overview Effects] Reset selected ship data on list ships changes'
);

export const selectShipOnShipMapClick = createAction(
  '[Overview page] select ship on ship map click',
  props<{ shipId: Number }>()
);

export const selectShipOnEventMapClick = createAction(
  '[Overview page] select ship on event map click',
  props<{ shipId: Number }>()
);

export const setSelectedShip = createAction(
  '[Overview Effect] Set selected ship',
  props<{ selectedShip: Ship }>()
);
export const setShipSafetyScoresOnShipMapClick = createAction(
  '[Overview Effect] Set ship safety scores on ship map click',
  props<{ shipSafetyScores: ShipSafetyScores }>()
);

export const saveOverviewShipLastLocations = createAction(
  '[Overview Effect] Save selected ship last locations',
  props<{ selectedShipLastLocations: ShipLastLocation[] }>()
);

export const saveOverviewPolygons = createAction(
  '[Overview Effect] Save ship polygons',
  props<{ polygons: Polygon[] }>()
);

export const getSelectedShipEventsData = createAction(
  '[Overview Effect] Get selected ship event data',
  props<{ ship: Ship | null }>()
);

export const saveSelectedShipEventsData = createAction(
  '[Overview Effect] Save selected ship event data',
  props<{ selectedShipEventsData: Partial<EventsData> }>()
);

export const setSelectedOverviewEventList = createAction(
  '[Overview Page] Set selected event on user click on overview event list',
  props<{ selectedEvent: Events }>()
);

export const selectEventOnEventMapClick = createAction(
  '[Overview page] select event on event map click',
  props<{ eventId: String }>()
);

export const setSelectedOverviewMapEvent = createAction(
  '[Overview Map] Set selected event on user click on overview map event',
  props<{ selectedEvent: Events }>()
);

export const updateSelectedEventOnMapSelectedEvent = createAction(
  '[Overview Effects] Update selected event on map selected event',
  props<{ event: Events }>()
);

export const getMoreEventsOnUserScroll = createAction(
  '[Overview Page] User scrolled events list'
);

export const resetSelectedShipIdOnCloseTooltip = createAction(
  '[Ship Tooltip] Reset selcted ship'
);

export const addMoreEventsAfterScroll = createAction(
  '[Overview Effects] Fetched more events after user scrolled',
  props<{ events: Event[] }>()
);

export const reachedEndOfEvents = createAction(
  '[Overview Effects] Reached end of events'
);

export const resetPagination = createAction(
  '[Overview Effects] Reset pagination on filter change'
);

export const updateScrollToTopByUser = createAction(
  '[Overview Page] Update scroll to top after user scroll'
);

export const resetScroll = createAction(
  '[Overview Effects] reset scroll to top on filters change'
);

export const updateMapPositionOnSelectEntity = createAction(
  '[Overview Effects] update map position on select entity',
  props<{ position: ShipPosition }>()
);
export const setSelectedShipIdOnShipProfileClickOnMap = createAction(
  '[Map Tooltip] Save ship id on ship profile button map click',
  props<{ shipId: number }>()
);

export const setVoteForInsight = createAction(
  '[Overview Page] Update Vote For Insight',
  props<{ insight: InsightCard; likeVote: boolean }>()
);

export const updateVoteForInsight = createAction(
  '[Overview Effects] Update Vote For Insight',
  props<{ insightVote: InsightVoteDto; insight: InsightCard }>()
);

export const insightLoad = createAction(
  '[Overview Effect] Insight Load',
  props<{ insight: InsightCard }>()
);

export const insightArrowClicked = createAction(
  '[Overview Page] User click on arrow to get next or prev insight',
  props<{ insight: InsightCard }>()
);

export const updateReadStatusForInsight = createAction(
  '[Overview Effects] Update readStatus For Insight',
  props<{ insightRead: InsightReadDto }>()
);

export const linkOfInsightWasClicked = createAction(
  '[Overview Page] Link of insight was clicked',
  props<{ insight: InsightCard }>()
);
