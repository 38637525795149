import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeatureFlagService } from '../../services/feature-toggle.service';
import { RoutingService } from '../../services/routing.service';
import { UtilService } from '../../services/util.service';
import { CoreState } from '../../store/state/core.state';
import {
  AuthenticationActions,
  AuthenticationSelectors,
} from '../../store/types';
import { NavItem } from '../../view-models/nav.view-model';
import { Breadcrumb } from '../../view-models/routes.view.model';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  tabs: NavItem[] = [
    {
      iconName: 'overview',
      navName: 'overview',
      show: true,
    },
    {
      iconName: 'events',
      navName: 'events',
      show: true,
    },
    {
      iconName: 'fleet',
      navName: 'fleet',
      show: true,
    },
    {
      iconName: 'live',
      navName: 'live',
      show: true,
      new: true,
    },
    {
      iconName: 'efficiency',
      navName: 'fuel',
      show: true,
      new: true,
    },
  ];

  @Input()
  userName$!: Observable<string>;
  breadcrumbs$!: Observable<Breadcrumb[]>;
  isOpen$!: Observable<boolean>;
  date$!: Observable<Date>;
  isUserAdmin$!: Observable<boolean | null>;
  tabName$!: Observable<string>;
  version = environment.version;
  isContainerHovered: boolean = false;

  constructor(
    private routing: RoutingService,
    private util: UtilService,
    private store: Store<CoreState>,
    private featureFlag: FeatureFlagService
  ) {}

  async ngOnInit(): Promise<void> {
    this.breadcrumbs$ = this.routing.breadcrumbs$;
    this.isOpen$ = this.routing.isOverviewPage();

    this.date$ = this.util.time$;

    this.userName$ = this.store.select(
      AuthenticationSelectors.selectAuthenticatedUserName
    );

    this.isUserAdmin$ = this.store.select(
      AuthenticationSelectors.selectIsUserOrcaAdmin
    );

    this.tabName$ = this.breadcrumbs$.pipe(
      map(breadcrumbs => breadcrumbs[0].label.split(' ')[0].toLowerCase())
    );

    const isUserCaptain = await firstValueFrom(
      this.store
        .select(AuthenticationSelectors.selectIsUserShipCaptain)
        .pipe(filter(user => user != null))
    );

    const showEfficiency = await firstValueFrom(
      this.featureFlag.getFeatureFlag$('efficiency')
    );

    this.tabs = [
      ...this.tabs.slice(0, 3),
      {
        iconName: 'live',
        navName: 'live',
        show: isUserCaptain ? false : true,
        new: true,
      },
      {
        iconName: 'fuel',
        navName: 'fuel',
        show: showEfficiency,
        new: true,
      },
    ];
  }

  logout(): void {
    this.store.dispatch(AuthenticationActions.logoutOnUserClick());
  }
}
