import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Data,
  NavigationEnd,
  ParamMap,
  Router,
} from '@angular/router';
import { BehaviorSubject, filter, map, Observable, of, startWith } from 'rxjs';
import { Breadcrumb } from '../view-models/routes.view.model';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  private history: string[] = [];
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(_ => {
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: Breadcrumb[] = [];
        this.addBreadcrumb(root, [], breadcrumbs);
        this._breadcrumbs$.next(breadcrumbs);
      });
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe(event => {
        this.history.push(event.urlAfterRedirects);
      });
  }

  private addBreadcrumb(
    route: ActivatedRouteSnapshot | null,
    parentUrl: string[],
    breadcrumbs: Breadcrumb[]
  ): void {
    if (route) {
      // Construct the route URL
      const routeUrl = parentUrl.concat(route.url.map(url => url.path));

      // Add an element for the current route part
      if (route.data['breadcrumb']) {
        const breadcrumb = {
          label: this.getLabel(route.data),
          url: '/' + routeUrl.join('/'),
        };
        if (!breadcrumbs.find(crumb => crumb.label === breadcrumb.label)) {
          breadcrumbs.push(breadcrumb);
        }
      }

      // Add another element for the next route part
      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  private getLabel(data: Data) {
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    return typeof data['breadcrumb'] === 'function'
      ? data['breadcrumb'](data)
      : data['breadcrumb'];
  }

  getSubtitle(): Observable<string> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(null),
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route.snapshot.data['subtitle'] || '';
      })
    );
  }

  isOverviewPage(): Observable<boolean> {
    return this.breadcrumbs$.pipe(
      map(
        breadcrumbs =>
          breadcrumbs.length === 1 && breadcrumbs[0].label === 'Overview'
      )
    );
  }

  isLiveNewTab$(): Observable<boolean> {
    return of(this.router.url.includes('/live?newTab=true'));
  }

  getCurrentPage$(): Observable<string> {
    return this.breadcrumbs$.pipe(
      filter(breadcrumbs => breadcrumbs.length > 0),
      map(breadcrumbs => breadcrumbs[breadcrumbs.length - 1].label)
    );
  }

  getCurrentShipId$(): Observable<number> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.activatedRoute.root;
        while (route.firstChild) route = route.firstChild;
        return +route.snapshot.params['shipId'];
      }),
      filter(shipId => !isNaN(shipId))
    );
  }

  getCurrentPageQueryParams$(): Observable<ParamMap> {
    return this.activatedRoute.queryParamMap.pipe(
      map(params => {
        return params;
      })
    );
  }

  shouldShowFleetAndTimeDropdowns(): Observable<boolean> {
    return this.breadcrumbs$.pipe(
      map(
        breadcrumbs =>
          breadcrumbs.some(crumb => crumb.label === 'Ship') ||
          !(
            breadcrumbs.some(crumb => crumb.label === 'Live View') ||
            breadcrumbs.some(crumb => crumb.label === 'Voyages')
          )
      )
    );
  }

  shouldShowFleetDropdowns(): Observable<boolean> {
    return of(true);
  }

  getPreviousUrl(): string {
    return this.history.length > 1
      ? this.history[this.history.length - 2]
      : '/';
  }
}
