import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, map, mergeMap, switchMap, withLatestFrom } from 'rxjs';
import { CommentsService } from '../../services/comments.service';
import { getEventExplorationUrl } from '../../utils/events';
import { CoreState } from '../state/core.state';
import { AuthenticationSelectors, CommentsActions } from '../types';

@Injectable()
export class CommentsEffects {
  constructor(
    private actions$: Actions,
    private commentsService: CommentsService,
    private platformLocation: PlatformLocation,
    private store: Store<CoreState>
  ) {}

  getThreadDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommentsActions.getThreadDetailsRequested),
      // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
      mergeMap(({ threadId }) => [
        CommentsActions.getUsersOfCurrentThread({ threadId }),
        CommentsActions.getCommentsOfCurrentThread({ threadId }),
      ])
    );
  });

  getUsersOfCurrentThread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommentsActions.getCommentsOfCurrentThread),
      switchMap(action =>
        from(this.commentsService.getUsersByThreadId(action.threadId)).pipe(
          map(users =>
            CommentsActions.setUsersOfCurrentThreadInStore({
              users: users,
            })
          )
        )
      )
    );
  });

  getCommentsOfCurrentThread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommentsActions.getCommentsOfCurrentThread),
      switchMap(action =>
        from(this.commentsService.getCommentsByThreadId(action.threadId)).pipe(
          map(comments =>
            CommentsActions.setCommentsOfCurrentThreadInStore({
              comments: comments,
            })
          )
        )
      )
    );
  });

  addCommentToThread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommentsActions.addCommentToThread),
      withLatestFrom(
        this.store.select(AuthenticationSelectors.selectAuthenticatedUser)
      ),
      switchMap(([action, user]) =>
        this.commentsService
          .addComment(action.threadEvent.eventId, {
            content: action.content,
            linkUrl: this.platformLocation.href,
            mentions: action.mentions,
            mixpanelContext: {
              userId: user!.id,
              eventId: action.threadEvent.eventId,
              eventUrl: getEventExplorationUrl(
                window.location.origin,
                action.threadEvent.eventId
              ),
              type: action.threadEvent.type,
              severity: action.threadEvent.severity,
            },
          })
          .pipe(
            map(_ => {
              return CommentsActions.getCommentsOfCurrentThread({
                threadId: action.threadEvent.eventId,
              });
            })
          )
      )
    );
  });

  editCommentToThread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommentsActions.editCommentOnThread),
      switchMap(action =>
        from(
          this.commentsService.updateComment(
            action.threadEvent.eventId,
            action.commentId,
            {
              content: action.content,
              linkUrl: this.platformLocation.href,
            }
          )
        ).pipe(
          map(_ =>
            CommentsActions.getCommentsOfCurrentThread({
              threadId: action.threadEvent.eventId,
            })
          )
        )
      )
    );
  });

  deleteCommentToThread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommentsActions.deleteCommentOnThread),
      switchMap(action =>
        from(
          this.commentsService.deleteComment(
            action.threadEvent.eventId,
            action.commentId
          )
        ).pipe(
          map(_ =>
            CommentsActions.getCommentsOfCurrentThread({
              threadId: action.threadEvent.eventId,
            })
          )
        )
      )
    );
  });
}
