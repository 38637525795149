import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import {
  concatMap,
  filter,
  firstValueFrom,
  from,
  of,
  switchMap,
  take,
} from 'rxjs';
import { EventsService } from '../services/events.service';
import { CoreState } from '../store/state/core.state';
import {
  AuthenticationSelectors,
  CommentsActions,
  EventsActions,
  EventsSelectors,
} from '../store/types';
import { mapEventToEventsType } from '../utils/events';
import { Events } from '../view-models/event.view.model';

@Injectable({
  providedIn: 'root',
})
export class EventExplorationResolver
  implements Resolve<Promise<Events | null>>
{
  constructor(
    private eventService: EventsService,
    private store: Store<CoreState>,
    private router: Router
  ) {}
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const eventId = route.params['eventId'];

    this.store
      .select(AuthenticationSelectors.selectAuthenticatedUser)
      .pipe(
        filter(authUser => authUser?.token !== ''),
        take(1)
      )
      .subscribe(() =>
        this.store.dispatch(
          CommentsActions.getThreadDetailsRequested({ threadId: eventId })
        )
      );

    const events = await firstValueFrom(
      this.store.select(AuthenticationSelectors.selectAuthenticatedUser).pipe(
        filter(authUser => authUser?.token != ''),
        concatMap(_ => this.store.select(EventsSelectors.selectEvents))
      )
    );

    const foundEvent = events.find(event => event.eventId === eventId);

    const eventToExplore =
      foundEvent ||
      (await firstValueFrom(
        from(this.eventService.getEventById(eventId)).pipe(
          switchMap(event => {
            return of(event ? mapEventToEventsType(event) : null);
          })
        )
      ));

    if (eventToExplore) {
      const currentPageUrl = `${window.location.origin}${state.url}`;
      this.store.dispatch(
        EventsActions.onExploreEventResolved({
          event: eventToExplore,
          currentPageUrl,
        })
      );
      return eventToExplore;
    }
    this.router.navigateByUrl('/private/overview');
    return null;
  }
}
