<div class="container">
  <div class="header">
    <span class="subtitle1" data-test="events-per-nm-title">{{ title }}</span>
    <div class="change-container">
      <span class="subtitle1 gray" data-test="events-per-nm-subtitle">
        {{ timeChangeTitle }}
      </span>
      <div class="change" data-test="events-per-nm-change">
        <img
          *ngIf="eventsChange > 0"
          src="{{
            isCloseEncounterDistanceGraph
              ? '/assets/icons/green_arrow_up.svg'
              : '/assets/icons/red_arrow_up.svg'
          }}"
          alt="" />
        <img
          *ngIf="eventsChange < 0"
          src="{{
            isCloseEncounterDistanceGraph
              ? '/assets/icons/red_arrow_down.svg'
              : '/assets/icons/green_arrow_down.svg'
          }}"
          alt="" />
        <span
          class="subtitle1"
          *ngIf="eventsChange !== 0"
          [ngClass]="{
            green: isCloseEncounterDistanceGraph
              ? eventsChange > 0
              : eventsChange < 0,
            red: isCloseEncounterDistanceGraph
              ? eventsChange < 0
              : eventsChange > 0
          }">
          {{ eventsChange | percent : '1.0-0' }}
        </span>
        <span class="subtitle1" *ngIf="eventsChange === 0"> N/A </span>
      </div>
    </div>
    <span class="subtitle3 events-count" data-test="last-events-avg"
      >{{ eventsCount | number : '1.1-1' }}
      <span class="caption">{{
        isCloseEncounterDistanceGraph ? 'NM' : 'Events'
      }}</span>
    </span>
  </div>
  <div class="graph-container">
    <ngx-charts-line-chart
      *ngIf="sailData; else NoGraphData"
      class="primary-line-chart"
      [results]="dataset"
      [xAxis]="true"
      [yAxis]="true"
      [curve]="linearCurve"
      [tooltipDisabled]="false"
      [yAxis]="true"
      [showGridLines]="false"
      [trimYAxisTicks]="false">
      <ng-template #tooltipTemplate #seriesTooltipTemplate let-sailData="model">
        <div class="tooltip-container">
          <span class="body1">Change from previous month:</span>
          <div class="change">
            <img
              *ngIf="
                sailData.changeInEvents > 0 || sailData[0]?.changeInEvents > 0
              "
              src="{{
                isCloseEncounterDistanceGraph
                  ? '/assets/icons/green_arrow_up.svg'
                  : '/assets/icons/red_arrow_up.svg'
              }}"
              alt="" />
            <img
              *ngIf="
                sailData.changeInEvents < 0 || sailData[0]?.changeInEvents < 0
              "
              src="{{
                isCloseEncounterDistanceGraph
                  ? '/assets/icons/red_arrow_down.svg'
                  : '/assets/icons/green_arrow_down.svg'
              }}"
              alt="" />
            <span
              class="subtitle1"
              *ngIf="sailData[0]?.changeInEvents !== 0"
              [ngClass]="{
                green: isCloseEncounterDistanceGraph
                  ? sailData.changeInEvents > 0 ||
                    sailData[0]?.changeInEvents > 0
                  : sailData.changeInEvents < 0 ||
                    sailData[0]?.changeInEvents < 0,
                red: isCloseEncounterDistanceGraph
                  ? sailData.changeInEvents < 0 ||
                    sailData[0]?.changeInEvents < 0
                  : sailData.changeInEvents > 0 ||
                    sailData[0]?.changeInEvents > 0
              }">
              {{
                sailData.changeInEvents || sailData[0]?.changeInEvents
                  | percent : '1.0-0'
              }}
            </span>
            <span class="subtitle1" *ngIf="sailData[0]?.changeInEvents === 0">
              N/A
            </span>
          </div>
        </div>
      </ng-template>
    </ngx-charts-line-chart>
  </div>
</div>

<ng-template #NoGraphData>
  <div class="no-data">
    <span class="caption">There is no data available.</span>
  </div>
</ng-template>
