import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { from, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Fleet, Ship } from 'src/app/core/models/fleet.model';
import { CreateSubFleetDto } from 'src/app/core/models/subfleet.models';
import { FleetService } from 'src/app/core/services/fleet.service';
import { SubFleetService } from 'src/app/core/services/subfleet.service';
import { FormDialogComponent } from 'src/app/shared/components/form-dialog/form-dialog.component';
import { SelectSearchDirective } from 'src/app/shared/directives/select-search.directive';
@Component({
  selector: 'app-new-subfleet',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormDialogComponent,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    SelectSearchDirective,
  ],
  templateUrl: './new-subfleet.component.html',
  styleUrls: ['./new-subfleet.component.scss'],
})
export class NewSubfleetComponent implements OnInit {
  subfleetForm = this.fb.group({
    fleetId: [null as number | null, Validators.required],
    subFleetName: ['', Validators.required],
    shipsList: [[] as number[], [Validators.required]],
  });

  fleets$ = new Observable<Fleet[]>();
  ships$ = new Observable<Ship[]>();
  searchBoxShipsControl = new FormControl('');

  constructor(
    private fb: FormBuilder,
    private fleetsService: FleetService,
    private subfleetService: SubFleetService
  ) {}

  ngOnInit() {
    this.fleets$ = this.fleetsService
      .getFleets$()
      .pipe(map(fleets => fleets.slice(1)));
    this.subfleetForm.get('fleetId')?.valueChanges.subscribe(fleetId => {
      if (fleetId) {
        this.ships$ = from(this.fleetsService.getShips$(fleetId));
      }
    });
  }

  onSubmitSubfleet(createSubFleetForm: CreateSubFleetDto): Observable<boolean> {
    return from(this.subfleetService.create(createSubFleetForm)).pipe(
      map(() => true),
      catchError((error: any) => {
        throw error.message;
      })
    );
  }
}
