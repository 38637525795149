import { AfterViewInit, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, firstValueFrom, Observable } from 'rxjs';
import { EventsRepository } from 'src/app/core/pages/events/events.repository';
import { CoreState } from 'src/app/core/store/state/core.state';
import { EventsActions, EventsSelectors } from 'src/app/core/store/types';
import {
  AppliedEventFilters,
  LocationsViewModel,
} from 'src/app/core/view-models/filter.view.model';
import { staticLocationsConfig } from './static-locations-config';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements AfterViewInit {
  private readonly eventsRepository = inject(EventsRepository);
  locations: LocationsViewModel[] = staticLocationsConfig;
  appliedLocationsFilter$!: Observable<string[]>;

  constructor(private store: Store<CoreState>) {}

  ngAfterViewInit(): void {
    this.appliedLocationsFilter$ = this.store
      .select(EventsSelectors.selectAppliedLocationsFilter)
      .pipe(distinctUntilChanged());
  }

  async selectAll() {
    const locations = this.locations
      .map(location => [
        ...location.subLocations.map(subLocation => subLocation.location),
      ])
      .flat();
    await this.onEventFiltersChange(locations, 'locations');
  }

  async clear() {
    await this.onEventFiltersChange([], 'locations');
  }

  async onEventFiltersChange(value: any, key: string): Promise<void> {
    const appliedFilters = await firstValueFrom(
      this.store.select(EventsSelectors.selectAppliedEventFilters)
    );
    const updatedAppliedFilters: AppliedEventFilters = {
      ...appliedFilters,
      [key]: value,
    };
    this.store.dispatch(
      EventsActions.onEventFilterChange({ updatedAppliedFilters })
    );

    this.eventsRepository.applyFilters(updatedAppliedFilters);
  }
}
