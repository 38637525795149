import { BehaviorSubject } from 'rxjs';
import {
  MapboxLegendConfigurations,
  MapboxLegendTick,
} from 'src/app/mapbox/models/mapbox-legend.models';
import {
  MapboxCustomSetting,
  MapboxSettingType,
} from 'src/app/mapbox/models/mapbox.models';
import { environment } from 'src/environments/environment';

export const TEMPERATURE_LEGEND_TICKS: MapboxLegendTick[] = [
  { value: -10.0, color: '#348CED' },
  { value: -8.0, color: '#44B1F6' },
  { value: -6.0, color: '#51CBFA' },
  { value: -4.0, color: '#80E0F7' },
  { value: -2.0, color: '#A0EAF7' },
  { value: 0.0, color: '#00EF7C' },
  { value: 2.0, color: '#00E452' },
  { value: 4.0, color: '#00C848' },
  { value: 6.0, color: '#10B87A' },
  { value: 8.0, color: '#297B5D' },
  { value: 10.0, color: '#007229' },
  { value: 12.0, color: '#3CA12C' },
  { value: 14.0, color: '#79D030' },
  { value: 16.0, color: '#B5FF33' },
  { value: 18.0, color: '#D8F7A1' },
  { value: 20.0, color: '#FFF600' },
  { value: 22.0, color: '#F8DF0B' },
  { value: 24.0, color: '#FDCA0C' },
  { value: 26.0, color: '#FCAC05' },
  { value: 28.0, color: '#F88D00' },
  { value: 30.0, color: '#FF6600' },
  { value: 32.0, color: '#FC4F00' },
  { value: 34.0, color: '#FF0100' },
  { value: 36.0, color: '#F31A00' },
  { value: 38.0, color: '#F31861' },
  { value: 40.0, color: '#F316C2' },
  { value: 42.0, color: '#D40F8C' },
  { value: 44.0, color: '#B40957' },
  { value: 46.0, color: '#950221' },
];

export const TEMPERATURE_LEGEND_CONFIG: MapboxLegendConfigurations = {
  id: 'temperature',
  unitsName: '°C',
  legendTicks: TEMPERATURE_LEGEND_TICKS,
};

export const TEMPERATURE_SETTING_ID = 'Temperature';

export const GetTemperatureSetting = (
  sampleDateString$: BehaviorSubject<string>
): MapboxCustomSetting => {
  const getTileSource = () => [
    `${
      environment.meteoblue.tilesUrl
    }/raster/ICONAUTO/${sampleDateString$.getValue()}/11~2%20m%20above%20gnd~hourly~none~contourSteps~-100.0~rgba(0,255,255,1.0)~-80.0~rgba(0,255,255,1.0)~-75.0~rgba(6,230,237,1.0)~-70.0~rgba(11,205,219,1.0)~-65.0~rgba(16,181,202,1.0)~-60.0~rgba(21,158,185,1.0)~-55.0~rgba(27,134,168,1.0)~-50.0~rgba(32,111,151,1.0)~-45.0~rgba(37,89,135,1.0)~-40.0~rgba(42,66,118,1.0)~-35.0~rgba(47,42,101,1.0)~-32.0~rgba(54,10,78,1.0)~-30.0~rgba(86,11,105,1.0)~-28.0~rgba(123,12,136,1.0)~-26.0~rgba(163,12,168,1.0)~-24.0~rgba(206,11,220,1.0)~-22.0~rgba(175,5,227,1.0)~-20.0~rgba(137,4,225,1.0)~-18.0~rgba(93,4,216,1.0)~-16.0~rgba(28,13,207,1.0)~-14.0~rgba(27,52,215,1.0)~-12.0~rgba(36,96,226,1.0)~-10.0~rgba(52,140,237,1.0)~-8.0~rgba(68,177,246,1.0)~-6.0~rgba(81,203,250,1.0)~-4.0~rgba(128,224,247,1.0)~-2.0~rgba(160,234,247,1.0)~0.0~rgba(0,239,124,1.0)~2.0~rgba(0,228,82,1.0)~4.0~rgba(0,200,72,1.0)~6.0~rgba(16,184,122,1.0)~8.0~rgba(41,123,93,1.0)~10.0~rgba(0,114,41,1.0)~12.0~rgba(60,161,44,1.0)~14.0~rgba(121,208,48,1.0)~16.0~rgba(181,255,51,1.0)~18.0~rgba(216,247,161,1.0)~20.0~rgba(255,246,0,1.0)~22.0~rgba(248,223,11,1.0)~24.0~rgba(253,202,12,1.0)~26.0~rgba(252,172,5,1.0)~28.0~rgba(248,141,0,1.0)~30.0~rgba(255,102,0,1.0)~32.0~rgba(252,79,0,1.0)~34.0~rgba(255,1,0,1.0)~36.0~rgba(243,26,0,1.0)~38.0~rgba(243,24,97,1.0)~40.0~rgba(243,22,194,1.0)~42.0~rgba(212,15,140,1.0)~44.0~rgba(180,9,87,1.0)~46.0~rgba(149,2,33,1.0)/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=km%2Fh&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${
      environment.meteoblue.apiKey
    }`,
  ];

  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'temperature-tile-source',
        source: {
          type: 'raster',
          tiles: getTileSource(),
          tileSize: 256,
        },
        updateTilesSource: getTileSource,
      },
    ],
    layers: [
      {
        id: 'temperature-png',
        type: 'raster',
        source: 'temperature-tile-source',
        paint: {
          'raster-opacity': 0.5,
        },
      },
    ],
    popups: [],
  };
};
