<div
  class="container"
  [ngClass]="{ 'blur-effect': isLiveStreamOpen && !isLivePageEnabled }">
  <div
    class="data-container"
    [ngStyle]="{ gap: isLiveStreamOpen ? '5px' : '30px' }">
    <ng-container *ngIf="isLiveStreamOpen; else defaultContent">
      <div mat-dialog-title class="dialog-title">
        <div class="nav-back">
          <img src="./assets/icons/arrow_left.svg" />
          <span class="caption" (click)="navigateBackFromLiveStream()"
            >Back</span
          >
        </div>
      </div>
      <div class="separator"></div>
      <app-ship-details [ship]="ship"></app-ship-details>
      <ng-container *ngIf="isLivePageEnabled; else notEnabled">
        <app-live-stream
          [fleetId]="ship.fleetId"
          [shipId]="ship.shipId"
          [@slideInOut]="isLiveStreamOpen ? 'in' : 'out'"></app-live-stream>
      </ng-container>
      <ng-template #notEnabled>
        <img src="./assets/images/blur_image.svg" />
      </ng-template>
    </ng-container>
    <ng-template #defaultContent>
      <div class="nav-back" *ngIf="(isShipCaptain$ | async) === false">
        <img src="./assets/icons/arrow_left.svg" />
        <span class="caption" (click)="navigateBack()">Back</span>
      </div>
      <div class="header" data-test="main-area-header">
        <h5>{{ ship.shipName }}</h5>
        <app-live-stream-button
          *ngIf="(isShipCaptain$ | async) === false"
          (click)="updateLiveStreamStatus(true)"
          data-test="live-stream-btn"></app-live-stream-button>
      </div>
      <div class="data">
        <mat-tab-group
          mat-stretch-tabs
          [selectedIndex]="selectedTabIndex"
          (selectedIndexChange)="onTabChanged($event)">
          <mat-tab label="Overview" data-test="general-tab">
            <div class="tab">
              <div class="general-data">
                <div class="info-container">
                  <div class="separator"></div>
                  <div class="info">
                    <span class="caption">Last Connection</span>
                    <div class="date">
                      <span
                        *ngIf="ship.lastConnection"
                        class="subtitle3"
                        data-test="last-connection-date">
                        {{
                          isConnectionWithinFourHours()
                            ? (ship.lastConnection | dateToHoursMinutesAgo)
                            : (ship.lastConnection
                              | date : 'dd MMM yy, HH:mm' : 'UTC')
                        }}
                      </span>
                      <div
                        *ngIf="isConnectionWithinFourHours()"
                        class="caption gray"
                        data-test="last-connection-text">
                        {{
                          ship.lastConnection
                            | date : 'dd MMM yy, HH:mm' : 'UTC'
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="info-container">
                  <div class="separator"></div>
                  <div class="info">
                    <span class="caption">Last Event Added</span>
                    <div class="date">
                      <span class="subtitle3" data-test="last-event-date">{{
                        ship.latestEventDateAdded
                          | date : 'dd MMM yy, HH:mm' : 'UTC'
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="info-container">
                  <div class="separator"></div>
                  <div class="info">
                    <span class="caption">Total NM Sailed</span>
                    <span class="subtitle3" data-test="nm-sailed-value">{{
                      totalDistanceSailed$ | async | number : '1.0-0'
                    }}</span>
                    <div class="caption gray" data-test="nm-sailed-subtitle">
                      {{ selectedDateKey$ | async }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="sail-data-container">
                <div class="events-count-container">
                  <div class="dropdown-container">
                    <mat-form-field
                      appearance="fill"
                      class="custom-form-field-selection selection"
                      data-test="general-time-filter">
                      <mat-select
                        [formControl]="sailDataPeriodFilter"
                        panelClass="custom-select"
                        (selectionChange)="onSailDataChange($event.value)">
                        <mat-option [value]="90"> Last 3 Months </mat-option>
                        <mat-option [value]="180"> Last 6 Months </mat-option>
                        <mat-option [value]="365"> Last Year </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <app-ship-sail-data-graph
                  [sailData]="(generalSailData$ | async)!"
                  [timeChangeTitle]="(currPeriod$ | async)!"
                  [eventsCount]="(generalLastMonthEventsCount$ | async)!"
                  [eventsChange]="
                    (changeInGeneralEventCount$ | async)!
                  "></app-ship-sail-data-graph>
                <hr />
                <mat-accordion class="congestion-level-accordion" multi="true">
                  <mat-expansion-panel
                    hideToggle
                    class="custom-expansion no-padding">
                    <mat-expansion-panel-header
                      class="ship-sail-graphs-accordion"
                      data-test="open-water-tree">
                      <mat-panel-title class="title">
                        <mat-icon class="icon">expand_more</mat-icon>
                        <span class="body1">Low Traffic</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="accordion-body">
                      <app-ship-sail-data-graph
                        [sailData]="(openWaterSailData$ | async)!"
                        [eventsChange]="(changeInOpenWaterCeCount$ | async)!"
                        [eventsCount]="
                          (openWaterLastMonthCeEventsCount$ | async)!
                        "
                        [isCloseEncounterDistanceGraph]="true"
                        [timeChangeTitle]="(currPeriod$ | async)!"
                        [title]="
                          'Avg. Min distance of Close Encounter Events'
                        "></app-ship-sail-data-graph>
                      <app-ship-sail-data-graph
                        [sailData]="(collisionRisOpenWaterSailData$ | async)!"
                        [timeChangeTitle]="(currPeriod$ | async)!"
                        [eventsChange]="
                          (changeInOpenWaterCollisionRiskEventsCount$ | async)!
                        "
                        [eventsCount]="
                          (openWaterLastMonthCollisionRiskEventsCount$ | async)!
                        "
                        [title]="
                          'Close Encounter events per 1000 NM sailed'
                        "></app-ship-sail-data-graph>
                      <app-ship-sail-data-graph
                        [sailData]="(openWaterSailData$ | async)!"
                        [eventsChange]="
                          (changeInOpenWaterEventsCount$ | async)!
                        "
                        [timeChangeTitle]="(currPeriod$ | async)!"
                        [eventsCount]="
                          (openWaterLastMonthEventsCount$ | async)!
                        "></app-ship-sail-data-graph>
                    </div>
                  </mat-expansion-panel>
                  <hr />
                  <mat-expansion-panel
                    hideToggle
                    class="custom-expansion no-padding">
                    <mat-expansion-panel-header
                      class="ship-sail-graphs-accordion"
                      data-test="congested-tree">
                      <mat-panel-title class="title">
                        <mat-icon class="icon">expand_more</mat-icon>
                        <span class="body1">Congested Water</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="accordion-body">
                      <app-ship-sail-data-graph
                        [sailData]="(congestedSailData$ | async)!"
                        [eventsChange]="(changeInCongestedCeCount$ | async)!"
                        [eventsCount]="
                          (congestedLastMonthCeEventsCount$ | async)!
                        "
                        [timeChangeTitle]="(currPeriod$ | async)!"
                        [isCloseEncounterDistanceGraph]="true"
                        [title]="
                          'Avg. Min distance of Close Encounter Events'
                        "></app-ship-sail-data-graph>
                      <app-ship-sail-data-graph
                        [sailData]="(collisionRiskCongestedSailData$ | async)!"
                        [timeChangeTitle]="(currPeriod$ | async)!"
                        [eventsChange]="
                          (changeInCongestedCollisionRiskEventsCount$ | async)!
                        "
                        [eventsCount]="
                          (congestedLastMonthCollisionRiskEventsCount$ | async)!
                        "
                        [title]="
                          'Close Encounter events per 1000 NM sailed'
                        "></app-ship-sail-data-graph>
                      <app-ship-sail-data-graph
                        [sailData]="(congestedSailData$ | async)!"
                        [eventsChange]="
                          (changeInCongestedEventsCount$ | async)!
                        "
                        [timeChangeTitle]="(currPeriod$ | async)!"
                        [eventsCount]="
                          (congestedLastMonthEventsCount$ | async)!
                        "></app-ship-sail-data-graph>
                    </div>
                  </mat-expansion-panel>
                  <hr />
                  <mat-expansion-panel
                    hideToggle
                    class="custom-expansion no-padding">
                    <mat-expansion-panel-header
                      class="ship-sail-graphs-accordion"
                      data-test="hyper-congested-tree">
                      <mat-panel-title class="title">
                        <mat-icon class="icon">expand_more</mat-icon>
                        <span class="body1">Hyper Congested Water</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="accordion-body">
                      <app-ship-sail-data-graph
                        [sailData]="(hyperCongestedSailData$ | async)!"
                        [eventsChange]="
                          (changeInHyperCongestedCeCount$ | async)!
                        "
                        [eventsCount]="
                          (hyperCongestedLastMonthCeEventsCount$ | async)!
                        "
                        [timeChangeTitle]="(currPeriod$ | async)!"
                        [isCloseEncounterDistanceGraph]="true"
                        [title]="
                          'Avg. Min distance of Close Encounter Events'
                        "></app-ship-sail-data-graph>
                      <app-ship-sail-data-graph
                        [sailData]="
                          (collisionRisHyperCongestedSailData$ | async)!
                        "
                        [timeChangeTitle]="(currPeriod$ | async)!"
                        [eventsChange]="
                          (changeInHyperCongestedCollisionRiskEventsCount$
                            | async)!
                        "
                        [eventsCount]="
                          (hyperCongestedLastMonthCollisionRiskEventsCount$
                            | async)!
                        "
                        [title]="
                          'Close Encounter events per 1000 NM sailed'
                        "></app-ship-sail-data-graph>
                      <app-ship-sail-data-graph
                        [sailData]="(hyperCongestedSailData$ | async)!"
                        [timeChangeTitle]="(currPeriod$ | async)!"
                        [eventsChange]="
                          (changeInHyperCongestedEventsCount$ | async)!
                        "
                        [eventsCount]="
                          (hyperCongestedLastMonthEventsCount$ | async)!
                        "></app-ship-sail-data-graph>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Events" data-test="events-tab"
            ><div class="tab">
              <div class="events-data">
                <mat-accordion class="accordion" multi="true">
                  <mat-expansion-panel
                    expanded="true"
                    hideToggle
                    class="custom-expansion no-padding">
                    <mat-expansion-panel-header class="panel-header">
                      <mat-panel-title class="title">
                        <mat-icon class="icon">expand_more</mat-icon>
                        <span class="body1">Event Statistics</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="event-graphs-container">
                      <div class="event-graph">
                        <app-ship-events-by-severity></app-ship-events-by-severity>
                      </div>
                      <div class="event-graph">
                        <app-ship-events-by-alert-name></app-ship-events-by-alert-name>
                      </div>
                      <div class="event-graph">
                        <app-ship-events-by-crew-shifts></app-ship-events-by-crew-shifts>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <hr />
                  <mat-expansion-panel
                    expanded="true"
                    hideToggle
                    class="custom-expansion no-padding">
                    <mat-expansion-panel-header class="panel-header">
                      <mat-panel-title class="title">
                        <mat-icon class="icon">expand_more</mat-icon>
                        <span class="body1">Ship's Events</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="preset-container">
                      <app-presets
                        class="presets"
                        label="Latest Highest Risk Events"
                        defaultOption="Latest Highest Risk Events"
                        [selectedPreset]="appliedPresetFilter$ | async"
                        (filterChanged)="onFilterChanged($event)"></app-presets>
                      <div class="events-count">
                        {{ eventsCount$ | async }} Events
                      </div>
                    </div>
                    <div
                      class="events-container"
                      #eventsContainer
                      appInfiniteScrolling
                      (scrolled)="onScroll()">
                      <app-events-list
                        [events]="events$ | async"
                        (eventClicked)="onSelectedListEvent($event)"
                        (eventExplorationClicked)="onEventExploration($event)"
                        [selectedEvent]="
                          selectedEvent$ | async
                        "></app-events-list>
                    </div>
                    <br />
                  </mat-expansion-panel>
                </mat-accordion>
              </div></div
          ></mat-tab>
        </mat-tab-group>
      </div>
    </ng-template>
  </div>
  <div class="graph-map-contianer">
    <div
      class="ship-imu-graph"
      *ngIf="isLivePageEnabled && isLiveStreamOpen; else notLiveOrDisabled">
      <app-ship-imu-samples-graph [ship]="ship"></app-ship-imu-samples-graph>
    </div>
    <ng-template #notLiveOrDisabled>
      <app-ship-average-safety-score-graph></app-ship-average-safety-score-graph>
    </ng-template>
    <div class="map-container">
      <app-maritime-map
        [ships$]="selectedShipEntities$"
        [events$]="shipEvents$"
        [changePositon$]="selectedPoisition$"
        (selectedMapEvent)="onSelectedMapEntity($event)"
        [minimalZoomLevel]="1.4"
        [settingToShow]="[
          'surface',
          'land',
          'ship',
          'event',
          'labels',
          'Contour'
        ]"></app-maritime-map>
    </div>
  </div>
</div>
