import { Component } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-delete-comment-dialog',
  templateUrl: './delete-comment-dialog.component.html',
  styleUrls: ['./delete-comment-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
})
export class DeleteCommentDialogComponent {
  constructor(public dialogRef: MatDialogRef<DeleteCommentDialogComponent>) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onDiscard(): void {
    this.dialogRef.close(true);
  }
}
