import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { DestroyRef } from 'projects/orca-lib-main/projects/orca-lib/src/lib/services/destroy-ref.service';
import { debounceTime, distinctUntilChanged, map, Subject } from 'rxjs';
import { CellTemplateContext } from './models/cell-template-context.model';
import { ColumnDefinition } from './models/column-definition.model';
import { HeaderTemplateContext } from './models/header-template-context.model';
import { RowKey } from './models/row-key.model';
import { SortDetails } from './models/sort-details.model';
import { TrackBySelector } from './models/track-by-selector.model';
import { TableStore } from './store.service';
import { ColumnVm } from './view-models/column.vm';
import { RowVm } from './view-models/row.vm';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'orc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [TableStore, DestroyRef],
})
export class TableComponent {
  @Input()
  set data(value: any[] | null) {
    this.store.setData(value ?? []);
  }

  @Input()
  set columns(value: ColumnDefinition<any>[]) {
    this.store.setColumns(value);
  }

  @Input()
  set trackBy(value: TrackBySelector<any>) {
    this.store.setTrackBy(value);
  }

  @Input()
  set sortBy(value: SortDetails | null) {
    if (value) this.store.setSortBy(value);
  }

  @Input()
  set selected(value: RowKey | null) {
    if (value === null) {
      this.store.clearSelected();
    } else {
      this.store.setSelected(value);
      this.scrollNow$.next();
    }
  }
  get selected(): RowKey | null {
    return this.store.state.selectedItemId;
  }

  @Output()
  selectionChanged = this.store.state$.pipe(
    map(state => state.selectedItemId),
    distinctUntilChanged(),
    debounceTime(0)
  );

  @Output() hoverItemsChange = new EventEmitter<any>();
  @Output() sortItem = new EventEmitter<any>();

  @ViewChild('defaultCellTemplate', { read: TemplateRef })
  set defaultCellTemplate(value: TemplateRef<CellTemplateContext>) {
    this.store.setDefaultCellTemplate(value);
  }

  @ViewChild('defaultHeaderTemplate', { read: TemplateRef })
  set defaultHeaderTemplate(value: TemplateRef<HeaderTemplateContext>) {
    this.store.setDefaultHeaderTemplate(value);
  }
  @HostBinding('class.has-sticky-row')
  get hasStickyRow(): boolean {
    return this.store.hasStickyRow();
  }

  protected vm$ = this.store.vm$;
  public readonly state$ = this.store.state$;
  protected scrollNow$ = new Subject<void>();

  constructor(public readonly store: TableStore) {}

  toggleSort(col: ColumnVm) {
    if (col.sortable) {
      this.store.toggleSort(col.id);
      this.sortItem.emit(this.store.getSortBy());
    }
  }

  toggleSelection(rowKey: RowKey) {
    this.store.toggleSelection(rowKey);
    this.scrollNow$.next();
  }

  onRowHover(rowKey: RowKey | null) {
    this.hoverItemsChange.emit(rowKey);
  }

  trackByColId: TrackByFunction<ColumnVm> = (index, col) => col.id;

  trackByRowKey: TrackByFunction<RowVm> = (index, row) => row.key;
}
