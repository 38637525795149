import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { from, Observable } from 'rxjs';
import { MonthlyAccelerationData } from 'src/app/core/view-models/fuel-optimization.view.models';

@Component({
  selector: 'app-acceleration-time-tooltip',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './acceleration-time-tooltip.component.html',
  styleUrls: ['./acceleration-time-tooltip.component.scss'],
})
export class AccelerationTimeTooltipComponent {
  @Input() data$: Observable<MonthlyAccelerationData | undefined> = from([
    undefined,
  ]);
}
