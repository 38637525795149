<form class="form" [formGroup]="commentForm" (ngSubmit)="onCommentSubmit()">
  <div class="add-comment comment-input-container">
    <div class="user-avatar" [style.background-color]="'#14105B'">
      {{ currentUserFullName | nameInitials }}
    </div>
    <textarea
      #commentInput
      cdkTextareaAutosize
      formControlName="comment"
      placeholder="Add a comment, use @ to mention other users..."
      class="subtitle1 comment-input"
      (input)="onCommentInput($event)"
      (keydown)="onCommentKeydown($event)"
      (focus)="commentInputFocused = true"
      maxlength="1000"></textarea>
    <div *ngIf="showMentionsList" class="mention-suggestions">
      <div
        *ngFor="let user of filteredUsers"
        class="mention-suggestion"
        (click)="insertMention(user)">
        {{ user.firstName }} {{ user.lastName }}
      </div>
    </div>
  </div>
  <div *ngIf="commentInputFocused" class="comment-actions">
    <button class="submit-button" type="submit" [disabled]="!commentForm.valid">
      Submit
    </button>
    <button class="cancel-button" type="button" (click)="onCommentCancel()">
      Cancel
    </button>
  </div>
</form>
