import {
  MapboxSettingType,
  MapboxStyleSetting,
} from 'src/app/mapbox/models/mapbox.models';

export const SURFACE_SETTING_ID = 'surface';

export const GetSurfaceSetting = (): MapboxStyleSetting => ({
  type: MapboxSettingType.FromStyle,
  layerIds: ['country-boundaries', 'water', 'waterway'],
});
