import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-module-will-come-soon-dialog',
  templateUrl: './module-will-come-soon-dialog.component.html',
  styleUrls: ['./module-will-come-soon-dialog.component.scss'],
})
export class ModuleWillComeSoonDialogComponent {
  constructor(
    private location: Location,
    public dialogRef: MatDialogRef<ModuleWillComeSoonDialogComponent>
  ) {}

  onBackClick(): void {
    this.location.back();
  }
}
