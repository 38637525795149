import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { FeatureFlagService } from '../services/feature-toggle.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFuelFlagGuard implements CanActivate {
  constructor(
    private featureFlagService: FeatureFlagService,
    private router: Router
  ) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const showFeature = await firstValueFrom(
      this.featureFlagService.getFeatureFlag$('efficiency')
    );

    if (showFeature) {
      return true;
    }
    this.router.navigateByUrl('/private/overview');
    return false;
  }
}
