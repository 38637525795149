import { GeoJSONFeature, LngLat } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import {
  MapboxCustomSetting,
  MapboxSettingType,
  MapEntity,
} from 'src/app/mapbox/models/mapbox.models';
import { CreatePopupFromComponent } from 'src/app/mapbox/utils/mapbox.utility';

export const SHIP_SETTING_ID = 'ship';

export const GetShipSetting = (
  ships$: BehaviorSubject<MapEntity[]>
): MapboxCustomSetting => {
  const getShipFeatures = () =>
    ships$.getValue().map(
      (ship): GeoJSONFeature => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [ship.long, ship.lat],
        },
        source: 'ship',
        properties: {
          id: ship.id,
          icon: ship.image,
          category: 'ship',
          textLabel: ship.textLabel,
          labelType: ship.labelType,
        },
      })
    );
  const getPopups = () =>
    ships$
      .getValue()
      .filter(ship => ship.textLabel)
      .map(ship => {
        const label = {
          long: ship.long,
          lat: ship.lat,
          textLabel: ship.textLabel?.toUpperCase(),
          offset: -35,
        };
        const popup = CreatePopupFromComponent('app-map-label', {
          label,
        })
          .setOffset(label.offset)
          .setLngLat(new LngLat(label.long, label.lat).wrap());
        popup.addClassName('large-white');
        return popup;
      });

  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'ship',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getShipFeatures(),
          },
        },
        updateFeatures: getShipFeatures,
      },
    ],
    layers: [
      {
        id: 'ship',
        type: 'symbol',
        source: 'ship',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      },
    ],
    popups: getPopups(),
    updatePopups: getPopups,
  };
};
