import { GeoJSONFeature, LngLat, LngLatLike, Popup } from 'mapbox-gl';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
  MapboxCustomSetting,
  MapboxSettingType,
} from 'src/app/mapbox/models/mapbox.models';
import { CreatePopupFromComponent } from 'src/app/mapbox/utils/mapbox.utility';
import { ShipLastLocation } from '../models/ship.models';
export const SHIP_TRAIL_SETTING_ID = 'ShipTrail';

export async function CreateShipTrailSetting(
  locations$: BehaviorSubject<ShipLastLocation[]>,
  shipCurrentLocation$: BehaviorSubject<ShipLastLocation | null>
): Promise<MapboxCustomSetting> {
  const openPopup$ = new BehaviorSubject<string | null>(null);

  const getTrailPointsFeatures = () => {
    const locations = locations$.getValue();
    const shipLocation = shipCurrentLocation$.getValue();
    if (shipLocation) {
      locations.push(shipLocation);
    }

    return locations.map(
      (location: ShipLastLocation): GeoJSONFeature => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [location.longitude, location.latitude],
        },
        source: 'point',
        properties: {
          id: `${location.time}-${location.sog}-${location.latitude}-${location.longitude}`,
          latitude: location.latitude,
          longitude: location.longitude,
          sog: location.sog,
          time: location.time,
          textLabel: `${location.time}\n${location.sog} Knts`,
          labelType: 'white',
        },
      })
    );
  };

  const getTrailLineFeatures = (): GeoJSONFeature[] => {
    const locations = locations$.getValue();
    const shipLocation = shipCurrentLocation$.getValue();
    if (shipLocation) {
      locations.push(shipLocation);
    }

    return [
      {
        type: 'Feature',
        source: 'dashed_line',
        geometry: {
          type: 'LineString',
          coordinates: locations.map((location: ShipLastLocation) => [
            location.longitude,
            location.latitude,
          ]),
        },
        properties: {},
      },
    ];
  };

  const getPopups = (): Popup[] => {
    return locations$
      .getValue()
      .map((location: ShipLastLocation) =>
        CreateTrackLabelPopup(
          `${location.time}-${location.sog}-${location.latitude}-${location.longitude}`,
          location.time,
          location.sog,
          new LngLat(location.longitude, location.latitude)
        )
      )
      .filter(popupLabel => popupLabel !== null) as Popup[];
  };

  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'point',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getTrailPointsFeatures(),
          },
        },
        updateFeatures: getTrailPointsFeatures,
      },
      {
        sourceId: 'dashed_line',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getTrailLineFeatures(),
          },
        },
        updateFeatures: getTrailLineFeatures,
      },
    ],
    layers: [
      {
        id: 'point',
        type: 'circle',
        source: 'point',
        paint: {
          'circle-radius': 4,
          'circle-color': '#5B588C',
        },
      },
      {
        id: 'dashed_line',
        type: 'line',
        source: 'dashed_line',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#5B588C',
          'line-width': 1,
          'line-dasharray': [2, 2],
        },
      },
    ],
    popups: getPopups(),
    updatePopups: getPopups,
    showPopup$: openPopup$,
  };

  function CreateTrackLabelPopup(
    id: string,
    time: string,
    sog: number,
    coordinates: LngLatLike
  ): Popup | null {
    const onPopupDestroy$ = new Subject<void>();
    const popup = CreatePopupFromComponent<{
      time: string;
      sog: number;
      onPopupDestroy$: Subject<void>;
    }>('app-track-label', {
      time,
      sog,
      onPopupDestroy$,
    });
    const subscription = openPopup$
      .pipe(map(popupId => popupId === id))
      .subscribe(show => {
        if (!show) {
          popup.addClassName('unselected');
        } else {
          popup.removeClassName('unsel ected');
        }
      });
    onPopupDestroy$.subscribe(() => {
      subscription.unsubscribe();
      onPopupDestroy$.unsubscribe();
    });
    popup.addClassName('trail');
    popup.setOffset(-40);
    popup.setLngLat(coordinates);
    return popup;
  }
}
