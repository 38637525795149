import * as _ from 'lodash';
import { MapEntity, PolygonEntity } from 'src/app/mapbox/models/mapbox.models';
import { isWithinLastHours } from 'src/app/shared/utils/calculators/general';
import { AlertNames } from '../models/events-filters.model';
import {
  DistanceData,
  SailData,
  Ship,
  ShipCamera,
  ShipCameraDto,
  ShipLiveStream,
  ShipSailData,
  ShipSailDataDto,
  ShipStreamDto,
  ShipStreamMasterDto,
  ShipType,
} from '../models/ship.model';
import { Polygon } from '../view-models/event.view.model';
import { ShipSailDataChange } from '../view-models/ship.view-model';
import { buildPolygonEntity } from './events';

export function mapShipCameraDto(camera: ShipCameraDto): ShipCamera {
  return {
    cameraId: camera.camera_id,
    isLive: camera.streaming_state === 'live',
    streamingStartedAt: camera.streaming_started_at,
  };
}

export function mapStreamMasterDto(
  streamMaster: ShipStreamMasterDto
): Partial<ShipLiveStream> {
  const result: Partial<ShipLiveStream> = {
    isStreamMaster: streamMaster.isMaster,
  };
  return result;
}

export function mapStreamDto(stream: ShipStreamDto): Partial<ShipLiveStream> {
  const result: Partial<ShipLiveStream> = {
    isStreamMaster: stream.isMaster,
    streamUrl: stream.streamHLS,
    sessionId: stream.session_id,
  };
  return result;
}

export function mapShipSalingData(
  sailingData: ShipSailDataDto,
  alertName: AlertNames | null = null
): ShipSailData {
  const data: ShipSailData = {
    congestionLevel: sailingData.congestion_level,
    sailData: sailingData.sailing_data.map(
      (sailDataDto): SailData => ({
        month: sailDataDto.month,
        year: sailDataDto.year,
        totalDistance: sailDataDto.total_distance_nm,
        avgNumOfEvents: sailDataDto.avg_sum_of_events,
      })
    ),
    ceEventsData: sailingData.ce_events_data.map(
      (DistanceDataDto): DistanceData => ({
        month: DistanceDataDto.month,
        year: DistanceDataDto.year,
        avgMinDistance: DistanceDataDto.avg_min_distance,
      })
    ),
    alertType: alertName,
    changesInEventsNum: [],
    changesInCeNum: [],
  };

  data.sailData.sort((d1, d2) => {
    const date1 = new Date(d1.year, d1.month);
    const date2 = new Date(d2.year, d2.month);
    return date1 > date2 ? 1 : -1;
  });

  data.ceEventsData.sort((d1, d2) => {
    const date1 = new Date(d1.year, d1.month);
    const date2 = new Date(d2.year, d2.month);
    return date1 > date2 ? 1 : -1;
  });

  return data;
}

function getValue(data: SailData | DistanceData): number {
  return 'avgNumOfEvents' in data ? data.avgNumOfEvents : data.avgMinDistance;
}

export function calcChangesBetweenMonth(
  sailData: (SailData | DistanceData)[]
): ShipSailDataChange[] {
  return sailData.map((data, i) => {
    if (i === 0) {
      return { month: data.month, year: data.year, changeInEvents: 0 };
    }
    const prevValue = getValue(sailData[i - 1]);
    const currentValue = getValue(data);
    if (prevValue === 0) {
      return { month: data.month, year: data.year, changeInEvents: 0 };
    }
    return {
      month: data.month,
      year: data.year,
      changeInEvents: (currentValue - prevValue) / prevValue,
    };
  });
}

export function reverseEventsCalc(sailData: SailData[]): SailData[] {
  const sailDataReversed = sailData.map(d => ({
    ...d,
    avgNumOfEvents: (d.avgNumOfEvents * d.totalDistance) / 1000,
  }));

  const grouped = _.groupBy(sailDataReversed, 'month');
  const groupedSailData = Object.values(grouped).map(
    sailDataArr =>
      sailDataArr.reduce((acc, curr) => ({
        ...acc,
        month: acc.month,
        year: acc.year,
        avgNumOfEvents: acc.avgNumOfEvents + curr.avgNumOfEvents,
        totalDistance: acc.totalDistance + curr.totalDistance,
      })),
    {}
  );

  groupedSailData.sort((d1, d2) => {
    const date1 = new Date(d1.year, d1.month);
    const date2 = new Date(d2.year, d2.month);
    return date1 > date2 ? 1 : -1;
  });
  return groupedSailData.map(d => {
    return {
      ...d,
      avgNumOfEvents:
        d.totalDistance === 0 ? 0 : (d.avgNumOfEvents / d.totalDistance) * 1000,
    };
  });
}

export function transformShipTypeDto(shipType: string): ShipType {
  let capitalizedString = shipType
    .replace('_', ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
    .replace('Lng', 'LNG')
    .replace('Lpg', 'LPG')
    .replace('Roro/vehicle', 'RoRo_Vehicle');
  if (!isShipType(capitalizedString)) {
    capitalizedString = 'Container Ship';
  }
  return capitalizedString as ShipType;
}

export function mapShipsToMapEntities(
  ships: Ship[],
  selectedShipId: number | null,
  addLabelData: boolean = false
): MapEntity[] {
  return ships.map((ship): MapEntity => {
    return mapSelectedShipToMapEntity(
      ship,
      ship.shipId === selectedShipId,
      addLabelData
    );
  });
}

export function polygonsToMapPolygons(polygons: Polygon[]): PolygonEntity[] {
  return polygons
    .map((polygon: Polygon) => buildPolygonEntity(polygon))
    .filter(
      (polygonEntity: PolygonEntity | null): polygonEntity is PolygonEntity =>
        polygonEntity !== null
    );
}
export function mapSelectedShipToMapEntity(
  ship: Ship,
  isSelectedShip: boolean,
  addLabelData: boolean = false
): MapEntity {
  if (isSelectedShip) {
    return {
      id: ship.shipId.toString(),
      lat: ship.latitude,
      long: ship.longitude,
      eyeOffset: [0, 0, -10000],
      image: isWithinLastHours(4, ship.lastConnection)
        ? `/assets/map/ships/${_.snakeCase(ship.shipType)}/ship_icon.png`
        : `/assets/map/ships/${_.snakeCase(ship.shipType)}/faded_ship_icon.png`,
    };
  } else {
    return {
      id: ship.shipId.toString(),
      lat: ship.latitude,
      long: ship.longitude,
      eyeOffset: [0, 0, 0],
      image: isWithinLastHours(4, ship.lastConnection)
        ? `/assets/map/ships/default.png`
        : `/assets/map/ships/faded_default.png`,
      ...(addLabelData
        ? { textLabel: ship.shipName.toUpperCase(), labelType: 'white' }
        : {}),
    };
  }
}

export function isShipType(value: any): value is ShipType {
  return [
    'Bulk Carrier',
    'Container Ship',
    'Crude Oil Tanker',
    'LNG Tanker',
    'LPG Tanker',
    'Tug',
    'RoRo/Vehicle Carrier',
  ].includes(value);
}
