<div class="dialog-container">
  <div class="content">
    <div class="container">
      <img src="./assets/icons/expired_access.svg" alt="Lock Icon" />
      <div>
        <div class="title">Live Module Access Expired</div>
        <div class="text-message">
          To continue using real-time premium features, please contact our
          customer success representative.
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="footer">
    <div class="back-button footer-action" (click)="goBack()">
      <img src="./assets/icons/small_arrow_left.svg" alt="Arrow Left Icon" />
      <div>Back</div>
    </div>
    <a class="footer-action" href="mailto:csm@orca-ai.io" target="_blank"
      >Contact Your CSM</a
    >
  </div>
</div>
