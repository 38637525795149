import { Injectable } from '@angular/core';
import { TooltipDirective } from '../directives/tooltip.directive';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  private activeTooltip: TooltipDirective | null = null;

  setActiveTooltip(tooltip: TooltipDirective | null) {
    this.activeTooltip = tooltip;
  }

  getActiveTooltip(): TooltipDirective | null {
    return this.activeTooltip;
  }

  closeActiveTooltip() {
    if (this.activeTooltip) {
      this.activeTooltip.hideTooltip();
      this.activeTooltip = null;
    }
  }
}
