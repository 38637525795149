import { Component, OnDestroy, OnInit } from '@angular/core';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import {
  BehaviorSubject,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ModuleWillComeSoonDialogComponent } from 'src/app/core/components/dialogs/module-will-come-soon-dialog/module-will-come-soon-dialog.component';
import { ScreenshotDialogComponent } from 'src/app/core/components/dialogs/screenshot-dialog/screenshot-dialog.component';
import { alertNamesToEventTypes } from 'src/app/core/models/events-filters.model';
import { ShipPosition } from 'src/app/core/models/map.models';
import { LiveShipData } from 'src/app/core/models/ship.model';
import { FeatureFlagService } from 'src/app/core/services/feature-toggle.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import {
  buildPolygonEntity,
  formatRTEventLimitations,
} from 'src/app/core/utils/events';
import { MapEntity, PolygonEntity } from 'src/app/mapbox/models/mapbox.models';
import {
  GraphDataView,
  GraphTooltipMapping,
  SampleGraphType,
} from 'src/app/shared/models/graph-data-model';
import { getTimeDiffWithSeconds } from 'src/app/shared/utils/date-transforms/date-diff';
import { CoreState } from '../../../store/state/core.state';
import {
  AuthenticationSelectors,
  ShipsActions,
  ShipsSelectors,
} from '../../../store/types';
import {
  isRTComplianceEvent,
  isRTHighPitchRollEvent,
  isRTNoGoZoneEvent,
  Polygon,
  RtEvent,
  RtEventGraphSegmentsChoices,
  RTEventLimitations,
  RTEventScreenshot,
  ShipLastLocation,
} from '../../../view-models/event.view.model';

@Component({
  selector: 'app-rt-event-exploration',
  templateUrl: './rt-event-exploration.component.html',
  styleUrls: ['./rt-event-exploration.component.scss'],
})
export class RtEventExplorationComponent implements OnInit, OnDestroy {
  rtMapEvents$ = new BehaviorSubject<MapEntity[]>([]);
  selectedEventPosition$: BehaviorSubject<ShipPosition | undefined> =
    new BehaviorSubject<ShipPosition | undefined>(undefined);
  shipId$ = new BehaviorSubject<number | null>(null);
  NoGoPolygons$ = new BehaviorSubject<PolygonEntity[]>([]);
  CompliancePolygons$ = new BehaviorSubject<PolygonEntity[]>([]);

  showFlag$!: Observable<boolean>;
  private destroy$ = new Subject<void>();

  rtEvent!: RtEvent;
  shipLastLocations!: ShipLastLocation[];
  isHighPitchRollEvent!: boolean;
  rtMapEvent$!: Observable<MapEntity[]>;
  eventEntities: MapEntity[] = [];
  isRtEventOver!: boolean;
  eventDuration!: string | null;
  screenshots$!: Observable<RTEventScreenshot[] | undefined>;
  token$!: Observable<string | undefined>;
  screenshotsDisabled$!: Observable<boolean>;
  graphDisabled!: boolean;
  private _clickedOnScreenshot$ = new Subject<string>();
  selectedScreenshot$!: Observable<string>;
  selectedGraph!: SampleGraphType;
  graphSegmentsChoices = RtEventGraphSegmentsChoices;
  activeGraph: string = this.graphSegmentsChoices.PITCH_AND_ROLL;
  prevUrl: string = '';

  isRTHighPitchRollEvent = isRTHighPitchRollEvent;
  isRTNoGoZoneEvent = isRTNoGoZoneEvent;
  isRTComplianceEvent = isRTComplianceEvent;

  graphPitchRollValuesMap: GraphTooltipMapping = {
    Pitch: {
      divClass: 'pitch',
    },
    Roll: {
      divClass: 'roll',
    },
  };

  graphSogValuesMap: GraphTooltipMapping = {
    SOG: {
      divClass: 'sog',
    },
  };

  pitchRollGraphDataView!: GraphDataView[];
  pitchRollColorScheme = {
    domain: ['var(--dv-purple)', 'var(--dv-dark-blue)'],
  };

  sogGraphDataView!: GraphDataView[];
  sogColorScheme = {
    domain: ['var(--dv-dark-blue)'],
  };

  constructor(
    private route: ActivatedRoute,
    private store: Store<CoreState>,
    public dialog: MatDialog,
    private routingService: RoutingService,
    private router: Router,
    private featureFlag: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.rtEvent = this.route.snapshot.data['rtEvent'].rtEvent;

    this.eventEntities = [
      {
        id: this.rtEvent.eventId,
        lat: this.rtEvent.lat,
        long: this.rtEvent.long,
        eyeOffset: [0.0, 0.0, 0.0],
        image: `/assets/map/events/${_.snakeCase(
          this.rtEvent.type
        )}/${_.snakeCase(this.rtEvent.severity)}.png`,
      },
    ];
    this.rtMapEvents$.next(this.eventEntities);
    this.shipId$.next(Number(this.rtEvent.shipId));

    this.selectedEventPosition$.next({
      lat: this.rtEvent.lat,
      long: this.rtEvent.long,
      height: 20000000,
    });

    this.prevUrl = this.routingService.getPreviousUrl();

    this.shipLastLocations =
      this.route.snapshot.data['rtEvent'].shipLastLocations || [];
    this.isHighPitchRollEvent =
      this.rtEvent.type === alertNamesToEventTypes['Dangerous motion'];
    this.token$ = this.store.select(AuthenticationSelectors.selectToken);

    this.initializeRtMapEventStream();
    this.initializePolygonsEntities();
    this.isRtEventOver = this.rtEvent.end ? true : false;
    const currentUtctime = new Date(
      new Date().getTime() + new Date().getTimezoneOffset() * 60000
    );
    this.eventDuration = getTimeDiffWithSeconds(
      this.rtEvent.end ?? currentUtctime,
      this.rtEvent.start
    );

    this.screenshots$ = this.token$.pipe(
      switchMap(token =>
        of(
          this.rtEvent.screenshots?.map(screenshot => {
            return {
              url: `${screenshot.url}?token=${token}`,
              timestamp: screenshot.timestamp,
            };
          })
        )
      )
    );

    this.screenshotsDisabled$ = this.screenshots$.pipe(
      map(screenshots => !screenshots || screenshots.length === 0)
    );

    this.graphDisabled =
      !this.rtEvent.imuSamples || this.rtEvent.imuSamples.length === 0;

    this.mapImuSampleToDateView();

    this.showFlag$ = this.featureFlag.getFeatureFlag$('LiveView');

    this.showFlag$.pipe(takeUntil(this.destroy$)).subscribe(showFlag => {
      if (!showFlag) {
        this.dialog.open(ModuleWillComeSoonDialogComponent, {
          disableClose: true,
        });
      }
    });
  }

  initializeRtMapEventStream(): void {
    this.rtMapEvent$ = this.store
      .select(ShipsSelectors.selectShipLiveData)
      .pipe(
        switchMap((shipLiveData: LiveShipData | null) => {
          this.rtMapEvents$.next(this.eventEntities);

          return of(this.eventEntities);
        })
      );
  }

  initializePolygonsEntities(): void {
    const polygons = this.route.snapshot.data['rtEvent'].polygons || [];
    if (this.rtEvent.type === 'No Go Zone') {
      this.NoGoPolygons$.next(
        polygons.map((polygon: Polygon) => buildPolygonEntity(polygon))
      );
    } else if (this.rtEvent.type === 'Compliance') {
      this.CompliancePolygons$.next(
        polygons.map((polygon: Polygon) => buildPolygonEntity(polygon))
      );
    } else {
      this.NoGoPolygons$.next([]);
    }
  }
  mapImuSampleToDateView(): void {
    if (this.rtEvent.imuSamples) {
      const rollSamplesByDateView = {
        name: 'Roll',
        series: this.rtEvent.imuSamples.map((imuSampleByDate, index) => {
          const customImuSample = {
            name: imuSampleByDate.sampledAt,
            value: imuSampleByDate.roll,
          };

          return customImuSample;
        }),
      };

      const pitchSamplesByDateView = {
        name: 'Pitch',
        series: this.rtEvent.imuSamples.map(imuSampleByDate => {
          const customImuSample = {
            name: imuSampleByDate.sampledAt,
            value: imuSampleByDate.pitch,
          };

          return customImuSample;
        }),
      };

      const sogSamplesByDateView = {
        name: 'SOG',
        series: this.rtEvent.imuSamples.map(imuSampleByDate => {
          const customImuSample = {
            name: imuSampleByDate.sampledAt,
            value: imuSampleByDate.sog,
          };

          return customImuSample;
        }),
      };

      this.pitchRollGraphDataView = [
        rollSamplesByDateView,
        pitchSamplesByDateView,
      ];

      this.sogGraphDataView = [sogSamplesByDateView];
    }
  }

  formatPitchRollYLabel(degrees: number): string {
    return degrees.toString().concat('\xB0');
  }

  formatSogYLabel(velocity: number): string {
    return velocity.toString().concat(' Kts');
  }

  formatAoiComplianceLimitations(aoiComplianceLimitations: RTEventLimitations) {
    return formatRTEventLimitations(aoiComplianceLimitations);
  }
  ngOnDestroy(): void {
    this.store.dispatch(ShipsActions.closeShipConnectionStatus());
    this.destroy$.next();
    this.destroy$.complete();
  }

  openImageDialog(screenshot: RTEventScreenshot): void {
    this.dialog.open(ScreenshotDialogComponent, {
      data: {
        url: screenshot.url,
        timestamp: screenshot.timestamp,
      },
    });
  }

  navigateBack(): void {
    if (this.prevUrl.includes('/fleet')) {
      this.router.navigateByUrl('/private/fleet');
    } else {
      if (this.prevUrl.includes('/events')) {
        this.router.navigateByUrl('/private/events');
      } else this.router.navigateByUrl('/private/overview');
    }
  }
  navigateToShipProfile(): void {
    this.router.navigateByUrl(`/private/fleet/ship/${this.rtEvent.shipId}`);
  }
  getEventPath(): string {
    if (this.rtEvent) {
      switch (this.rtEvent.type) {
        case 'Compliance':
          return './assets/icons/compliance_event.svg';
        case 'No Go Zone':
          return './assets/icons/no_go_zone_event.svg';
        case 'High Pitch/Roll':
          return './assets/icons/high_pitch_roll_event.svg';
      }
    }
    return './assets/icons/rt_event.svg';
  }
  getTimeDiff(event: RtEvent): string {
    const endTime = new Date(
      event.timestampEnd ??
        new Date().getTime() + new Date().getTimezoneOffset() * 60000
    );
    const startTime = new Date(event.timestamp);
    return getTimeDiffWithSeconds(endTime, startTime);
  }
}
