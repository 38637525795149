<div class="container">
  <ng-container *ngIf="eventOfSelectedShip$ | async as eventOfSelectedShip">
    <div *ngIf="eventOfSelectedShip.length" class="events">
      <div class="events-header" *ngIf="selectedShip$ | async as selectedShip">
        <span class="ship-name">{{ selectedShip.shipName }}</span>
        <span class="events-count"
          >{{ eventOfSelectedShip.length }} Events</span
        >
      </div>
      <div
        class="events-container"
        #eventsContainer
        appInfiniteScrolling
        (scrolled)="onEventListScrollToEnd()">
        <app-events-list
          [events]="eventOfSelectedShip"
          (eventClicked)="onSelectedEvent($event)"
          (eventExplorationClicked)="onEventExploration($event)"
          [selectedEvent]="selectedEvent$ | async"
          [showTargetEventData]="false"></app-events-list>
      </div>
    </div>
  </ng-container>
  <div class="map">
    <app-maritime-map
      [ships$]="ships$"
      [events$]="events$"
      [rtEvents$]="rtEvents$"
      [noGoZones$]="noGoZones$"
      [complianceZones$]="complianceZones$"
      [screenshots$]="screenshots$"
      [shipsSafetyScores$]="shipsSafetyScores$"
      [selectedEvent$]="selectedEventMapEntity$"
      [changePositon$]="selectedEventPosition$"
      [token]="token$ | async"
      [showLayersMenu]="true"
      [isCaptain]="(isShipCaptain$ | async)!"
      (selectedMapEvent)="onSelectedMapEntity($event)"
      (diselectedMapEvent)="onCloseTooltip()"
      (shipTooltipButtonClicked)="onShipTooltipButtonClicked($event)"
      (shipTooltipDismissButtonClicked)="onShipTooltipDismissButtonClicked()">
    </app-maritime-map>
  </div>
</div>
