import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';

import { CoreState } from '../store/state/core.state';
import {
  AuthenticationSelectors,
  EventsActions,
  ShipsSelectors,
} from '../store/types';

@Injectable({
  providedIn: 'root',
})
export class EventsResolver implements Resolve<void> {
  constructor(private store: Store<CoreState>, private router: Router) {}
  async resolve(route: ActivatedRouteSnapshot): Promise<void> {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { queryParams: any } | undefined;

    if (state) {
      const queryParams = state.queryParams;
      const appliedFiltersToUpdate = {
        ...queryParams,
      };

      const dateFilter = queryParams['dateFilter'];

      this.store.dispatch(
        EventsActions.setEventAndDateFilterByUrlParams({
          appliedFiltersToUpdate,
          dateFilter,
        })
      );
    }

    await firstValueFrom(
      this.store
        .select(AuthenticationSelectors.selectAuthenticatedUser)
        .pipe(filter(authUser => authUser?.token != ''))
    );

    await firstValueFrom(
      this.store
        .select(ShipsSelectors.selectShips)
        .pipe(filter(ships => ships.length > 0))
    );
  }
}
