import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './core/guards/admin.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { EmailVerificationGuard } from './core/guards/email-verification.guard';
import { FeatureFuelFlagGuard } from './core/guards/feature-fuel-flag.guard';
import { FirstLoginGuard } from './core/guards/first-login.guard';
import { NewPassGuard } from './core/guards/new-pass.guard';
import { ShipCaptainGuard } from './core/guards/ship-captain.guard';
import { UnauthGuard } from './core/guards/unauth.guard';
import { UserRecoveryGuard } from './core/guards/user-recovery.guard';
import { PrivateComponent } from './core/pages/private/private.component';
import { AdminResolver } from './core/resolvers/admin.resolver';
import { EventExplorationResolver } from './core/resolvers/event-exploration.resolver';
import { EventsResolver } from './core/resolvers/events.resolver';
import { OverviewResolver } from './core/resolvers/overview.resolver';
import { RtEventResolver } from './core/resolvers/rt-event.resolver';
import { ShipEventCountResolver } from './core/resolvers/ship-event-count.resolver';
import { ShipSailDataResolver } from './core/resolvers/ship-sail-data.resolver';
import { ShipScoringFactorsResolver } from './core/resolvers/ship-scoring-factors.resolver';
import { ShipResolver } from './core/resolvers/ship.resolver';

const routes: Routes = [
  {
    component: PrivateComponent,
    path: 'private',
    canActivate: [UnauthGuard],
    children: [
      {
        path: 'events',
        canActivate: [UnauthGuard],
        data: { breadcrumb: 'Events' },

        children: [
          {
            path: '',
            loadChildren: () =>
              import('./core/pages/events/events.module').then(
                m => m.EventsModule
              ),
            resolve: { selectedEvent: EventsResolver },
          },
          {
            loadChildren: () =>
              import('./core/pages/explore-event/explore-event.module').then(
                m => m.ExploreEventModule
              ),
            path: 'event-exploration/:eventId',
            data: {
              breadcrumb: 'Event Exploration',
            },
            canActivate: [UnauthGuard],
            resolve: { selectedEvent: EventExplorationResolver },
          },
          {
            loadChildren: () =>
              import(
                './core/pages/rt-events/rt-event-exploration/rt-event-exploration.module'
              ).then(m => m.RtEventExplorationModule),
            path: 'rt-event-exploration/:eventId',
            data: {
              breadcrumb: 'Event Exploration',
            },
            canActivate: [UnauthGuard],
            resolve: { rtEvent: RtEventResolver },
          },
        ],
      },
      {
        path: 'overview',
        canActivate: [UnauthGuard],
        data: { breadcrumb: 'Overview' },
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./core/pages/overview/overview.module').then(
                m => m.OverviewModule
              ),
            resolve: { selectedEvent: OverviewResolver },
          },
          {
            loadChildren: () =>
              import('./core/pages/explore-event/explore-event.module').then(
                m => m.ExploreEventModule
              ),
            path: 'event-exploration/:eventId',
            data: {
              breadcrumb: 'Event Exploration',
            },
            canActivate: [UnauthGuard],
            resolve: { selectedEvent: EventExplorationResolver },
          },
          {
            loadChildren: () =>
              import(
                './core/pages/rt-events/rt-event-exploration/rt-event-exploration.module'
              ).then(m => m.RtEventExplorationModule),
            path: 'rt-event-exploration/:eventId',
            data: {
              breadcrumb: 'Event Exploration',
            },
            canActivate: [UnauthGuard],
            resolve: { rtEvent: RtEventResolver },
          },
        ],
      },
      {
        loadChildren: () =>
          import('./core/pages/admin/admin/admin.module').then(
            m => m.AdminModule
          ),
        path: 'admin',
        data: { breadcrumb: 'Admin Settings' },
        canActivate: [AdminGuard],
        resolve: { shipIdToNameMap: AdminResolver },
      },
      {
        path: 'fleet',
        data: { breadcrumb: 'Fleet' },
        children: [
          {
            path: '',
            canActivate: [ShipCaptainGuard],
            loadChildren: () =>
              import('./core/pages/fleet/fleet.module').then(
                m => m.FleetModule
              ),
          },
          {
            loadChildren: () =>
              import('./core/pages/fleet/ship/ship.module').then(
                m => m.ShipModule
              ),
            path: 'ship/:shipId',
            data: { breadcrumb: 'Ship' },
            canActivate: [UnauthGuard],
            resolve: {
              ship: ShipResolver,
              _: ShipSailDataResolver,
              shipEventCountResolver: ShipEventCountResolver,
              shipScoringFactorsResolver: ShipScoringFactorsResolver,
            },
          },
          {
            loadChildren: () =>
              import('./core/pages/explore-event/explore-event.module').then(
                m => m.ExploreEventModule
              ),
            path: 'event-exploration/:eventId',
            data: {
              breadcrumb: 'Event Exploration',
            },
            canActivate: [UnauthGuard],
            resolve: { selectedEvent: EventExplorationResolver },
          },
        ],
      },
      {
        path: 'live',
        data: { breadcrumb: 'Live View' },
        canActivate: [ShipCaptainGuard],
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./core/pages/live/live.module').then(m => m.LiveModule),
          },
          {
            loadChildren: () =>
              import('./core/pages/fleet/ship/ship.module').then(
                m => m.ShipModule
              ),
            path: 'ship/:shipId',
            data: { breadcrumb: 'Ship' },
            canActivate: [UnauthGuard],
            resolve: {
              ship: ShipResolver,
              _: ShipSailDataResolver,
              shipEventCountResolver: ShipEventCountResolver,
              shipScoringFactorsResolver: ShipScoringFactorsResolver,
            },
          },
        ],
      },
      {
        path: 'fuel',
        data: {
          breadcrumb: 'Fuel Optimization',
        },
        canActivate: [FeatureFuelFlagGuard],
        loadChildren: () =>
          import(
            './core/pages/fuel-optimization/fuel-optimization.module'
          ).then(m => m.FuelOptimizationModule),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview',
      },
    ],
  },
  {
    loadChildren: () =>
      import('./core/pages/auth/login/login.module').then(m => m.LoginModule),
    path: 'login',
    canActivate: [AuthGuard],
  },
  {
    loadChildren: () =>
      import('./core/pages/auth/reset-pass/reset.module').then(
        m => m.ResetModule
      ),
    path: 'reset-password',
    canActivate: [AuthGuard],
  },
  {
    loadChildren: () =>
      import('./core/pages/auth/user-management/user-management.module').then(
        m => m.UserManagementModule
      ),
    path: 'user-management',
    canActivate: [AuthGuard, UserRecoveryGuard],
  },
  {
    loadChildren: () =>
      import('./core/pages/auth/new-password/new-password.module').then(
        m => m.NewPasswordModule
      ),
    path: 'new-password',
    canActivate: [AuthGuard, NewPassGuard],
  },
  {
    loadChildren: () =>
      import('./core/pages/auth/sign-up/sign-up.module').then(
        m => m.SignUpModule
      ),
    path: 'sign-up',
    canActivate: [FirstLoginGuard],
  },
  {
    loadChildren: () =>
      import('./core/pages/auth/account-created/account-created.module').then(
        m => m.AccountCreatedModule
      ),
    path: 'account-created',
    canActivate: [AuthGuard],
  },
  {
    loadChildren: () =>
      import(
        './core/pages/auth/password-recovery/password-recovery.module'
      ).then(m => m.PasswordRecoveryModule),
    path: 'password-recovery',
    canActivate: [AuthGuard],
  },
  {
    loadChildren: () =>
      import('./core/pages/auth/email-verified/email-verified.module').then(
        m => m.EmailVerifiedModule
      ),
    path: 'email-verification',
    canActivate: [EmailVerificationGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
