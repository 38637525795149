import * as AuthenticationActions from './actions/authentication.actions';
import * as FiltersActions from './actions/filters.actions';
import * as ShipsActions from './actions/ships.actions';

import * as AuthenticationSelectors from './selectors/authentication.selectors';
import * as FiltersSelectors from './selectors/filters.selectore';
import * as ShipsSelectors from './selectors/ships.selectors';

import * as FuelOptimizationAction from './actions/fuel-optimization.actions';
import * as FuelOptimizationSelectors from './selectors/fuel-optimization.selectors';

import * as EventsActions from './actions/events.actions';
import * as EventsSelectors from './selectors/events.selectors';

import * as OverviewActions from './actions/overview.actions';
import * as OverviewSelector from './selectors/overview.selectors';

import * as CommentsActions from './actions/comments.actions';
import * as CommentsSelectors from './selectors/comments.selectors';

export {
  AuthenticationActions,
  OverviewActions,
  ShipsActions,
  EventsActions,
  FiltersActions,
  FuelOptimizationAction,
  CommentsActions,
};
export {
  AuthenticationSelectors,
  OverviewSelector,
  ShipsSelectors,
  EventsSelectors,
  FiltersSelectors,
  FuelOptimizationSelectors,
  CommentsSelectors,
};

export interface Range {
  min?: number;
  max?: number;
}
