import {
  MapboxSettingType,
  MapboxStyleSetting,
} from 'src/app/mapbox/models/mapbox.models';

export const LABELS_SETTING_ID = 'labels';

export const GetLabelsSetting = (): MapboxStyleSetting => ({
  type: MapboxSettingType.FromStyle,
  layerIds: [
    'settlement-subdivision-label',
    'settlement-minor-label',
    'settlement-major-label',
    'state-label',
    'country-label',
    'continent-label',
  ],
});
