import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DestroyRef } from 'projects/orca-lib-main/projects/orca-lib/src/lib/services/destroy-ref.service';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs';
import { EventMapEntity } from 'src/app/mapbox/models/mapbox.models';
import {
  DateFilterKeys,
  Dates,
} from 'src/app/shared/view-models/dates.view.model';
import { EventFilters, EventsSortBy } from '../../models/events-filters.model';
import { Event, EventLocation } from '../../models/events.model';
import { ShipPosition } from '../../models/map.models';
import { LoggerService } from '../../services/logger.service';
import { selectAuthenticatedUser } from '../../store/selectors/authentication.selectors';
import {
  selectFilterdDatesKey,
  selectFilteredDates,
} from '../../store/selectors/filters.selectore';
import { CoreState } from '../../store/state/core.state';
import {
  mapEventsToMapEntities,
  mapEventToEventsType,
  mapEventToMapEntity,
} from '../../utils/events';
import { Events } from '../../view-models/event.view.model';
import { eventsSortList } from '../../view-models/events.sort.view.model';
import { AppliedEventFilters } from '../../view-models/filter.view.model';

const EVENT_VIEW_HEIGHT = 25000000;

@Injectable({
  providedIn: 'root',
})
export class EventsStore {
  private logger = new LoggerService();

  // DI
  private readonly destroyRef = inject(DestroyRef);
  private readonly store: Store<CoreState> = inject(Store);

  // Events
  public readonly events$ = new BehaviorSubject<Event[]>([]);
  public readonly totalEventsCount$ = new BehaviorSubject<number>(0);
  public readonly mapEvents$ = new BehaviorSubject<EventMapEntity[]>([]);
  public readonly transformedEvents$ = new BehaviorSubject<Events[]>([]);
  public readonly dataExists$ = this.transformedEvents$.pipe(
    map(events => events.length > 0)
  );
  public readonly selectedEvent$ = new BehaviorSubject<Events | null>(null);
  public readonly selectedEventMapEntity$ = new BehaviorSubject<
    EventMapEntity | undefined
  >(undefined);
  public readonly selectedEventPosition$ = new BehaviorSubject<
    ShipPosition | undefined
  >(undefined);

  // Filters
  public readonly eventFilters$ = new BehaviorSubject<EventFilters | null>(
    null
  );
  public readonly appliedEventFilters$ =
    new BehaviorSubject<AppliedEventFilters | null>(null);

  // Sort By
  public readonly selectedSortOption$ =
    new BehaviorSubject<EventsSortBy | null>(eventsSortList[0].value);

  // Current events page from pagination
  public readonly currentPage$ = new BehaviorSubject<number>(1);

  // Properties from global store
  public readonly daysFilter$ = new BehaviorSubject<Dates | null>(null);
  public readonly dateFilterKey$ = new BehaviorSubject<DateFilterKeys | null>(
    null
  );
  public readonly selectedFleet$ = new BehaviorSubject<number | null>(null);

  constructor() {
    this.observeDaysFilter();
    this.observeFleetChange();
  }

  setEvents(events: Event[]) {
    const transformedEvents = events.map(event => mapEventToEventsType(event));

    this.events$.next(events);
    this.transformedEvents$.next(transformedEvents);
  }

  setMapEvents(eventsLocation: EventLocation[]) {
    const mapEvents = mapEventsToMapEntities(eventsLocation, null);
    this.mapEvents$.next(mapEvents);
  }

  appendEvents(events: Event[]) {
    const existingEvents = this.events$.value;
    let uniqueEvents = events.filter(event =>
      existingEvents.every(e => e.id !== event.id)
    );
    uniqueEvents = existingEvents.concat(uniqueEvents);

    this.setEvents(uniqueEvents);
  }

  setSelectedEvent(event: Events) {
    const mapEntity = mapEventToMapEntity(event);
    this.selectedEvent$.next(event);
    this.selectedEventMapEntity$.next(mapEntity);
    this.selectedEventPosition$.next({
      lat: event.lat,
      long: event.long,
      height: EVENT_VIEW_HEIGHT,
    } as ShipPosition);
  }

  observeDaysFilter() {
    this.store
      .select(selectFilterdDatesKey)
      .pipe(takeUntil(this.destroyRef.destroy$))
      .subscribe(key => {
        this.dateFilterKey$.next(key);
      });

    this.store
      .select(selectFilteredDates)
      .pipe(takeUntil(this.destroyRef.destroy$))
      .subscribe(dates => {
        const currentDates = this.daysFilter$.value;
        const toDateString = (date: Date) => date.toISOString().split('T')[0];
        if (
          currentDates &&
          toDateString(new Date(currentDates.startDate)) ===
            toDateString(new Date(dates.startDate))
        ) {
          return;
        }
        this.daysFilter$.next(dates);
      });
  }

  observeFleetChange() {
    combineLatest([this.store.select(selectAuthenticatedUser)])
      .pipe(
        takeUntil(this.destroyRef.destroy$),
        distinctUntilChanged(([prevAuth], [currAuth]) => {
          return prevAuth?.token === currAuth?.token;
        })
      )
      .subscribe(([auth]) => {
        const fleetId = auth?.fleetId ?? null;
        this.selectedFleet$.next(fleetId);
      });
  }
}
