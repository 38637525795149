import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SubfleetListComponent } from './subfleet-list/subfleet-list.component';

@Component({
  selector: 'app-subfleet-management',
  standalone: true,
  imports: [CommonModule, SubfleetListComponent],
  templateUrl: './subfleet-management.component.html',
  styleUrls: ['./subfleet-management.component.scss'],
})
export class SubfleetManagementComponent {}
