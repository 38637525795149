import {
  DateGraphFilter,
  DateGraphFilterKeys,
  dateGraphFilters,
} from 'src/app/shared/view-models/dates.view.model';

export interface FuelOptimizationState {
  readonly potentialSavingsGraphSelectedDate: Partial<DateGraphFilter>;
  readonly accelerationTimeGraphSelectedDate: Partial<DateGraphFilter>;
}

export const initialFuelOptimizationState: FuelOptimizationState = {
  potentialSavingsGraphSelectedDate: {
    [DateGraphFilterKeys.SixMonths]:
      dateGraphFilters[DateGraphFilterKeys.SixMonths],
  },
  accelerationTimeGraphSelectedDate: {
    [DateGraphFilterKeys.SixMonths]:
      dateGraphFilters[DateGraphFilterKeys.SixMonths],
  },
};
