import {
  MapboxSettingType,
  MapboxStyleSetting,
} from 'src/app/mapbox/models/mapbox.models';

export const CONTOUR_SETTING_ID = 'Contour';

export const GetCountriesContourSetting = (): MapboxStyleSetting => ({
  type: MapboxSettingType.FromStyle,
  layerIds: ['countries-contour'],
});
