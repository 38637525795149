<div class="menu-container">
  <div class="buttons-container">
    <button
      *ngFor="let button of buttons"
      class="menu-button {{ button.id }}"
      (click)="onClick(button)">
      <img [src]="button.iconUrl" alt="icon-menu" />
    </button>
  </div>

  <div *ngIf="openMenu" class="menu">
    <div class="menu-title">
      <ng-container *ngTemplateOutlet="menuTitle"></ng-container>
    </div>

    <div class="menu-content">
      <ng-container *ngTemplateOutlet="menuContent"></ng-container>
    </div>
  </div>
</div>
