<div class="dialog-container">
  <h2 mat-dialog-title class="dialog-title">
    <button mat-icon-button class="close-button" [mat-dialog-close]="false">
      <img src="assets/icons/close.svg" alt="close" />
    </button>

    <div class="title-text">{{ title }}</div>
  </h2>

  <div class="dialog-subtitle" *ngIf="subtitle">
    {{ subtitle }}
  </div>

  <mat-dialog-content>
    <form [formGroup]="formGroup" class="dialog-form">
      <div class="form-errors" *ngIf="formGroup.errors && formGroup.touched">
        <mat-error *ngFor="let error of formGroup.errors | keyvalue">
          {{ error.value }}
        </mat-error>
      </div>

      <ng-content></ng-content>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="dialog-actions">
    <button
      mat-flat-button
      color="primary"
      (click)="onSubmit()"
      [disabled]="!formGroup.valid">
      {{ submitButtonText }}
    </button>
    <button
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="false"
      class="cancel-button"
      cdkFocusInitial>
      {{ cancelButtonText }}
    </button>
  </mat-dialog-actions>
</div>
