import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  EMPTY,
  filter,
  from,
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';
import { EventsService } from '../services/events.service';
import { FleetService } from '../services/fleet.service';
import { ShipsService } from '../services/ships.service';
import {
  fetchShipLiveData,
  setSelectedShipIdOnViewRtEvent,
  setShipConnectionStatusOnPageCreation,
} from '../store/actions/ships.actions';
import { CoreState } from '../store/state/core.state';
import { AuthenticationSelectors, ShipsSelectors } from '../store/types';
import { RtEventData } from '../view-models/event.view.model';

@Injectable({
  providedIn: 'root',
})
export class RtEventResolver implements Resolve<RtEventData> {
  constructor(
    private eventService: EventsService,
    private shipsService: ShipsService,
    private fleetsService: FleetService,
    private store: Store<CoreState>,
    private router: Router
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<RtEventData> {
    const eventId = route.params['eventId'];

    return combineLatest([
      this.store.select(ShipsSelectors.selectShips),
      this.store.select(AuthenticationSelectors.selectAuthenticatedUser),
    ]).pipe(
      filter(([ships, authUser]) => authUser?.token != '' && ships.length > 0),
      take(1),
      switchMap(([ships]) =>
        from(this.eventService.getRtEventById(eventId)).pipe(
          switchMap(event => {
            if (!event) {
              this.router.navigateByUrl('/private/overview');
              return EMPTY;
            }
            this.store.dispatch(
              setSelectedShipIdOnViewRtEvent({
                shipId: event?.shipId,
              })
            );

            const ship = ships.find(s => s.shipId === event.shipId);

            if (!event.end) {
              this.store.dispatch(
                setShipConnectionStatusOnPageCreation({
                  fleetId: event.fleetId,
                  shipId: event.shipId,
                })
              );
            }

            return from(
              this.shipsService.getShipLiveData$(ship?.shipId ?? 0)
            ).pipe(
              switchMap(liveShipData => {
                return from(
                  this.fleetsService.getShipLastLocations$(
                    ship?.shipId ?? 0,
                    new Date(event.timestampEnd ?? new Date())
                  )
                ).pipe(
                  switchMap(lastLocations => {
                    if (!event.polygonId) {
                      return of({
                        rtEvent: {
                          ...event,
                          shipName: ship ? ship.shipName : '',
                          lat: event.lat,
                          long: event.long,
                        },
                        shipLastLocations: lastLocations
                          ? lastLocations
                          : undefined,
                      });
                    } else {
                      this.store.dispatch(
                        fetchShipLiveData({ shipId: ship?.shipId ?? 0 })
                      );
                      return from(this.fleetsService.getGeoPolygons$()).pipe(
                        switchMap(geoPolygons => {
                          const polygon = geoPolygons.find(
                            p => p.eventId === event.eventId
                          );
                          return of({
                            rtEvent: {
                              ...event,
                              shipName: ship ? ship.shipName : '',
                              lat: event.lat,
                              long: event.long,
                            },
                            shipLastLocations: lastLocations ?? undefined,
                            polygons: polygon ? [polygon] : undefined,
                          });
                        })
                      );
                    }
                  })
                );
              })
            );
          })
        )
      )
    );
  }
}
