<div class="groups-container">
  <div class="layer-content" *ngFor="let optionGroup of optionGroups">
    <div class="layer-title">{{ optionGroup.id }}</div>
    <div class="layer-item" *ngFor="let option of optionGroup.options">
      <mat-checkbox
        [checked]="option.isChecked"
        color="primary"
        (change)="onSelection(optionGroup.id, option.id)"
        >{{ option.id }}</mat-checkbox
      >
    </div>
  </div>
</div>
