import _ from 'lodash';
import { GeoJSONFeature, LngLat } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import {
  MAX_LATITUDE,
  MAX_LONGTITUDE,
  MIN_LATITUDE,
  MIN_LONGTITUDE,
} from 'src/app/mapbox/models/mapbox-configurations.models';
import {
  MapboxCustomSetting,
  MapboxSettingType,
  PolygonEntity,
} from 'src/app/mapbox/models/mapbox.models';
import {
  CreatePopupFromComponent,
  GetCenterOfPolygon,
} from 'src/app/mapbox/utils/mapbox.utility';

export const EVENT_AREA_SETTING_ID = 'EventArea';
const EVENT_AREA_FILL_COLOR = 'rgba(156, 156, 156, 0.30)';
const EVENT_AREA_BORDER_COLOR = '#9C9C9C';

export const GetEventAreaSetting = (
  eventAreaZones$: BehaviorSubject<PolygonEntity[]>
): MapboxCustomSetting => {
  const getEventAreaFeatures = () => {
    return eventAreaZones$.getValue().map(
      (eventAreaZone: PolygonEntity): GeoJSONFeature => ({
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [eventAreaZone.coordinates],
        },
        source: 'EventArea',
        properties: eventAreaZone,
      })
    );
  };
  const getPopups = () =>
    eventAreaZones$
      .getValue()
      .filter(eventZone => {
        const center = GetCenterOfPolygon(eventZone.coordinates);
        return (
          _.inRange(center[0], MIN_LONGTITUDE, MAX_LONGTITUDE) &&
          _.inRange(center[1], MIN_LATITUDE, MAX_LATITUDE)
        );
      })
      .map(eventZone => {
        const center = GetCenterOfPolygon(eventZone.coordinates);
        const label = {
          long: center[0],
          lat: center[1],
          textLabel: eventZone.textLabel?.toUpperCase(),
          offset: 0,
        };
        const popup = CreatePopupFromComponent('app-map-label', {
          label,
        })
          .setOffset(label.offset)
          .setLngLat(new LngLat(label.long, label.lat).wrap());
        popup.addClassName('EventArea');
        popup.addClassName('red');
        return popup;
      });
  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'EventArea',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getEventAreaFeatures(),
          },
        },
        updateFeatures: getEventAreaFeatures,
      },
    ],
    layers: [
      {
        id: 'EventArea',
        type: 'fill',
        source: 'EventArea',
        paint: {
          'fill-color': EVENT_AREA_FILL_COLOR,
          'fill-outline-color': EVENT_AREA_BORDER_COLOR,
        },
      },
    ],
    popups: getPopups(),
    updatePopups: getPopups,
    maxZoomForPopups: 5,
  };
};
