import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  DateFilter,
  DateFilterKeys,
  Dates,
} from 'src/app/shared/view-models/dates.view.model';
import { RoutingService } from '../../services/routing.service';
import { CoreState } from '../../store/state/core.state';
import { FiltersActions, FiltersSelectors } from '../../store/types';
import { Breadcrumb } from '../../view-models/routes.view.model';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  breadcrumbs$!: Observable<Breadcrumb[]>;
  subtitle$!: Observable<string>;
  isShort$!: Observable<boolean>;
  shouldShowFleetAndTimeDropdowns$!: Observable<boolean>;
  shouldShowFleetDropdowns$!: Observable<boolean>;
  currentDateFilter$!: Observable<DateFilterKeys>;
  currentDateFilterRange$!: Observable<Dates>;
  isDatesFilterFilterDisabled$!: Observable<boolean>;
  isCustomDatesFiltersDisabled$!: Observable<boolean>;
  isCustomDateFilterOptionDisabled$!: Observable<boolean>;
  hideSideNav$!: Observable<boolean>;

  constructor(
    public routing: RoutingService,
    private store: Store<CoreState>
  ) {}

  ngOnInit(): void {
    this.breadcrumbs$ = this.routing.breadcrumbs$;
    this.subtitle$ = this.routing.getSubtitle();
    this.isShort$ = this.routing.isOverviewPage();
    this.shouldShowFleetAndTimeDropdowns$ =
      this.routing.shouldShowFleetAndTimeDropdowns();
    this.shouldShowFleetDropdowns$ = this.routing.shouldShowFleetDropdowns();
    this.currentDateFilter$ = this.store.select(
      FiltersSelectors.selectFilterdDatesKey
    );

    this.currentDateFilterRange$ = this.store.select(
      FiltersSelectors.selectFilteredDates
    );

    this.isDatesFilterFilterDisabled$ = this.store.select(
      FiltersSelectors.selectIsDatesFilterDisabled
    );

    this.isCustomDateFilterOptionDisabled$ = this.store.select(
      FiltersSelectors.selectIsCustomDateOptionDisabled
    );

    this.hideSideNav$ = this.routing.isLiveNewTab$();
  }

  onDatesEvent(dateFilter: Partial<DateFilter>) {
    this.store.dispatch(
      FiltersActions.saveSelectedDatesFilter({
        dateFilter,
      })
    );
  }
}
