<div class="container">
  <div class="title">
    <div class="wrap-text">
      <span class="title-text">{{ fleetName$ | async }} - Latest Reports</span>
      <span class="subtitle-text"
        >View or download a report from the following list</span
      >
    </div>
    <img (click)="onNoClick()" src="./assets/icons/exit.svg" />
  </div>
  <div class="separator"></div>
  <div class="reports-count-and-dropdown">
    <span>{{ filteredList.length }} Reports</span>
    <mat-form-field
      appearance="fill"
      class="custom-form-field-selection custom-events-form-field-selection selection">
      <mat-select
        panelClass="custom-select"
        (valueChange)="onFilterChange($event)"
        [value]="selectedFilter">
        <mat-option value="all">All Documents</mat-option>
        <mat-option *ngFor="let filter of filters" [value]="filter">
          {{ filter }}
        </mat-option>
        <mat-option *ngIf="isUserOrcaAdmin$ | async" value="new"
          >Add New</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <ng-container *ngIf="selectedFilter === 'new'">
    <form
      (ngSubmit)="onUploadReport()"
      #uploadForm="ngForm"
      class="upload-form">
      <div class="form-group">
        <label for="pdfFile">Upload PDF:</label>
        <input
          type="file"
          id="pdfFile"
          name="pdfFile"
          accept="application/pdf"
          (change)="onFileSelected($event)"
          required />
      </div>

      <div class="form-group">
        <label for="year">Select Year:</label>
        <select id="year" name="year" [(ngModel)]="selectedYear" required>
          <option *ngFor="let year of availableYears" [value]="year">
            {{ year }}
          </option>
        </select>
      </div>

      <button type="submit" [disabled]="!uploadForm.form.valid">Submit</button>
    </form>
  </ng-container>
  <div *ngIf="selectedFilter !== 'new'">
    <div *ngIf="filteredList.length > 0" class="reports-list">
      <app-report-item
        *ngFor="let report of filteredList"
        [file_name]="report.file_name"
        [last_updated]="report.last_updated"
        [url]="report.url"></app-report-item>
    </div>
    <div *ngIf="filteredList.length === 0" class="no-results">
      <img src="./assets/images/no_results.svg" />
      <span class="no-results-title">No results</span>
      <span class="no-results-subtitle"
        >We couldn't find any relevant reports.</span
      >
    </div>
  </div>
</div>
