import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { MapEntity } from 'src/app/mapbox/models/mapbox.models';
import { ShipPosition } from '../../models/map.models';
import { CoreState } from '../../store/state/core.state';
import { EventsActions, EventsSelectors } from '../../store/types';

const EVENT_VIEW_HEIGHT = 37000000;

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit, AfterViewInit {
  dataExists$!: Observable<boolean>;
  mapEvents$: BehaviorSubject<MapEntity[]> = new BehaviorSubject<MapEntity[]>(
    []
  );
  selectedEventId$ = new BehaviorSubject<string>('');
  selectedEventPosition$: BehaviorSubject<ShipPosition | undefined> =
    new BehaviorSubject<ShipPosition | undefined>(undefined);
  constructor(private store: Store<CoreState>) {}
  ngOnInit(): void {
    this.dataExists$ = this.store
      .select(EventsSelectors.selectGeneralFilters)
      .pipe(
        // eslint-disable-next-line @ngrx/avoid-mapping-selectors
        map(generalFilters =>
          generalFilters.shipNames.length > 0 ? true : false
        )
      );
    this.store
      .select(EventsSelectors.selectMapEvents)
      .subscribe(events => this.mapEvents$.next(events));
    this.store.select(EventsSelectors.selectSelectedEvent).subscribe(event => {
      this.selectedEventId$.next(event?.eventId ?? '');
      if (event) {
        this.selectedEventPosition$.next({
          lat: event.lat,
          long: event.long,
          height: EVENT_VIEW_HEIGHT,
        });
      }
    });
    this.selectedEventId$.subscribe(eventId =>
      EventsActions.setSelectedEventsMapEvent({ eventId })
    );
  }

  ngAfterViewInit(): void {
    this.store
      .select(EventsSelectors.selectSelectedEventPosition)
      .subscribe(position => {
        this.selectedEventPosition$.next({
          height: EVENT_VIEW_HEIGHT,
          ...position,
        });
      });
  }

  async onSelectedMapEvent(entityParams: string[]): Promise<void> {
    const eventId = entityParams[0];
    this.store.dispatch(EventsActions.setSelectedEventsMapEvent({ eventId }));
  }
}
