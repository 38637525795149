import {
  InsightContentTemplates,
  InsightLinkTemplates,
  insightTitleByType,
} from 'src/app/shared/models/InsightData.model';
import { InsightCard } from 'src/app/shared/view-models/insight.view.model';
import { InsightDataDto, InsightType } from '../models/insights.models';

export function mapInsightsDtoToInsights(
  insights: InsightDataDto[]
): InsightCard[] {
  const allowedInsightTypes: InsightType[] = Object.values(InsightType);

  return insights
    .filter((insight: InsightDataDto) =>
      allowedInsightTypes.includes(insight.insightType)
    )
    .map((insight: InsightDataDto) => {
      return {
        insightId: insight.insightId,
        insightType: insight.insightType,
        title: insightTitleByType[insight.insightType],
        content: InsightContentTemplates[insight.insightType](
          insight.insightData,
          insight.lastUpdatedAt
        ),
        dateUpdated: new Date(insight.lastUpdatedAt),
        link: InsightLinkTemplates[insight.insightType](
          insight.insightData,
          insight.lastUpdatedAt
        ),
        likeVote: insight.likeVote,
        readStatus: insight.readStatus,
      };
    });
}
