import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
  selector: 'app-outside-click-detector',
  standalone: true,
  templateUrl: './outside-click-detector.component.html',
})
export class OutsideClickDetectorComponent {
  @Output() outsideClick: EventEmitter<void> = new EventEmitter<void>();
  clickInsideFlag: boolean = false;

  @HostListener('document:click')
  documentClick() {
    if (!this.clickInsideFlag) {
      this.outsideClick.emit();
    }
    this.clickInsideFlag = false;
  }

  @HostListener('click')
  hostClick() {
    this.clickInsideFlag = true;
  }
}
