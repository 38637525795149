import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateIndex',
  standalone: true,
})
export class CalculateIndexPipe implements PipeTransform {
  transform(i: number, pageIndex: number): number {
    if (pageIndex === 0) {
      return i + 1;
    }
    const firstPageRowCount = 8;
    const rowsPerOtherPage = 22;
    const previousPagesCount = pageIndex - 1;
    const rowsFromPreviousPages = previousPagesCount * rowsPerOtherPage;
    const currentPageRowNumber = i + 1;
    return firstPageRowCount + rowsFromPreviousPages + currentPageRowNumber;
  }
}
