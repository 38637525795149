import { LngLatBoundsLike, LngLatLike } from 'mapbox-gl';

export const MAX_LATITUDE = 85;
export const MIN_LATITUDE = -80;
export const MAX_LONGTITUDE = 360;
export const MIN_LONGTITUDE = -360;
export const DEFAULT_LOWER_BOUNDS: LngLatLike = [MIN_LONGTITUDE, MIN_LATITUDE];
export const DEFAULT_UPPER_BOUNDS: LngLatLike = [MAX_LONGTITUDE, MAX_LATITUDE];
export const DEFAULT_MAX_BOUNDS: LngLatBoundsLike = [
  DEFAULT_LOWER_BOUNDS,
  DEFAULT_UPPER_BOUNDS,
];
