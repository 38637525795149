import { BehaviorSubject } from 'rxjs';
import {
  MapboxLegendConfigurations,
  MapboxLegendTick,
} from 'src/app/mapbox/models/mapbox-legend.models';
import {
  MapboxCustomSetting,
  MapboxSettingType,
} from 'src/app/mapbox/models/mapbox.models';
import { environment } from 'src/environments/environment';

export const WIND_LEGEND_TICKS: MapboxLegendTick[] = [
  { value: 0, color: 'transparent' },
  { value: 0.5, color: '#5579a9' },
  { value: 1, color: '#4d8fc1' },
  { value: 1.5, color: '#4da3d3' },
  { value: 2, color: '#4db9d8' },
  { value: 3, color: '#65c8cb' },
  { value: 4, color: '#4dacb5' },
  { value: 5, color: '#4d9B99' },
  { value: 6, color: '#4f9175' },
  { value: 7, color: '#5caa76' },
  { value: 8, color: '#75bc7e' },
  { value: 10, color: '#b0d292' },
  { value: 12, color: '#ccdc85' },
  { value: 14, color: '#e8e55d' },
  { value: 16, color: '#fdf483' },
  { value: 18, color: '#fdf4cb' },
  { value: 20, color: '#fcdca9' },
  { value: 25, color: '#f5cf61' },
  { value: 30, color: '#f19c51' },
  { value: 35, color: '#ee8659' },
  { value: 45, color: '#ec6e5f' },
  { value: 55, color: '#e8515f' },
  { value: 65, color: '#d5515f' },
  { value: 75, color: '#b95d63' },
  { value: 90, color: '#a16363' },
  { value: 105, color: '#896b68' },
];

export const WIND_LEGEND_CONFIG: MapboxLegendConfigurations = {
  id: 'wind',
  unitsName: 'kts',
  legendTicks: WIND_LEGEND_TICKS,
};

export const WIND_SETTING_ID = 'Wind';

export const GetWindSetting = (
  sampleDateString$: BehaviorSubject<string>
): MapboxCustomSetting => {
  const getTileSource = () => [
    `${
      environment.meteoblue.tilesUrl
    }/vector/ICONAUTO/${sampleDateString$.getValue()}/windsoftRainbowColortable~32~10%20m%20above%20gnd~hourly~none~contourSteps~0.0~0.5~1.0~1.5~2.0~3.0~4.0~5.0~6.0~7.0~8.0~10.0~12.0~14.0~16.0~18.0~20.0~25.0~30.0~35.0~45.0~55.0~65.0~75.0~90.0~105.0_arrowshourly~31~10%20m%20above%20gnd~hourly~none~windArrows/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=kn&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${
      environment.meteoblue.apiKey
    }`,
  ];
  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'wind-vector-tile-source',
        source: {
          type: 'vector',
          tiles: getTileSource(),
        },
        updateTilesSource: getTileSource,
      },
    ],
    layers: [
      {
        id: 'wind-speed-fill',
        source: 'wind-vector-tile-source',
        'source-layer': 'windsoftRainbowColortable',
        type: 'fill',
        paint: {
          'fill-antialias': false,
          'fill-opacity': 0.75,
          'fill-color': [
            'interpolate',
            ['linear'],
            ['get', 'minValue'],
            ...WIND_LEGEND_TICKS.flatMap(item => [item.value, item.color]),
          ],
        },
        filter: ['all', ['==', '$type', 'Polygon']],
      },
      {
        id: 'wind-arrows',
        type: 'line',
        source: 'wind-vector-tile-source',
        'source-layer': 'arrowshourly',
        paint: {
          'line-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            'black',
            '#848884',
          ],
          'line-width': {
            stops: [
              [0, 1.5],
              [22, 2.5],
            ],
          },
          'line-opacity': 0.8,
        },
        filter: ['all', ['==', '$type', 'LineString']],
      },
    ],
    popups: [],
  };
};
