import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { FeatureKey } from '../models/feature-flag.model';
import { FeatureFlagService } from '../services/feature-toggle.service';

export const featureFlagGuard: CanActivateFn = async (
  route,
  _state
): Promise<boolean> => {
  const featureFlagService = inject(FeatureFlagService);
  const router = inject(Router);

  const featureFlag = route.data['featureFlag'] as FeatureKey;
  const isEnabled = await firstValueFrom(
    featureFlagService.getFeatureFlag$(featureFlag)
  );

  if (!isEnabled) {
    router.navigate(['/private/overview']);
  }

  return isEnabled;
};
