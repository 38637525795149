import dayjs from 'dayjs';
import _ from 'lodash';
import { GeoJSONFeature, LngLat, Popup } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { ShipSafteyScore } from 'src/app/core/models/ship.model';
import {
  MapboxCustomSetting,
  MapboxSettingType,
  MapEntity,
} from 'src/app/mapbox/models/mapbox.models';
import { CreatePopupFromComponent } from 'src/app/mapbox/utils/mapbox.utility';
import { Ship, ShipCardOuputs, ShipScreenshot } from '../models/ship.models';

export const SHIP_FOCUS_SETTING_ID = 'ShipFocus';

export async function CreateShipFocusSetting(
  isShipCaptain: boolean,
  outputs: ShipCardOuputs,
  screenshot$: BehaviorSubject<ShipScreenshot | null>,
  ship$: BehaviorSubject<Ship | null>,
  shipEvents$: BehaviorSubject<MapEntity[]>,
  shipSaftyScores$: BehaviorSubject<ShipSafteyScore | null>
): Promise<MapboxCustomSetting> {
  const getPopups = () =>
    ship$.getValue()
      ? [
          CreateShipStatusPopup(
            ship$.getValue()!,
            screenshot$.getValue(),
            shipSaftyScores$.getValue()?.scoreStart ?? NaN,
            shipSaftyScores$.getValue()?.scoreEnd ?? NaN,
            outputs,
            isShipCaptain
          ),
        ]
      : [];
  const getEventFeatures = (): GeoJSONFeature[] =>
    shipEvents$.getValue().map(
      (event): GeoJSONFeature => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [event.long, event.lat],
        },
        source: 'ship-focus',
        properties: {
          id: event.id,
          icon: event.image,
          category: 'event',
          textLabel: event.textLabel,
        },
      })
    );
  const getShipFeature = (): GeoJSONFeature[] =>
    ship$.getValue()
      ? [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [
                ship$.getValue()!.longitude,
                ship$.getValue()!.latitude,
              ],
            },
            source: 'ship-focus',
            properties: {
              id: ship$.getValue()!.shipId,
              icon: dayjs(new Date())
                .subtract(4, 'hours')
                .isBefore(ship$.getValue()!.lastConnection)
                ? `/assets/map/ships/${_.snakeCase(
                    ship$.getValue()!.shipType
                  )}/ship_icon.png`
                : `/assets/map/ships/${_.snakeCase(
                    ship$.getValue()!.shipType
                  )}/faded_ship_icon.png`,
              category: 'ship',
              textLabel: ship$.getValue()!.shipName,
            },
          },
        ]
      : [];

  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'ship-focus',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getEventFeatures().concat(getShipFeature()),
          },
        },
        updateFeatures: () => getEventFeatures().concat(getShipFeature()),
      },
    ],
    layers: [
      {
        id: 'ship-focus',
        type: 'symbol',
        source: 'ship-focus',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      },
    ],
    popups: getPopups(),
    updatePopups: getPopups,
  };
}

function CreateShipStatusPopup(
  ship: Ship,
  screenshot: ShipScreenshot | null,
  startDateAvg: number,
  endDateAvg: number,
  shipCardOutputs: ShipCardOuputs,
  isShipCaptain: boolean
): Popup {
  const popup = CreatePopupFromComponent<{
    shipData: Ship | null;
    screenshot: ShipScreenshot | null;
    endDateAvgShipSafetyScore: number;
    startDateAvgShipSafetyScore: number;
    showLiveStreamFlag: boolean;
    isLiveStreamOpen: boolean;
    shipCardOutputs: ShipCardOuputs;
    isShipCaptain: boolean;
  }>('app-ship-profile-card', {
    shipData: ship,
    screenshot: screenshot,
    endDateAvgShipSafetyScore: endDateAvg,
    startDateAvgShipSafetyScore: startDateAvg,
    showLiveStreamFlag: true,
    isLiveStreamOpen: false,
    shipCardOutputs: shipCardOutputs,
    isShipCaptain,
  });
  popup.setLngLat(new LngLat(ship.longitude, ship.latitude).wrap());
  popup.setOffset(-460);
  return popup;
}
