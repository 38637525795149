<div class="container">
  <div class="title">
    <span class="body1">Location</span>
    <span class="body1 select-all-and-clear-btn">
      <span (click)="selectAll()">Select All</span>/<span (click)="clear()"
        >Clear</span
      ></span
    >
  </div>
  <div class="scrollable">
    <orc-tree-select
      [value]="(appliedLocationsFilter$ | async)!"
      (selectionChanged)="onEventFiltersChange($event, 'locations')">
      <orc-tree-option
        [key]="location.parentLocation.id"
        *ngFor="let location of locations">
        <orc-tree-content>
          <span class="body1">
            {{ location.parentLocation.location }}
          </span>
        </orc-tree-content>
        <orc-tree-option
          *ngFor="let subLocation of location.subLocations"
          [key]="subLocation.id">
          <orc-tree-content
            ><span class="body1">
              {{ subLocation.location }}
            </span></orc-tree-content
          >
        </orc-tree-option>
      </orc-tree-option>
    </orc-tree-select>
  </div>
</div>
