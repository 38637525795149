import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, firstValueFrom, map, Observable } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';
import { EventsActions, EventsSelectors } from 'src/app/core/store/types';
import {
  AppliedEventFilters,
  LocationsViewModel,
} from 'src/app/core/view-models/filter.view.model';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
  locations$!: Observable<LocationsViewModel[]>;
  appliedLocationsFilter$!: Observable<string[]>;

  constructor(private store: Store<CoreState>) {}

  ngOnInit(): void {
    this.locations$ = this.store.select(EventsSelectors.selectLocations);

    this.appliedLocationsFilter$ = this.store
      .select(EventsSelectors.selectAppliedLocationsFilter)
      .pipe(distinctUntilChanged());
  }

  async selectAll() {
    const locations = await firstValueFrom(
      this.locations$.pipe(
        map(locations =>
          locations
            .map(location => [
              ...location.subLocations.map(subLocation => subLocation.location),
            ])
            .flat()
        )
      )
    );
    await this.onEventFiltersChange(locations, 'locations');
  }

  async clear() {
    await this.onEventFiltersChange([], 'locations');
  }

  async onEventFiltersChange(value: any, key: string): Promise<void> {
    const appliedFilters = await firstValueFrom(
      this.store.select(EventsSelectors.selectAppliedEventFilters)
    );
    const updatedAppliedFilters: AppliedEventFilters = {
      ...appliedFilters,
      [key]: value,
    };
    this.store.dispatch(
      EventsActions.onEventFilterChange({ updatedAppliedFilters })
    );
  }
}
