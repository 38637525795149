import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { EventsRepository } from 'src/app/core/pages/events/events.repository';
import { CoreState } from 'src/app/core/store/state/core.state';
import { EventsActions, EventsSelectors } from 'src/app/core/store/types';
import { AppliedEventFilters } from 'src/app/core/view-models/filter.view.model';
import { EventSeverityKeys } from 'src/app/shared/view-models/general.event.view.model';

interface SeverityCheckbox {
  readonly severityLevel: number;
  readonly checked: boolean;
}
@Component({
  selector: 'app-severity-filter',
  templateUrl: './severity-filter.component.html',
  styleUrls: ['./severity-filter.component.scss'],
})
export class SeverityFilterComponent implements OnInit {
  private readonly eventsRepository = inject(EventsRepository);

  highSeverityLevel$!: Observable<boolean>;
  mediumSeverityLevel$!: Observable<boolean>;
  lowSeverityLevel$!: Observable<boolean>;

  constructor(private store: Store<CoreState>) {}

  ngOnInit(): void {
    this.highSeverityLevel$ = this.store.select(
      EventsSelectors.selectIsHighSeverityLevel
    );

    this.mediumSeverityLevel$ = this.store.select(
      EventsSelectors.selectIsMediumSeverityLevel
    );

    this.lowSeverityLevel$ = this.store.select(
      EventsSelectors.selectIsLowSeverityLevel
    );
  }

  async onSeverityChange(
    checked: boolean,
    severityLevel: EventSeverityKeys
  ): Promise<void> {
    const currSeverityLevels: EventSeverityKeys[] = await firstValueFrom(
      this.store.select(EventsSelectors.selectSeverityLevels)
    );
    if (checked) {
      const updatedSeverityLevels = [...currSeverityLevels, severityLevel];

      this.onEventFiltersChange(updatedSeverityLevels, 'severityLevels');
    } else {
      const updatedSeverityLevels = currSeverityLevels.filter(
        severity => severity !== severityLevel
      );

      this.onEventFiltersChange(updatedSeverityLevels, 'severityLevels');
    }
  }

  async onEventFiltersChange(value: any, key: string): Promise<void> {
    const appliedFilters = await firstValueFrom(
      this.store.select(EventsSelectors.selectAppliedEventFilters)
    );
    const updatedAppliedFilters: AppliedEventFilters = {
      ...appliedFilters,
      [key]: value,
    };
    this.store.dispatch(
      EventsActions.onEventFilterChange({ updatedAppliedFilters })
    );

    this.eventsRepository.applyFilters(updatedAppliedFilters);
  }
}
