import { PolygonDto, PolygonType } from '../models/fleet.model';
import { Polygon } from '../view-models/event.view.model';
import { mapRtEventLimitationsDtoToRtEventLimitations } from './events';

export function mapPolygonDtoToPolygon(polygonDto: PolygonDto): Polygon {
  return {
    polygonId: polygonDto.polygon_id,
    fleetId: polygonDto.fleet_id,
    polygonArea: polygonDto.polygon_area,
    polygonType: polygonDto.polygon_type_name as PolygonType,
    areaOfInterest: polygonDto.area_of_interest,
    edges: polygonDto.edges
      ? polygonDto.edges
          .replace('LINESTRING(', '')
          .replace(')', '')
          .split(',')
          .map(pair => pair.split(' ').map(Number))
      : [],
    aoiComplianceLimitations: mapRtEventLimitationsDtoToRtEventLimitations(
      polygonDto.aoi_compliance_limitations
    ),
  };
}
