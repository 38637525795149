import { Type } from '@angular/core';
import { MapLabelComponent } from 'src/app/maritime-map/components/map-label/map-label.component';
import { ShipProfileCardComponent } from 'src/app/maritime-map/components/ship-profile-card/ship-profile-card.component';
import { AccelerationTimeTooltipComponent } from '../core/components/fuel-saving-charts/acceleration-time-tooltip/acceleration-time-tooltip.component';
import { FuelSavingTooltipComponent } from '../core/components/fuel-saving-charts/fuel-saving-tooltip/fuel-saving-tooltip.component';
import { TrackLabelComponent } from '../maritime-map/components/track-label/track-label.component';

export interface CustomElementsMap {
  [selector: string]: Type<any>;
}

export const customElementsMap: CustomElementsMap = {
  'app-ship-profile-card': ShipProfileCardComponent,
  'app-map-label': MapLabelComponent,
  'app-track-label': TrackLabelComponent,
  'app-fuel-saving-tooltip': FuelSavingTooltipComponent,
  'app-acceleration-time-tooltip': AccelerationTimeTooltipComponent,
};
