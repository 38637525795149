import { InsightCard } from 'src/app/shared/view-models/insight.view.model';
import { EventLocation, EventsData } from '../../models/events.model';
import { defaultPosition, ShipPosition } from '../../models/map.models';
import { defaultOverviewEventsData } from '../../models/overview.models';
import { Ship, ShipSafetyScore } from '../../models/ship.model';
import {
  Events,
  Polygon,
  ShipLastLocation,
} from '../../view-models/event.view.model';

export interface OverviewState {
  readonly insights: InsightCard[];
  readonly eventsLocation: EventLocation[];
  readonly selectedEvent: Events | null;
  readonly selectedShip: Ship | null;
  readonly mapPosition: ShipPosition;
  readonly selectedShipEventsData: Partial<EventsData>;
  readonly selectedShipLastLocations: ShipLastLocation[];
  readonly polygons: Polygon[];
  readonly selectedShipSafetyScores: ShipSafetyScore[];
}

export const initialOverviewState: OverviewState = {
  insights: [],
  eventsLocation: [],
  mapPosition: defaultPosition,
  selectedEvent: null,
  selectedShip: null,
  selectedShipEventsData: defaultOverviewEventsData,
  selectedShipLastLocations: [],
  polygons: [],
  selectedShipSafetyScores: [],
};
