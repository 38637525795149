import { Component, Input } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@Component({
  selector: 'app-loading-indicator',
  standalone: true,
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  imports: [MatProgressSpinnerModule],
})
export class LoadingIndicatorComponent {
  @Input() strokeWidth: number = 5;
  @Input() diameter: number = 60;
  @Input() color!: string;
}
