import { createReducer, on } from '@ngrx/store';
import { EventsState, initialEventsState } from '../state/events.state';
import { EventsActions } from '../types';

export const eventsStateKey = 'events';

export const eventsReducer = createReducer(
  initialEventsState,
  on(
    EventsActions.saveEventFiltersOnAuth,
    (state, action): EventsState => ({
      ...state,
      eventFilters: { ...action.eventFilters },
    })
  ),
  on(
    EventsActions.saveEventFiltersOnDateFilterChange,
    (state, action): EventsState => ({
      ...state,
      eventFilters: { ...action.eventFilters },
    })
  ),
  on(
    EventsActions.saveEventsFilterOnFleetChange,
    (state, action): EventsState => ({
      ...state,
      eventFilters: { ...action.eventFilters },
    })
  ),
  on(
    EventsActions.initAllFiltersOnAuth,
    (state, action): EventsState => ({
      ...state,
      appliedEventFilters: { ...action.appliedFilters },
    })
  ),
  on(
    EventsActions.resetAllFiltersOnFleetChange,
    (state, action): EventsState => ({
      ...state,
      appliedEventFilters: { ...action.appliedFilters },
    })
  ),
  on(
    EventsActions.resetAllFiltersOnDateFilterChange,
    (state, action): EventsState => ({
      ...state,
      appliedEventFilters: { ...action.appliedFilters },
    })
  ),
  on(
    EventsActions.resetAllFiltersOnUserPress,
    (state, action): EventsState => ({
      ...state,
      appliedEventFilters: { ...action.appliedFilters },
    })
  ),
  on(
    EventsActions.saveEventsDataOnAuth,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, ...action.eventsData },
    })
  ),
  on(
    EventsActions.saveEventsDataOnDateFilterChange,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, ...action.eventsData },
    })
  ),
  on(
    EventsActions.saveEventsDataOnFleetIdChange,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, ...action.eventsData },
    })
  ),
  on(
    EventsActions.saveEventsDataResetToDefaultAppliedFilters,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, ...action.eventsData },
    })
  ),
  on(
    EventsActions.saveOverviewEventsOnDateFilterChange,
    (state, action): EventsState => ({
      ...state,
      overviewEvents: [...action.events],
    })
  ),
  on(
    EventsActions.saveOverviewEventsOnPresetChange,
    (state, action): EventsState => ({
      ...state,
      overviewEvents: [...action.events],
    })
  ),
  on(
    EventsActions.saveOverviewEventsOnFleetIdChange,
    (state, action): EventsState => ({
      ...state,
      overviewEvents: [...action.events],
    })
  ),
  on(
    EventsActions.saveShipEventsOnPresetChange,
    (state, action): EventsState => ({
      ...state,
      selectedShipEventsData: {
        ...state.selectedShipEventsData,
        ...action.eventsData,
      },
    })
  ),
  on(
    EventsActions.saveShipEventsDataOnDateFilterChange,
    (state, action): EventsState => ({
      ...state,
      selectedShipEventsData: {
        ...state.selectedShipEventsData,
        ...action.eventsData,
      },
    })
  ),
  on(
    EventsActions.saveSortByOptionOnUserChange,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, sortby: action.sortBy },
    })
  ),
  on(
    EventsActions.saveSortedEventsOnUserChange,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, events: [...action.events] },
    })
  ),
  on(
    EventsActions.getMoreEventsOnUserScroll,
    (state, action): EventsState => ({
      ...state,
      eventsData: {
        ...state.eventsData,
        pageNumber: state.eventsData.pageNumber + 1,
      },
    })
  ),
  on(
    EventsActions.getMoreShipEventsOnUserScroll,
    (state, action): EventsState => ({
      ...state,
      selectedShipEventsData: {
        ...state.selectedShipEventsData,
        pageNumber: state.selectedShipEventsData.pageNumber + 1,
      },
    })
  ),
  on(
    EventsActions.addMoreEventsAfterScroll,
    (state, action): EventsState => ({
      ...state,
      eventsData: {
        ...state.eventsData,
        events: [...state.eventsData.events, ...action.events],
      },
    })
  ),
  on(
    EventsActions.addMoreShipEventsAfterScroll,
    (state, action): EventsState => ({
      ...state,
      selectedShipEventsData: {
        ...state.selectedShipEventsData,
        events: [...state.selectedShipEventsData.events, ...action.events],
      },
    })
  ),
  on(
    EventsActions.reachedEndOfShipEvents,
    (state, action): EventsState => ({
      ...state,
      selectedShipEventsData: {
        ...state.selectedShipEventsData,
        pageNumber: state.selectedShipEventsData.pageNumber - 1,
      },
    })
  ),
  on(
    EventsActions.reachedEndOfEvents,
    (state, action): EventsState => ({
      ...state,
      eventsData: {
        ...state.eventsData,
        pageNumber: state.eventsData.pageNumber - 1,
      },
    })
  ),
  on(
    EventsActions.resetPagination,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, pageNumber: 1 },
    })
  ),
  on(
    EventsActions.resetScroll,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, scrollToTop: true },
    })
  ),
  on(
    EventsActions.updateScrollToTopByUser,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, scrollToTop: false },
    })
  ),
  on(
    EventsActions.updateEventsDateFilterOnGeneralDateFilterChange,
    (state, action): EventsState => ({
      ...state,
      eventsData: {
        ...state.eventsData,
        eventsDateFilter: { dates: { ...action.dates }, safetyScore: null },
      },
    })
  ),
  on(
    EventsActions.setEventFilterByUrlParams,
    (state, action): EventsState => ({
      ...state,
      eventFilters: { ...action.eventFilters },
      appliedEventFilters: { ...action.appliedEventFilters },
    })
  ),
  on(
    EventsActions.saveEventsDataOnNavigateFromInsight,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, ...action.eventsData },
      selectedEvent: action.selectedEvent,
    })
  ),
  on(
    EventsActions.updateEventsDateFilterOnUserClick,
    (state, action): EventsState => ({
      ...state,
      eventsData: {
        ...state.eventsData,
        eventsDateFilter: { ...action.eventDayFilter },
      },
    })
  ),
  on(
    EventsActions.getEventFilterByUrlParams,
    (state, action): EventsState => ({
      ...state,
      eventsData: {
        ...state.eventsData,
        eventsDateFilter: {
          dates: Object.values(action.dateFilter)[0],
          safetyScore: null,
        },
      },
    })
  ),
  on(
    EventsActions.updateToGeneralDateFilterOnClear,
    (state, action): EventsState => ({
      ...state,
      eventsData: {
        ...state.eventsData,
        eventsDateFilter: { dates: { ...action.dates }, safetyScore: null },
      },
    })
  ),
  on(
    EventsActions.getEventsOnEventsDateFilter,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, ...action.eventsData },
    })
  ),
  on(
    EventsActions.getEventsOnResetEventsDateFilter,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, ...action.eventsData },
    })
  ),
  on(
    EventsActions.updateEventsOnSelectedMapEvent,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, events: [...action.events] },
    })
  ),
  on(
    EventsActions.updateSelectedEventOnMapSelectedEvent,
    (state, action): EventsState => ({
      ...state,
      selectedEvent: action.event,
      eventsData: { ...state.eventsData },
    })
  ),
  on(
    EventsActions.addSelectedEventToList,
    (state, action): EventsState => ({
      ...state,
      eventsData: {
        ...state.eventsData,
        events: state.eventsData.events.find(
          event => event.id === action.event.id
        )
          ? state.eventsData.events
          : [...state.eventsData.events, action.event],
      },
    })
  ),
  on(
    EventsActions.onExploreEventResolved,
    (state, action): EventsState => ({
      ...state,
      selectedEvent: action.event,
    })
  ),
  on(
    EventsActions.setDefaultSelectedEventOnEventsListChange,
    (state, action): EventsState => ({
      ...state,
      selectedEvent: action.selectedEvent,
    })
  ),
  on(
    EventsActions.getEventsOnClearMapAndDayFilters,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, ...action.eventsData },
    })
  ),
  on(
    EventsActions.setSelectedEventOnOverviewResolver,
    (state, action): EventsState => ({
      ...state,
      selectedEvent: action.selectedEvent,
    })
  ),
  on(
    EventsActions.setSelectedOverviewEvent,
    (state, action): EventsState => ({
      ...state,
      selectedEvent: { ...action.event },
    })
  ),
  on(
    EventsActions.setSelectedFilteredEvent,
    (state, action): EventsState => ({
      ...state,
      selectedEvent: { ...action.event },
    })
  ),
  on(
    EventsActions.setDefaultSelectedEventOnGlobalFiltersChange,
    (state, action): EventsState => ({
      ...state,
      selectedEvent: action.selectedEvent,
    })
  ),
  on(
    EventsActions.saveEventsOnFilterChange,
    (state, action): EventsState => ({
      ...state,
      eventsData: { ...state.eventsData, ...action.eventsData },
    })
  ),
  on(
    EventsActions.saveEventsLocation,
    (state, action): EventsState => ({
      ...state,
      eventsData: {
        ...state.eventsData,
        eventsLocation: [...action.eventsLocation],
      },
    })
  ),
  on(
    EventsActions.onEventFilterChange,
    (state, action): EventsState => ({
      ...state,
      appliedEventFilters: { ...action.updatedAppliedFilters },
    })
  ),
  on(
    EventsActions.loadPresetsFromDb,
    (state, action): EventsState => ({
      ...state,
      presetEventFilters: [...action.presets],
    })
  ),
  on(
    EventsActions.loadSelectedPresetFromDb,
    (state, action): EventsState => ({
      ...state,
      selectedPresetFilterInOverview: action.selectedPresetFilter,
    })
  ),
  on(
    EventsActions.setPresetFilterInEvents,
    (state, action): EventsState => ({
      ...state,
      selectedPresetFilterInEvents: action.selectedPresetFilter,
    })
  ),
  on(
    EventsActions.setPresetFilterInOverview,
    (state, action): EventsState => ({
      ...state,
      selectedPresetFilterInOverview: action.selectedPresetFilter,
    })
  ),
  on(
    EventsActions.setPresetFilterInShip,
    (state, action): EventsState => ({
      ...state,
      selectedPresetFilterInShip: action.selectedPresetFilter,
    })
  ),
  on(
    EventsActions.applyFiltersOnPresetFilterChange,
    (state, action): EventsState => ({
      ...state,
      appliedEventFilters: { ...action.updatedAppliedFilters },
    })
  ),
  on(
    EventsActions.setPresetFilterOnCreation,
    (state, action): EventsState => ({
      ...state,
      selectedPresetFilterInEvents: { ...action.selectedPresetFilter! },
    })
  ),
  on(
    EventsActions.setPresetFilterToDefault,
    (state, action): EventsState => ({
      ...state,
      selectedPresetFilterInEvents: undefined,
    })
  ),
  on(
    EventsActions.resetShipSettings,
    (state, action): EventsState => ({
      ...state,
      selectedPresetFilterInShip: undefined,
      selectedEvent: null,
      selectedShipEventsData: {
        ...state.selectedShipEventsData,
        pageNumber: 1,
      },
    })
  ),
  on(
    EventsActions.clearSelectedEvent,
    (state, action): EventsState => ({
      ...state,
      selectedEvent: null,
    })
  )
);
