<div class="container">
  <div class="event-data-container">
    <div class="nav-back">
      <img src="./assets/icons/arrow_left.svg" />
      <span class="back-button caption" (click)="navigateBack()">Back</span>
    </div>
    <div class="separator"></div>
    <div class="event-data">
      <ng-container
        *ngIf="
          selectedEvent
            | typeGuard : isCloseEncounterEvent as closeEncounterEvent
        ">
        <app-ship-event-header
          [event]="selectedEvent"
          [eventPathImg]="'assets/icons/close_encounter_event.svg'">
          <ng-template #subtitleTemplate let-event>
            {{ selectedEvent.type }} |
            {{
              closeEncounterEvent.colregClassification
                | colregClassToDisplayName
            }}
          </ng-template>
        </app-ship-event-header>
        <app-info-row>
          <app-info-column
            title="Location"
            [value]="selectedEvent.location"></app-info-column>
          <app-info-column
            title="Date"
            [value]="
              selectedEvent.timestamp | date : 'dd MMM yyyy HH:mm'
            "></app-info-column>
          <app-info-column
            title="Congestion level"
            *ngIf="selectedEvent.congestionLevel"
            [value]="
              congestionLevelsViewMap[selectedEvent.congestionLevel]
            "></app-info-column>
          <app-info-column
            title="Geo Classification"
            [value]="
              selectedEvent.inCoastalWaters ? 'Coastal Waters' : 'Open Waters'
            "></app-info-column>
        </app-info-row>
        <div class="separator"></div>
        <app-event-info>
          <app-event-data
            title="Min Distance"
            [value]="closeEncounterEvent.minDistance | number : '1.0-2'"
            unit="NM"></app-event-data>
          <app-event-data
            title="SOG"
            [value]="selectedEvent.sog | number : '1.0-2'"
            unit="Kts"></app-event-data>
          <app-event-data
            title="Target SOG"
            [value]="closeEncounterEvent.targetSog | number : '1.0-2'"
            unit="Kts"></app-event-data>
          <app-event-data
            title="Target Type"
            [value]="
              closeEncounterEvent.targetType | targetTypeName
            "></app-event-data>
          <app-event-data
            title="Target MMSI"
            [value]="closeEncounterEvent.targetId"></app-event-data>
        </app-event-info>
      </ng-container>
      <ng-container
        *ngIf="
          selectedEvent | typeGuard : isHighPitchRollEvent as highPitchRollEvent
        ">
        <app-ship-event-header
          [event]="selectedEvent"
          [eventPathImg]="'assets/icons/high_pitch_roll_event.svg'">
        </app-ship-event-header>
        <app-info-row>
          <app-info-column
            title="Location"
            [value]="selectedEvent.location"></app-info-column>
          <app-info-column
            title="Date"
            [value]="
              selectedEvent.timestamp | date : 'dd MMM yyyy HH:mm'
            "></app-info-column>
          <app-info-column
            title="Congestion level"
            *ngIf="selectedEvent.congestionLevel"
            [value]="
              congestionLevelsViewMap[selectedEvent.congestionLevel]
            "></app-info-column>
          <app-info-column
            title="Geo Classification"
            [value]="
              selectedEvent.inCoastalWaters ? 'Coastal Waters' : 'Open Waters'
            "></app-info-column>
        </app-info-row>
        <div class="separator"></div>

        <app-event-info>
          <app-event-data
            title="Roll"
            [value]="
              (highPitchRollEvent.roll | number : '1.0-2') + '°'
            "></app-event-data>
          <app-event-data
            title="Pitch"
            [value]="
              (highPitchRollEvent.pitch | number : '1.0-2') + '°'
            "></app-event-data>
          <app-event-data
            title="SOG"
            [value]="selectedEvent.sog | number : '1.0-2'"
            unit="Kts"></app-event-data>
        </app-event-info>
      </ng-container>
      <ng-container *ngIf="selectedEvent | typeGuard : isUKCEvent as ukcEvent">
        <app-ship-event-header
          [event]="selectedEvent"
          [eventPathImg]="'assets/icons/ukc_event.svg'">
        </app-ship-event-header>
        <app-info-row>
          <app-info-column
            title="Location"
            [value]="selectedEvent.location"></app-info-column>
          <app-info-column
            title="Date"
            [value]="
              selectedEvent.timestamp | date : 'dd MMM yyyy HH:mm'
            "></app-info-column>
          <app-info-column
            title="Congestion level"
            *ngIf="selectedEvent.congestionLevel"
            [value]="
              congestionLevelsViewMap[selectedEvent.congestionLevel]
            "></app-info-column>
          <app-info-column
            title="Geo Classification"
            [value]="
              selectedEvent.inCoastalWaters ? 'Coastal Waters' : 'Open Waters'
            "></app-info-column>
        </app-info-row>
        <div class="separator"></div>

        <app-event-info>
          <app-event-data
            title="Depth"
            [value]="ukcEvent.depth | number : '1.0-2'"
            unit="m"></app-event-data>
          <app-event-data
            title="SOG"
            [value]="selectedEvent.sog | number : '1.0-2'"
            unit="Kts"></app-event-data>
        </app-event-info>
      </ng-container>
      <ng-container
        *ngIf="selectedEvent | typeGuard : isSpeedDropEvent as speedDropEvent">
        <app-ship-event-header
          [event]="selectedEvent"
          [eventPathImg]="'assets/icons/speed_drop_event.svg'">
        </app-ship-event-header>
        <app-info-row>
          <app-info-column
            title="Location"
            [value]="selectedEvent.location"></app-info-column>
          <app-info-column
            title="Date"
            [value]="
              selectedEvent.timestamp | date : 'dd MMM yyyy HH:mm'
            "></app-info-column>
          <app-info-column
            title="Congestion level"
            *ngIf="selectedEvent.congestionLevel"
            [value]="
              this.congestionLevelsViewMap[selectedEvent.congestionLevel]
            "></app-info-column>
          <app-info-column
            title="Geo Classification"
            [value]="
              selectedEvent.inCoastalWaters ? 'Coastal Waters' : 'Open Waters'
            "></app-info-column>
        </app-info-row>
        <div class="separator"></div>

        <app-event-info>
          <app-event-data
            title="Initial SOG"
            [value]="selectedEvent.sog | number : '1.0-2'"
            unit="Kts"></app-event-data>
          <app-event-data
            title="Speed Drop"
            [value]="speedDropEvent.eSog | number : '1.0-2'"
            unit="Kts"></app-event-data>
        </app-event-info>
      </ng-container>
      <ng-container
        *ngIf="selectedEvent | typeGuard : isHighRotEvent as highRotEvent">
        <app-ship-event-header
          [event]="selectedEvent"
          [eventPathImg]="'assets/icons/high_rot_event.svg'">
        </app-ship-event-header>
        <app-info-row>
          <app-info-column
            title="Location"
            [value]="selectedEvent.location"></app-info-column>
          <app-info-column
            title="Date"
            [value]="
              selectedEvent.timestamp | date : 'dd MMM yyyy HH:mm'
            "></app-info-column>
          <app-info-column
            title="Congestion level"
            *ngIf="selectedEvent.congestionLevel"
            [value]="
              congestionLevelsViewMap[selectedEvent.congestionLevel]
            "></app-info-column>
          <app-info-column
            title="Geo Classification"
            [value]="
              selectedEvent.inCoastalWaters ? 'Coastal Waters' : 'Open Waters'
            "></app-info-column>
        </app-info-row>
        <div class="separator"></div>

        <app-event-info>
          <app-event-data
            title="ROT"
            [value]="(highRotEvent.rot | number : '1.0-2') + '°'"
            unit="Kts"></app-event-data>
          <app-event-data
            title="SOG"
            [value]="selectedEvent.sog | number : '1.0-2'"
            unit="Kts"></app-event-data>
        </app-event-info>
      </ng-container>

      <div class="body">
        <mat-tab-group
          class="custom-tabs"
          mat-stretch-tabs
          [selectedIndex]="selectedTabIndex"
          (selectedIndexChange)="onTabChanged($event)">
          <mat-tab>
            <ng-template mat-tab-label>
              <span>Videos</span>
            </ng-template>
            <div [ngStyle]="{ height: '28vh' }" class="stream">
              <ng-container *ngIf="cameraControl.value; else NoStreamLive">
                <div class="main-video-container">
                  <div class="camera-boxes-container">
                    <span class="body1-bold name-box camera-box">{{
                      cameraControl.value.key | appendCamera
                    }}</span>
                  </div>
                  <video
                    controls
                    controlsList="nodownload"
                    class="main-video"
                    type="video/mp4"
                    onloadedmetadata="this.playbackRate = 16"
                    *ngIf="cameraControl.value"
                    [src]="cameraControl.value.value"></video>
                </div>
              </ng-container>
              <ng-template #NoStreamLive>
                <div class="no-stream-live">
                  <img
                    class="live-icon icon"
                    src="./assets/icons/binoculars.svg" />
                  <span class="subtitle3">Video isn't available right now</span>
                </div>
              </ng-template>
            </div>
            <div
              class="cameras-selector-container"
              *ngIf="combinedVideos$ | async as videoCategories">
              <ng-container *ngFor="let category of videoCategories">
                <ng-container *ngIf="category.videos.length > 0">
                  <div class="cameras-selector">
                    <span class="subtitle1">{{ category.type }}</span>
                    <mat-button-toggle-group [formControl]="cameraControl">
                      <ng-container *ngFor="let video of category.videos">
                        <mat-button-toggle
                          [value]="video.key"
                          [checked]="cameraControl.value?.value === video.value"
                          (click)="onVideoClick(video)">
                          {{ video.key }}
                        </mat-button-toggle>
                      </ng-container>
                    </mat-button-toggle-group>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <ng-container *ngIf="(combinedVideos$ | async)?.length === 0">
              <app-loading-indicator></app-loading-indicator>
            </ng-container>
          </mat-tab>
          <mat-tab [disabled]="(showComments$ | async) === false">
            <ng-template mat-tab-label>
              <span>{{ (commentTabLabel$ | async)?.text }}</span>
              <span
                class="comments-count"
                *ngIf="(commentTabLabel$ | async)?.count as count"
                >({{ count }})</span
              >
              <app-new-badge class="new-badge"></app-new-badge>
            </ng-template>
            <app-comment-section
              [comments]="(comments$ | async) || []"
              [currentUserId]="(currentUserId$ | async) || ''"
              [currentUserFullName]="(currentUserFullName$ | async) || ''"
              [usersToMention]="(usersToMention$ | async) || []"
              (addComment)="onCommentSubmit($event)"
              (editComment)="onCommentEdit($event)"
              (deleteComment)="onCommentDelete($event)">
            </app-comment-section>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
  <div class="kepler-container">
    <div class="menu">
      <div class="legends">
        <div class="legend">
          <span class="dot blue"></span>
          <span class="body1">{{ selectedEvent.shipName }}</span>
        </div>
        <div
          class="legend"
          *ngIf="
            selectedEvent
              | typeGuard : isCloseEncounterEvent as closeEncounterEvent
          ">
          <span class="dot red"></span>
          <span class="body1">Target Vessel</span>
        </div>
        <div class="legend">
          <span class="dot green"></span>
          <span class="body1">Other Ships</span>
        </div>
      </div>
      <div
        *ngIf="selectedEvent.keplerUrl"
        class="download-btn"
        (click)="downloadKepler()">
        <img src="./assets/icons/download.svg" alt="" />
        <span class="button-text">Download Map</span>
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="downloadKepler()">
          <img src="./assets/icons/download.svg" />
          <span class="button-text">Download Map</span>
        </button>
      </mat-menu>
      <button class="ellipsis-btn" mat-icon-button [matMenuTriggerFor]="menu">
        <img class="dots-icon" src="./assets/icons/three_dots.svg" />
      </button>
    </div>
    <iframe class="kepler" [src]="keplerUrl$ | async | safeUrl"></iframe>
  </div>
</div>
