import { CommonModule } from '@angular/common';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { CoreState } from 'src/app/core/store/state/core.state';
import { OverviewActions } from 'src/app/core/store/types';
import { InsightDataDirective } from '../../directives/insight-data.directive';

import {
  InsightCard,
  InsightDataType,
} from '../../view-models/insight.view.model';
import { LikeUnlikeComponent } from '../like-unlike/like-unlike.component';

@Component({
  selector: 'app-insight-card',
  standalone: true,
  imports: [CommonModule, LikeUnlikeComponent, RouterModule],
  templateUrl: './insight-card.component.html',
  styleUrls: ['./insight-card.component.scss'],
})
export class InsightCardComponent {
  @Input()
  insight!: InsightCard;

  @ContentChild(InsightDataDirective, { read: TemplateRef })
  insightData!: TemplateRef<InsightDataType> | null;

  constructor(
    private store: Store<CoreState>,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  getInsightText(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.insight.content);
  }

  updateStatusLike(statusLike: boolean): void {
    this.store.dispatch(
      OverviewActions.setVoteForInsight({
        insight: this.insight,
        likeVote: statusLike,
      })
    );
  }

  public async clickLinkInsight(): Promise<void> {
    const linkUrl = this.insight.link.linkTo;
    const queryParams = this.insight.link.filterEventsQueryParams;

    const navigationExtras = {
      state: {
        queryParams,
      },
    };
    this.router.navigate([linkUrl], navigationExtras);
    this.store.dispatch(
      OverviewActions.linkOfInsightWasClicked({
        insight: this.insight,
      })
    );
  }
}
