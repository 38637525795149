import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'app-live-stream-button',
  templateUrl: './live-stream-button.component.html',
  styleUrls: ['./live-stream-button.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
})
export class LiveStreamButtonComponent {
  @Input()
  text: string = 'Live';

  @Input()
  isDisabled: boolean = false;
}
