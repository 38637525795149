<app-confirmation-dialog
  [title]="'Delete Sub Fleet'"
  [confirmButtonText]="'Yes Remove'"
  [cancelButtonText]="'Cancel'"
  [icon]="'assets/icons/big_trash.svg'"
  [onConfirmAction]="onRemoveSubfleet.bind(this)">
  <div class="confirmation-container">
    <div class="subfleet-label">
      Sub fleet name:
      <span class="subfleet-value">{{ subFleet.subFleetName }}</span>
    </div>
    <br />
    <div class="confirmation-text">
      <span
        >Are you sure you want to remove "{{ subFleet.subFleetName }}"?</span
      >
      <br />
      <span>The action can not be undone.</span>
    </div>
  </div>
</app-confirmation-dialog>
