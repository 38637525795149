import { BehaviorSubject } from 'rxjs';
import {
  MapboxLegendConfigurations,
  MapboxLegendTick,
} from 'src/app/mapbox/models/mapbox-legend.models';
import {
  MapboxCustomSetting,
  MapboxSettingType,
} from 'src/app/mapbox/models/mapbox.models';
import { environment } from 'src/environments/environment';

export const HUMIDITY_LEGEND_TICKS: MapboxLegendTick[] = [
  { value: 0, color: 'transparent' },
  { value: 0.1, color: '#FBFBF2' },
  { value: 5, color: '#ECF4D3' },
  { value: 10, color: '#DBF1BD' },
  { value: 15, color: '#CAF0B1' },
  { value: 20, color: '#AEEDA1' },
  { value: 25, color: '#96EB9C' },
  { value: 30, color: '#8EEC9F' },
  { value: 35, color: '#84F0AA' },
  { value: 40, color: '#79EDB9' },
  { value: 45, color: '#6EE5C8' },
  { value: 50, color: '#62E2D7' },
  { value: 55, color: '#5AE0E0' },
  { value: 60, color: '#54CCDD' },
  { value: 65, color: '#49B2D8' },
  { value: 70, color: '#3F9FD6' },
  { value: 75, color: '#3A8AE0' },
  { value: 80, color: '#3C7AD6' },
  { value: 85, color: '#3E6AC6' },
  { value: 90, color: '#4660B2' },
  { value: 95, color: '#4B60A5' },
  { value: 100, color: '#4B5A91' },
];

export const HUMIDITY_LEGEND_CONFIG: MapboxLegendConfigurations = {
  id: 'humidity',
  unitsName: '%',
  legendTicks: HUMIDITY_LEGEND_TICKS,
};

export const HUMIDITY_SETTING_ID = 'Humidity';

export const GetHumiditySetting = (
  sampleDateString$: BehaviorSubject<string>
): MapboxCustomSetting => {
  const getTileSource = () => [
    `${
      environment.meteoblue.tilesUrl
    }/raster/NEMSAUTO/${sampleDateString$.getValue()}/52~2%20m%20above%20gnd~hourly~none~contourSteps~-0.1~rgba(251,251,242,1.0)~5.0~rgba(236,244,211,1.0)~10.0~rgba(219,241,189,1.0)~15.0~rgba(202,240,177,1.0)~20.0~rgba(174,237,161,1.0)~25.0~rgba(150,235,156,1.0)~30.0~rgba(142,236,159,1.0)~35.0~rgba(132,240,170,1.0)~40.0~rgba(121,237,185,1.0)~45.0~rgba(110,229,200,1.0)~50.0~rgba(98,226,215,1.0)~55.0~rgba(90,224,224,1.0)~60.0~rgba(84,204,221,1.0)~65.0~rgba(73,178,216,1.0)~70.0~rgba(63,159,214,1.0)~75.0~rgba(58,138,224,1.0)~80.0~rgba(60,122,214,1.0)~85.0~rgba(62,106,198,1.0)~90.0~rgba(70,96,178,1.0)~95.0~rgba(75,96,165,1.0)~100.0~rgba(75,90,145,1.0)/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=km%2Fh&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${
      environment.meteoblue.apiKey
    }`,
  ];
  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'humidity-tile-source',
        source: {
          type: 'raster',
          tiles: getTileSource(),
          tileSize: 256,
        },
        updateTilesSource: getTileSource,
      },
    ],
    layers: [
      {
        id: 'humidity-png',
        type: 'raster',
        source: 'humidity-tile-source',
        paint: {
          'raster-opacity': 0.7,
        },
      },
    ],
    popups: [],
  };
};
