import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MenuBotton } from '../../models/mapbox-menu.models';

@Component({
  selector: 'app-map-menu-plugin',
  standalone: true,
  templateUrl: './map-menu-plugin.component.html',
  styleUrls: ['./map-menu-plugin.component.scss'],
  imports: [CommonModule],
})
export class MapMenuPluginComponent implements OnInit, OnDestroy {
  @Input() showMenu$!: BehaviorSubject<string | undefined>;
  @Input() buttons: MenuBotton[] = [];
  @Output() menuButtonClick: EventEmitter<string> = new EventEmitter<string>();
  openMenu: boolean = false;

  @ContentChild('menuTitle')
  menuTitle!: TemplateRef<any>;

  @ContentChild('menuContent')
  menuContent!: TemplateRef<any>;

  subscription!: Subscription;

  ngOnInit(): void {
    this.openMenu = !!this.showMenu$.getValue();
    this.subscription = this.showMenu$.subscribe(
      menuId => (this.openMenu = !!menuId)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClick(button: MenuBotton) {
    this.menuButtonClick.emit(button.id);
  }
}
