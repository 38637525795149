import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { createCustomElement } from '@angular/elements';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { AppModule } from './app/app.module';
import { customElementsMap } from './app/shared/custom-elements';
import { environment } from './environments/environment';

dayjs.extend(utc);
dayjs.extend(duration);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(appModule => {
    Object.entries(customElementsMap).forEach(([selector, componentClass]) =>
      customElements.define(
        selector,
        createCustomElement(componentClass, { injector: appModule.injector })
      )
    );
  })
  .catch(err => console.error(err));
