import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RedRTEventIndicationComponent } from 'src/app/core/components/red-rt-event-indication/red-rt-event-indication.component';
import { Screenshot } from 'src/app/core/view-models/gallery.view.model';
import { ShipMapEntity } from 'src/app/mapbox/models/mapbox.models';
import { DeltaRisksComponent } from 'src/app/shared/components/delta-risks/delta-risks.component';
import { LiveStreamButtonComponent } from 'src/app/shared/components/live-stream-button/live-stream-button.component';
import { DateToHoursMinutesAgoPipe } from 'src/app/shared/pipes/date-to-hours-minutes-ago.pipe';
import { ShipCardOuputs } from '../../models/ship.models';

@Component({
  selector: 'app-ship-profile-card',
  templateUrl: './ship-profile-card.component.html',
  standalone: true,
  imports: [
    CommonModule,
    LiveStreamButtonComponent,
    DateToHoursMinutesAgoPipe,
    DeltaRisksComponent,
    MatButtonModule,
    LazyLoadImageModule,
    MatIconModule,
    RedRTEventIndicationComponent,
  ],
  styleUrls: ['./ship-profile-card.component.scss'],
})
export class ShipProfileCardComponent {
  @Input() isShipCaptain!: boolean;
  @Input() shipData: ShipMapEntity | null = null;
  @Input() screenshot: Screenshot | null = null;
  @Input() endDateAvgShipSafetyScore: number | null = null;
  @Input() startDateAvgShipSafetyScore: number | null = null;
  @Input() showLiveStreamFlag: boolean = false;
  @Input() isLiveStreamOpen: boolean = false;
  @Input() shipCardOutputs!: ShipCardOuputs;

  isConnectionWithinFourHours(): boolean {
    if (this.shipData && this.shipData.lastConnection) {
      const fourHoursAgo = new Date(new Date().getTime() - 4 * 60 * 60 * 1000);
      return this.shipData.lastConnection >= fourHoursAgo;
    }
    return false;
  }

  onButtonClicked(
    link: string,
    shipId: number,
    isLiveStreamOpen: boolean = false
  ): void {
    let navigateUrl = `${link}${shipId}`;
    if (isLiveStreamOpen) {
      navigateUrl = navigateUrl + '?isLiveStreamOpen=true';
    }
    this.shipCardOutputs.onButtonClicked$.next({
      nagivationUrl: navigateUrl,
      shipId,
    });
  }

  close(): void {
    this.shipCardOutputs.onClose$.next();
  }
}
