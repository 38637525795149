<div class="container">
  <div class="subfleet-table">
    <div class="header">
      <div class="subfleet-count">{{ subFleets.length }} Sub Fleets</div>
      <div class="header-right">
        <div class="search-box">
          <mat-form-field
            appearance="outline"
            data-test="subfleets-search-box"
            class="search-form">
            <mat-label>Search</mat-label>
            <input
              matInput
              [formControl]="searchBoxControl"
              autocomplete="off"
              class="search-form" />
            <mat-icon matSuffix class="search-icon">search</mat-icon>
          </mat-form-field>
        </div>
        <button
          mat-flat-button
          class="create-subfleet-button"
          color="primary"
          (click)="openCreateDialog()"
          data-test="create-subfleet-button">
          Create Subfleet
        </button>
      </div>
    </div>
    <orc-table
      *ngIf="subFleets.length > 0; else NoSubFleets"
      [data]="subFleets"
      [columns]="columns"
      [sortBy]="sortBy"
      [trackBy]="trackBy">
      <orc-column id="fleetId">
        <div *orcTableHeader class="header-text text-x-small text-bold">
          <div>Fleet Id</div>
        </div>
        <div *orcTableCell="let item = item" class="cell text-base">
          <div class="text-x-small text-bold">{{ item.fleetId }}</div>
        </div>
      </orc-column>

      <orc-column id="subFleetName">
        <div *orcTableHeader class="header-text text-x-small text-bold">
          <div>Subfleet Name</div>
        </div>
        <div *orcTableCell="let item = item" class="cell text-base">
          <div class="text-x-small text-bold">{{ item.subFleetName }}</div>
        </div>
      </orc-column>

      <orc-column id="assignedUsers">
        <div *orcTableHeader class="header-text text-x-small text-bold">
          <div>Associated Users</div>
        </div>
        <div *orcTableCell="let item = item" class="cell text-base">
          <ng-container *ngIf="item.assignedUsers?.length">
            <ng-container
              *ngIf="item.assignedUsers.length > 1; else singleUser">
              <div
                class="text-x-small text-bold cell-count"
                [appTooltip]="assignedUsers"
                [tooltipContext]="{
                  usersNames: getUserNames(item.assignedUsers)
                }"
                [title]="'Associated Users'">
                <span>{{ item.assignedUsers.length }}</span>
              </div>
              <ng-template #assignedUsers let-usersNames="usersNames">
                <app-tag-list [items]="usersNames"></app-tag-list>
              </ng-template>
            </ng-container>
            <ng-template #singleUser>
              <app-tag [text]="getUserNames(item.assignedUsers)[0]"></app-tag>
            </ng-template>
          </ng-container>
        </div>
      </orc-column>

      <orc-column id="shipsList">
        <div *orcTableHeader class="header-text text-x-small text-bold">
          <div>Associated Ships</div>
        </div>
        <div *orcTableCell="let item = item" class="cell text-base">
          <ng-container *ngIf="item.shipsList.length > 1; else singleShip">
            <div
              class="text-x-small text-bold cell-count"
              [appTooltip]="shipsList"
              [tooltipContext]="{ shipsNames: getShipNames(item.shipsList) }"
              [title]="'Associated Ships'">
              <span>{{ item.shipsList.length }}</span>
            </div>
            <ng-template #shipsList let-shipsNames="shipsNames">
              <app-tag-list [items]="shipsNames"></app-tag-list>
            </ng-template>
          </ng-container>
          <ng-template #singleShip>
            <app-tag [text]="getShipNames(item.shipsList)[0]"></app-tag>
          </ng-template>
        </div>
      </orc-column>

      <orc-column id="actions">
        <div *orcTableCell="let item = item" class="cell cell-end">
          <div class="action-buttons">
            <button
              mat-icon-button
              aria-label="Edit subfleet"
              (click)="openEditDialog(item)"
              data-test="edit subfleet">
              <div data-svg-wrapper>
                <img src="assets/icons/edit.svg" alt="edit" />
              </div>
            </button>

            <button
              mat-icon-button
              aria-label="Delete subfleet"
              (click)="confirmDelete(item)"
              data-test="delete subfleet">
              <div data-svg-wrapper>
                <img src="assets/icons/trash.svg" alt="delete" />
              </div>
            </button>
          </div>
        </div>
      </orc-column>
    </orc-table>

    <ng-template #NoSubFleets>
      <div class="no-subfleets-container">
        <div class="no-subfleets-text">No subfleets found</div>
      </div>
    </ng-template>
  </div>
</div>
