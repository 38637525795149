<div class="dialog-container">
  <h2 mat-dialog-title class="dialog-title">
    <button mat-icon-button class="close-button" [mat-dialog-close]="false">
      <img src="assets/icons/close.svg" alt="close" />
    </button>
    <div class="title-icon">
      <img [src]="icon" alt="title icon" />
    </div>
    <div class="title-text">{{ title }}</div>
  </h2>

  <mat-dialog-content class="dialog-content">
    <div class="errors" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>

    <ng-content></ng-content>
  </mat-dialog-content>

  <mat-dialog-actions class="dialog-actions" align="end">
    <button
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="false"
      class="cancel-button">
      {{ cancelButtonText }}
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="onConfirm()"
      cdkFocusInitial>
      {{ confirmButtonText }}
    </button>
  </mat-dialog-actions>
</div>
