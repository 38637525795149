<mat-radio-group class="layers-content" [disabled]="!isEnabled">
  <div class="layer-item" *ngFor="let weatherOption of weatherSettingOptions">
    <mat-radio-button
      color="primary"
      [value]="weatherOption.id"
      [checked]="weatherOption.isChecked"
      (change)="onSelect($event.value)">
      {{ weatherOption.id }}
    </mat-radio-button>
  </div>
</mat-radio-group>
