import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SubFleet } from 'src/app/core/models/subfleet.models';
import { SubFleetService } from 'src/app/core/services/subfleet.service';
import { ColumnDefinition } from 'src/app/shared/components/table/models/column-definition.model';
import { SortDetails } from 'src/app/shared/components/table/models/sort-details.model';
import { TrackBySelector } from 'src/app/shared/components/table/models/track-by-selector.model';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-subfleet-list',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './subfleet-list.component.html',
  styleUrls: ['./subfleet-list.component.scss'],
})
export class SubfleetListComponent implements OnInit {
  subFleets: SubFleet[] = [];
  trackBy: TrackBySelector<SubFleet> = subFleet => subFleet.subFleetId;
  sortBy: SortDetails | null = {
    column: 'subFleetName',
    direction: 'asc',
  };

  columns: ColumnDefinition<SubFleet>[] = [
    {
      id: 'subFleetId',
      header: 'ID',
      value: s => s.subFleetId,
    },
    {
      id: 'fleetId',
      header: 'Fleet Id',
      value: s => s.fleetId,
    },
    {
      id: 'subFleetName',
      header: 'Subfleet Name',
      value: s => s.subFleetName,
      width: 200,
    },
    {
      id: 'shipsList',
      header: 'Ships',
      value: s => s.shipsList.length,
    },
    {
      id: 'actions',
      header: '',
      width: 200,
    },
  ];

  searchBoxControl = new FormControl('');

  constructor(private subFleetService: SubFleetService) {}

  ngOnInit(): void {
    this.loadSubfleets();
  }

  private loadSubfleets(): void {
    this.subFleetService.getAll().subscribe({
      next: subFleets => {
        this.subFleets = subFleets;
      },
      error: error => {
        console.error('Error loading subfleets:', error);
      },
    });
  }
}
