import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EventsSortBy } from 'src/app/core/models/events-filters.model';
import { CoreState } from 'src/app/core/store/state/core.state';
import { EventsActions } from 'src/app/core/store/types';
import { Events } from 'src/app/core/view-models/event.view.model';
import { eventsSortList } from 'src/app/core/view-models/events.sort.view.model';
import { EventsRepository } from '../events.repository';
import { EventsStore } from '../events.store';

@Component({
  selector: 'app-filtered-events',
  templateUrl: './filtered-events.component.html',
  styleUrls: ['./filtered-events.component.scss'],
})
export class FilteredEventsComponent {
  // Dependencies
  private readonly store: Store<CoreState> = inject(Store);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly eventsRepository = inject(EventsRepository);
  public readonly eventsStore = inject(EventsStore);
  // Component properties
  public readonly eventsSortList = eventsSortList;
  @ViewChild('eventsContainer') eventsContainer!: ElementRef;

  resetScroll() {
    if (this.eventsContainer && this.eventsContainer.nativeElement) {
      this.eventsContainer.nativeElement.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  }

  onSortByChange(sortBy: EventsSortBy): void {
    this.eventsRepository.updateSortBy(sortBy);
  }

  onScroll() {
    this.eventsRepository.getEvents({ nextPage: true });
  }

  onSelectedEvent(event: Events): void {
    this.eventsRepository.updateSelectedEvent(event);
  }

  onEventExploration(event: Events): void {
    if (event.isRtEvent) {
      this.router.navigate(['rt-event-exploration', event.eventId], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this.router.navigate(['event-exploration', event.eventId], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  onExportClick(): void {
    this.store.dispatch(EventsActions.onExportFile());
  }
}
