import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import {
  catchError,
  EMPTY,
  filter,
  forkJoin,
  from,
  interval,
  map,
  of,
  switchMap,
  takeUntil,
  timer,
  withLatestFrom,
} from 'rxjs';
import { EventsService } from '../../services/events.service';
import { FleetService } from '../../services/fleet.service';
import { ShipsService } from '../../services/ships.service';
import { CoreState } from '../state/core.state';
import {
  AuthenticationActions,
  AuthenticationSelectors,
  EventsActions,
  FiltersActions,
  FiltersSelectors,
  ShipsActions,
  ShipsSelectors,
} from '../types';

@Injectable()
export class ShipsEffects {
  constructor(
    private actions$: Actions,
    private shipsService: ShipsService,
    private fleetsService: FleetService,
    private eventsService: EventsService,
    private store: Store<CoreState>
  ) {}

  onAuthUserExistsGetShipsSeverity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateTokenOnRegularUserSetClaims),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([_, dates]) =>
        from(this.shipsService.getShipsSeverity(dates)).pipe(
          map(ships => ShipsActions.saveShipsSeverityOnAuth({ ships }))
        )
      )
    );
  });

  onAuthUserExistsGetHighestSafteyScoreShips$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateTokenOnRegularUserSetClaims),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(ShipsSelectors.selectSafteyScoreShipsSortBy),
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([action, sortby, dates]) =>
        from(this.shipsService.getSafteyScoreShips$(sortby, dates)).pipe(
          map(ships => {
            const shipsWithScore = [...ships].filter(ship => ship.avg_score);
            return ShipsActions.saveHighestSafteyScoreShipsOnAuth({
              ships: shipsWithScore,
            });
          })
        )
      )
    );
  });

  onDateFilterChangeGetShipSafetyScores$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FiltersActions.saveSelectedDatesFilter,
        FiltersActions.resetDateFilterToDefault
      ),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates),
            this.store.select(ShipsSelectors.selectSelectedShipId)
          )
        )
      ),
      filter(([_, dates, shipId]) => shipId != null),
      switchMap(([_, dates, shipId]) =>
        from(this.shipsService.getShipSafetyScores(shipId!, dates)).pipe(
          map(_shipSafetyScores =>
            ShipsActions.setShipSafetyScoresOnDateRangeChanged({
              shipSafetyScores: _shipSafetyScores,
            })
          )
        )
      )
    );
  });

  onDateFilterChangeGetShipsSeverity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FiltersActions.setDateFilterByUrlParams,
        FiltersActions.saveSelectedDatesFilter,
        FiltersActions.resetDateFilterToDefault
      ),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([_, dates]) =>
        from(this.shipsService.getShipsSeverity(dates)).pipe(
          map(ships => ShipsActions.updateShipsSeverityOnRangeChange({ ships }))
        )
      )
    );
  });

  onDateFilterChangeGetShipsEventCountByGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FiltersActions.saveSelectedDatesFilter,
        FiltersActions.resetDateFilterToDefault
      ),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates),
            this.store.select(ShipsSelectors.selectSelectedShipId)
          )
        )
      ),
      switchMap(([_, dates, shipId]) => {
        return from(
          this.shipsService.getShipEventsGroupCount$(shipId, dates)
        ).pipe(
          map(shipEventsCountByGroup =>
            ShipsActions.updateSelectedShipEventCountData({
              shipEventCountData: shipEventsCountByGroup,
            })
          )
        );
      })
    );
  });

  onDateFilterChangeGetHighestSafteyScoreShips$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FiltersActions.saveSelectedDatesFilter,
        FiltersActions.setDateFilterByUrlParams,
        FiltersActions.resetDateFilterToDefault
      ),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(ShipsSelectors.selectSafteyScoreShipsSortBy),
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([_, sortby, dates]) =>
        from(this.shipsService.getSafteyScoreShips$(sortby, dates)).pipe(
          map(ships => {
            const shipsWithScore = [...ships].filter(ship => ship.avg_score);
            return ShipsActions.updateHighestSafteyScoreShipsOnRangeChange({
              ships: shipsWithScore,
            });
          })
        )
      )
    );
  });

  onUpdateTokenGetShipsSeverity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateUserOnAdminSetClaims),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([_, dates]) =>
        from(this.shipsService.getShipsSeverity(dates)).pipe(
          map(ships => ShipsActions.updateShipsSeverityOnFleetChange({ ships }))
        )
      )
    );
  });

  onUpdateTokenGethighestSafetyScoreShips$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateUserOnAdminSetClaims),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(ShipsSelectors.selectSafteyScoreShipsSortBy),
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([action, sortby, dates]) =>
        from(this.shipsService.getSafteyScoreShips$(sortby, dates)).pipe(
          map(ships => {
            const shipsWithScore = [...ships].filter(ship => ship.avg_score);
            return ShipsActions.updateHighestSafteyScoreShipsOnFleetChange({
              ships: shipsWithScore,
            });
          })
        )
      )
    );
  });

  getAllShipsScoreSortedBy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.saveSortByOptionOnUserChange),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([action, dates]) =>
        from(
          this.shipsService.getSafteyScoreShips$(action.sortBy, dates, 3)
        ).pipe(
          map(ships =>
            ShipsActions.saveSortedShipsScoreOnUserChange({
              ships,
            })
          )
        )
      )
    );
  });

  onAuthUserExistsGetShips$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateTokenOnRegularUserSetClaims),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([_, dates]) =>
        from(this.shipsService.getShips$(dates)).pipe(
          map(ships => ShipsActions.saveShipsOnRegularUserAuth({ ships }))
        )
      )
    );
  });

  onUpdateTokenGetShips$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateUserOnAdminSetClaims),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([_, dates]) => {
        return from(this.shipsService.getShips$(dates)).pipe(
          map(ships => ShipsActions.saveShipsOnAdminUserAuth({ ships }))
        );
      })
    );
  });

  onDateFilterChangeGetShips$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FiltersActions.saveSelectedDatesFilter,
        FiltersActions.resetDateFilterToDefault
      ),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([_, dates]) => {
        return from(this.shipsService.getShips$(dates)).pipe(
          map(ships => ShipsActions.updateShipsOnDateRangeChange({ ships }))
        );
      })
    );
  });

  onUpdateShipConnectionStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.setShipConnectionStatusOnPageCreation),
      switchMap(({ shipId, fleetId }) =>
        interval(7000).pipe(
          map(() =>
            ShipsActions.updateShipConnectionStatus({
              shipConnection: {
                fleetId: fleetId,
                shipId: shipId,
              },
            })
          ),
          takeUntil(
            this.actions$.pipe(ofType(ShipsActions.closePollingLiveStream))
          )
        )
      )
    );
  });

  onUpdateShipCameras$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.updateShipConnectionStatus),
      switchMap(action => {
        const shipId = action.shipConnection.shipId;
        const fleetId = action.shipConnection.fleetId;
        return from(this.shipsService.getShipCameras$(shipId!, fleetId!)).pipe(
          map(shipCameras =>
            ShipsActions.updateShipCameras({
              cameras: shipCameras,
            })
          )
        );
      })
    );
  });

  onGetStream$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.getShipStream),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(ShipsSelectors.selectShipStreamingId),
            this.store.select(ShipsSelectors.selectFleetStreamingId)
          )
        )
      ),
      filter(
        ([action, shipId, fleetId]) => shipId !== null && fleetId !== null
      ),
      switchMap(([action, shipId, fleetId]) =>
        from(
          this.shipsService.patchStream$(action.camera_id, shipId!, fleetId!)
        ).pipe(
          switchMap(stream =>
            from(this.shipsService.getShipCameras$(shipId!, fleetId!)).pipe(
              switchMap(shipCameras =>
                this.store.select(ShipsSelectors.selectSelectedShipId).pipe(
                  filter(currentShipId => shipId === currentShipId),
                  map(() =>
                    ShipsActions.updateShipStream({
                      shipStream: { cameras: shipCameras, ...stream },
                    })
                  )
                )
              )
            )
          )
        )
      )
    );
  });

  onGetShipStartStream$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.getShipStartStream),
      filter(action => action.shipId !== null && action.fleetId !== null),
      switchMap(action =>
        from(
          this.shipsService.startStream$(action.shipId!, action.fleetId!)
        ).pipe(
          withLatestFrom(
            this.store.select(ShipsSelectors.selectSelectedShipId)
          ),
          filter(([_, currentShipId]) => action.shipId === currentShipId),
          map(([stream]) =>
            ShipsActions.updateShipStream({
              shipStream: { ...stream },
            })
          )
        )
      )
    );
  });

  onPatchStreamMaster$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.patchShipStreamMaster),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(ShipsSelectors.selectShipStreamingId),
            this.store.select(ShipsSelectors.selectFleetStreamingId)
          )
        )
      ),
      filter(
        ([action, shipId, fleetId]) => shipId !== null && fleetId !== null
      ),
      switchMap(([_, shipId, fleetId]) => {
        return from(
          this.shipsService.patchStreamMaster$(shipId!, fleetId!)
        ).pipe(
          map(isStreamMaster =>
            ShipsActions.updateIsShipStreamMaster({
              isStreamMaster: isStreamMaster,
            })
          )
        );
      })
    );
  });

  getShipSafetyScoreOnViewShipProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.setSelectedShipIdOnViewShipProfile),
      concatLatestFrom(() =>
        this.store.select(FiltersSelectors.selectFilteredDates)
      ),
      switchMap(([action, dates]) => {
        return from(
          this.shipsService.getShipSafetyScores(action.shipId, dates)
        ).pipe(
          map(result =>
            ShipsActions.setShipSafetyScoresOnShipListClick({
              shipSafetyScores: result,
            })
          )
        );
      })
    );
  });
  getSelectedShipIdOnTooltipShipProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.setSelectedShipIdOnTooltipShipProfile),
      concatLatestFrom(() =>
        this.store.select(FiltersSelectors.selectFilteredDates)
      ),
      switchMap(([action, dates]) => {
        return from(
          this.shipsService.getShipSafetyScores(action.shipId, dates)
        ).pipe(
          map(result =>
            ShipsActions.setShipSafetyScoresOnShipListClick({
              shipSafetyScores: result,
            })
          )
        );
      })
    );
  });

  onGetShipSailData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.getShipSailData),
      switchMap(action => {
        return from(
          this.shipsService.getShipSailData$(action.shipId, action.periodInDays)
        ).pipe(
          map(sailData => ShipsActions.updateSelectedShipSailData({ sailData }))
        );
      })
    );
  });

  fetchRTEventsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.saveAuthUser),
      switchMap(() => timer(0, 180000)),
      switchMap(() =>
        this.store.select(AuthenticationSelectors.selectToken).pipe(
          filter(token => !!token),
          switchMap(() =>
            from(this.eventsService.getActiveRTEvents()).pipe(
              withLatestFrom(this.store.select(ShipsSelectors.selectRTEvents)),
              filter(
                ([newEvents, currentEvents]) =>
                  !isEqual(Object.keys(newEvents), Object.keys(currentEvents))
              ),
              map(([rtEvents]) => EventsActions.updateRTEvents({ rtEvents })),
              catchError(() =>
                of(EventsActions.updateRTEvents({ rtEvents: {} }))
              )
            )
          )
        )
      )
    );
  });

  fetchScreenshotsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.saveAuthUser),
      switchMap(() => timer(0, 3600000)),
      switchMap(() =>
        this.store.select(AuthenticationSelectors.selectToken).pipe(
          filter(token => !!token),
          switchMap(() =>
            from(this.fleetsService.getScreenshots()).pipe(
              map(screenshots =>
                ShipsActions.updateScreenshots({ screenshots })
              ),
              catchError(() =>
                of(ShipsActions.updateScreenshots({ screenshots: [] }))
              )
            )
          )
        )
      )
    );
  });

  fetchAndSaveShipDataOnSelectShipEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ShipsActions.fetchAndSaveShipDataOnSelectListShip,
        ShipsActions.fetchAndSaveShipDataOnSelectMapShip
      ),
      switchMap(action => {
        const { shipId } = action;

        return this.store.select(FiltersSelectors.selectFilteredDates).pipe(
          switchMap(dates => {
            return forkJoin({
              shipRealTimeData: this.shipsService.getShipRealTimeData$(
                dates,
                shipId
              ),
              shipSafetyScores: this.shipsService.getShipSafetyScores(
                shipId,
                dates
              ),
            }).pipe(
              map(({ shipRealTimeData, shipSafetyScores }) => {
                return ShipsActions.saveShipDataOnSelectShip({
                  shipId,
                  shipRealTimeData,
                  shipSafetyScores,
                });
              })
            );
          })
        );
      })
    );
  });

  fetchShipRealTimeDataEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.fetchShipDetails),
      switchMap(action =>
        timer(0, 60000).pipe(
          withLatestFrom(
            this.store.select(ShipsSelectors.selectSelectedShipId),
            this.store.select(FiltersSelectors.selectFilteredDates)
          ),
          filter(([_, shipId]) => shipId != null),
          switchMap(([_, shipId, dates]) =>
            this.shipsService.getShipRealTimeData$(dates, shipId!).pipe(
              map(shipRealTimeData => {
                if (shipRealTimeData === null) {
                  return ShipsActions.updateShipRealTimeData({
                    shipRealTimeData: null,
                  });
                } else {
                  return ShipsActions.updateShipRealTimeData({
                    shipRealTimeData,
                  });
                }
              })
            )
          )
        )
      )
    );
  });

  fetchLatestShipData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.fetchLatestShipImu),
      switchMap(action =>
        timer(0, 60000).pipe(
          switchMap(() =>
            from(this.shipsService.getLatestPitchAndRoll(action.shipId)).pipe(
              map(shipPitchRoll =>
                ShipsActions.updateLatestShipImu({ shipPitchRoll })
              )
            )
          ),
          catchError(() =>
            of(ShipsActions.updateLatestShipImu({ shipPitchRoll: [] }))
          )
        )
      )
    );
  });

  fetchShipLiveLocationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.fetchShipLiveData),
      switchMap(action =>
        timer(5 * 60 * 1000, 5 * 60 * 1000).pipe(
          withLatestFrom(
            this.store.select(ShipsSelectors.selectSelectedShipId)
          ),
          filter(([_, shipId]) => shipId != null),
          switchMap(([_, shipId]) =>
            this.shipsService.getShipLiveData$(shipId!).pipe(
              map(liveShipData => {
                if (liveShipData === null) {
                  return ShipsActions.updateShipLiveData({
                    liveShipData: null,
                  });
                } else {
                  return ShipsActions.updateShipLiveData({
                    liveShipData: liveShipData,
                  });
                }
              })
            )
          )
        )
      )
    );
  });

  checkConnectivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.getShipStreamMaster),
      concatLatestFrom(() =>
        this.store.select(ShipsSelectors.selectShipStreamingId)
      ),
      filter(([_, shipId]) => shipId !== null),
      switchMap(([_, shipId]) =>
        this.store.select(ShipsSelectors.selectShipById(shipId!)).pipe(
          switchMap(ship => {
            if (ship && ship.fleetName && ship.hostName) {
              return from(
                this.shipsService.checkConnectivity$(
                  ship.fleetName,
                  ship.hostName
                )
              ).pipe(
                map(status =>
                  ShipsActions.updateConnectivityStatusOnLoadingStage({
                    status,
                  })
                )
              );
            }
            return EMPTY;
          })
        )
      )
    );
  });

  getStreamQuality$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShipsActions.getStreamQuality),
      concatLatestFrom(() => [
        this.store.select(ShipsSelectors.selectStreamSessionId),
        this.store.select(ShipsSelectors.selectShipStreamingId),
      ]),
      filter(
        ([_, streamSessionId, streamShipId]) =>
          streamSessionId !== null && streamShipId !== null
      ),
      switchMap(([_, streamSessionId, streamShipId]) =>
        this.store.select(ShipsSelectors.selectShipById(streamShipId!)).pipe(
          switchMap(ship => {
            if (ship && ship.hostName) {
              return from(
                this.shipsService.checkQuality$(streamSessionId!, ship.hostName)
              ).pipe(
                map(quality =>
                  ShipsActions.updateStreamQualityStatusOnLive({ quality })
                ),
                catchError(() => EMPTY)
              );
            }
            return EMPTY;
          })
        )
      )
    );
  });
}
