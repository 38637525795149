import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable, of } from 'rxjs';
import { CoreState } from '../../store/state/core.state';
import { AuthenticationSelectors, ShipsActions } from '../../store/types';

@Component({
  selector: 'app-report-item',
  templateUrl: './report-item.component.html',
  styleUrls: ['./report-item.component.scss'],
})
export class ReportItemComponent {
  @Input() file_name: string = '';
  @Input() last_updated: string = '';
  @Input() url: string = '#';

  token$: Observable<string | undefined> = of(undefined);

  constructor(private store: Store<CoreState>) {
    this.token$ = this.store.select(AuthenticationSelectors.selectToken);
  }

  get formattedFileName(): string {
    return this.file_name.endsWith('.pdf')
      ? this.file_name.slice(0, -4)
      : this.file_name;
  }

  async downloadReport() {
    this.store.dispatch(
      ShipsActions.reportDownloaded({ reportName: this.file_name })
    );

    const token = await firstValueFrom(this.token$);

    fetch(`${this.url}?jwt=${token}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.blob();
      })
      .then(blob => {
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = this.file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(fileURL);
      })
      .catch(error => {
        console.error('Error fetching the file:', error);
      });
  }

  viewReport() {
    this.store.dispatch(
      ShipsActions.reportViewed({ reportName: this.file_name })
    );
  }
}
