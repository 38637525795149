import { LngLat, MapOptions } from 'mapbox-gl';
import { DEFAULT_MAX_BOUNDS } from 'src/app/mapbox/models/mapbox-configurations.models';
import { MapboxEntityConfiguration } from 'src/app/mapbox/models/mapbox.models';
import { environment } from 'src/environments/environment';

export const MAP_ENTITIES_CONFIGURATIONS: MapboxEntityConfiguration[] = [
  '/assets/map/events/close_encounter/high.png',
  '/assets/map/events/close_encounter/low.png',
  '/assets/map/events/close_encounter/medium.png',
  '/assets/map/events/compliance/high.png',
  '/assets/map/events/high_pitch_roll/high.png',
  '/assets/map/events/high_pitch_roll/low.png',
  '/assets/map/events/high_pitch_roll/medium.png',
  '/assets/map/events/high_rot/high.png',
  '/assets/map/events/high_rot/low.png',
  '/assets/map/events/high_rot/medium.png',
  '/assets/map/events/high.png',
  '/assets/map/events/high_full.png',
  '/assets/map/events/low.png',
  '/assets/map/events/medium.png',
  '/assets/map/events/no_go_zone/high.png',
  '/assets/map/events/speed_drop/high.png',
  '/assets/map/events/speed_drop/low.png',
  '/assets/map/events/speed_drop/medium.png',
  '/assets/map/events/ukc/high.png',
  '/assets/map/events/ukc/low.png',
  '/assets/map/events/ukc/medium.png',
  '/assets/map/ships/bulk_carrier/faded_ship_icon.png',
  '/assets/map/ships/bulk_carrier/ship_icon.png',
  '/assets/map/ships/container_ship/faded_ship_icon.png',
  '/assets/map/ships/container_ship/ship_icon.png',
  '/assets/map/ships/crude_oil_tanker/faded_ship_icon.png',
  '/assets/map/ships/crude_oil_tanker/ship_icon.png',
  '/assets/map/ships/default.png',
  '/assets/map/ships/demo/faded_ship_icon.png',
  '/assets/map/ships/demo/ship_icon.png',
  '/assets/map/ships/faded_default.png',
  '/assets/map/ships/lng_tanker/faded_ship_icon.png',
  '/assets/map/ships/lng_tanker/ship_icon.png',
  '/assets/map/ships/lpg_tanker/faded_ship_icon.png',
  '/assets/map/ships/lpg_tanker/ship_icon.png',
  '/assets/map/ships/roro_vehicle_carrier/faded_ship_icon.png',
  '/assets/map/ships/roro_vehicle_carrier/ship_icon.png',
  '/assets/map/ships/tug/faded_ship_icon.png',
  '/assets/map/ships/tug/ship_icon.png',
  '/assets/map/ships/vehicles_carrier/faded_ship_icon.png',
  '/assets/map/ships/vehicles_carrier/ship_icon.png',
  '/assets/map/voyages/start.png',
  '/assets/map/voyages/end.png',
].map(image => ({ image }));

const MARITIME_MAP_CETNER = new LngLat(-17.743711, 34.903953);

export const MARITIME_MAP_OPTIONS: MapOptions = {
  container: '',
  style: 'mapbox://styles/eladv/clzvare2u000101pl8cpd1f3i',
  preserveDrawingBuffer: true,
  accessToken: environment.mapbox.mapProviderAccessToken,
  center: MARITIME_MAP_CETNER,
  maxBounds: DEFAULT_MAX_BOUNDS,
  dragRotate: false,
  minZoom: 1.8,
  touchZoomRotate: false,
};
