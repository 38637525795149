export type FeatureKey =
  | 'LiveView'
  | 'efficiency'
  | 'weather'
  | 'comments'
  | 'insights'
  | 'FeatureLock'
  | 'mobile'
  | 'ff-livepage-v2'
  | 'ff-voyages';

export interface FeatureFlag {
  readonly featureKey: FeatureKey;
  readonly value: boolean;
}

export enum FeatureFlagState {
  Enabled = 1,
  Disabled = 2,
  Expired = 3,
}
