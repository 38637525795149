import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';
import { VoyagesRepository } from '../pages/voyages/voyages.repository';
import { CoreState } from '../store/state/core.state';
import { AuthenticationSelectors } from '../store/types';

export const voyagesResolver: ResolveFn<any> = async () => {
  const store: Store<CoreState> = inject(Store);
  const repository = inject(VoyagesRepository);

  await firstValueFrom(
    store
      .select(AuthenticationSelectors.selectAuthenticatedUser)
      .pipe(filter(authUser => authUser?.token != ''))
  );

  repository.getOriginsAndDestinations();
};
