import { FormControl, FormGroup } from '@angular/forms';
import { DropdownViewModel } from 'src/app/shared/view-models/dropdown.options.view.model';

export enum UserRolesEnum {
  FleetManager = 'fleet-manager',
  ShipCaptain = 'ship-captain',
  SubFleetManager = 'sub-fleet-manager',
}

export enum UserRoleDynamicallyEnabledFormFieldEnum {
  Ships = 'shipId',
  SubFleets = 'subFleetIds',
}

export const userRoleEnabledFormFields: {
  [key in UserRolesEnum]: UserRoleDynamicallyEnabledFormFieldEnum[];
} = {
  [UserRolesEnum.FleetManager]: [],
  [UserRolesEnum.ShipCaptain]: [UserRoleDynamicallyEnabledFormFieldEnum.Ships],
  [UserRolesEnum.SubFleetManager]: [
    UserRoleDynamicallyEnabledFormFieldEnum.SubFleets,
  ],
};

export const rolesDropdownOptions: DropdownViewModel[] = [
  {
    value: UserRolesEnum.FleetManager,
    viewValue: 'Fleet Manager',
  },
  {
    value: UserRolesEnum.ShipCaptain,
    viewValue: 'Captain',
  },
  {
    value: UserRolesEnum.SubFleetManager,
    viewValue: 'Sub Fleet Manager',
  },
];

interface UserRoleFormControls {
  fleetId: FormControl<number | null>;
  role: FormControl<UserRolesEnum | null>;
  shipId: FormControl<number | null>;
  subFleetIds: FormControl<number[]>;
}

export type UserRoleFormGroup = FormGroup<UserRoleFormControls>;
