/* eslint-disable @typescript-eslint/naming-convention */
import { TypeGuard } from 'src/app/shared/pipes/type-guard.pipe';
import { GeneralEventViewModel } from 'src/app/shared/view-models/general.event.view.model';
import { Range } from 'src/app/shared/view-models/range.view.model';

import {
  ColregClassificationNames,
  TargetTypeNames,
} from '../models/events-filters.model';
import { PolygonType } from '../models/fleet.model';

export type ViewNamesDto = 'stitched' | 'screen';
export type ViewStreamNamesDto = 'screen_casting';
export type StreamingStateType = 'Ok' | 'WaitingForStream' | 'Timeout';
export type DayNamesDto =
  | 'day_left_2nd'
  | 'day_left'
  | 'day_center'
  | 'day_right'
  | 'day_right_2nd';
export type ThermalNamesDto =
  | 'thermal_left'
  | 'thermal_center'
  | 'thermal_right';
export type ViewsVideosNames = 'Stitched View' | 'Bridge Screen View';
export type ViewsStreamNames = 'Bridge Screen View';
export type VideosNames =
  | 'Port-Stern'
  | 'Port'
  | 'Bow'
  | 'Starboard'
  | 'Starboard-Stern';

export type ViewsVideosMap = {
  [key in ViewNamesDto]: ViewsVideosNames;
};

export type ViewsStreamMap = {
  [key in ViewStreamNamesDto]: ViewsStreamNames;
};

export type DaysVideosMap = {
  [key in DayNamesDto]: VideosNames;
};

export type ThermalVideosMap = {
  [key in ThermalNamesDto]: VideosNames;
};

export type ViewsVideo = {
  [key in ViewsVideosNames]: string | null;
};

export const viewsVideoMap: ViewsVideosMap = {
  stitched: 'Stitched View',
  screen: 'Bridge Screen View',
};

export const viewsStreamMap: ViewsStreamMap = {
  screen_casting: 'Bridge Screen View',
};

export const dayVideoMap: DaysVideosMap = {
  day_left_2nd: 'Port-Stern',
  day_left: 'Port',
  day_center: 'Bow',
  day_right: 'Starboard',
  day_right_2nd: 'Starboard-Stern',
};

export const thermalVideoMap: ThermalVideosMap = {
  thermal_left: 'Port',
  thermal_center: 'Bow',
  thermal_right: 'Starboard',
};
export type Videos = {
  [key in VideosNames]: string | null;
};

export interface StreamVideo {
  readonly cameraId: DayNamesDto | ThermalNamesDto | ViewStreamNamesDto;
  readonly cameraName: VideosNames | ViewsStreamNames;
}
export interface StreamingState {
  readonly state: StreamingStateType;
  readonly streamUrl: string;
}

export interface SelectedVideo {
  readonly label: ViewsVideosNames | VideosNames;
  readonly url: string | null;
}

export type EventType =
  | 'Close Encounter'
  | 'Compliance'
  | 'High Pitch/Roll'
  | 'High ROT'
  | 'No Go Zone'
  | 'Speed Drop'
  | 'UKC';
export type Events =
  | CloseEncounterEvent
  | HighPitchRollEvent
  | SpeedDropEvent
  | UKCEvent
  | HighRotEvent
  | ComplianceEvent
  | NoGoZoneEvent
  | RtEvent;

export interface IEventType {
  readonly type: EventType;
}

export interface CloseEncounterEvent extends GeneralEventViewModel, IEventType {
  readonly minDistance: number;
  readonly targetSog: number;
  readonly targetType: TargetTypeNames;
  readonly colregClassification: ColregClassificationNames;
  readonly targetId: string;
  readonly costOfExtraDistanceSailedDollar: number | null | undefined;
  readonly extraFuelConsumedMt: number | null | undefined;
}

export interface HighPitchRollEvent extends GeneralEventViewModel, IEventType {
  readonly roll: number;
  readonly pitch: number;
}

export interface SpeedDropEvent extends GeneralEventViewModel, IEventType {
  readonly eSog: number;
}

export interface UKCEvent extends GeneralEventViewModel, IEventType {
  readonly depth: number;
}

export interface HighRotEvent extends GeneralEventViewModel, IEventType {
  readonly rot: number;
}

export interface ComplianceEvent extends GeneralEventViewModel, IEventType {}

export interface NoGoZoneEvent extends GeneralEventViewModel, IEventType {}

export interface RtEvent extends GeneralEventViewModel, IEventType {
  readonly start: Date;
  readonly end: Date | null;
  readonly pitch: number;
  readonly roll: number;
  readonly screenshots: RTEventScreenshot[] | null;
  readonly imuSamples: RtEventImuSample[] | null;
  readonly location: string;
  readonly polygonId?: string;
  readonly areaOfInterest?: string;
}

export type RtEventToMap = RtEvent | ActiveRTEvent;

export interface Polygon {
  polygonId: string;
  fleetId: number;
  polygonArea: string;
  edges: number[][];
  polygonType: PolygonType;
  areaOfInterest?: string;
  aoiComplianceLimitations: RTEventLimitations | null;
}

export interface ShipLastLocation {
  latitude: number;
  longitude: number;
  sog: number;
  time: string;
}

export interface RtEventData {
  rtEvent: RtEvent;
  polygons?: Polygon[];
}

export interface RTEventScreenshot {
  readonly url: string;
  readonly timestamp: string;
}

export interface RtEventImuSample {
  readonly roll: number;
  readonly pitch: number;
  readonly sog: number;
  readonly sampledAt: Date;
}
export interface Limitation {
  readonly minAllowed?: number;
  readonly maxAllowed?: number;
  readonly notInRange?: Range;
}

export interface RTEventLimitations {
  readonly sog: Limitation;
  readonly cog: Limitation;
}

export const isCloseEncounterEvent: TypeGuard<Events, CloseEncounterEvent> = (
  event: Events
): event is CloseEncounterEvent => event.type === 'Close Encounter';

export const isHighPitchRollEvent: TypeGuard<Events, HighPitchRollEvent> = (
  event: Events
): event is HighPitchRollEvent => event.type === 'High Pitch/Roll';

export const isSpeedDropEvent: TypeGuard<Events, SpeedDropEvent> = (
  event: Events
): event is SpeedDropEvent => event.type === 'Speed Drop';

export const isUKCEvent: TypeGuard<Events, UKCEvent> = (
  event: Events
): event is UKCEvent => event.type === 'UKC';

export const isHighRot: TypeGuard<Events, HighRotEvent> = (
  event: Events
): event is HighRotEvent => event.type === 'High ROT';

export const isRTHighPitchRollEvent: TypeGuard<Events, RtEvent> = (
  event: Events
): event is RtEvent => event.type === 'High Pitch/Roll';

export const isRTNoGoZoneEvent: TypeGuard<Events, RtEvent> = (
  event: Events
): event is RtEvent => event.type === 'No Go Zone';

export const isRTComplianceEvent: TypeGuard<Events, RtEvent> = (
  event: Events
): event is RtEvent => event.type === 'Compliance';

export interface ActiveRTEventDto {
  ship_id: number;
  polygon_uuid: string;
  aoi_compliance_limitations: RTEventLimitations;
  event_type: string;
  timestamp_start: string;
  area_of_interest: string;
}

export interface ActiveRTEvent {
  areaOfInterest: string;
  aoiComplianceLimitations: RTEventLimitations;
  polygonId: string;
  alertName: string;
  timestampStart: Date;
}

export enum RtEventGraphSegmentsChoices {
  PITCH_AND_ROLL = 'Pitch and Roll',
  SOG = 'Sog',
}
