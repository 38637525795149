import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  @Input() cancelButtonText: string = 'Cancel';
  @Input() confirmButtonText: string = 'Yes';
  @Input() title: string = 'Confirm';
  @Input() icon: string = 'assets/icons/big_trash.svg';
  @Input() onConfirmAction?: () => Observable<boolean>;
  errorMessage: string = '';
  constructor(private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}

  onConfirm(): void {
    if (this.onConfirmAction) {
      this.errorMessage = '';
      this.onConfirmAction().subscribe({
        next: () => this.dialogRef.close(true),
        error: (error: Error) => {
          this.errorMessage = error.message;
        },
      });
    } else {
      this.dialogRef.close(true);
    }
  }
}
