import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import {
  catchError,
  firstValueFrom,
  forkJoin,
  map,
  Observable,
  of,
  timeout,
} from 'rxjs';
import { getNumOfDaysInMonth } from 'src/app/shared/utils/date-transforms/date-diff';
import { Dates } from 'src/app/shared/view-models/dates.view.model';
import { environment } from 'src/environments/environment';
import { EventCountDto } from '../models/events.model';
import {
  ConnectivityStatus,
  defaultShipsSeverityByDate,
  FleetSeverityScoreDto,
  LiveShipData,
  LiveShipDataDto,
  PitchAndRollData,
  PitchAndRollDataDto,
  QualityStatus,
  ScoringFactorDtoKey,
  scoringFactorDtoKeyToDescription,
  ScoringFactorsStats,
  ScoringFactorsStatsDto,
  Ship,
  ShipCamera,
  shipCamerasDto,
  ShipDto,
  ShipEventCount,
  ShipLiveStream,
  ShipSafetyScore,
  ShipSafetyScoreDto,
  ShipSafetyScores,
  ShipSafteyScore,
  ShipSafteyScoreDto,
  ShipSailData,
  ShipSailDataDto,
  ShipSeverityScore,
  ShipsSeverityByDate,
  ShipStreamDto,
  ShipStreamMasterDto,
  TotalAvgShipsSeverity,
} from '../models/ship.model';
import { ShipsSortBy } from '../models/ships-filters.model';
import {
  calcChangesBetweenMonth,
  mapShipCameraDto,
  mapShipSalingData,
  mapStreamDto,
  reverseEventsCalc,
  transformShipTypeDto,
} from '../utils/ship';
import { EventGroup } from '../view-models/filter.view.model';
import { IsBusyService } from './is-busy.service';

@Injectable({
  providedIn: 'root',
})
export class ShipsService {
  constructor(private http: HttpClient, private isBusyService: IsBusyService) {}

  getShipSafetyScores(shipId: number, dates: Dates): Promise<ShipSafetyScores> {
    return this.isBusyService.add(() => {
      return firstValueFrom(
        this.http
          .get<ShipSafetyScoreDto[]>(
            `${environment.api.shipsSeverity}/${shipId}`,
            {
              params: {
                start_date: dates.startDate.toISOString(),
                end_date: dates.endDate.toISOString(),
              },
            }
          )
          .pipe(
            map((shipScores): ShipSafetyScores | null => {
              const modifiedScores: ShipSafetyScore[] = shipScores.map(
                score => ({
                  safetyScore: score.score,
                  date: new Date(score.date),
                })
              );
              const sortedModifiedScores = modifiedScores.sort((a, b) =>
                a.date.getTime() > b.date.getTime() ? 1 : -1
              );

              return {
                safetyScores: sortedModifiedScores,
                shipId: shipId,
              };
            }),
            catchError(_ => of({ safetyScores: [], shipId: shipId }))
          )
      );
    });
  }

  getShipsScoreStatistics(shipId: number): Promise<ScoringFactorsStats> {
    return this.isBusyService.add(() => {
      return firstValueFrom(
        this.http
          .get<ScoringFactorsStatsDto>(
            `${environment.api.ships}/${shipId}/explainable_score`
          )
          .pipe(
            map((scoringFactorsDto): ScoringFactorsStats => {
              const scoringFactors: ScoringFactorsStats =
                {} as ScoringFactorsStats;

              for (const key in scoringFactorsDto) {
                scoringFactors[key as ScoringFactorDtoKey] = {
                  value: scoringFactorsDto[key as ScoringFactorDtoKey].value,
                  label: scoringFactorsDto[key as ScoringFactorDtoKey].label,
                  description:
                    scoringFactorDtoKeyToDescription[
                      key as ScoringFactorDtoKey
                    ],
                };
              }

              return scoringFactors;
            }),
            catchError(_ => of(null))
          )
      );
    });
  }

  getShipsSeverity(dates: Dates): Promise<ShipsSeverityByDate> {
    return this.isBusyService.add(() => {
      return firstValueFrom(
        this.http
          .get<FleetSeverityScoreDto>(environment.api.shipsSeverity, {
            params: {
              start_date: dates.startDate.toISOString(),
              end_date: dates.endDate.toISOString(),
            },
          })
          .pipe(
            map((shipsDto): ShipsSeverityByDate | null => {
              if (shipsDto.safety_scores.length == 0) {
                return defaultShipsSeverityByDate;
              }
              const shipsSeverityByDate = shipsDto.safety_scores.map(e => {
                const shipsSeverityDto: ShipSeverityScore = {
                  excellentSeverityShips: e.sum_of_excellent_severity,
                  goodSeverityShips: e.sum_of_good_severity,
                  moderateSeverityShips: e.sum_of_moderate_severity,
                  lowSeverityShips: e.sum_of_low_severity,
                  naSeverityShips: e.sum_of_na_severity,
                  avgScore: e.avg_score_per_fleet,
                  date: new Date(e.date),
                };
                return shipsSeverityDto;
              });
              const sorted = shipsSeverityByDate.sort((a, b) =>
                a.date.getTime() > b.date.getTime() ? 1 : -1
              );
              const totalAvgShipsSeverity: TotalAvgShipsSeverity = {
                excellentSeverityShips:
                  shipsDto.avg_severities.sum_of_excellent_severity,
                goodSeverityShips: shipsDto.avg_severities.sum_of_good_severity,
                moderateSeverityShips:
                  shipsDto.avg_severities.sum_of_moderate_severity,
                lowSeverityShips: shipsDto.avg_severities.sum_of_low_severity,
                naSeverityShips: shipsDto.avg_severities.sum_of_na_severity,
              };
              return {
                totalAvgShipsSeverity: totalAvgShipsSeverity,
                shipsSeverityByDate: [...sorted],
                startDate: sorted[0].date,
                endDate: sorted[sorted.length - 1].date,
                avgFleetScore: shipsDto.avg_fleet_safety_score,
                avgOrcaScore: shipsDto.avg_orca_safety_score,
              };
            }),
            catchError(_ => of(defaultShipsSeverityByDate))
          )
      );
    });
  }

  getSafteyScoreShips$(sortBy: ShipsSortBy, dates: Dates, limit = 3) {
    return firstValueFrom(
      this.http
        .get<ShipSafteyScoreDto[]>(environment.api.highestSafteyScoreShips, {
          params: {
            sort_by: sortBy,
            start_date: dates.startDate.toISOString(),
            end_date: dates.endDate.toISOString(),
            size: limit,
          },
        })
        .pipe(
          map(shipsSafetyScoresDto => {
            const safteyScoreShips = shipsSafetyScoresDto.map(
              (dto): ShipSafteyScore => {
                return {
                  shipName: dto.ship_name,
                  shipId: dto.own_ship_id,
                  scoreEnd: dto.score_end,
                  scoreStart: dto.score_start,
                  avg_score: dto.avg_score,
                };
              }
            );
            return safteyScoreShips;
          }),
          catchError(_ => of([]))
        )
    );
  }

  getLatestPitchAndRoll(shipId: number): Observable<PitchAndRollData[]> {
    return this.http
      .get<PitchAndRollDataDto[]>(
        `${environment.api.ships}/${shipId}/get_latest_pitch_and_roll`
      )
      .pipe(
        map(dtos =>
          dtos.map(dto => ({
            date: dto.timestamp_captured,
            pitch: dto.imu_pitch,
            roll: dto.imu_roll,
          }))
        )
      );
  }

  getShipRealTimeData$(dates: Dates, shipId: number): Observable<Ship | null> {
    return this.http
      .get<ShipDto[]>(`${environment.api.ships}/management/${shipId}`, {
        params: {
          start_date: dates.startDate.toISOString(),
          end_date: dates.endDate.toISOString(),
        },
      })
      .pipe(
        map(shipsDto => {
          const firstShipDto = shipsDto[0];
          return {
            shipId: firstShipDto.ship_id,
            shipName: firstShipDto.ship_name,
            hostName: firstShipDto.host_name,
            safetyScore: firstShipDto.avg_score ?? 0,
            fleetId: firstShipDto.fleet_id,
            fleetName: firstShipDto.fleet_name,
            lastConnection: firstShipDto.last_connection_datetime
              ? new Date(firstShipDto.last_connection_datetime)
              : null,
            latestEventDateAdded: firstShipDto.latest_event_added_date
              ? new Date(firstShipDto.latest_event_added_date)
              : null,
            lastLocationTimestamp: firstShipDto.last_location_timestamp,
            textualLocation: firstShipDto.textual_location,
            sog: firstShipDto.sog ?? null,
            cog: firstShipDto.cog ?? null,
            max_pitch: firstShipDto.max_pitch ?? null,
            max_roll: firstShipDto.max_roll ?? null,
            latestEventaddedId: firstShipDto.latest_event_added_id,
            latitude: firstShipDto.latitude ?? 0,
            longitude: firstShipDto.longitude ?? 0,
            scoreTrend: firstShipDto.score_trend ?? 0,
            shipType: transformShipTypeDto(firstShipDto.ship_type),
            totalSailedDistance: firstShipDto.total_last_sailed_distance,
          };
        }),
        catchError(_ => of(null))
      );
  }

  getShips$(dates: Dates): Promise<Ship[]> {
    return this.isBusyService.add(() => {
      return firstValueFrom(
        this.http
          .get<ShipDto[]>(`${environment.api.ships}/management`, {
            params: {
              start_date: dates.startDate.toISOString(),
              end_date: dates.endDate.toISOString(),
            },
          })
          .pipe(
            map(shipsDto =>
              shipsDto.map(
                (ship): Ship => ({
                  shipId: ship.ship_id,
                  shipName: ship.ship_name,
                  hostName: ship.host_name,
                  safetyScore: ship.avg_score ?? 0,
                  fleetId: ship.fleet_id,
                  fleetName: ship.fleet_name,
                  lastConnection: ship.last_connection_datetime
                    ? new Date(ship.last_connection_datetime)
                    : null,
                  latestEventDateAdded: ship.latest_event_added_date
                    ? new Date(ship.latest_event_added_date)
                    : null,
                  lastLocationTimestamp: ship.last_location_timestamp,
                  textualLocation: ship.textual_location,
                  sog: ship.sog ?? null,
                  cog: ship.cog ?? null,
                  max_pitch: ship.max_pitch ?? null,
                  max_roll: ship.max_roll ?? null,
                  latestEventaddedId: ship.latest_event_added_id,
                  latitude: ship.latitude ?? 0,
                  longitude: ship.longitude ?? 0,
                  scoreTrend: ship.score_trend ?? 0,
                  shipType: transformShipTypeDto(ship.ship_type),
                  totalSailedDistance: ship.total_last_sailed_distance,
                })
              )
            ),
            catchError(_ => of([]))
          )
      );
    });
  }

  getShipCameras$(shipId: number, fleetId: number): Observable<ShipCamera[]> {
    const url = `${environment.api.liveStream}/fleets/${fleetId}/ships/${shipId}/cameras`;

    return this.http.get<shipCamerasDto>(url).pipe(
      timeout(20000),
      map((camerasDto): ShipCamera[] =>
        camerasDto.cameras.map((dto): ShipCamera => mapShipCameraDto(dto))
      ),
      catchError(_ => of([]))
    );
  }

  patchStreamMaster$(shipId: number, fleetId: number): Observable<boolean> {
    const url = `${environment.api.liveStream}/fleets/${fleetId}/ships/${shipId}/streamMaster`;

    return this.http.patch<ShipStreamMasterDto>(url, {}).pipe(
      timeout(20000),
      map((streamMaster): boolean => streamMaster.isMaster),
      catchError(_ => of(false))
    );
  }

  patchStream$(
    cameraId: string,
    shipId: number,
    fleetId: number
  ): Observable<Partial<ShipLiveStream>> {
    const url = `${environment.api.liveStream}/fleets/${fleetId}/ships/${shipId}/cameras/${cameraId}`;

    return this.http.patch<ShipStreamDto>(url, {}).pipe(
      timeout(20000),
      map((stream): Partial<ShipLiveStream> => mapStreamDto(stream)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Forbidden) {
          return of(mapStreamDto(error.error as ShipStreamDto));
        }
        return of({ streamUrl: '' });
      })
    );
  }

  startStream$(
    shipId: number,
    fleetId: number
  ): Observable<Partial<ShipLiveStream>> {
    const url = `${environment.api.liveStream}/fleets/${fleetId}/ships/${shipId}`;

    return this.http.post<ShipStreamDto>(url, {}).pipe(
      timeout(20000),
      map((stream): Partial<ShipLiveStream> => mapStreamDto(stream)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Forbidden) {
          return of(mapStreamDto(error.error as ShipStreamDto));
        }
        return of({ streamUrl: '' });
      })
    );
  }

  checkConnectivity$(
    fleetName: string,
    hostName: string
  ): Observable<ConnectivityStatus> {
    const url = `${environment.api.liveStreamConnectivity}?fleet=${_.snakeCase(
      fleetName
    )}&ship=${hostName}`;
    return this.http.get<{ status: ConnectivityStatus }>(url).pipe(
      timeout(20000),
      map(response => response.status),
      catchError(() => of(ConnectivityStatus.No))
    );
  }

  checkQuality$(sessionId: string): Observable<QualityStatus> {
    const url = `${environment.api.liveStreamQuality}?session_id=${sessionId}`;
    return this.http.get<{ stream_quality_status: QualityStatus }>(url).pipe(
      timeout(20000),
      map(response => response.stream_quality_status),
      catchError(() => of(QualityStatus.Unknown))
    );
  }

  getShipSailData$(
    shipId: number,
    periodInDays: number
  ): Promise<ShipSailData[]> {
    // we only want to calc completed month, so we calc back 90\180\365 days, and set the date to the start of the month. e.g: 28.9 - 90 days is 28.6 -> set date to 1 = 1.6
    // the end date is always up to the 1.current month so end date is current month with date set to 1.
    const dates = this.setShipSailQueryParams(periodInDays);
    return this.isBusyService.add(() => {
      return firstValueFrom(
        forkJoin([
          this.http
            .get<ShipSailDataDto[]>(`${environment.api.ships}/${shipId}/sail`, {
              params: {
                start_date: dates.startDate.toISOString(),
                end_date: dates.endDate.toISOString(),
                group_by: 'congestion_level',
              },
            })
            .pipe(
              map(dto => dto.map(d => mapShipSalingData(d))),
              map(sailData =>
                sailData.map(data => ({
                  ...data,
                  changesInEventsNum: calcChangesBetweenMonth(data.sailData),
                  changesInCeNum: calcChangesBetweenMonth(data.ceEventsData),
                }))
              ),

              catchError(_ => of([]))
            ),
          this.http
            .get<ShipSailDataDto[]>(`${environment.api.ships}/${shipId}/sail`, {
              params: {
                start_date: dates.startDate.toISOString(),
                end_date: dates.endDate.toISOString(),
              },
            })
            .pipe(
              map(dto => dto.map(d => mapShipSalingData(d))),
              map(data =>
                data.map(d => ({
                  ...d,
                  sailData: reverseEventsCalc(d.sailData),
                }))
              ),
              map(sailData =>
                sailData.map(data => ({
                  ...data,
                  changesInEventsNum: calcChangesBetweenMonth(data.sailData),
                  changesInCeNum: calcChangesBetweenMonth(data.ceEventsData),
                }))
              ),

              catchError(_ => of([]))
            ),
          this.http
            .get<ShipSailDataDto[]>(`${environment.api.ships}/${shipId}/sail`, {
              params: {
                start_date: dates.startDate.toISOString(),
                end_date: dates.endDate.toISOString(),
                group_by: 'congestion_level',
                alert_type: 'Collision risk',
              },
            })
            .pipe(
              map(dto => dto.map(d => mapShipSalingData(d, 'Collision risk'))),
              map(sailData =>
                sailData.map(data => ({
                  ...data,
                  changesInEventsNum: calcChangesBetweenMonth(data.sailData),
                  changesInCeNum: calcChangesBetweenMonth(data.ceEventsData),
                }))
              ),
              catchError(_ => of([]))
            ),
        ]).pipe(
          map(([groupedSailData, sailData, groupedByAlertAndCongestion]) => [
            ...groupedSailData,
            ...sailData,
            ...groupedByAlertAndCongestion,
          ])
        )
      );
    });
  }

  getShipLiveData$(shipId: number): Observable<LiveShipData | null> {
    return this.http
      .get<LiveShipDataDto[]>(`${environment.api.realTime}/${shipId}`)
      .pipe(
        map(shipData => {
          const firstShipDto = shipData[0];
          return {
            shipId: firstShipDto.ship_id,
            latitude: firstShipDto.latitude ?? 0,
            longitude: firstShipDto.longitude ?? 0,
          };
        }),
        catchError(_ => of(null))
      );
  }

  private setShipSailQueryParams(periodInDays: number): Dates {
    const startDateLocal = new Date(
      new Date(new Date().setDate(new Date().getDate() - periodInDays)).setDate(
        1
      )
    );
    const startDate = new Date(
      Date.UTC(
        startDateLocal.getUTCFullYear(),
        startDateLocal.getUTCMonth(),
        startDateLocal.getUTCDate(),
        0,
        0,
        0
      )
    );

    const currentMonth = new Date().getMonth();

    const prevMonth = currentMonth - 1 < 0 ? 11 : currentMonth - 1; // if the current month is jan - then prev month is dec.
    const currentYear =
      currentMonth - 1 < 0
        ? new Date().getFullYear() - 1
        : new Date().getFullYear();
    const daysInPrevMonth = getNumOfDaysInMonth(currentYear, prevMonth);
    const endDate = new Date(
      Date.UTC(currentYear, prevMonth, daysInPrevMonth, 0, 0, 0)
    );

    return {
      startDate,
      endDate,
    };
  }

  public getShipEventsCount$(
    shipId: number | null,
    group: EventGroup,
    datesFilter: Dates
  ): Observable<EventCountDto> {
    if (shipId == null) {
      return of({
        total_count: 0,
        group_result: [],
      });
    }
    return this.http
      .get<EventCountDto>(`${environment.api.eventsCount}/ship/${shipId}`, {
        params: {
          group_by: group,
          inside_port: false,
          start_date: datesFilter.startDate.toISOString(),
          end_date: datesFilter.endDate.toISOString(),
        },
      })
      .pipe(
        map(res => res),
        catchError(_ =>
          of({
            total_count: 0,
            group_result: [],
          })
        )
      );
  }

  public getShipEventsGroupCount$(
    shipId: number | null,
    datesFilter: Dates
  ): Promise<ShipEventCount> {
    return this.isBusyService.add(() => {
      return firstValueFrom(
        forkJoin([
          this.getShipEventsCount$(shipId, 'alert_name', datesFilter),
          this.getShipEventsCount$(shipId, 'severity', datesFilter),
          this.getShipEventsCount$(shipId, 'crew_shift', datesFilter),
        ]).pipe(
          map(
            ([
              eventsByAlertNameDto,
              eventsBySeverityDto,
              eventsByCrewShiftDto,
            ]) => {
              const shipEventCountByGroup: ShipEventCount = {
                totalCount: eventsByAlertNameDto.total_count,
                byAlertName: eventsByAlertNameDto.group_result,
                bySeverity: eventsBySeverityDto.group_result,
                byCrewShift: eventsByCrewShiftDto.group_result,
              };
              return shipEventCountByGroup;
            }
          )
        )
      );
    });
  }
}
