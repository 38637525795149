import { Expose } from 'class-transformer';
import { User } from 'dashboard-frontend-library/models/auth/user.model';

export class SubFleet {
  @Expose({ name: 'sub_fleet_id' })
  subFleetId!: number;

  @Expose({ name: 'sub_fleet_name' })
  subFleetName!: string;

  @Expose({ name: 'fleet_id' })
  fleetId!: number;

  @Expose({ name: 'ships_list' })
  shipsList!: number[];
  constructor(
    subFleetId: number,
    subFleetName: string,
    fleetId: number,
    shipsList: number[]
  ) {
    this.subFleetId = subFleetId;
    this.subFleetName = subFleetName;
    this.fleetId = fleetId;
    this.shipsList = shipsList;
  }
}

export class SubFleetWithUsers extends SubFleet {
  @Expose({ name: 'assigned_users' })
  assignedUsers!: User[];
}

export class CreateSubFleetDto {
  @Expose({ name: 'sub_fleet_name' })
  subFleetName!: string;

  @Expose({ name: 'ships_list' })
  shipsList!: number[];

  @Expose({ name: 'fleet_id' })
  fleetId!: number;

  constructor(subFleetName: string, shipsList: number[], fleetId: number) {
    this.subFleetName = subFleetName;
    this.shipsList = shipsList;
    this.fleetId = fleetId;
  }
}

export class UpdateSubFleetDto {
  @Expose({ name: 'sub_fleet_name' })
  subFleetName: string;

  @Expose({ name: 'ships_list' })
  shipsList: number[];

  constructor(subFleetName: string, shipsList: number[]) {
    this.subFleetName = subFleetName;
    this.shipsList = shipsList;
  }
}
