import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MapboxLegendConfigurations } from '../../models/mapbox-legend.models';

const DEFAULT_CONFIGURATIONS: MapboxLegendConfigurations = {
  id: 'default',
  unitsName: 'legend',
  legendTicks: [],
};

@Component({
  selector: 'app-map-legend-plugin',
  standalone: true,
  templateUrl: './map-legend-plugin.component.html',
  styleUrls: ['./map-legend-plugin.component.scss'],
  imports: [CommonModule],
})
export class MapLegendPluginComponent implements OnInit, OnDestroy {
  @Input() configurations$!: BehaviorSubject<MapboxLegendConfigurations>;
  configurations: MapboxLegendConfigurations = DEFAULT_CONFIGURATIONS;
  subscription!: Subscription;

  ngOnInit(): void {
    this.configurations = this.configurations$.getValue();
    this.subscription = this.configurations$.subscribe(
      configurations => (this.configurations = configurations)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
