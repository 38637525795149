import { Ship } from '../models/ship.model';
import { RtEvent, ShipLastLocation } from '../view-models/event.view.model';

export function computeEventCoordinates(
  event: RtEvent,
  ship: Ship | undefined,
  lastLocations: ShipLastLocation[]
): ShipLastLocation[] {
  const lastLocation = lastLocations?.[lastLocations.length - 1];
  const isEventEnded = Boolean(event.timestampEnd);

  const [eventLatitude, eventLongitude] = isEventEnded
    ? [
        lastLocation?.latitude ?? ship?.latitude,
        lastLocation?.longitude ?? ship?.longitude,
      ]
    : [
        ship?.latitude ?? lastLocation?.latitude,
        ship?.longitude ?? lastLocation?.longitude,
      ];

  const updatedLocations = [
    ...lastLocations,
    {
      latitude: eventLatitude,
      longitude: eventLongitude,
      sog: ship?.sog ?? 0,
      time: new Date().toISOString(),
    },
  ];

  return updatedLocations;
}
