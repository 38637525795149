import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { catchError, firstValueFrom, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CreateSubFleetDto,
  SubFleet,
  SubFleetWithUsers,
  UpdateSubFleetDto,
} from '../models/subfleet.models';
import { IsBusyService } from './is-busy.service';

@Injectable({
  providedIn: 'root',
})
export class SubFleetService {
  private readonly apiUrl = `${environment.api.subfleets}`;

  constructor(
    private readonly http: HttpClient,
    private readonly busyService: IsBusyService
  ) {}

  create(createSubFleet: CreateSubFleetDto): Promise<SubFleet> {
    const createSubFleetDto = instanceToPlain(
      new CreateSubFleetDto(
        createSubFleet.subFleetName,
        createSubFleet.shipsList,
        createSubFleet.fleetId
      )
    );
    return this.busyService.add(() =>
      firstValueFrom(
        this.http.post<SubFleet>(`${this.apiUrl}`, createSubFleetDto).pipe(
          map(response =>
            plainToInstance(SubFleet, response, {
              excludeExtraneousValues: true,
            })
          ),
          catchError(this.handleSubFleetError)
        )
      )
    );
  }

  private getSubFleets<T extends SubFleet | SubFleetWithUsers>(
    fleetId: number | undefined,
    searchTerm: string | undefined,
    includeUsers: boolean | undefined,
    classType: new (...args: any[]) => T
  ): Promise<T[]> {
    const params = Object.entries({
      fleet_id: fleetId,
      search: searchTerm,
      include_users: includeUsers,
    })
      .filter(([_, value]) => !!value)
      .reduce(
        (params, [key, value]) => params.set(key, value as string),
        new HttpParams()
      );
    return this.busyService.add(() =>
      firstValueFrom(this.http.get<T[]>(this.apiUrl, { params })).then(
        subFleets =>
          plainToInstance(classType, subFleets, {
            excludeExtraneousValues: true,
          })
      )
    );
  }

  getAll(fleetId?: number, searchTerm?: string): Promise<SubFleet[]> {
    return this.getSubFleets<SubFleet>(fleetId, searchTerm, false, SubFleet);
  }

  getAllWithUsers(
    fleetId?: number,
    searchTerm?: string
  ): Promise<SubFleetWithUsers[]> {
    return this.getSubFleets<SubFleetWithUsers>(
      fleetId,
      searchTerm,
      true,
      SubFleetWithUsers
    );
  }

  getById(id: number): Promise<SubFleet> {
    return this.busyService.add(() =>
      firstValueFrom(
        this.http
          .get<SubFleet>(`${this.apiUrl}/${id}`)
          .pipe(map(response => plainToInstance(SubFleet, response)))
      )
    );
  }

  update(id: number, updateSubFleet: UpdateSubFleetDto): Promise<SubFleet> {
    const updateSubFleetDto = instanceToPlain(
      new UpdateSubFleetDto(
        updateSubFleet.subFleetName,
        updateSubFleet.shipsList
      )
    );
    return this.busyService.add(() =>
      firstValueFrom(
        this.http.put<SubFleet>(`${this.apiUrl}/${id}`, updateSubFleetDto).pipe(
          map(response => plainToInstance(SubFleet, response)),
          catchError(this.handleSubFleetError)
        )
      )
    );
  }

  delete(id: number): Promise<{ message: string }> {
    return this.busyService.add(() =>
      firstValueFrom(
        this.http
          .delete<{ message: string }>(`${this.apiUrl}/${id}`)
          .pipe(catchError(this.handleSubFleetError))
      )
    );
  }

  private handleSubFleetError(error: HttpErrorResponse) {
    if (error.status === HttpStatusCode.Conflict) {
      return throwError(
        () => new Error('This sub-fleet name already exists for this fleet.')
      );
    }
    if (error.status === HttpStatusCode.BadRequest && error.error?.errors) {
      const validationErrors = Object.entries(error.error.errors)
        .map(([field, messages]) => {
          if (Array.isArray(messages)) {
            return `${field}: ${messages.join(', ')}`;
          }
          return `${field}: Unknown error`;
        })
        .join('; ');
      return throwError(() => new Error(validationErrors));
    }
    if (error.status === HttpStatusCode.NotFound) {
      return throwError(() => new Error('Sub-fleet not found.'));
    }
    return throwError(
      () =>
        new Error(
          'An error occurred while processing the request. Please try again.'
        )
    );
  }
}
