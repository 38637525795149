import { Model } from 'common';
import { environment } from '../environment';

declare var pendo: any;

export function initializePendo(user: Model.User): void {
  pendo.initialize({
    apiKey: environment.pendoApiKey,
    visitor: {
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    },
    account: {
      id: user.companyName,
    },
  });
  console.log('Pendo initialized');
}

export {};
