import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { filter, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[appSelectSearch]',
  standalone: true,
})
export class SelectSearchDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() filterFn = (option: MatOption, inputValue: string) =>
    !option.viewValue.toLowerCase().includes(inputValue.toLowerCase());

  get inputElement(): HTMLInputElement {
    return this.hostElRef.nativeElement as HTMLInputElement;
  }

  constructor(private hostElRef: ElementRef, private select: MatSelect) {}

  @HostListener('input')
  onInput() {
    this.select.options.forEach(option => {
      const isFiltered = this.filterFn(option, this.inputElement.value);
      option._getHostElement().toggleAttribute('hidden', isFiltered);
    });
  }

  @HostListener('keydown.space', ['$event'])
  stopSpaceKeyDownPropagation(event: KeyboardEvent) {
    event.stopPropagation();
  }

  ngOnInit(): void {
    this.select.openedChange
      .pipe(
        takeUntil(this.destroy$),
        filter(isOpened => !isOpened)
      )
      .subscribe(() => {
        this.inputElement.value = '';
        this.inputElement.dispatchEvent(new Event('input'));
      });

    this.select.optionSelectionChanges
      .pipe(
        filter(e => e.isUserInput),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.inputElement.select();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
