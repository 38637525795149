import { Model } from 'common';

export type UsersGroupedByCompany = {
  [companyName: string]: Model.PreliminaryUser[];
};

export function groupBy(array: Model.PreliminaryUser[]): UsersGroupedByCompany {
  return array.reduce(
    (grouped, element) => ({
      ...grouped,
      [element.companyName]: [
        ...((grouped as UsersGroupedByCompany)[element.companyName] || []),
        element,
      ],
    }),
    {}
  );
}

export function canAccessShip(
  userRole: Model.Role | null,
  accessibleShips: number | number[] | null,
  shipId: number
): boolean {
  if (userRole === 'orca-admin' || userRole === 'fleet-manager') {
    return true;
  }
  if (
    userRole === 'sub-fleet-manager' &&
    accessibleShips != null &&
    Array.isArray(accessibleShips)
  ) {
    return accessibleShips.includes(shipId);
  }

  if (userRole === 'ship-captain' && accessibleShips != null) {
    return accessibleShips == shipId;
  }

  return false;
}
