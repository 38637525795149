import { computed, signal, WritableSignal } from '@angular/core';
import {
  OriginsAndDestinationsResponse,
  VoyageDataResponse,
  VoyageFilter,
  VoyageFilterDropdownOption,
  VoyageModel,
  VoyagesResponse,
  VoyageType,
  VoyageTypeTitle,
} from './models/voyages.types';

const initialFilters: VoyageFilter[] = [
  {
    label: 'Origin',
    value: '',
    description: '',
    requestValue: '',
    isFocused: false,
    placeholder: 'Search port of origin',
    defaultPlaceholder: 'Search port of origin',
    dropdown: {
      type: 'Port',
      options: [],
      filteredOptions: [],
    },
  },
  {
    label: 'Destination',
    value: '',
    description: '',
    requestValue: '',
    isFocused: false,
    placeholder: 'Search port of destination',
    defaultPlaceholder: 'Search port of destination',
    dropdown: {
      type: 'Port',
      options: [],
      filteredOptions: [],
    },
  },
  {
    label: 'Time',
    value: 'All',
    description: '01.01-31.12',
    requestValue: 'All',
    isFocused: false,
    placeholder: 'Select time',
    defaultPlaceholder: 'Select time',
    dropdown: {
      type: 'Time',
      options: [
        {
          value: 'All',
          description: '01.01-31.12',
          img: '',
        },
        {
          value: 'Jan-Mar',
          description: '01.01-31.03',
          img: '',
        },
        {
          value: 'Apr-Jun',
          description: '01.04-30.06',
          img: '',
        },
        {
          value: 'Jul-Sep',
          description: '01.07-30.09',
          img: '',
        },
        {
          value: 'Oct-Dec',
          description: '01.10-31.12',
          img: '',
        },
      ],
      filteredOptions: [],
    },
  },
];

export class VoyagesStore {
  // Origins and Destinations
  private _originsAndDestinations: WritableSignal<OriginsAndDestinationsResponse | null> =
    signal(null);

  // Voyages
  private _alternativeVoyages: WritableSignal<VoyageModel[] | null> =
    signal(null);
  alternativeVoyages = computed(() => this._alternativeVoyages());
  private _selectedVoyage: WritableSignal<VoyageModel | null> = signal(null);
  selectedVoyage = computed(() => this._selectedVoyage());
  hasResults = computed(() => {
    const data = this.alternativeVoyages();
    if (!data) {
      return null;
    }

    return data.length > 0;
  });

  // Filters
  filters: WritableSignal<VoyageFilter[]> = signal(initialFilters);
  private _showFiltersPopup: WritableSignal<boolean> = signal(false);
  showFiltersPopup = computed(() => this._showFiltersPopup());

  // Setters
  setOriginsAndDestinations(data: OriginsAndDestinationsResponse) {
    this._originsAndDestinations.set(data);

    const ports = this.transformPortResponse(data.ports);

    if (this.filters().length < 2) {
      return;
    }

    this.filters.update(existingFilters => {
      existingFilters[0].dropdown.options = ports;
      existingFilters[0].dropdown.filteredOptions = ports;
      existingFilters[1].dropdown.options = ports;
      existingFilters[1].dropdown.filteredOptions = ports;
      return existingFilters;
    });
  }

  setAlternativeVoyages(data: VoyagesResponse | null) {
    if (!data) {
      this._alternativeVoyages.set([]);
      return;
    }

    const transformedData = Object.keys(data).map(key => {
      const type = key as VoyageType;
      const voyage = data[type];
      return this.transformVoyageDataResponseToVoyageModel(voyage, type);
    });

    this._alternativeVoyages.set(transformedData);
  }

  setSelectedVoyage(voyage: VoyageModel | null) {
    this._selectedVoyage.set(voyage);
  }

  setFiltersPopup(value: boolean) {
    if (value && !this.hasResults()) {
      return;
    }

    this._showFiltersPopup.set(value);
  }

  // Helper functions
  transformPortResponse(data: string[]) {
    return (
      data.map(element => {
        const [port, country, code] = element.split(';');
        return {
          value: port,
          description: `${country} (${code})`,
          img: code.toLowerCase(),
        } as VoyageFilterDropdownOption;
      }) ?? []
    ).sort((a, b) => {
      const countryCompare = a.description.localeCompare(b.description);
      if (countryCompare !== 0) {
        return countryCompare;
      }
      return a.value.localeCompare(b.value);
    });
  }

  transformVoyageDataResponseToVoyageModel(
    voyage: VoyageDataResponse,
    key: VoyageType
  ): VoyageModel {
    return {
      key,
      title: VoyageTypeTitle[key.toUpperCase() as keyof typeof VoyageTypeTitle],
      className: key,
      isAccTimeImportant: key === VoyageType.MOST_EFFICIENT,
      isDistanceImportant: key === VoyageType.SHORTEST,
      isCloseEncountersImportant: key === VoyageType.SAFEST,
      isDurationImportant: key === VoyageType.FASTEST,
      // Properties with different name in the response
      startTime: voyage.start_time,
      endTime: voyage.end_time,
      shipType: voyage.ship_type,
      startPort: voyage.start_port,
      endPort: voyage.end_port,
      lengthNm: voyage.length_nm,
      sog: voyage.sog,
      durationMin: voyage.duration_m,
      totalAccTimeMin: voyage.total_acc_time_m,
      totalEncounterEvents: voyage.total_encounter_events,
      // Properties with the same name in the response
      ...voyage,
    };
  }
}
