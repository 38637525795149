import { createAction, props } from '@ngrx/store';
import { DateFilter, Dates } from 'src/app/shared/view-models/dates.view.model';
import {
  EventDayFilter,
  EventFilters,
  EventsSortBy,
} from '../../models/events-filters.model';
import { Event, EventLocation, EventsData } from '../../models/events.model';
import { ActiveRTEvent, Events } from '../../view-models/event.view.model';
import {
  AppliedEventFilters,
  PresetFilter,
} from '../../view-models/filter.view.model';

export const saveEventFiltersOnAuth = createAction(
  '[Events Effects] Save events filters values on user authenticated',
  props<{ eventFilters: EventFilters }>()
);

export const saveEventFiltersOnDateFilterChange = createAction(
  '[Events Effects] Save events filters values on date filter changed',
  props<{ eventFilters: EventFilters }>()
);

export const saveEventsFilterOnFleetChange = createAction(
  '[Events Effects] Save events filters values on fleet change',
  props<{ eventFilters: EventFilters }>()
);

export const initAllFiltersOnAuth = createAction(
  '[Events Effects] Init All Filters on auth user',
  props<{ appliedFilters: AppliedEventFilters }>()
);

export const resetAllFiltersOnUserPress = createAction(
  '[Events Effects] Reset all filters on user press',
  props<{ appliedFilters: AppliedEventFilters }>()
);

export const resetAllFiltersOnFleetChange = createAction(
  '[Events Effects] Reset All Filters on updated token',
  props<{ appliedFilters: AppliedEventFilters }>()
);

export const resetAllFiltersOnDateFilterChange = createAction(
  '[Events Effects] Reset All Filters on date change',
  props<{ appliedFilters: AppliedEventFilters }>()
);

export const saveEventsDataOnFleetIdChange = createAction(
  '[Events Effects] Save events data on fleed id changed',
  props<{ eventsData: Partial<EventsData> }>()
);

export const saveEventsDataOnDateFilterChange = createAction(
  '[Events Effects] Save events data on date filter change',
  props<{ eventsData: Partial<EventsData> }>()
);

export const saveEventsDataOnNavigateFromInsight = createAction(
  '[Events Effects] Save events data on navigation from insight',
  props<{ eventsData: Partial<EventsData>; selectedEvent: Events | null }>()
);

export const saveEventsDataOnAuth = createAction(
  '[Events Effects] Save events data on auth',
  props<{ eventsData: Partial<EventsData> }>()
);

export const saveEventsDataResetToDefaultAppliedFilters = createAction(
  '[Events Effects] Save events data on reset to default applied filters',
  props<{ eventsData: Partial<EventsData> }>()
);

export const saveOverviewEventsOnFleetIdChange = createAction(
  '[Events Effects] Save overview events on fleet id change',
  props<{ events: Event[] }>()
);

export const saveOverviewEventsOnDateFilterChange = createAction(
  '[Events Effects] Save overview events on date filter change',
  props<{ events: Event[] }>()
);

export const saveSortByOptionOnUserChange = createAction(
  '[Events Page] Save sort by option on user change',
  props<{ sortBy: EventsSortBy }>()
);

export const saveSortedEventsOnUserChange = createAction(
  '[Events Effects] Save sorted events',
  props<{ events: Event[] }>()
);

export const getMoreEventsOnUserScroll = createAction(
  '[Events Page] User scrolled events list'
);

export const addMoreEventsAfterScroll = createAction(
  '[Events Effects] Fetched more events after user scrolled',
  props<{ events: Event[] }>()
);

export const reachedEndOfEvents = createAction(
  '[Events Effects] Reached end of events'
);

export const resetPagination = createAction(
  '[Events Effects] Reset pagination on filters change'
);

export const updateScrollToTopByUser = createAction(
  '[Events Page] Update scroll to top after user scroll'
);

export const resetScroll = createAction(
  '[Events Effects] reset scroll to top on filters change'
);

export const updateEventsDateFilterOnGeneralDateFilterChange = createAction(
  '[Events Effects] Update events date filter on general date filter change',
  props<{ dates: Dates }>()
);

export const updateEventsDateFilterOnUserClick = createAction(
  '[Events Graph] Update events date filter on user click',
  props<{ eventDayFilter: EventDayFilter }>()
);

export const resetEventsDateFilterOnClear = createAction(
  '[Events Graph] Reset events date filter on clear'
);

export const updateToGeneralDateFilterOnClear = createAction(
  '[Events Effects] Update event date filter to general dates filter on clear',
  props<{ dates: Dates }>()
);

export const getEventsOnEventsDateFilter = createAction(
  '[Events Effects] Get events on events date filter change',
  props<{ eventsData: Partial<EventsData> }>()
);

export const getEventsOnResetEventsDateFilter = createAction(
  '[Events Effects] Get events on reset events date filter',
  props<{ eventsData: Partial<EventsData> }>()
);

export const getEventsOnClearMapAndDayFilters = createAction(
  '[Events Effects] Get events on clear map and day filters',
  props<{ eventsData: Partial<EventsData> }>()
);

export const setSelectedOverviewMapEvent = createAction(
  '[Overview Map] Set selected event on user click on overview map event',
  props<{ event: Events }>()
);

export const setSelectedEventsMapEvent = createAction(
  '[Events Map] Set selected event on user click on events map event',
  props<{ eventId: string }>()
);

export const gotSelectedMapEvent = createAction(
  '[Events Effects] Got selected map event',
  props<{ event: Event }>()
);

export const addSelectedEventToList = createAction(
  '[Events Effects] Add selected event to list',
  props<{ event: Event }>()
);

export const updateEventsOnSelectedMapEvent = createAction(
  '[Events Effects] Update events on selected map event',
  props<{ events: Event[] }>()
);

export const updateSelectedEventOnMapSelectedEvent = createAction(
  '[Events Effects] Update selected event on map selected event',
  props<{ event: Events }>()
);

export const getEventAndAddToList = createAction(
  '[Events Effects] Get event and add to list',
  props<{ eventId: string }>()
);

export const onExploreEventResolved = createAction(
  '[Events Resolver] on explore event resolved',
  props<{ event: Events }>()
);

export const setDefaultSelectedEventOnEventsListChange = createAction(
  '[Events Effects] Set default selected event on events list change',
  props<{ selectedEvent: Events | null }>()
);

export const setDefaultSelectedEventOnGlobalFiltersChange = createAction(
  '[Events Effects] Set default selected event on global filters change',
  props<{ selectedEvent: Events | null }>()
);

export const clearEventsListSubFilters = createAction(
  '[Events Page] Clear events list from day or map filters'
);

export const setSelectedEventOnOverviewResolver = createAction(
  '[Overview Resolver] set selected event for overview page',
  props<{ selectedEvent: Events | null }>()
);

export const setSelectedOverviewEvent = createAction(
  '[Overview Page] Set selected event on user click on overview event list',
  props<{ event: Events }>()
);

export const setSelectedFilteredEvent = createAction(
  '[Events Page] Set selected event on user click on filtered events list',
  props<{ event: Events }>()
);

export const downloadKeplerFile = createAction(
  '[Event Exploration Page] Download kepler file',
  props<{ url: string; event: Events }>()
);

export const onExportFile = createAction('[Event Page] Export Events list');

export const saveEventsOnFilterChange = createAction(
  '[Events Effects] Save events on events filters change',
  props<{ eventsData: Partial<EventsData> }>()
);

export const saveEventsLocation = createAction(
  '[Events Effects] Save events location',
  props<{ eventsLocation: EventLocation[] }>()
);

export const onEventFilterChange = createAction(
  '[Events Page] Events filter changed',
  props<{ updatedAppliedFilters: AppliedEventFilters }>()
);

export const navigateToEventAfterClickInsightsLink = createAction(
  '[Events Page] Navigation to events page after click insight link',
  props<{ updatedAppliedFilters: AppliedEventFilters; dates: Dates }>()
);

export const setEventAndDateFilterByUrlParams = createAction(
  '[Events Effects] Set events filter and date filter by url params',
  props<{
    appliedFiltersToUpdate: Partial<AppliedEventFilters>;
    dateFilter: Partial<DateFilter>;
  }>()
);

export const setEventFilterByUrlParams = createAction(
  '[Events Effects] Set events filter by url params',
  props<{
    eventFilters: EventFilters;
    appliedEventFilters: AppliedEventFilters;
    dates: Dates;
  }>()
);

export const getEventFilterByUrlParams = createAction(
  '[Events Effects] Get events filter by url params',
  props<{
    appliedFiltersToUpdate: Partial<AppliedEventFilters>;
    dateFilter: Partial<DateFilter>;
  }>()
);

export const getEventsDataAfterFilterByInsights = createAction(
  '[Events Effects] Get events after filter by insights'
);

export const savePresetFilters = createAction(
  '[Events Page] Save preset filters',
  props<{ presetName: string }>()
);

export const loadPresetsFromDb = createAction(
  '[Events Effects] Load presets from db',
  props<{ presets: PresetFilter[] }>()
);

export const loadSelectedPresetFromDb = createAction(
  '[Events Effects] Load selected preset from db',
  props<{ selectedPresetFilter: PresetFilter | undefined }>()
);

export const setPresetFilterInEvents = createAction(
  '[Events Page] Set preset filter',
  props<{ selectedPresetFilter: PresetFilter | undefined }>()
);

export const setPresetFilterInOverview = createAction(
  '[Overview Page] Set preset filter',
  props<{ selectedPresetFilter: PresetFilter | undefined }>()
);
export const setPresetFilterOnCreation = createAction(
  '[Events Page] Set preset filter on creation',
  props<{ selectedPresetFilter: PresetFilter | undefined }>()
);

export const applyFiltersOnPresetFilterChange = createAction(
  '[Events Effects] Set applied filters on perset filter change',
  props<{ updatedAppliedFilters: AppliedEventFilters }>()
);

export const setPresetFilterToDefault = createAction(
  '[Events Effects] Set preset filters to default'
);

export const setShipConnectionStatusOnCreation = createAction(
  '[Events Effects] Set ship connection status on creation',
  props<{ shipId: number; fleetId: number }>()
);

export const changeEventsOnOverviewPresetChange = createAction(
  '[Events Effects] Set events on overview perset filter change',
  props<{ updatedAppliedFilters: AppliedEventFilters }>()
);

export const saveOverviewEventsOnPresetChange = createAction(
  '[Events Effects] Save events on overview events presets change',
  props<{ events: Event[] }>()
);

export const getMoreShipEventsOnUserScroll = createAction(
  '[Fleet Page] User scrolled events list',
  props<{ shipName: string }>()
);

export const addMoreShipEventsAfterScroll = createAction(
  '[Fleet Effects] Fetched more events after user scrolled',
  props<{ events: Event[] }>()
);

export const reachedEndOfShipEvents = createAction(
  '[Fleet Effects] Reached end of events'
);

export const setPresetFilterInShip = createAction(
  '[Fleet Page] Set preset filter',
  props<{ selectedPresetFilter: PresetFilter | undefined; shipName: string }>()
);

export const saveShipEventsOnPresetChange = createAction(
  '[Fleet Effects] Save events on ship events presets change',
  props<{ eventsData: Partial<EventsData> }>()
);

export const saveShipEventsDataOnDateFilterChange = createAction(
  '[Fleet Effects] Save events on date filter change',
  props<{ eventsData: Partial<EventsData> }>()
);

export const resetShipSettings = createAction(
  '[Fleet Page] reset ship settings'
);

export const clearSelectedEvent = createAction(
  '[Fleet Page] reset selected event'
);

export const updateRTEvents = createAction(
  '[RT Events] Update RT Events',
  props<{ rtEvents: { [shipId: number]: ActiveRTEvent } }>()
);

export const filterChanged = createAction(
  '[Events Page] Selected filter value',
  props<{ filterChanges: { delta: any } }>()
);
