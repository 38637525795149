import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DestroyRef } from 'projects/orca-lib-main/projects/orca-lib/src/lib/services/destroy-ref.service';
import {
  BehaviorSubject,
  fromEvent,
  Observable,
  of,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import {
  MapSettingsOption,
  ShipPosition,
} from 'src/app/core/models/map.models';
import { Ship, ShipSafteyScore } from 'src/app/core/models/ship.model';
import { FeatureFlagService } from 'src/app/core/services/feature-toggle.service';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  AuthenticationSelectors,
  OverviewActions,
  OverviewSelector,
  ShipsSelectors,
} from 'src/app/core/store/types';
import { Events } from 'src/app/core/view-models/event.view.model';
import {
  EventMapEntity,
  MapLabel,
  PolygonEntity,
  ShipMapEntity,
} from 'src/app/mapbox/models/mapbox.models';
import { ShipScreenshot } from 'src/app/maritime-map/models/ship.models';

@Component({
  selector: 'app-overview-map',
  templateUrl: './overview-map.component.html',
  styleUrls: ['./overview-map.component.scss'],
  providers: [DestroyRef],
})
export class OverviewMapComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  ships$ = new BehaviorSubject<ShipMapEntity[]>([]);
  events$ = new BehaviorSubject<EventMapEntity[]>([]);
  selectedEventMapEntity$ = new BehaviorSubject<EventMapEntity | undefined>(
    undefined
  );
  selectedEventPosition$: BehaviorSubject<ShipPosition | undefined> =
    new BehaviorSubject<ShipPosition | undefined>(undefined);

  rtEvents$ = new BehaviorSubject<MapLabel[]>([]);
  noGoZones$ = new BehaviorSubject<PolygonEntity[]>([]);
  complianceZones$ = new BehaviorSubject<PolygonEntity[]>([]);
  screenshots$ = new BehaviorSubject<ShipScreenshot[]>([]);
  shipsSafetyScores$ = new BehaviorSubject<ShipSafteyScore[]>([]);
  token$: Observable<string | undefined> = of(undefined);

  eventOfSelectedShip$!: Observable<Events[]>;
  selectedEvent$!: Observable<Events | null>;
  startDateAvgShipSafetyScore$!: Observable<number>;
  endDateAvgShipSafetyScore$!: Observable<number>;
  showFlag$!: Observable<boolean>;
  selectedShip$!: Observable<Ship | null>;
  isShipCaptain$!: Observable<boolean>;
  offsetLongPositionDestination: number = 2.5;
  initialEnabledLayers = [
    MapSettingsOption.Events,
    MapSettingsOption.LiveEvents,
    MapSettingsOption.NoGoAreas,
  ];
  @ViewChild('eventsContainer') eventsContainer!: ElementRef;

  constructor(
    private store: Store<CoreState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private featureFlag: FeatureFlagService,
    private dr: DestroyRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.token$ = this.store.select(AuthenticationSelectors.selectToken);

    this.store
      .select(OverviewSelector.selectOverviewMapShips)
      .pipe(takeUntil(this.dr.destroy$))
      .subscribe(ships => this.ships$.next(ships));
    this.store
      .select(OverviewSelector.selectMapEvents)
      .pipe(takeUntil(this.dr.destroy$))
      .subscribe(events => this.events$.next(events));
    this.store
      .select(OverviewSelector.selectMapRtEventLabels)
      .pipe(takeUntil(this.dr.destroy$))
      .subscribe(rtEvents => this.rtEvents$.next(rtEvents));
    this.store
      .select(OverviewSelector.selectMapPolygons)
      .pipe(takeUntil(this.dr.destroy$))
      .subscribe(polygons => {
        const noGoPolygons: PolygonEntity[] = [];
        const compliancePolygons: PolygonEntity[] = [];
        polygons.forEach(polygon =>
          polygon.labelSource === 'compliance'
            ? compliancePolygons.push(polygon)
            : noGoPolygons.push(polygon)
        );
        this.complianceZones$.next(compliancePolygons);
        this.noGoZones$.next(noGoPolygons);
      });
    this.store
      .select(ShipsSelectors.selectScreenshots)
      .pipe(takeUntil(this.dr.destroy$))
      .subscribe(screenshots => this.screenshots$.next(screenshots));

    this.store
      .select(ShipsSelectors.selectSafteyScoreShips)
      .pipe(takeUntil(this.dr.destroy$))
      .subscribe(scores => this.shipsSafetyScores$.next(scores));

    this.store
      .select(OverviewSelector.selectSelectedEventMapEntity)
      .pipe(takeUntil(this.dr.destroy$))
      .subscribe(event => {
        this.selectedEventMapEntity$.next(event);
        if (event) {
          this.selectedEventPosition$.next({
            lat: event.lat,
            long: event.long,
          });
        }
      });

    this.selectedShip$ = this.store.select(OverviewSelector.selectSelectedShip);

    this.selectedEvent$ = this.store.select(
      OverviewSelector.selectSelectedEvent
    );

    this.endDateAvgShipSafetyScore$ = this.store.select(
      OverviewSelector.selectEndDateAvgShipSafetyScore
    );

    this.startDateAvgShipSafetyScore$ = this.store.select(
      OverviewSelector.selectStartDateAvgShipSafetyScore
    );

    this.showFlag$ = this.featureFlag.getFeatureFlag$('LiveView');

    this.eventOfSelectedShip$ = this.store.select(
      OverviewSelector.selectEventsOfSelectedShip
    );

    this.subscribeToEventChanges();

    this.showFlag$ = this.featureFlag.getFeatureFlag$('LiveView');

    this.isShipCaptain$ = this.store.select(
      AuthenticationSelectors.selectIsUserShipCaptain
    );
  }

  ngAfterViewInit(): void {
    this.checkAndSubscribeToScroll();
  }

  ngAfterViewChecked(): void {
    this.checkAndSubscribeToScroll();
  }

  checkAndSubscribeToScroll(): void {
    if (
      this.eventsContainer &&
      !this.eventsContainer.nativeElement.hasAttribute('data-subscribed')
    ) {
      this.updateScrollToTopOnUserScroll();
      this.eventsContainer.nativeElement.setAttribute(
        'data-subscribed',
        'true'
      );
    }
  }

  updateScrollToTopOnUserScroll(): void {
    fromEvent(this.eventsContainer.nativeElement, 'scroll')
      .pipe(takeUntil(this.dr.destroy$))
      .subscribe(() => {
        this.store.dispatch(OverviewActions.updateScrollToTopByUser());
      });
  }

  subscribeToEventChanges(): void {
    this.eventOfSelectedShip$
      .pipe(
        takeUntil(this.dr.destroy$),
        withLatestFrom(
          this.store.select(
            OverviewSelector.selectScrollTopOfSelectedShipEventData
          )
        )
      )
      .subscribe(([_, scrollToTop]) => {
        if (scrollToTop) this.resetScroll();
      });
  }

  async onSelectedMapEntity(entityParams: string[]): Promise<void> {
    const entityId = entityParams[0];
    const entityCategory = entityParams[1];
    if (entityCategory == 'ship')
      this.store.dispatch(
        OverviewActions.selectShipOnShipMapClick({ shipId: Number(entityId) })
      );
    else
      this.store.dispatch(
        OverviewActions.selectEventOnEventMapClick({ eventId: entityId })
      );
  }

  onSelectedEvent(selectedEvent: Events): void {
    this.store.dispatch(
      OverviewActions.setSelectedOverviewEventList({ selectedEvent })
    );
  }

  onCloseTooltip(): void {
    this.store.dispatch(OverviewActions.resetSelectedShipIdOnCloseTooltip());
  }

  onEventListScrollToEnd(): void {
    this.store.dispatch(OverviewActions.getMoreEventsOnUserScroll());
  }

  onShipTooltipButtonClicked(shipId: number): void {
    this.store.dispatch(
      OverviewActions.setSelectedShipIdOnShipProfileClickOnMap({
        shipId,
      })
    );
  }

  resetScroll(): void {
    if (this.eventsContainer && this.eventsContainer.nativeElement) {
      this.eventsContainer.nativeElement.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  }

  onEventExploration(event: Events): void {
    if (event.isRtEvent) {
      this.router.navigate([`rt-event-exploration`, event.eventId], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this.router.navigate([`event-exploration`, event.eventId], {
        relativeTo: this.activatedRoute,
      });
    }
  }
}
