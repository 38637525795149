import {
  InsightType,
  ShipWithMostEventsInsight,
} from 'src/app/core/models/insights.models';
import { AppliedEventFilters } from 'src/app/core/view-models/filter.view.model';
import { DateFilterKeys, dateFilters } from '../view-models/dates.view.model';
import {
  InsightDataType,
  InsightTypeFromat,
} from '../view-models/insight.view.model';

export const insightTitleByType: Record<InsightType, string> = {
  ShipWithMostEventsInsight: 'Ship with most events per 1000NM',
  HighDuringLastWeekInsight: 'High Severity Events During Last Week',
};
export interface InsightLink {
  text: string;
  linkTo: string;
  filterEventsQueryParams: AppliedEventFilters;
}

export function insightLinkMap(insight: InsightDataType): {
  [key in InsightType]: InsightLink;
} {
  return {
    [InsightTypeFromat.ShipWithMostEventsInsight]: {
      text: 'Explore Ship Events',
      linkTo: '/private/events',
      filterEventsQueryParams: getEventFiltersValue(insight),
    },
    [InsightTypeFromat.HighDuringLastWeekInsight]: {
      text: 'Explore in the Events page',
      linkTo: '/private/events',
      filterEventsQueryParams: getEventFiltersValue(insight),
    },
  };
}

export function getTitleAndLinkDetails(
  insightType: InsightType,
  insight: InsightDataType
): InsightLink {
  const map = insightLinkMap(insight);
  return map[insightType] || { text: '', linkTo: '' };
}

function getEventFiltersValue(insight: InsightDataType): any {
  if (insight instanceof ShipWithMostEventsInsight) {
    const currentDateFilter: DateFilterKeys = 'Last 30 days' as DateFilterKeys;

    return {
      shipName: { value: [insight.outsandShipName] },
      dateFilter: { [currentDateFilter]: dateFilters[currentDateFilter] },
    };
  } else {
    const currentDateFilter: DateFilterKeys = 'Last 7 days' as DateFilterKeys;

    return {
      severityLevels: ['high'],
      dateFilter: { [currentDateFilter]: dateFilters[currentDateFilter] },
    };
  }
}
