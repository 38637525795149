import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MapLabel } from 'src/app/mapbox/models/mapbox.models';

@Component({
  selector: 'app-map-label',
  standalone: true,
  templateUrl: './map-label.component.html',
  styleUrls: ['./map-label.component.scss'],
  imports: [CommonModule],
})
export class MapLabelComponent implements OnInit {
  @Input() label!: MapLabel;
  labelText: string[] = [];

  ngOnInit(): void {
    this.labelText = this.label.textLabel.split('\n');
  }
}
