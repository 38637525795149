import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, filter, take, timer } from 'rxjs';
import {
  DayNamesDto,
  ThermalNamesDto,
  ViewStreamNamesDto,
} from 'src/app/core/view-models/event.view.model';
import videojs, { VideoJsPlayer } from 'video.js';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('videoPlayer', { read: ElementRef, static: false })
  private videoPlayerElementRef!: ElementRef;
  private interval!: NodeJS.Timeout;
  private previousTotalFrames = 0;

  @Input()
  streamUrl!: string;

  @Input()
  cameraControl!: FormControl<
    DayNamesDto | ThermalNamesDto | ViewStreamNamesDto | null
  >;

  @Output()
  qualityStatus = new EventEmitter<string>();
  @Output()
  establishedYet = new EventEmitter<boolean>();

  private player!: VideoJsPlayer;
  constructor(videoPlayerElementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.initializePlayer();
    this.cameraControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter(cameraId => cameraId !== null)
      )
      .subscribe(_ => {
        this.visuallyReloadPlayer();
      });
  }

  ngOnDestroy() {
    this.disposePlayer();
    clearInterval(this.interval);
  }

  private initializePlayer(): void {
    this.player = videojs(this.videoPlayerElementRef.nativeElement, {
      autoplay: true,
      muted: true,
      controlBar: {
        fullscreenToggle: true,
      },
      sources: [
        {
          src: this.streamUrl,
          type: 'application/x-mpegURL',
        },
      ],
    });

    this.videoPlayerElementRef.nativeElement.setAttribute('controls', '');

    this.player.on('ended', () => {
      this.player.src({ src: this.streamUrl, type: 'application/x-mpegURL' });
      this.player.play();
    });

    this.player.on('error', () => {
      this.player.src(this.player.currentSrc());
    });

    const videoElement = this.player
      .el()
      .getElementsByTagName('video')[0] as HTMLVideoElement;

    this.waitForStream(videoElement)
      .then(() => {
        this.establishedYet.emit(true);
      })
      .catch(error => {
        console.error('Stream not established:', error);
        this.establishedYet.emit(false);
      });

    this.interval = setInterval(() => {
      const videoStats = videoElement.getVideoPlaybackQuality
        ? videoElement.getVideoPlaybackQuality()
        : null;

      if (videoStats) {
        const totalFrames = videoStats.totalVideoFrames;

        const framesInLast5Seconds =
          totalFrames - (this.previousTotalFrames || 0);
        this.previousTotalFrames = totalFrames;

        let quality;
        if (framesInLast5Seconds > 13) {
          quality = 'High';
        } else if (framesInLast5Seconds > 10) {
          quality = 'Medium';
        } else {
          quality = 'Low';
        }

        this.qualityStatus.emit(quality);
      }
    }, 5000);
  }

  private waitForStream(
    videoElement: HTMLVideoElement,
    timeout = 45000
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (
        !videoElement ||
        typeof videoElement.getVideoPlaybackQuality !== 'function'
      ) {
        return reject(new Error('Invalid video element.'));
      }

      const start = Date.now();

      const checkStream = () => {
        const stats = videoElement.getVideoPlaybackQuality();

        if (stats.totalVideoFrames > 0 && stats.droppedVideoFrames === 0) {
          return resolve();
        }

        if (Date.now() - start >= timeout) {
          return reject(
            new Error('Timeout reached before stream established.')
          );
        }

        requestAnimationFrame(checkStream);
      };

      checkStream();
    });
  }

  private disposePlayer(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  private visuallyReloadPlayer(): void {
    this.player.pause();
    this.player.src({ src: '', type: 'application/x-mpegURL' });
    timer(5000) // Wait 5 seconds
      .pipe(take(1))
      .subscribe(() => {
        this.player.src({
          src: this.streamUrl,
          type: 'application/x-mpegURL',
        });
        this.player.play();

        const videoElement = this.player
          .el()
          .getElementsByTagName('video')[0] as HTMLVideoElement;

        this.waitForStream(videoElement)
          .then(() => {
            this.establishedYet.emit(true);
          })
          .catch(error => {
            console.error('Stream not established after reload:', error);
            this.establishedYet.emit(false);
          });
      });
  }
}
