import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import _ from 'lodash';
import { EasingOptions, MapOptions } from 'mapbox-gl';
import { BehaviorSubject, firstValueFrom, Subject, Subscription } from 'rxjs';
import { ShipPosition } from 'src/app/core/models/map.models';
import { ShipSafteyScore } from 'src/app/core/models/ship.model';
import { FleetService } from 'src/app/core/services/fleet.service';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  AuthenticationSelectors,
  EventsSelectors,
  OverviewActions,
  ShipsSelectors,
} from 'src/app/core/store/types';
import { MapboxModule } from 'src/app/mapbox/mapbox.module';
import { MapboxLegendConfigurations } from 'src/app/mapbox/models/mapbox-legend.models';
import {
  MapboxMenuOption,
  MapboxMenuOptionGroup,
  MenuBotton,
} from 'src/app/mapbox/models/mapbox-menu.models';
import {
  MapboxEntityClickEvent,
  MapboxEntityConfiguration,
  MapboxSettingOption,
  MapEntity,
  MapLabel,
  PolygonEntity,
} from 'src/app/mapbox/models/mapbox.models';
import { OutsideClickDetectorComponent } from 'src/app/shared/components/outside-click-detector/outside-click-detector.component';
import { COMPLIANCE_SETTING_ID } from '../../map-settings/compliance.setting';
import { CONTOUR_SETTING_ID } from '../../map-settings/countries-contour.setting';
import { EVENT_FOCUS_SETTING_ID } from '../../map-settings/event-focus.setting';
import { EVENT_SETTING_ID } from '../../map-settings/event.setting';
import { HUMIDITY_SETTING_ID } from '../../map-settings/humidity.setting';
import { LAND_SETTING_ID } from '../../map-settings/land.setting';
import { NO_GO_SETTING_ID } from '../../map-settings/no-go.setting';
import { PERCIPITATION_SETTING_ID } from '../../map-settings/percipitation.setting';
import { RT_EVENT_SETTING_ID } from '../../map-settings/rt-event.setting';
import { SHIP_FOCUS_SETTING_ID } from '../../map-settings/ship-focus.setting';
import { SHIP_TRAIL_SETTING_ID } from '../../map-settings/ship-trail.setting';
import { SHIP_SETTING_ID } from '../../map-settings/ship.setting';
import { TEMPERATURE_SETTING_ID } from '../../map-settings/temperature.setting';
import { WIND_SETTING_ID } from '../../map-settings/wind.setting';
import {
  MAP_ENTITIES_CONFIGURATIONS,
  MARITIME_MAP_OPTIONS,
} from '../../models/maritime-configurations.models';
import {
  Ship,
  ShipCardOuputs,
  ShipLastLocation,
  ShipScreenshot,
} from '../../models/ship.models';
import { MaritimeSettingsService } from '../../services/maritime-settings.service';
import { LayersMenuFormComponent } from '../layers-menu-form/layers-menu-form.component';
import { LayersMenuTitleComponent } from '../layers-menu-title/layers-menu-title.component';
import { ShipProfileCardComponent } from '../ship-profile-card/ship-profile-card.component';
import { WeatherMenuFormComponent } from '../weather-menu-form/weather-menu-form.component';
import { WeatherMenuTitleComponent } from '../weather-menu-title/weather-menu-title.component';

const DEFAULT_LEGEND_CONFIG: MapboxLegendConfigurations = {
  id: 'default',
  unitsName: '',
  legendTicks: [],
};

const DEFAULT_FOCUS_HEIGHT = 25000000;
const CARD_LATITUDE_BUFFER = 0.5;

@Component({
  selector: 'app-maritime-map',
  standalone: true,
  templateUrl: './maritime-map.component.html',
  styleUrls: ['./maritime-map.component.scss'],
  imports: [
    CommonModule,
    MapboxModule,
    OutsideClickDetectorComponent,
    WeatherMenuFormComponent,
    WeatherMenuTitleComponent,
    LayersMenuFormComponent,
    LayersMenuTitleComponent,
    ShipProfileCardComponent,
  ],
})
export class MaritimeMapComponent implements OnInit, OnDestroy {
  @Input() ships$: BehaviorSubject<MapEntity[]> = new BehaviorSubject<
    MapEntity[]
  >([]);
  @Input() events$: BehaviorSubject<MapEntity[]> = new BehaviorSubject<
    MapEntity[]
  >([]);
  @Input() rtEvents$: BehaviorSubject<MapLabel[]> = new BehaviorSubject<
    MapLabel[]
  >([]);
  @Input() noGoZones$: BehaviorSubject<PolygonEntity[]> = new BehaviorSubject<
    PolygonEntity[]
  >([]);
  @Input() eventZones$: BehaviorSubject<PolygonEntity[]> = new BehaviorSubject<
    PolygonEntity[]
  >([]);
  @Input() complianceZones$: BehaviorSubject<PolygonEntity[]> =
    new BehaviorSubject<PolygonEntity[]>([]);
  @Input() showShipLocationsByShipId$: BehaviorSubject<number | null> =
    new BehaviorSubject<number | null>(null);
  @Input() shipTrail$: BehaviorSubject<ShipLastLocation[]> =
    new BehaviorSubject<ShipLastLocation[]>([]);
  @Input() selectedEventId$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  @Input() changePositon$: BehaviorSubject<ShipPosition | undefined> =
    new BehaviorSubject<ShipPosition | undefined>(undefined);
  @Input() minimalZoomLevel: number = MARITIME_MAP_OPTIONS.minZoom ?? 0;
  @Input() showLayersMenu: boolean = false;
  @Input() showWeatherMenu: boolean = true;
  @Input() settingToShow?: string[];
  @Input() isCaptain: boolean = false;
  @Output() selectedMapEvent = new EventEmitter<
    [entityId: string, entityType: string]
  >();
  @Output() diselectedMapEvent = new EventEmitter<void>();

  // map settings
  mapSettings: MapboxSettingOption[] = [];
  entitiesConfiguration: MapboxEntityConfiguration[] =
    MAP_ENTITIES_CONFIGURATIONS;
  mapOptions: MapOptions = { ...MARITIME_MAP_OPTIONS };
  weatherSamplingTimeString$ = new BehaviorSubject<string>('');
  shipLastLocations$ = new BehaviorSubject<ShipLastLocation[]>([]);
  focusedShipScreenshot$ = new BehaviorSubject<ShipScreenshot | null>(null);
  focusedShip$ = new BehaviorSubject<Ship | null>(null);
  focusedShipEvents$ = new BehaviorSubject<MapEntity[]>([]);
  focusedShipSafetyScores$ = new BehaviorSubject<ShipSafteyScore | null>(null);

  // map triggers
  flyTo$: BehaviorSubject<EasingOptions> = new BehaviorSubject<EasingOptions>({
    duration: 0,
    center: MARITIME_MAP_OPTIONS.center,
  });

  // legend settings
  legendConfig$: BehaviorSubject<MapboxLegendConfigurations> =
    new BehaviorSubject<MapboxLegendConfigurations>(DEFAULT_LEGEND_CONFIG);
  showLegend$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // menu settings
  menuToShow$: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);
  menuButtons: MenuBotton[] = [];
  lastUpdateTime$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    new Date(0)
  );
  weatherMenuToggle$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  layersFormOptionGroups$: BehaviorSubject<MapboxMenuOptionGroup[]> =
    new BehaviorSubject<MapboxMenuOptionGroup[]>([]);
  weatherMenuOptions: MapboxMenuOption[] = [];
  weatherSelection$: BehaviorSubject<MapboxMenuOption | undefined> =
    new BehaviorSubject<MapboxMenuOption | undefined>(undefined);

  // lifecycle resources
  private subscriptions: Subscription[] = [];
  private shipCardOuputs: ShipCardOuputs = {
    onButtonClicked$: new Subject<{ nagivationUrl: string; shipId: number }>(),
    onClose$: new Subject<void>(),
  };

  constructor(
    private settingService: MaritimeSettingsService,
    private store: Store<CoreState>,
    private router: Router,
    private fleetService: FleetService
  ) {
    this.weatherSamplingTimeString$.next(
      settingService.GetWeatherSamplingTimeString()
    );
    this.subscriptions.push(
      ...[
        this.legendConfig$.subscribe(config =>
          this.showLegend$.next(
            !_.isEmpty(config.legendTicks) && !_.isEmpty(config.unitsName)
          )
        ),
        this.shipCardOuputs.onButtonClicked$.subscribe(
          ({ nagivationUrl, shipId }) => {
            this.router.navigateByUrl(nagivationUrl);
            this.store.dispatch(
              OverviewActions.setSelectedShipIdOnShipProfileClickOnMap({
                shipId,
              })
            );
          }
        ),
        this.shipCardOuputs.onClose$.subscribe(() => {
          this.mapSettings
            .find(setting => setting.id === SHIP_FOCUS_SETTING_ID)
            ?.show$?.next(false);
          this.mapSettings
            .find(setting => setting.id === SHIP_TRAIL_SETTING_ID)
            ?.show$?.next(false);
          this.mapSettings
            .find(setting => setting.id === SHIP_SETTING_ID)
            ?.show$?.next(true);
          this.SelectGroupLayerSetting(
            this.layersFormOptionGroups$.getValue(),
            this.mapSettings
          );
          this.diselectedMapEvent.emit();
        }),
      ]
    );
    setInterval(() => {
      this.weatherSamplingTimeString$.next(
        settingService.GetWeatherSamplingTimeString()
      );
    }, dayjs.duration(3, 'hour').asMilliseconds());
  }

  async ngOnInit(): Promise<void> {
    const changePositionDebounced = _.debounce(
      (position: ShipPosition | undefined) => {
        if (position) {
          this.flyTo$.next(this.getFlightDestination(position));
          if (position.shipId) {
            this.FocusOnShip(position.shipId);
          }
        }
      },
      dayjs.duration(400, 'millisecond').asMilliseconds()
    );

    this.mapOptions.minZoom = this.minimalZoomLevel;
    const weatherSettingIds = this.settingService.GetWeatherSettingIds();
    const menusConfigurations = this.settingService.GetMenuSettings(
      this.showLayersMenu,
      this.showWeatherMenu
    );
    this.mapSettings = (
      await this.settingService.GetAllMapSettings(
        this.noGoZones$,
        this.complianceZones$,
        this.events$,
        this.rtEvents$,
        this.ships$,
        this.weatherSamplingTimeString$,
        this.selectedEventId$,
        this.shipLastLocations$,
        this.isCaptain,
        this.shipCardOuputs,
        this.focusedShipScreenshot$,
        this.focusedShip$,
        this.focusedShipEvents$,
        this.focusedShipSafetyScores$,
        this.eventZones$
      )
    ).filter(
      setting =>
        _.isEmpty(this.settingToShow) ||
        this.settingToShow?.includes(setting.id) ||
        weatherSettingIds.includes(setting.id)
    );
    if (this.settingToShow) {
      this.mapSettings.forEach(settingOption =>
        settingOption.show$.next(this.settingToShow!.includes(settingOption.id))
      );
    }
    this.lastUpdateTime$.next(await this.settingService.GetMapLastUpdateTime());
    this.menuButtons = menusConfigurations.menuButtons;
    this.layersFormOptionGroups$.next(
      menusConfigurations.layersFormOptionGroups
    );
    this.weatherMenuOptions = menusConfigurations.weatherMenuOptions;
    this.subscriptions.push(
      ...[
        this.weatherSelection$.subscribe(selection => {
          if (selection) {
            this.weatherMenuToggle$.next(true);
            this.SelectWeatherSetting(selection.id);
          } else {
            this.SelectWeatherSetting(undefined);
          }
        }),
        this.layersFormOptionGroups$.subscribe(optionGroups =>
          this.SelectGroupLayerSetting(optionGroups, this.mapSettings)
        ),
        this.weatherMenuToggle$.subscribe(showWeather => {
          if (!showWeather && this.weatherSelection$.getValue()) {
            this.weatherSelection$.next(undefined);
          }
        }),
        this.changePositon$.subscribe(position =>
          changePositionDebounced(position)
        ),
        this.showShipLocationsByShipId$.subscribe(async id => {
          const shipTrailSetting = this.mapSettings.find(
            setting => setting.id === SHIP_TRAIL_SETTING_ID
          );
          if (id && shipTrailSetting) {
            await this.updateFocusedShip(id);
            shipTrailSetting?.show$.next(true);
          }
        }),
        this.shipTrail$.subscribe(trail => {
          const shipTrailSetting = this.mapSettings.find(
            setting => setting.id === SHIP_TRAIL_SETTING_ID
          );
          if (shipTrailSetting) {
            this.shipLastLocations$.next(trail);
            shipTrailSetting?.show$.next(true);
          }
        }),
      ]
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // click events handling
  async HandleEntityClicked(event: MapboxEntityClickEvent): Promise<void> {
    const entity = _.first(event.entities);
    switch (entity?.source) {
      case SHIP_SETTING_ID:
        const id = Number(entity.properties!['id']);
        await this.FocusOnShip(id);
        this.selectedMapEvent.emit([id.toString(), 'ship']);
        break;
      case EVENT_SETTING_ID:
        const shipId = await this.settingService.GetEventShipId(
          entity.properties?.['id']
        );
        if (
          this.mapSettings
            .find(setting => setting.id === EVENT_FOCUS_SETTING_ID)
            ?.show$.getValue()
        ) {
          const eventEntity = await this.settingService.GetEventEntity(
            entity.properties!['id']
          );
          if (eventEntity) {
            this.selectedEventId$.next(eventEntity.id);
            this.changePositon$.next({
              lat: eventEntity.lat,
              long: eventEntity.long,
              height: DEFAULT_FOCUS_HEIGHT,
            });
          }
        } else if (shipId) {
          await this.FocusOnShip(shipId);
        }
        this.selectedMapEvent.emit([
          entity.properties?.['id'].toString(),
          'event',
        ]);
        break;
      default:
        break;
    }
  }

  // menu events handling
  OnOutOfMenuClick(): void {
    this.menuToShow$.next(undefined);
  }

  OnMenuButtonClick(menuId: string): void {
    this.menuToShow$.next(
      this.menuToShow$.getValue() === menuId ? undefined : menuId
    );
    if (menuId === 'weather' && !this.IsShowingWeatherSetting()) {
      this.weatherSelection$.next(_.first(this.weatherMenuOptions));
    }
  }

  // internal functions
  private IsShowingWeatherSetting(): boolean {
    const weatherSettingOptionIds = this.weatherMenuOptions.map(
      option => option.id
    );
    return this.mapSettings.some(
      setting =>
        setting.show$.getValue() && weatherSettingOptionIds.includes(setting.id)
    );
  }
  private SelectWeatherSetting(weatherOptionId: string | undefined): void {
    const weatherSettingOptionIds = this.weatherMenuOptions.map(
      option => option.id
    );
    this.mapSettings.forEach(setting => {
      if (
        setting.id !== weatherOptionId &&
        weatherSettingOptionIds.includes(setting.id)
      ) {
        setting.show$.next(false);
      }
      if (setting.id === weatherOptionId) {
        setting.show$.next(true);
      }
      if (setting.id === CONTOUR_SETTING_ID) {
        setting.show$.next(
          [
            WIND_SETTING_ID,
            HUMIDITY_SETTING_ID,
            TEMPERATURE_SETTING_ID,
            PERCIPITATION_SETTING_ID,
          ].includes(weatherOptionId!)
        );
      }
      if (setting.id === LAND_SETTING_ID) {
        setting.show$.next(!!weatherOptionId);
      }
    });
    weatherOptionId
      ? this.legendConfig$.next(
          this.settingService.GetLegendSettings(weatherOptionId)
        )
      : this.legendConfig$.next(DEFAULT_LEGEND_CONFIG);
  }
  private SelectGroupLayerSetting(
    optionGroups: MapboxMenuOptionGroup[],
    settings: MapboxSettingOption[]
  ): void {
    optionGroups
      .flatMap(group => group.options)
      .forEach(option => {
        settings
          .find(setting => setting.id === option.settingsOptionId)
          ?.show$.next(option.isChecked);
      });
  }
  private async FocusOnShip(shipId: number) {
    const shipFocusSetting = this.mapSettings.find(
      setting => setting.id === SHIP_FOCUS_SETTING_ID
    );
    const shipTrailSetting = this.mapSettings.find(
      setting => setting.id === SHIP_TRAIL_SETTING_ID
    );
    if (shipTrailSetting || shipFocusSetting) {
      await this.updateFocusedShip(shipId);

      shipFocusSetting?.show$.next(true);
      shipTrailSetting?.show$.next(true);
      if (!this.settingToShow) {
        this.mapSettings
          .filter(setting =>
            [
              SHIP_SETTING_ID,
              EVENT_SETTING_ID,
              RT_EVENT_SETTING_ID,
              NO_GO_SETTING_ID,
              COMPLIANCE_SETTING_ID,
            ].includes(setting.id)
          )
          .forEach(setting => setting.show$.next(false));
      }
      const ship = this.focusedShip$.getValue();
      if (ship) {
        let position: ShipPosition = {
          long: ship.longitude,
          lat: ship.latitude + CARD_LATITUDE_BUFFER,
        };
        if (
          !this.settingToShow ||
          !this.settingToShow.includes(SHIP_FOCUS_SETTING_ID)
        ) {
          position = { ...position, height: DEFAULT_FOCUS_HEIGHT };
        }
        this.flyTo$.next(this.getFlightDestination(position));
      }
    }
  }
  private getFlightDestination(position: ShipPosition): EasingOptions {
    return {
      duration: dayjs.duration(1, 'second').asMilliseconds(),
      center: [position?.long, position?.lat],
      zoom: (40000000 - (position?.height ?? DEFAULT_FOCUS_HEIGHT)) / 2000000,
    };
  }

  private async updateFocusedShip(shipId: number) {
    const ship = await firstValueFrom(
      this.store.select(ShipsSelectors.selectShipById(shipId))
    );
    const locations =
      this.shipTrail$.getValue() ??
      (await this.fleetService.getShipLastLocations$(ship?.shipId, new Date()));
    const score = (
      await firstValueFrom(
        this.store.select(ShipsSelectors.selectSafteyScoreShips)
      )
    ).find(score => score.shipId === shipId);
    const shipEventIds = (
      await firstValueFrom(this.store.select(EventsSelectors.selectEvents))
    )
      .filter(event => event.shipId === shipId)
      .map(event => event.eventId);
    const shipEventEntities = (
      await firstValueFrom(this.store.select(EventsSelectors.selectMapEvents))
    ).filter(entity => shipEventIds.includes(entity.id));

    let screenshot = _.first(
      (
        await firstValueFrom(
          this.store.select(ShipsSelectors.selectScreenshots)
        )
      )
        .filter(screenShot => screenShot.shipId === shipId)
        .sort(
          (screenShotA, screenShotB) =>
            new Date(screenShotA.timestamp).getTime() -
            new Date(screenShotB.timestamp).getTime()
        )
    );

    if (screenshot) {
      screenshot = {
        ...screenshot,
        cdnUrl: `${screenshot.cdnUrl}?jwt=${await firstValueFrom(
          this.store.select(AuthenticationSelectors.selectToken)
        )}`,
      };
    }

    this.focusedShip$.next(ship ?? null);
    this.shipLastLocations$.next(locations);
    this.focusedShipSafetyScores$.next(score ?? null);
    this.focusedShipScreenshot$.next(screenshot ?? null);
    this.focusedShipEvents$.next(shipEventEntities);
  }
}
