import { Expose } from 'class-transformer';

export class SubFleet {
  @Expose({ name: 'sub_fleet_id' })
  subFleetId!: number;

  @Expose({ name: 'sub_fleet_name' })
  subFleetName!: string;

  @Expose({ name: 'fleet_id' })
  fleetId!: number;

  @Expose({ name: 'ships_list' })
  shipsList!: number[];
}

export class CreateSubFleetDto {
  @Expose({ name: 'sub_fleet_name' })
  subFleetName!: string;

  @Expose({ name: 'ships_list' })
  shipsList!: number[];

  @Expose({ name: 'fleet_id' })
  fleetId!: number;
}

export class UpdateSubFleetDto {
  @Expose({ name: 'sub_fleet_name' })
  subFleetName!: string;

  @Expose({ name: 'ships_list' })
  shipsList!: number[];
}
