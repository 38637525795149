<div class="container">
  <ngx-charts-line-chart
    class="primary-line-chart"
    [results]="dataset"
    [xAxis]="true"
    [yAxis]="false"
    [curve]="linearCurve"
    [tooltipDisabled]="false"
    [xAxisTickFormatting]="formatXLabel"
    [xAxisTicks]="xAxisTicks">
    <ng-template
      #seriesTooltipTemplate
      #tooltipTemplate
      let-shipsDataPerDay="model">
      <div class="tooltip-container">
        <div class="tooltip-title">
          <div class="score">
            <h2 class="regular">
              {{
                shipsDataPerDay.value || shipsDataPerDay[0].value
                  | number : '1.0-0'
              }}
            </h2>
            <span class="subtitle1">/10</span>
          </div>
          <span class="simple-dot"></span>
          <caption>
            {{
              shipsDataPerDay.name || shipsDataPerDay[0].date
                | dateToDayMonthTime
            }}
          </caption>
        </div>
        <ng-container *ngIf="shipsDataPerDay">
          <div class="risks-container">
            <div
              *ngIf="
                shipsDataPerDay.lowSeverityShips > 0 ||
                shipsDataPerDay[0]?.lowSeverityShips > 0
              "
              appGraphTooltipItem
              [class]="riskClass"
              [colorClass]="risksMap['low'].divClass"
              [text]="risksMap['low'].text"
              [value]="
                shipsDataPerDay.lowSeverityShips ||
                shipsDataPerDay[0]?.lowSeverityShips
              "></div>
            <div
              *ngIf="
                shipsDataPerDay.moderateSeverityShips > 0 ||
                shipsDataPerDay[0]?.moderateSeverityShips > 0
              "
              appGraphTooltipItem
              [class]="riskClass"
              [colorClass]="risksMap['moderate'].divClass"
              [text]="risksMap['moderate'].text"
              [value]="
                shipsDataPerDay.moderateSeverityShips ||
                shipsDataPerDay[0]?.moderateSeverityShips
              "></div>
            <div
              *ngIf="
                shipsDataPerDay.goodSeverityShips > 0 ||
                shipsDataPerDay[0]?.goodSeverityShips > 0
              "
              appGraphTooltipItem
              [class]="riskClass"
              [colorClass]="risksMap['good'].divClass"
              [text]="risksMap['good'].text"
              [value]="
                shipsDataPerDay.goodSeverityShips ||
                shipsDataPerDay[0]?.goodSeverityShips
              "></div>
            <div
              *ngIf="
                shipsDataPerDay.excellentSeverityShips > 0 ||
                shipsDataPerDay[0]?.excellentSeverityShips > 0
              "
              appGraphTooltipItem
              [class]="riskClass"
              [colorClass]="risksMap['excellent'].divClass"
              [text]="risksMap['excellent'].text"
              [value]="
                shipsDataPerDay.excellentSeverityShips ||
                shipsDataPerDay[0]?.excellentSeverityShips
              "></div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </ngx-charts-line-chart>
</div>
