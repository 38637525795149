import { createAction, props } from '@ngrx/store';
import { DateGraphFilter } from 'src/app/shared/view-models/dates.view.model';

export const selectPotentialSavingsGraphRangeDate = createAction(
  '[Fleet Fuel optimization] Select potential savings dates of the graph',
  props<{
    potentialSavingsGraphSelectedDate: Partial<DateGraphFilter>;
  }>()
);

export const selectAccelerationTimeGraphRangeDate = createAction(
  '[Ship Fuel optimization] Select acceleration time dates of the graph',
  props<{
    accelerationTimeGraphSelectedDate: Partial<DateGraphFilter>;
  }>()
);
