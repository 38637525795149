<div
  class="container"
  [class.short]="isShort$ | async"
  [class.no-sidenav]="hideSideNav$ | async">
  <div class="title">
    <div class="breadcrumbs">
      <div class="url-seg" *ngFor="let crumb of breadcrumbs$ | async">
        <h3 routerLink="{{ crumb.url }}">
          {{ crumb.label | titlecase }}
        </h3>
        <mat-icon class="icon">keyboard_arrow_right</mat-icon>
      </div>
    </div>
    <div class="subtitle" *ngIf="subtitle$ | async as subtitle">
      {{ subtitle }}
    </div>
  </div>
  <div
    class="right-side"
    [class.hidden]="(shouldShowFleetAndTimeDropdowns$ | async) === false">
    <app-fleet-dropdown
      [class.hidden]="
        (shouldShowFleetDropdowns$ | async) === false
      "></app-fleet-dropdown>
    <app-time-frame
      [currentDateFilter]="(currentDateFilter$ | async)!"
      [currentDateFilterRange]="(currentDateFilterRange$ | async)!"
      (dateFilterChange)="onDatesEvent($event)"
      [isDisabled]="(isDatesFilterFilterDisabled$ | async)!"
      [isCustomDatesDisabled]="
        (isCustomDateFilterOptionDisabled$ | async)!
      "></app-time-frame>
  </div>
</div>
