import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
  ],
})
export class FormDialogComponent {
  @Input() title: string = '';
  @Input() formGroup!: FormGroup;
  @Input() submitButtonText: string = 'Save';
  @Input() cancelButtonText: string = 'Cancel';
  @Input() subtitle: string = '';
  @Input() onSubmitAction?: (formValue: any) => Observable<boolean>;
  constructor(private dialogRef: MatDialogRef<FormDialogComponent>) {}

  onSubmit(): void {
    if (this.formGroup.valid) {
      if (this.onSubmitAction) {
        this.onSubmitAction(this.formGroup.value).subscribe({
          next: () => this.dialogRef.close(this.formGroup.value),
          error: (error: string) => {
            this.formGroup.setErrors({ serverError: error });
          },
        });
      } else {
        this.dialogRef.close(this.formGroup.value);
      }
    }
  }
}
