import { GeoJSONFeature, LngLat } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import {
  MapboxCustomSetting,
  MapboxSettingType,
  MapLabel,
} from 'src/app/mapbox/models/mapbox.models';
import { CreatePopupFromComponent } from 'src/app/mapbox/utils/mapbox.utility';

export const RT_EVENT_SETTING_ID = 'rt-events';

export const GetRTEventSetting = (
  rtEvents$: BehaviorSubject<MapLabel[]>
): MapboxCustomSetting => {
  const getRTEventFeatures = () =>
    rtEvents$.getValue().map(
      (rtEvent, index): GeoJSONFeature => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [rtEvent.long, rtEvent.lat],
        },
        source: 'rt-event',
        properties: {
          id: index,
          icon: rtEvent.iconSrc,
          category: 'rt-event',
          textLabel: rtEvent.textLabel,
          labelType: rtEvent.labelType,
        },
      })
    );
  const getPopups = () =>
    rtEvents$.getValue().map(rtEvent => {
      const popup = CreatePopupFromComponent('app-map-label', {
        label: rtEvent,
      })
        .setOffset(rtEvent.offset)
        .setLngLat(new LngLat(rtEvent.long, rtEvent.lat).wrap());
      popup.addClassName('rt-event');
      popup.addClassName('red');
      return popup;
    });

  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'rt-event',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getRTEventFeatures(),
          },
        },
        updateFeatures: getRTEventFeatures,
      },
    ],
    layers: [
      {
        id: 'rt-event',
        type: 'symbol',
        source: 'rt-event',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      },
    ],
    popups: getPopups(),
    updatePopups: getPopups,
  };
};
