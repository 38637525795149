<div class="tooltip-container">
  <div class="tooltip-header" *ngIf="title || showCloseButton">
    <h4 class="tooltip-title" *ngIf="title">{{ title }}</h4>
    <button mat-icon-button *ngIf="showCloseButton" class="close-tooltip">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider *ngIf="title"></mat-divider>

  <div class="tooltip-content">
    <ng-container *ngIf="templateContent; else defaultContent">
      <ng-container *ngTemplateOutlet="templateContent; context: context">
      </ng-container>
    </ng-container>

    <ng-template #defaultContent>
      {{ context }}
    </ng-template>
  </div>

  <div class="tooltip-arrow"></div>
</div>
