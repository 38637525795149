import _ from 'lodash';
import { GeoJSONFeature, LngLat } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import {
  MAX_LATITUDE,
  MAX_LONGTITUDE,
  MIN_LATITUDE,
  MIN_LONGTITUDE,
} from 'src/app/mapbox/models/mapbox-configurations.models';
import {
  MapboxCustomSetting,
  MapboxSettingType,
  PolygonEntity,
} from 'src/app/mapbox/models/mapbox.models';
import {
  CreatePopupFromComponent,
  GetCenterOfPolygon,
} from 'src/app/mapbox/utils/mapbox.utility';

export const NO_GO_SETTING_ID = 'No-Go Zone';
const NO_GO_ZONE_FILL_COLOR = 'rgba(231, 50, 82, 0.20)';
const NO_GO_ZONE_BORDER_COLOR = '#E73252';

export const GetNoGoSetting = (
  noGoZones$: BehaviorSubject<PolygonEntity[]>
): MapboxCustomSetting => {
  const getNoGoZoneFeatures = () =>
    noGoZones$.getValue().map(
      (noGoZone): GeoJSONFeature => ({
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [noGoZone.coordinates],
        },
        source: 'no_go_zone',
        properties: noGoZone,
      })
    );
  const getPopups = () =>
    noGoZones$
      .getValue()
      .filter(noGoZone => {
        const center = GetCenterOfPolygon(noGoZone.coordinates);
        return (
          _.inRange(center[0], MIN_LONGTITUDE, MAX_LONGTITUDE) &&
          _.inRange(center[1], MIN_LATITUDE, MAX_LATITUDE)
        );
      })
      .map(noGoZone => {
        const center = GetCenterOfPolygon(noGoZone.coordinates);
        const label = {
          long: center[0],
          lat: center[1],
          textLabel: noGoZone.textLabel?.toUpperCase(),
          offset: 35,
        };
        const popup = CreatePopupFromComponent('app-map-label', { label })
          .setOffset(label.offset)
          .setLngLat(new LngLat(label.long, label.lat).wrap());
        popup.addClassName('no_go_zone');
        popup.addClassName('red');
        return popup;
      });
  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'no_go_zone',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getNoGoZoneFeatures(),
          },
        },
        updateFeatures: getNoGoZoneFeatures,
      },
    ],
    layers: [
      {
        id: 'no_go_zone',
        type: 'fill',
        source: 'no_go_zone',
        paint: {
          'fill-color': NO_GO_ZONE_FILL_COLOR,
          'fill-outline-color': NO_GO_ZONE_BORDER_COLOR,
        },
      },
    ],
    popups: getPopups(),
    updatePopups: getPopups,
    maxZoomForPopups: 5,
  };
};
