<button
  [disabled]="isDisabled"
  mat-raised-button
  color="primary"
  class="btn dark-color">
  <img
    class="video-icon"
    src="./assets/icons/live_stream.svg"
    *ngIf="!isDisabled" />
  <img
    class="video-icon"
    src="./assets/icons/disabled_video.svg"
    *ngIf="isDisabled" />
  <span class="button-text primary">{{ text }}</span>
</button>
