<div class="container">
  <button
    class="menu-button"
    mat-button
    [matMenuTriggerFor]="menu"
    [disabled]="isDisabled">
    <div class="selection">
      <span
        class="body1"
        *ngIf="currentDateFilter !== 'Custom dates average'"
        >{{ currentDateFilter }}</span
      >
      <span class="body1" *ngIf="currentDateFilter === 'Custom dates average'">
        {{ datePickerRange.value.startDate?.toDate() | date : 'dd/MM/yyyy' }} -
        {{ datePickerRange.value.endDate?.toDate() | date : 'dd/MM/yyyy' }}
      </span>
      <img src="./assets/icons/arrow_down.svg" />
    </div>
  </button>
  <mat-menu class="time-menu" #menu="matMenu">
    <mat-radio-group [ngModel]="currentDateFilter" ngDefaultControl>
      <ng-container *ngFor="let dateKey of orderedDateFilters">
        <mat-radio-button
          class="time-radio-button"
          [value]="dateKey"
          (change)="onConstDatesSelected($event)"
          *ngIf="dateKey !== 'Custom dates average'">
          <div class="preset-name">
            {{ dateKey }}
          </div>
        </mat-radio-button>
      </ng-container>
      <mat-radio-button
        class="time-radio-button"
        [disabled]="!datePickerRange.valid || isCustomDatesDisabled"
        [value]="'Custom dates average'"
        (change)="onCustomDatesSelected()">
        <mat-form-field appearance="fill" class="custom-dates">
          <mat-label>Choose a date</mat-label>
          <mat-date-range-input
            [rangePicker]="rangePicker"
            [formGroup]="datePickerRange"
            [required]="true"
            [max]="maxDate">
            <input
              matStartDate
              formControlName="startDate"
              placeholder="Start date"
              (dateChange)="onCustomDatesSelected()" />
            <input
              matEndDate
              formControlName="endDate"
              placeholder="End date"
              (dateChange)="onCustomDatesSelected()" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            class="datepicker-toggle"
            matSuffix
            [for]="rangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker
            class="date-range-picker"
            #rangePicker
            [disabled]="isCustomDatesDisabled">
            <mat-date-range-picker-actions>
              <button mat-button matDateRangePickerCancel>Close</button>
              <button
                class="dates-button"
                mat-raised-button
                color="primary"
                matDateRangePickerApply>
                Apply
              </button>
            </mat-date-range-picker-actions>
          </mat-date-range-picker>
        </mat-form-field>
      </mat-radio-button>
    </mat-radio-group>
  </mat-menu>
</div>
