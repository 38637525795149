<div class="container">
  <app-title></app-title>
  <ng-container *ngIf="insights$ | async as insights">
    <app-carousel
      (arrowClicked)="onArrowClicked($event)"
      *ngIf="insights.length > 0; else NoDataAvailable">
      <ng-template #carouselItem *ngFor="let insight of insights">
        <app-insight-card [insight]="insight"></app-insight-card>
      </ng-template>
    </app-carousel>
  </ng-container>
</div>

<ng-template #NoDataAvailable>
  <div class="no-data-container">
    <div class="divider"></div>
    <div class="no-data-img"><img src="./assets/icons/fishing_rod.svg" /></div>
    <div class="no-data-text-container">
      <p class="title">No available insights</p>
      <p class="text">There are no insights at this moment</p>
    </div>
  </div>
</ng-template>
