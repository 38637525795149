import { createSelector } from '@ngrx/store';

import {
  EventMapEntity,
  MapLabel,
  ShipMapEntity,
} from 'src/app/mapbox/models/mapbox.models';
import { getTimeDiff } from 'src/app/shared/utils/date-transforms/date-diff';
import { InsightCard } from 'src/app/shared/view-models/insight.view.model';
import { EventLocation } from '../../models/events.model';
import {
  defaultPosition,
  MapTooltip,
  ShipPosition,
} from '../../models/map.models';
import {
  mapEventsToMapEntities,
  mapEventToEventsType,
  mapEventToMapEntity,
} from '../../utils/events';
import {
  mapSelectedShipToMapEntity,
  mapShipsToMapEntities,
  polygonsToMapPolygons,
} from '../../utils/ship';
import { CoreState } from '../state/core.state';
import { OverviewState } from '../state/overview.state';
import { ShipsSelectors } from '../types';
import { selectIsUserShipCaptain } from './authentication.selectors';

const selectOverviewState = (state: CoreState): OverviewState => state.overview;

export const selectInsights = createSelector(selectOverviewState, state => {
  return state.insights;
});

export const selectEventsLocation = createSelector(
  selectOverviewState,
  state => {
    return state.eventsLocation;
  }
);

export const selectSelectedEvent = createSelector(
  selectOverviewState,
  state => state.selectedEvent
);

export const selectSelectedEventMapEntity = createSelector(
  selectSelectedEvent,
  (event): EventMapEntity | undefined =>
    event ? mapEventToMapEntity(event) : undefined
);

export const selectPolygons = createSelector(
  selectOverviewState,
  state => state.polygons
);

export const selectMapEvents = createSelector(
  selectEventsLocation,
  (events): EventMapEntity[] => mapEventsToMapEntities(events, null)
);

export const selectSelectedEventPositionOverview = createSelector(
  selectSelectedEvent,
  (event): ShipPosition => {
    if (event) {
      return {
        long: event.long,
        lat: event.lat,
      };
    }
    return defaultPosition;
  }
);

export const selectSelectedShip = createSelector(
  selectOverviewState,
  state => state.selectedShip
);

export const selectSelectedShipId = createSelector(selectSelectedShip, state =>
  state ? state.shipId : null
);

export const selectSelectedShipPositionOverview = createSelector(
  selectSelectedShip,
  (selectedShip): ShipPosition => {
    if (selectedShip) {
      return { lat: selectedShip.latitude, long: selectedShip.longitude };
    }
    return defaultPosition;
  }
);

export const selectSelectedShipEventsData = createSelector(
  selectOverviewState,
  state => state.selectedShipEventsData
);

export const selectSelectedShipLastLocations = createSelector(
  selectOverviewState,
  state => state.selectedShipLastLocations
);

export const selectEventsOfSelectedShip = createSelector(
  selectSelectedShipEventsData,
  state => state.events!.map(event => mapEventToEventsType(event))
);

export const selectEventsMapOfSelectedShip = createSelector(
  selectSelectedShipEventsData,
  selectSelectedEvent,
  (eventsData, selectedEvent): EventMapEntity[] =>
    mapEventsToMapEntities(eventsData.eventsLocation ?? [], selectedEvent)
);

export const selectScrollTopOfSelectedShipEventData = createSelector(
  selectSelectedShipEventsData,
  state => state.scrollToTop
);

export const selectOverviewMapShips = createSelector(
  ShipsSelectors.selectShips,
  (ships): ShipMapEntity[] => {
    const addLabelData = true;
    return mapShipsToMapEntities(ships, null, addLabelData);
  }
);

export const selectSelectedMapShip = createSelector(
  selectSelectedShip,
  (selectedShip): any => {
    if (selectedShip) return mapSelectedShipToMapEntity(selectedShip, true);
    return null;
  }
);

export const selectMapShipEntities = createSelector(
  selectOverviewMapShips,
  selectSelectedMapShip,
  (mapShips, mapOfSelectedShip): ShipMapEntity[] => {
    if (mapOfSelectedShip) {
      return [mapOfSelectedShip];
    } else return [...mapShips];
  }
);
export const selectMapEventEntities = createSelector(
  selectEventsMapOfSelectedShip,
  selectMapEvents,
  selectSelectedMapShip,
  (mapEventsOfSelectedShip, mapEvents, mapOfSelectedShip): EventMapEntity[] => {
    if (mapOfSelectedShip) {
      return [...mapEventsOfSelectedShip];
    } else return [...mapEvents];
  }
);

export const selectMapPosition = createSelector(
  selectOverviewState,
  state => state.mapPosition
);

export const selectMapPositionDefaultByLastEvent = createSelector(
  selectMapPosition,
  selectSelectedEvent,
  selectSelectedShip,
  selectEventsLocation,
  (mapPosition, selectedEvent, selectedShip, eventsLocation): ShipPosition => {
    if (selectedEvent || selectedShip) {
      return mapPosition;
    } else if (eventsLocation?.length) {
      const [firstLocation = {} as EventLocation] = eventsLocation;
      return {
        lat: firstLocation.lat,
        long: firstLocation.long,
      };
    }
    return defaultPosition;
  }
);

export const selectMapPolygons = createSelector(
  selectSelectedShip,
  selectPolygons,
  (selectedShip, polygons) => {
    if (selectedShip) return [];
    return polygonsToMapPolygons(polygons);
  }
);

export const selectSelectedShipSafetyScores = createSelector(
  selectOverviewState,
  state => state.selectedShipSafetyScores
);

export const selectEndDateAvgShipSafetyScore = createSelector(
  selectSelectedShipSafetyScores,
  shipSafetyScores => {
    if (shipSafetyScores.length === 0) return 0;
    return shipSafetyScores[shipSafetyScores.length - 1].safetyScore;
  }
);

export const selectStartDateAvgShipSafetyScore = createSelector(
  selectSelectedShipSafetyScores,
  shipSafetyScores => {
    if (shipSafetyScores.length === 0) return 0;
    return shipSafetyScores[0].safetyScore;
  }
);

export const selectEnhancedScreenshotByShip = createSelector(
  selectSelectedShipId,
  ShipsSelectors.selectEnhancedScreenshots,
  (selectShipId, screenshots) => {
    const screenshot =
      screenshots.find(screenshot => screenshot.shipId === selectShipId) ||
      null;
    return screenshot;
  }
);

export const selectShipMapLabel = createSelector(
  ShipsSelectors.selectShips,
  (ships): MapLabel[] => {
    if (!ships) {
      return [];
    }
    return ships.map(ship => {
      return {
        lat: ship.latitude,
        long: ship.longitude,
        textLabel: ship.shipName.toUpperCase(),
        labelType: 'large-white',
        offset: 35,
      };
    });
  }
);

export const selectRtEventsMapLabel = createSelector(
  ShipsSelectors.selectShips,
  ShipsSelectors.selectRTEvents,
  (ships, rtEvents): MapLabel[] => {
    if (!ships || !rtEvents) {
      return [];
    }
    const currentUtctime = new Date(
      new Date().getTime() + new Date().getTimezoneOffset() * 60000
    );

    return ships
      .filter(ship => rtEvents.hasOwnProperty(ship.shipId.toString()))
      .map(ship => {
        const rtEventTime = getTimeDiff(
          currentUtctime,
          new Date(rtEvents[ship.shipId].timestampStart)
        );
        return {
          iconSrc: './assets/icons/rt_event.svg',
          lat: ship.latitude,
          long: ship.longitude,
          textLabel: `${
            rtEvents[ship.shipId].alertName
          } Duration: ${rtEventTime} Hrs`,
          labelType: 'red',
          labelSource: 'rt-event',
          offset: 65,
        };
      });
  }
);

export const selectMapShipNameLabels = createSelector(
  selectShipMapLabel,
  selectSelectedShipId,
  (shipLabels, selectedShipId): MapLabel[] =>
    !selectedShipId ? [...shipLabels] : []
);

export const selectMapRtEventLabels = createSelector(
  selectRtEventsMapLabel,
  selectSelectedShipId,
  (rtEventLabels, selectedShipId): MapLabel[] =>
    !selectedShipId ? [...rtEventLabels] : []
);

export const selectMapTooltip = createSelector(
  selectSelectedShip,
  selectEnhancedScreenshotByShip,
  selectStartDateAvgShipSafetyScore,
  selectEndDateAvgShipSafetyScore,
  selectIsUserShipCaptain,
  (ship, screenshot, startDateAvg, endDateAvg, isShipCaptain): MapTooltip => ({
    shipData: ship,
    endDateAvgShipSafetyScore: startDateAvg,
    startDateAvgShipSafetyScore: endDateAvg,
    showLiveStreamFlag: true,
    isLiveStreamOpen: false,
    screenshot: screenshot!,
    isVisible: true,
    visibleByLoading: true,
    isShipCaptain: isShipCaptain,
  })
);

export const selectUnreadInsightsCount = createSelector(
  selectInsights,
  (insights: InsightCard[]) =>
    insights.filter(insight => !insight.readStatus).length
);
