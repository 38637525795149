<div class="container">
  <div class="inner-container" (click)="updateLikeStatus(true)">
    <img
      class="icon"
      [src]="
        isLikeActive
          ? './assets/icons/like.svg'
          : './assets/icons/like_unselected.svg'
      " />
    <div class="text">{{ likeText }}</div>
  </div>
  <div class="inner-container" (click)="updateLikeStatus(false)">
    <img
      class="icon unlike-image"
      [src]="
        !isLikeActive && isLikeActive !== null
          ? './assets/icons/like.svg'
          : './assets/icons/like_unselected.svg'
      " />
    <div class="text">{{ unlikeText }}</div>
  </div>
</div>
