import { DropdownViewModel } from 'src/app/shared/view-models/dropdown.options.view.model';

export enum UserRolesEnum {
  FleetManager = 'fleet-manager',
  ShipCaptain = 'ship-captain',
  SubFleetManager = 'sub-fleet-manager',
}

export enum UserRoleDynamicallyEnabledFormFieldEnum {
  Ships = 'shipId',
  SubFleetShips = 'subFleetShipsId',
}

export const userRoleEnabledFormFields: {
  [key in UserRolesEnum]: UserRoleDynamicallyEnabledFormFieldEnum[];
} = {
  [UserRolesEnum.FleetManager]: [],
  [UserRolesEnum.ShipCaptain]: [UserRoleDynamicallyEnabledFormFieldEnum.Ships],
  [UserRolesEnum.SubFleetManager]: [
    UserRoleDynamicallyEnabledFormFieldEnum.SubFleetShips,
  ],
};

export const rolesDropdownOptions: DropdownViewModel[] = [
  {
    value: UserRolesEnum.FleetManager,
    viewValue: 'Fleet Manager',
  },
  {
    value: UserRolesEnum.ShipCaptain,
    viewValue: 'Captain',
  },
  {
    value: UserRolesEnum.SubFleetManager,
    viewValue: 'Sub Fleet Manager',
  },
];
