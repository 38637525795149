<div class="container">
  <div class="filters">
    <app-filters></app-filters>
  </div>
  <app-filtered-events></app-filtered-events>

  <div class="map-container">
    <div class="score-by-date">
      <app-events-graph></app-events-graph>
    </div>

    <div class="map">
      <app-maritime-map
        [events$]="eventsStore.mapEvents$"
        [changePositon$]="eventsStore.selectedEventPosition$"
        [selectedEvent$]="eventsStore.selectedEventMapEntity$"
        [minimalZoomLevel]="1"
        (selectedMapEvent)="onSelectedMapEvent($event)"
        [settingToShow]="[
          'surface',
          'land',
          'event',
          'EventFocus',
          'labels',
          'Contour'
        ]"></app-maritime-map>
    </div>
  </div>
</div>
