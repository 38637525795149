import { createAction, props } from '@ngrx/store';

export const voyageSearched = createAction(
  '[Voyages] Voyage Searched',
  props<{
    origin: string;
    originCountry: string;
    destination: string;
    destinationCountry: string;
    timePeriod: string;
  }>()
);

export const voyageExportedPDF = createAction(
  '[Voyages] Voyage Exported PDF',
  props<{
    voyageAlternativeTypeSelected: string;
    accelerationTime: number;
    distance: number;
    sog: number;
    duration: number;
    closeEncounters: number;
    origin: string;
    originCountry: string;
    destination: string;
    destinationCountry: string;
    timePeriod: string;
    fleetType: string;
  }>()
);
