import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { MatAccordion } from '@angular/material/expansion';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, firstValueFrom, Observable } from 'rxjs';
import { SavePresetDialogComponent } from 'src/app/core/components/dialogs/save-preset-dialog/save-preset-dialog.component';
import { EventsService } from 'src/app/core/services/events.service';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  AuthenticationSelectors,
  EventsActions,
  EventsSelectors,
} from 'src/app/core/store/types';
import {
  AppliedEventFilters,
  PresetFilter,
  ViewAlertNames,
  ViewCongestionLevelNames,
} from 'src/app/core/view-models/filter.view.model';
import {
  DropdownViewModel,
  ViewValue,
} from 'src/app/shared/view-models/dropdown.options.view.model';
import { Range } from 'src/app/shared/view-models/range.view.model';
import { CustomSliderOptions } from 'src/app/shared/view-models/slider.options.model';
import { EventsRepository } from '../events.repository';
import { EventsStore } from '../events.store';

@UntilDestroy()
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  private readonly eventsRepository = inject(EventsRepository);
  public readonly eventsStore = inject(EventsStore);

  sliderOwnSog$!: Observable<CustomSliderOptions>;
  shipNamesDropdown$!: Observable<DropdownViewModel[]>;
  congestionLevelDropdown$!: Observable<DropdownViewModel[]>;
  eventTypeDropdown$!: Observable<DropdownViewModel[]>;
  appliedPresetFilter$: Observable<PresetFilter | undefined> | undefined;

  appliedInPort$!: Observable<boolean>;

  appliedShipNameFilter$!: Observable<ViewValue<string[] | string>>;

  appliedEventTypeFilter$!: Observable<ViewValue<ViewAlertNames>>;

  appliedCongestionLevelFilter$!: Observable<
    ViewValue<ViewCongestionLevelNames>
  >;

  appliedOwnSogFilter$!: Observable<Range>;

  isCloseEncounterEvent$!: Observable<boolean>;
  isUKCEvent$!: Observable<boolean>;
  isSpeedDropEvent$!: Observable<boolean>;
  isHighRotEvent$!: Observable<boolean>;
  isHighPitchRollEvent$!: Observable<boolean>;
  appliedShowOnlyRt$!: Observable<boolean>;
  isShipCaptain$!: Observable<boolean>;

  @ViewChild('eventFiltersAccordion')
  accordion!: MatAccordion;
  isSavePresetDisabled$!: Observable<boolean>;

  constructor(
    private store: Store<CoreState>,
    private eventsService: EventsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.sliderOwnSog$ = this.store.select(EventsSelectors.selectSliderOwnSog);
    this.shipNamesDropdown$ = this.store.select(
      EventsSelectors.selectShipNamesDropdown
    );

    this.congestionLevelDropdown$ = this.store.select(
      EventsSelectors.selectCongestionLevelsDropdown
    );

    this.eventTypeDropdown$ = this.store.select(
      EventsSelectors.selectEventTypeDropdown
    );

    this.appliedShipNameFilter$ = this.store
      .select(EventsSelectors.selectAppliedShipNameFilter)
      .pipe(distinctUntilChanged());

    this.appliedCongestionLevelFilter$ = this.store.select(
      EventsSelectors.selectAppliedCongestionLevel
    );

    this.appliedEventTypeFilter$ = this.store.select(
      EventsSelectors.selectAppliedEventTypeFilter
    );

    this.isCloseEncounterEvent$ = this.store.select(
      EventsSelectors.selectIsCloseEncounterEvent
    );

    this.isHighPitchRollEvent$ = this.store.select(
      EventsSelectors.selectIsHighPitchRollEvent
    );

    this.isUKCEvent$ = this.store.select(EventsSelectors.selectIsUkcEvent);

    this.isSpeedDropEvent$ = this.store.select(
      EventsSelectors.selectIsSpeedDropEvent
    );
    this.isHighRotEvent$ = this.store.select(
      EventsSelectors.selectIsHighRotEvent
    );

    this.appliedInPort$ = this.store.select(
      EventsSelectors.selectShowEventsInPort
    );

    this.isSavePresetDisabled$ = this.store.select(
      EventsSelectors.selectIsPresetValuesExist
    );

    this.appliedPresetFilter$ = this.store.select(
      EventsSelectors.selectAppliedPresetInEvents
    );

    this.appliedShowOnlyRt$ = this.store.select(
      EventsSelectors.selectAppliedShowOnlyRt
    );

    this.isShipCaptain$ = this.store.select(
      AuthenticationSelectors.selectIsUserShipCaptain
    );
  }

  async resetToDefaultAllFilters(): Promise<void> {
    this.accordion.closeAll();
    const eventFilters = await firstValueFrom(
      this.store.select(EventsSelectors.selectEventsFilters)
    );

    const resetedFilters =
      this.eventsService.setAppliedFiltersToDefault(eventFilters);

    this.store.dispatch(
      EventsActions.resetAllFiltersOnUserPress({
        appliedFilters: resetedFilters,
      })
    );

    this.eventsRepository.resetFilters();
  }

  async onEventFiltersChange(value: any, key: string): Promise<void> {
    const appliedFilters = this.eventsStore.appliedEventFilters$.value;

    if (!appliedFilters) return;

    const updatedAppliedFilters: AppliedEventFilters = {
      ...appliedFilters,
      [key]: value,
    };

    this.store.dispatch(
      EventsActions.onEventFilterChange({ updatedAppliedFilters })
    );

    this.eventsRepository.applyFilters(updatedAppliedFilters);
  }

  async saveFilters(): Promise<void> {
    const existingPresets = await firstValueFrom(
      this.store.select(EventsSelectors.selectPresetFilters)
    );

    const dialogRef = this.dialog.open(SavePresetDialogComponent, {
      data: { numOfPresets: existingPresets.length },
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(filter(presetName => presetName != null))
      .subscribe(presetName => {
        this.store.dispatch(EventsActions.savePresetFilters({ presetName }));
      });
  }

  onFilterChanged(change: PresetFilter | undefined): void {
    this.store.dispatch(
      EventsActions.setPresetFilterInEvents({
        selectedPresetFilter: change ? { ...change } : undefined,
      })
    );
  }
}
