import { Dates } from 'src/app/shared/view-models/dates.view.model';
import { EventSeverityKeys } from 'src/app/shared/view-models/general.event.view.model';
import {
  defaultRange,
  Range,
} from 'src/app/shared/view-models/range.view.model';
import { EventType } from '../view-models/event.view.model';

export type AlertNames =
  | 'Dangerous motion'
  | 'Dangerous Speed Drop'
  | 'Dangerous ROT'
  | 'Grounding risk'
  | 'Collision risk'
  | 'Compliance'
  | 'No Go Zone';
export type AlertNamesToEventTypeMap = { [key in AlertNames]: EventType };

export const alertNamesToEventTypes: AlertNamesToEventTypeMap = {
  'Dangerous motion': 'High Pitch/Roll',
  'Dangerous Speed Drop': 'Speed Drop',
  'Dangerous ROT': 'High ROT',
  'Grounding risk': 'UKC',
  'Collision risk': 'Close Encounter',
  'No Go Zone': 'No Go Zone',
  Compliance: 'Compliance',
};

export type CongestionLevelsNames =
  | 'Hyper_Congested'
  | 'Open'
  | 'Congested'
  | 'N/A';

export type CongestionLevelsViewNames =
  | 'Low Traffic'
  | 'Congested Waters'
  | 'Hyper-Congested Waters'
  | 'N/A';
export type CongestionLevelsNamesMap = {
  [key in CongestionLevelsNames]: CongestionLevelsViewNames;
};

export const congestionLevelsMap: CongestionLevelsNamesMap = {
  Hyper_Congested: 'Hyper-Congested Waters',
  Open: 'Low Traffic',
  Congested: 'Congested Waters',
  'N/A': 'N/A',
};
export type ColregClassificationViewNames =
  | 'Unknown'
  | 'Bow-Crossing'
  | 'Rule 13 - Being Overtaken'
  | 'Rule 13 - Overtaking'
  | 'Rule 14 - Head-On'
  | 'Other';
export type ColregClassificationNames =
  | 'Unknown'
  | 'Crossing'
  | 'Headon'
  | 'Overtaken'
  | 'Overtaking'
  | 'Other';

export type colregClassificationNamesMap = {
  [key in ColregClassificationNames]: ColregClassificationViewNames;
};

export const colregClassificationMap: colregClassificationNamesMap = {
  Unknown: 'Unknown',
  Crossing: 'Bow-Crossing',
  Overtaking: 'Rule 13 - Overtaking',
  Overtaken: 'Rule 13 - Being Overtaken',
  Headon: 'Rule 14 - Head-On',
  Other: 'Other',
};

export type TargetTypeNames =
  | 'cargo'
  | 'fishing'
  | 'military_ops'
  | 'other'
  | 'passenger'
  | 'pleasure_craft'
  | 'sailing_vessel'
  | 'tanker'
  | 'unknown';
export type TargetTypeNamesViewNames =
  | 'Cargo'
  | 'Fishing'
  | 'Military Ops'
  | 'Other'
  | 'Passenger'
  | 'Pleasure Craft'
  | 'Sailing Vessel'
  | 'Tanker'
  | 'Unknown';
export type TargetTypeNamesMap = {
  [key in TargetTypeNames]: TargetTypeNamesViewNames;
};

export const targetTypeNamesMap: TargetTypeNamesMap = {
  cargo: 'Cargo',
  fishing: 'Fishing',
  military_ops: 'Military Ops',
  other: 'Other',
  passenger: 'Passenger',
  pleasure_craft: 'Pleasure Craft',
  sailing_vessel: 'Sailing Vessel',
  tanker: 'Tanker',
  unknown: 'Unknown',
};

export type EventsSortBy =
  | 'default'
  | 'date'
  | 'severity'
  | 'alert_name'
  | 'ship_name'
  | 'acceleration_time';

export interface LocationsDto {
  readonly location: string;
  readonly sub_locations: string[];
}
export interface Locations {
  readonly parentLocation: string;
  readonly subLocations: string[];
}

export interface GeneralFiltersValues {
  readonly shipNames: string[];
  readonly severityLevels: EventSeverityKeys[];
  readonly eventTypes: AlertNames[];
  readonly congestionLevels: CongestionLevelsNames[];
  readonly locations: Locations[];
  readonly ownSog: Range;
}
export interface CloseEncounterFilterValues {
  readonly minDistance: Range;
  readonly colregClassifications: ColregClassificationNames[];
  readonly targetSog: Range;
  readonly targetType: TargetTypeNames[];
}

export interface UKCFilterValues {
  readonly minDepth: Range;
}

export interface HighPitchRollFilterValues {
  readonly roll: Range;
  readonly pitch: Range;
}

export interface SpeedDropFilterValues {
  readonly sogDiff: Range;
}

export interface HighRotValues {
  readonly rot: Range;
}
export interface EventFilters {
  readonly generalFilters: GeneralFiltersValues;
  readonly closeEncounterFilter: CloseEncounterFilterValues;
  readonly ukcFilter: UKCFilterValues;
  readonly highPitchRoll: HighPitchRollFilterValues;
  readonly speedDrop: SpeedDropFilterValues;
  readonly highRot: HighRotValues;
}

export const defaultEventFilters: EventFilters = {
  generalFilters: {
    severityLevels: [],
    congestionLevels: [],
    eventTypes: [],
    locations: [],
    shipNames: [],
    ownSog: defaultRange,
  },
  closeEncounterFilter: {
    colregClassifications: [],
    minDistance: defaultRange,
    targetSog: defaultRange,
    targetType: [],
  },
  highPitchRoll: {
    pitch: defaultRange,
    roll: defaultRange,
  },
  highRot: {
    rot: defaultRange,
  },
  speedDrop: {
    sogDiff: defaultRange,
  },
  ukcFilter: {
    minDepth: defaultRange,
  },
};

export interface DynamicFiltersDto {
  readonly ship_names: string[];
  readonly severity_levels: EventSeverityKeys[];
  readonly alert_names: AlertNames[];
  readonly locations: LocationsDto[];
  readonly congestion_levels: CongestionLevelsNames[];
  readonly min_sog: number;
  readonly max_sog: number;
  readonly min_target_distance_min_dis: number;
  readonly max_target_distance_min_dis: number;
  readonly colreg_classifications: ColregClassificationNames[];
  readonly min_target_sog_start: number;
  readonly max_target_sog_start: number;
  readonly target_types: TargetTypeNames[];
  readonly min_depth: number;
  readonly max_depth: number;
  readonly min_roll: number;
  readonly max_roll: number;
  readonly min_pitch: number;
  readonly max_pitch: number;
  readonly min_sog_diff: number;
  readonly max_sog_diff: number;
  readonly min_rot: number;
  readonly max_rot: number;
}

export interface EventDayFilter {
  readonly dates: Dates;
  readonly safetyScore: number | null;
}

export type CongestionLevelsViews =
  | 'Low Traffic'
  | 'Congested'
  | 'Hyper-Congested'
  | 'N/A';

export type CongestionLevelsNamesToViews = {
  [key in CongestionLevelsNames]: CongestionLevelsViews;
};

export const congestionLevelsViewMap: CongestionLevelsNamesToViews = {
  Hyper_Congested: 'Hyper-Congested',
  Open: 'Low Traffic',
  Congested: 'Congested',
  'N/A': 'N/A',
};
