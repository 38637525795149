import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DestroyRef } from 'projects/orca-lib-main/projects/orca-lib/src/lib/services/destroy-ref.service';
import {
  distinctUntilChanged,
  firstValueFrom,
  from,
  map,
  of,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { Dates } from 'src/app/shared/view-models/dates.view.model';
import { EventsSortBy } from '../../models/events-filters.model';
import { EventsService as EventsServiceOld } from '../../services/events.service';
import { LoggerService } from '../../services/logger.service';
import { CoreState } from '../../store/state/core.state';
import { ShipsSelectors } from '../../store/types';
import { mapEventToEventsType } from '../../utils/events';
import { Events } from '../../view-models/event.view.model';
import {
  AppliedEventFilters,
  defaultAppliedEventFilters,
} from '../../view-models/filter.view.model';
import { EventsStore } from './events.store';

interface GetEventsOptions {
  filters?: AppliedEventFilters;
  dates?: Dates | null;
  sortBy?: EventsSortBy;
  nextPage?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EventsRepository {
  private readonly destroyRef = inject(DestroyRef);
  private readonly eventsStore = inject(EventsStore);
  private readonly eventsService = inject(EventsServiceOld);
  private readonly logger = new LoggerService();
  private itemsPerPage = 100;
  private eventsRequestTrigger$ = new Subject<GetEventsOptions>();

  constructor() {
    this.eventsRequestTrigger$
      .pipe(
        switchMap(options => from(this.getEvents(options))),
        takeUntil(this.destroyRef.destroy$)
      )
      .subscribe();

    this.eventsStore.selectedFleet$
      .pipe(takeUntil(this.destroyRef.destroy$))
      .subscribe(_ => {
        this.resetFilters(false);
        this.updateSortBy('default', false);
        this.eventsRequestTrigger$.next({
          filters: defaultAppliedEventFilters,
          sortBy: 'default',
        });
      });

    this.eventsStore.daysFilter$
      .pipe(
        distinctUntilChanged(),
        switchMap(dates => {
          this.resetFilters(false);
          this.updateSortBy('default', false);
          this.eventsRequestTrigger$.next({
            dates,
            sortBy: 'default',
          });
          return of(null);
        }),
        takeUntil(this.destroyRef.destroy$)
      )
      .subscribe();
  }

  private store: Store<CoreState> = inject(Store);

  async initLoad() {
    await this.getFilters();
    this.eventsRequestTrigger$.next({ sortBy: 'default' });
  }

  async getFilters() {
    if (this.eventsStore.appliedEventFilters$.value) {
      return;
    }

    const shipNames = await firstValueFrom(
      this.store
        .select(ShipsSelectors.selectShips)
        .pipe(map(ships => ships.map(ship => ship.shipName)))
    );

    const filters =
      this.eventsService.getEventsFilterHardcodedValues(shipNames);
    const appliedFilters =
      this.eventsService.setAppliedFiltersToDefault(filters);

    this.eventsStore.eventFilters$.next(filters);
    this.eventsStore.appliedEventFilters$.next(appliedFilters);
  }

  resetFilters(shouldFetchEvents = true) {
    const filters = this.eventsStore.eventFilters$.value;
    if (!filters) return;
    const appliedFilters =
      this.eventsService.setAppliedFiltersToDefault(filters);
    this.applyFilters(appliedFilters, shouldFetchEvents);
  }

  applyFilters(filters: AppliedEventFilters, shouldFetchEvents = true) {
    this.eventsStore.appliedEventFilters$.next(filters);
    if (shouldFetchEvents) {
      this.getEvents({ filters });
    }
  }

  async getEvents(options: GetEventsOptions) {
    const {
      filters = this.eventsStore.appliedEventFilters$.value ??
        defaultAppliedEventFilters,
      dates = this.eventsStore.daysFilter$.value,
      sortBy = this.eventsStore.selectedSortOption$.value ?? 'default',
      nextPage = false,
    } = options;

    if (!dates) {
      return;
    }

    if (nextPage) {
      await this.getEventsNextPage(filters, dates, sortBy);
    } else {
      await this.getEventsInitLoad(filters, dates, sortBy);
    }
  }

  async getEventByIdAndUpdateStore(
    eventId: string
  ): Promise<Events | undefined> {
    const event = await this.eventsService.getEventById(eventId);

    if (!event) {
      return;
    }

    const transformedEvent = mapEventToEventsType(event);
    this.eventsStore.appendEvents([event]);

    return transformedEvent;
  }

  async getEventsInitLoad(
    filters: AppliedEventFilters,
    dates: Dates,
    sortBy: EventsSortBy
  ) {
    this.eventsStore.currentPage$.next(1);
    this.logger.log('EVENTS_REPOSITORY:GET_EVENTS_INIT_LOAD', filters);

    try {
      const eventsData = await this.eventsService.getEventsData$(
        filters,
        sortBy,
        dates,
        this.eventsStore.currentPage$.value,
        this.itemsPerPage
      );

      const events = eventsData.events;
      const locations = eventsData.eventsLocation;
      const eventsCount = eventsData.totalCount ?? 0;

      if (!events || !locations) {
        return;
      }

      this.eventsStore.totalEventsCount$.next(eventsCount);
      this.eventsStore.setEvents(events);
      this.eventsStore.setMapEvents(locations);

      if (events.length > 0) {
        const firstEvent = mapEventToEventsType(events[0]);
        this.updateSelectedEvent(firstEvent);
      }
    } catch (err) {
      this.logger.error('EVENTS_REPOSITORY:GET_EVENTS_INIT_LOAD:ERROR', err);
    }
  }

  async getEventsNextPage(
    filters: AppliedEventFilters,
    dates: Dates,
    sortBy: EventsSortBy
  ) {
    if (
      this.eventsStore.events$.value.length >=
      this.eventsStore.totalEventsCount$.value
    ) {
      return;
    }

    this.eventsStore.currentPage$.next(this.eventsStore.currentPage$.value + 1);

    try {
      const events = await this.eventsService.getEvents$(
        filters,
        sortBy,
        dates,
        this.eventsStore.currentPage$.value,
        this.itemsPerPage
      );

      this.eventsStore.appendEvents(events);
    } catch (err) {
      this.logger.error('EVENTS_REPOSITORY:GET_EVENTS_NEXT_PAGE:ERROR', err);
    }
  }

  updateSelectedEvent(event: Events) {
    this.eventsStore.setSelectedEvent(event);
  }

  async updateSelectedEventById(eventId: string) {
    let event = this.eventsStore.transformedEvents$.value.find(
      event => event.eventId === eventId
    );

    if (!event) {
      event = await this.getEventByIdAndUpdateStore(eventId);
    }

    setTimeout(() => {
      if (event) {
        this.updateSelectedEvent(event);
      }
    }, 500);
  }

  updateSortBy(sortBy: EventsSortBy, shouldFetchEvents = true) {
    this.eventsStore.selectedSortOption$.next(sortBy);

    if (shouldFetchEvents) {
      this.getEvents({ sortBy });
    }
  }
}
