import {
  HighDuringLastWeekInsight,
  InsightType,
  ShipWithLeastEventsInsight,
  ShipWithMostEventsInsight,
} from 'src/app/core/models/insights.models';
import { InsightLink } from '../models/InsightData.model';

export type InsightDataType =
  | ShipWithMostEventsInsight
  | ShipWithLeastEventsInsight
  | HighDuringLastWeekInsight;

export interface InsightCard {
  readonly insightId: string;
  readonly insightType: InsightType;
  readonly title: string;
  readonly content: string;
  readonly dateUpdated: Date;
  readonly link: InsightLink;
  readonly likeVote: boolean | null;
  readonly readStatus: boolean;
}

export type InsightTypeClassificationViewNames =
  | 'ship_wmost_events'
  | 'ship_wleast_events'
  | 'high_during_lst_week';

export type InsightTypeClassificationNames =
  | 'ShipWithMostEventsInsight'
  | 'ShipWithLeastEventsInsight'
  | 'HighDuringLastWeekInsight';

export type InsightTypeNamesMap = {
  [key in InsightTypeClassificationViewNames]: InsightTypeClassificationNames;
};

export const InsightTypeFromatFromDto: InsightTypeNamesMap = {
  high_during_lst_week: 'HighDuringLastWeekInsight',
  ship_wmost_events: 'ShipWithMostEventsInsight',
  ship_wleast_events: 'ShipWithLeastEventsInsight',
};

export enum InsightTypeFromat {
  ShipWithMostEventsInsight = 'ShipWithMostEventsInsight',
  ShipWithLeastEventsInsight = 'ShipWithLeastEventsInsight',
  HighDuringLastWeekInsight = 'HighDuringLastWeekInsight',
}
