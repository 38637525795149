import { HttpClient, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  OriginsAndDestinationsResponse,
  VoyagesResponse,
  VoyagesSeasonMap,
} from './models/voyages.types';

export class VoyagesService {
  private http = inject(HttpClient);

  getOriginsAndDestinations() {
    return this.http.get<OriginsAndDestinationsResponse>(
      `${environment.api.originsAndDestinations}`
    );
  }

  getVoyages(origin: string, destination: string, season: VoyagesSeasonMap) {
    const params = new HttpParams()
      .set('start_port', origin)
      .set('end_port', destination)
      .set('season', season);

    return this.http.get<VoyagesResponse>(`${environment.api.voyages}`, {
      params,
    });
  }
}
