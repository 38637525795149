import {
  GeoJSONFeature,
  LayerSpecification,
  Popup,
  SourceSpecification,
} from 'mapbox-gl';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventSeverity } from 'src/app/core/models/events.model';
import { EventType } from 'src/app/core/view-models/event.view.model';

export interface MapboxSettingOption {
  id: string;
  setting: MapboxCustomSetting | MapboxStyleSetting;
  update$: Observable<void>;
  show$: BehaviorSubject<boolean>;
}

export enum MapboxSettingType {
  Custom = 'Custom',
  FromStyle = 'FromStyle',
}

export interface MapboxCustomSetting {
  type: MapboxSettingType.Custom;
  sources: MapSource[];
  layers: LayerSpecification[];
  popups: Popup[];
  maxZoomForPopups?: number;
  updatePopups?: () => Popup[];
  showPopup$?: BehaviorSubject<string | null>;
}

export interface MapboxStyleSetting {
  type: MapboxSettingType.FromStyle;
  layerIds: string[];
}

export interface MapSource {
  sourceId: string;
  source: SourceSpecification;
  updateFeatures?: () => GeoJSONFeature[];
  updateTilesSource?: () => string[];
}

export interface MapboxEntityConfiguration {
  image: string;
}

export interface MapboxEntityClickEvent {
  longtitude: number;
  latitude: number;
  entities: GeoJSONFeature[];
}

export interface MapEntity {
  readonly id: string;
  readonly image?: string;
  readonly lat: number;
  readonly long: number;
  readonly eyeOffset: [number, number, number];
  readonly textLabel?: string;
  readonly labelType?: LabelType;
}

export interface ShipMapEntity {
  readonly shipId: number;
  readonly shipType: string;
  readonly shipName: string;
  readonly lastConnection: Date | null;
  readonly sog: number | null;
  readonly cog: number | null;
  readonly maxRoll: number | null;
  readonly maxPitch: number | null;
  readonly safetyScore: number;
  readonly image: string;
  readonly lat: number;
  readonly long: number;
  readonly eyeOffset: [number, number, number];
  readonly textLabel?: string;
  readonly labelType?: LabelType;
}

export interface EventMapEntity {
  readonly id: string;
  readonly shipId: number;
  readonly eventType: EventType;
  readonly severity: EventSeverity;
  readonly image: string;
  readonly lat: number;
  readonly long: number;
  readonly eyeOffset: [number, number, number];
}

export type LabelType = 'white' | 'large-white' | 'red';

export interface PolygonEntity {
  readonly id: string;
  readonly textLabel: string;
  readonly labelType: LabelType;
  readonly coordinates: number[][];
  readonly color: string;
  readonly borderColor: string;
  readonly labelSource: string;
}

export interface MapLabel {
  readonly iconSrc?: string;
  readonly lat: number;
  readonly long: number;
  readonly textLabel: string;
  readonly labelType: LabelType;
  readonly offset: number;
}
