import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import * as moment from 'moment';
import {
  DateFilter,
  DateFilterKeys,
  dateFilters,
  Dates,
} from '../../view-models/dates.view.model';
interface DatePickerForm {
  readonly startDate: FormControl<Date | null>;
  readonly endDate: FormControl<Date | null>;
}
@Component({
  selector: 'app-time-frame',
  templateUrl: './time-frame.component.html',
  styleUrls: ['./time-frame.component.scss'],
})
export class TimeFrameComponent implements OnChanges {
  @Output()
  dateFilterChange = new EventEmitter<Partial<DateFilter>>();

  @Input()
  currentDateFilter!: DateFilterKeys;

  @Input()
  currentDateFilterRange!: Dates;

  @Input()
  isDisabled!: boolean;

  @Input()
  isCustomDatesDisabled: boolean = false;

  maxDate = new Date();
  dateFilters = dateFilters;

  orderedDateFilters: DateFilterKeys[] = [
    'Last 7 days',
    'Last 30 days',
    'Last 90 days',
    'Last Year',
  ];

  datePickerRange = this.fb.group({
    startDate: [null as moment.Moment | null, [Validators.required]],
    endDate: [null as moment.Moment | null, [Validators.required]],
  });

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentDateFilterRange']) {
      this.datePickerRange.patchValue({
        startDate: moment(this.currentDateFilterRange.startDate),
        endDate: moment(this.currentDateFilterRange.endDate),
      });
    }
  }

  onConstDatesSelected(event: MatRadioChange) {
    this.currentDateFilter = event.value as DateFilterKeys;
    const constDateFilter: Partial<DateFilter> = {
      [this.currentDateFilter]: this.dateFilters[this.currentDateFilter],
    };
    this.dateFilterChange.emit(constDateFilter);
  }

  onCustomDatesSelected() {
    if (this.datePickerRange.controls.endDate.valid) {
      this.currentDateFilter = 'Custom dates average';
      const customDates: Dates = {
        startDate: this.datePickerRange.value.startDate!.toDate(),
        endDate: this.datePickerRange.value.endDate!.toDate(),
      };
      const customDateFilter: Partial<DateFilter> = {
        [this.currentDateFilter]: { ...customDates },
      };

      this.dateFilterChange.emit(customDateFilter);
    }
  }
}
