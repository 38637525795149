import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import dayjs from 'dayjs';
import _ from 'lodash';
import { EasingOptions, Map, MapOptions, NavigationControl } from 'mapbox-gl';
import { BehaviorSubject, first, Subscription } from 'rxjs';
import {
  MapboxEntityClickEvent,
  MapboxEntityConfiguration,
  MapboxSettingOption,
} from '../../models/mapbox.models';
import { MapboxService } from '../../services/mapbox.service';

const DEFAULT_MAX_ZOOM = 16;

@Component({
  selector: 'app-mapbox',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mapbox.component.html',
  styleUrls: ['./mapbox.component.scss'],
})
export class MapboxComponent implements AfterViewInit, OnDestroy {
  // rendering data:
  @Input() settings!: MapboxSettingOption[];
  @Input() entitiesConfiguration: MapboxEntityConfiguration[] = [];
  @Input() mapOptions: MapOptions = { container: '' };
  @Input() hideControls: boolean = false;

  // event subscriptions:
  @Input() flyTo$!: BehaviorSubject<EasingOptions>;

  // Output emissions:
  @Output() entityClicked: EventEmitter<MapboxEntityClickEvent> =
    new EventEmitter<MapboxEntityClickEvent>();

  // map
  map!: Map;
  mapId: string = _.uniqueId('map_');

  // internal map events
  private onMapStyleLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  // lifecycle resources
  private subscriptions: Subscription[] = [];
  private resizeObserver!: ResizeObserver;
  private resizedFlag: boolean = false;

  // child components
  @ContentChild('mapMenu')
  menuTemplate!: TemplateRef<any>;
  @ContentChild('mapLegend')
  legendTemplate!: TemplateRef<any>;

  constructor(private mapboxService: MapboxService) {
    const SortMapDebounced = _.debounce(
      () => this.mapboxService.SortMapSettings(this.map, this.settings),
      dayjs.duration(100, 'millisecond').asMilliseconds()
    );

    this.resizeObserver = new ResizeObserver(this.ResizeMapDebounced);
    this.subscriptions.push(
      this.onMapStyleLoaded$.subscribe(styleLoaded => {
        if (styleLoaded) {
          this.settings.forEach(settingOption => {
            this.subscriptions.push(
              ...[
                settingOption.update$.subscribe(async () => {
                  this.mapboxService.UpdateSetting(
                    this.map,
                    settingOption.setting,
                    settingOption.show$.getValue()
                  );
                  SortMapDebounced();
                }),
                settingOption.show$.subscribe(shouldShow =>
                  this.mapboxService.SetSettingVisibility(
                    this.map,
                    shouldShow,
                    settingOption.setting
                  )
                ),
              ]
            );
          });
          const containerElement = document.getElementById(this.mapId);
          if (containerElement) {
            this.resizeObserver.observe(containerElement);
          }
        }
      })
    );
  }

  ngAfterViewInit() {
    this.mapOptions.container = this.mapId;
    this.map = this.mapboxService.CreateMap(
      this.mapOptions,
      this.entitiesConfiguration
    );
    this.map.on('style.load', () => {
      !this.onMapStyleLoaded$.getValue() && this.onMapStyleLoaded$.next(true);
    });
    this.map.on('click', event => {
      const features = this.map.queryRenderedFeatures(event.point);
      const entityClickEvent: MapboxEntityClickEvent = {
        latitude: event.lngLat.lat,
        longtitude: event.lngLat.lng,
        entities: features,
      };
      this.entityClicked.emit(entityClickEvent);
    });
    this.map.on('zoom', event => {
      this.mapboxService.SetAllPopupsVisibility(this.map, this.settings);
    });
    this.map.on('load', () => this.map.resize());
    this.subscriptions.push(
      this.flyTo$.subscribe((destination: EasingOptions) => {
        if (this.onMapStyleLoaded$.getValue()) {
          this.map.flyTo(destination);
          this.map.setMaxZoom(this.mapOptions.maxZoom ?? DEFAULT_MAX_ZOOM);
        } else {
          this.subscriptions.push(
            this.onMapStyleLoaded$
              .pipe(first(value => value))
              .subscribe(() => this.map.flyTo(destination))
          );
        }
      })
    );
    if (!this.hideControls) {
      this.map.addControl(
        new NavigationControl({
          showCompass: false,
          showZoom: true,
        }),
        'bottom-right'
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe);
    this.map.remove();
  }

  private ResizeMapDebounced = _.debounce(() => {
    try {
      this.map.resize();
    } catch (error) {
      this.ResizeMapDebounced();
    }
  }, dayjs.duration(100, 'millisecond').asMilliseconds());
}
