import _ from 'lodash';
import { GeoJSONFeature, LngLat } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import {
  MAX_LATITUDE,
  MAX_LONGTITUDE,
  MIN_LATITUDE,
  MIN_LONGTITUDE,
} from 'src/app/mapbox/models/mapbox-configurations.models';
import {
  MapboxCustomSetting,
  MapboxSettingType,
  PolygonEntity,
} from 'src/app/mapbox/models/mapbox.models';
import {
  CreatePopupFromComponent,
  GetCenterOfPolygon,
} from 'src/app/mapbox/utils/mapbox.utility';

export const COMPLIANCE_SETTING_ID = 'Compliance';
const COMPLIANCE_FILL_COLOR = 'rgba(156, 156, 156, 0.30)';
const COMPLIANCE_BORDER_COLOR = '#9C9C9C';

export const GetComplianceSetting = (
  complianceZones$: BehaviorSubject<PolygonEntity[]>
): MapboxCustomSetting => {
  const getComplienceFeatures = () =>
    complianceZones$.getValue().map(
      (complianceZone: PolygonEntity): GeoJSONFeature => ({
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [complianceZone.coordinates],
        },
        source: 'compliance',
        properties: complianceZone,
      })
    );

  const getPopups = () =>
    complianceZones$
      .getValue()
      .filter(complianceZone => {
        const center = GetCenterOfPolygon(complianceZone.coordinates);
        return (
          _.inRange(center[0], MIN_LONGTITUDE, MAX_LONGTITUDE) &&
          _.inRange(center[1], MIN_LATITUDE, MAX_LATITUDE)
        );
      })
      .map(complianceZone => {
        const center = GetCenterOfPolygon(complianceZone.coordinates);
        const label = {
          long: center[0],
          lat: center[1],
          textLabel: complianceZone.textLabel,
          offset: 0,
        };
        const popup = CreatePopupFromComponent('app-map-label', {
          label,
        })
          .setOffset(label.offset)
          .setLngLat(new LngLat(label.long, label.lat).wrap());
        popup.addClassName('compliance');
        popup.addClassName('gray');
        return popup;
      });
  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'compliance',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getComplienceFeatures(),
          },
        },
        updateFeatures: getComplienceFeatures,
      },
    ],
    layers: [
      {
        id: 'compliance',
        type: 'fill',
        source: 'compliance',
        paint: {
          'fill-color': COMPLIANCE_FILL_COLOR,
          'fill-outline-color': COMPLIANCE_BORDER_COLOR,
        },
      },
    ],
    popups: getPopups(),
    updatePopups: getPopups,
    maxZoomForPopups: 5,
  };
};
