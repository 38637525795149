import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Model } from 'common';
import {
  concatMap,
  filter,
  first,
  from,
  map,
  of,
  switchMap,
  take,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';
import { defaultFleet } from '../../models/fleet.model';
import { AuthService } from '../../services/auth.service';
import { FeatureFlagService } from '../../services/feature-toggle.service';
import { FleetService } from '../../services/fleet.service';
import { CoreState } from '../state/core.state';
import {
  AuthenticationActions,
  AuthenticationSelectors,
  ShipsActions,
} from '../types';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<CoreState>,
    private router: Router,
    private fleetService: FleetService,
    private ngZone: NgZone,
    private featureFlagService: FeatureFlagService
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.login),
      switchMap(action =>
        from(this.authService.login(action.loginReq)).pipe(
          map(user => AuthenticationActions.savePreliminaryUser({ user }))
        )
      )
    );
  });

  onPreliminaryUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.savePreliminaryUser),
        filter(user => user !== null && user !== undefined),
        switchMap(_ =>
          from(this.ngZone.run(() => this.router.navigateByUrl('/sign-up')))
        )
      );
    },
    {
      dispatch: false,
    }
  );

  completeSignUp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.signup),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(AuthenticationSelectors.selectPreliminaryUserId)
          ),
          map(([action, preliminaryUserId]) => {
            return {
              ...action.signupForm,
              preliminaryUserId: preliminaryUserId,
            } as Model.SignUpRequest;
          })
        )
      ),
      concatMap(signupReq =>
        from(this.authService.signup(signupReq)).pipe(
          filter(isSignedUp => isSignedUp === true),
          switchMap(_ => {
            this.ngZone.run(() =>
              this.router.navigateByUrl('/account-created')
            );
            return of(AuthenticationActions.clearPreliminaryUser());
          })
        )
      )
    );
  });

  onAuthUserChange$ = createEffect(() => {
    return this.authService.currentAuthenticatedUser$.pipe(
      filter(authUser => authUser != null),
      switchMap(authUser =>
        from(this.featureFlagService.init(authUser)).pipe(
          map(() => AuthenticationActions.saveAuthUser({ authUser }))
        )
      )
    );
  });

  onSubFleetLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateTokenOnRegularUserSetClaims),
      switchMap(() => {
        return this.store
          .select(AuthenticationSelectors.selectAuthenticatedUser)
          .pipe(
            filter(authUser => {
              return (
                authUser?.role === 'sub-fleet-manager' &&
                authUser.subFleetShipsId == null &&
                (authUser.subFleetId != null || authUser.subFleetIds != null)
              );
            }),
            switchMap(authUser =>
              from(this.fleetService.getShipsOfSubFleetManager$()).pipe(
                map(ships =>
                  AuthenticationActions.initAuthUser({
                    authUser: {
                      ...authUser,
                      subFleetShipsId: ships,
                    } as Model.User,
                  })
                )
              )
            )
          );
      })
    );
  });

  onOrcaAdminAuth$ = createEffect(() => {
    return this.authService.currentAuthenticatedUser$.pipe(
      filter(user => !!user && user.role === 'orca-admin'),
      concatMap(authUser => {
        const claimsReq: Model.SetClaimsRequest = {
          userId: authUser!.id,
          claims: {
            fleetId: defaultFleet.fleetId,
            shipId: null,
            subFleetShipsId: null,
            subFleetId: null,
            subFleetIds: null,
          },
        };
        return from(this.authService.setClaimsForOrcaAdmin(claimsReq)).pipe(
          map(
            (token): Model.User => ({
              ...authUser!,
              fleetId: defaultFleet.fleetId,
              shipId: null,
              token,
            })
          )
        );
      }),
      map(user => AuthenticationActions.updateUserOnAdminSetClaims({ user }))
    );
  });

  onRegularUserAuth$ = createEffect(() => {
    return this.authService.currentAuthenticatedUser$.pipe(
      filter(user => user != null && user.role !== 'orca-admin'),
      concatMap(authUser => {
        const authReq: Model.AuthenticatedRequest = {
          uid: authUser!.id,
        };
        return from(
          this.authService.setClaimsForRegularUsers(authReq, authUser!)
        );
      }),
      map(token =>
        AuthenticationActions.updateTokenOnRegularUserSetClaims({ token })
      )
    );
  });

  onResetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.requestResetPassword),
      concatMap(action =>
        from(this.authService.resetPassword(action.resetPasswordReq)).pipe(
          switchMap(result => {
            if (result === true) {
              this.ngZone.run(() =>
                this.router.navigateByUrl('/password-recovery')
              );
            }
            return of(result);
          }),
          concatMap(result => {
            return of(
              AuthenticationActions.passwordResetEmailChecked({
                passwordReset: {
                  email: action.resetPasswordReq.email,
                  isRegisteredUser: result,
                },
              })
            );
          })
        )
      )
    );
  });

  OnNewPassword$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.newPassword),
        switchMap(action =>
          from(this.authService.confirmNewPassword(action.newPasswordReq)).pipe(
            switchMap(_ =>
              this.ngZone.run(() => this.router.navigateByUrl('/login'))
            )
          )
        )
      );
    },
    {
      dispatch: false,
    }
  );

  onFleetIdChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.changeFleetId),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(AuthenticationSelectors.selectAuthenticatedUser)
          ),
          map(([action, user]) => {
            const claimsReq: Model.SetClaimsRequest = {
              userId: user!.id,
              claims: {
                fleetId: action.fleetId,
                shipId: null,
                subFleetShipsId: null,
              },
            };
            return [user, claimsReq] as const;
          })
        )
      ),
      concatMap(([user, claimsReq]) =>
        from(this.authService.setClaimsForOrcaAdmin(claimsReq)).pipe(
          map(token => {
            const fleetId = claimsReq.claims['fleetId']
              ? Number(claimsReq.claims['fleetId']!)
              : null;
            return AuthenticationActions.updateUserOnAdminSetClaims({
              user: {
                ...user!,
                fleetId: fleetId,
                shipId: null,
                token,
              },
            });
          })
        )
      )
    );
  });

  onFleetIdChangeResetShipId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.changeFleetId),
      map(() => ShipsActions.resetSelectedShipIdOnFleetChange())
    );
  });

  getFleetsOrcaAdminLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateUserOnAdminSetClaims),
      first(),
      switchMap(user =>
        this.fleetService
          .getFleets$()
          .pipe(map(fleets => AuthenticationActions.saveFleets({ fleets })))
      )
    );
  });

  onLogout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.logout),
        switchMap(_ =>
          from(this.authService.logout()).pipe(
            switchMap(_ =>
              this.ngZone.run(() => this.router.navigateByUrl('/login'))
            )
          )
        )
      );
    },
    {
      dispatch: false,
    }
  );

  logoutOnUserClick$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.logoutOnUserClick),
        switchMap(_ =>
          from(this.authService.logoutFromAllOpenTab()).pipe(
            map(_ => AuthenticationActions.logout())
          )
        )
      );
    },
    { dispatch: true }
  );

  logoutFromAllTab$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.logoutFromAllTab),
        take(1),
        tap(_ => {
          this.ngZone.run(() => this.router.navigateByUrl('/login'));
        })
      );
    },
    { dispatch: true }
  );

  refreshToken$ = createEffect(() => {
    return this.authService.currentAuthenticatedUser$.pipe(
      filter(user => !!user),
      take(1), // the initial trigger is user logs in
      switchMap(_ => {
        const interval = 55 * 60 * 1000;
        return timer(interval, interval).pipe(
          //every 55 mins (token expires after 1 hour) get last user data, get claims from it (for regular user they will stay the same, for admin they might change)
          withLatestFrom(
            this.store.select(AuthenticationSelectors.selectAuthenticatedUser)
          ),
          filter(([_, user]) => !!user), // if user logs out,
          switchMap(([_, user]) => {
            const claims = { fleetId: user!.fleetId, shipId: user!.shipId };
            return from(this.authService.refreshToken(claims)).pipe(
              // refresh token
              map(token => AuthenticationActions.saveRefreshedToken({ token }))
            );
          })
        );
      })
    );
  });
}
