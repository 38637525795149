<div class="tooltip">
  <div class="container">
    <div class="image-container">
      <img
        [lazyLoad]="
          screenshot?.cdnUrl ?? './assets/images/screenshot_not_found.svg'
        "
        [ngClass]="{ 'no-connection': !isConnectionWithinFourHours() }"
        class="image-size" />
      <img
        src="./assets/icons/blue_close.svg"
        class="close-icon"
        alt="Icon"
        (click)="close()" />
      <div class="no-connection-label" *ngIf="!isConnectionWithinFourHours()">
        No Connection
      </div>
      <app-red-rt-event-indication
        *ngIf="screenshot?.rtEventName"
        [displayDurationText]="false"
        [rtEventName]="screenshot?.rtEventName ?? ''"
        [rtEventTime]="screenshot?.rtEventTime"
        class="indication-container"></app-red-rt-event-indication>
    </div>
    <div class="text-container" *ngIf="shipData">
      <div class="title">{{ shipData.shipName }}</div>
      <div class="sub-title" *ngIf="shipData.lastConnection">
        Last Connection:
        <span>
          {{
            isConnectionWithinFourHours()
              ? (shipData.lastConnection | dateToHoursMinutesAgo)
              : (shipData.lastConnection | date : 'HH:mm dd/MM/yy' : 'UTC')
          }}</span
        >
      </div>
      <div class="seperator"></div>
      <div class="inner-container">
        <div class="info-container">
          <div class="info-item">
            <span class="info-label">COG:</span>
            <span class="info-value">
              {{
                shipData.cog !== null
                  ? (shipData.cog | number : '1.0-0') + '°'
                  : 'N/A'
              }}
            </span>
          </div>
          <div class="info-item">
            <span class="info-label">SOG:</span>
            <span class="info-value">
              {{
                shipData.sog !== null
                  ? (shipData.sog | number : '1.0-0') + ' Knts'
                  : 'N/A'
              }}
            </span>
          </div>
          <div class="info-item">
            <span class="info-label">Roll:</span>
            <span class="info-value">
              {{
                shipData.maxRoll !== null
                  ? (shipData.maxRoll | number : '1.0-0') + '°'
                  : 'N/A'
              }}
            </span>
          </div>
          <div class="info-item">
            <span class="info-label">Pitch:</span>
            <span class="info-value">
              {{
                shipData.maxPitch !== null
                  ? (shipData.maxPitch | number : '1.0-0') + '°'
                  : 'N/A'
              }}
            </span>
          </div>
        </div>
        <div class="score-container">
          <div class="score">
            <h2 class="regular score-font">
              {{ shipData.safetyScore | number : '1.0-0' }}
            </h2>
            <div class="score-limit">/10</div>
          </div>
          <div class="line"></div>
          <div class="delta-risks-container">
            <app-delta-risks
              [currRisk]="endDateAvgShipSafetyScore"
              [oldRisk]="startDateAvgShipSafetyScore"></app-delta-risks>
          </div>
        </div>
      </div>
      <app-live-stream-button
        [hidden]="isShipCaptain"
        [isDisabled]="
          !isConnectionWithinFourHours() ||
          isLiveStreamOpen ||
          !showLiveStreamFlag
        "
        (click)="onButtonClicked('/private/live/ship/', shipData.shipId, true)"
        class="live-stream-button"
        [text]="'Live'">
      </app-live-stream-button>
      <button
        mat-stroked-button
        (click)="onButtonClicked('/private/fleet/ship/', shipData.shipId)"
        color="primary"
        class="primary-border ship-profile-btn">
        <div class="ship-profile-btn-content">
          <img src="./assets/icons/ship.svg" />
          <div class="ship-profile-btn-text">Ship Profile</div>
        </div>
      </button>
    </div>
  </div>
</div>
