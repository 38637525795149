import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { DestroyRef } from 'projects/orca-lib-main/projects/orca-lib/src/lib/services/destroy-ref.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-track-label',
  templateUrl: './track-label.component.html',
  styleUrls: ['./track-label.component.scss'],
})
export class TrackLabelComponent implements OnInit {
  @Input() time!: string;
  @Input() sog!: number;
  @Input() onPopupDestroy$!: Subject<void>;

  timeString: string = '';
  speedString: string = '';

  constructor(private destroyRef: DestroyRef) {
    const subscription = this.destroyRef.destroy$.subscribe(() => {
      this.onPopupDestroy$.next();
      subscription.unsubscribe();
    });
  }

  ngOnInit() {
    this.timeString = this.time.match(/^[0-9]{2}:[0-9]{2}$/)
      ? this.time
      : dayjs(this.time).format('HH:mm');
    this.speedString = `${this.sog} Knts`;
  }
}
