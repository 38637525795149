import { createReducer, on } from '@ngrx/store';
import { FiltersState, initialFiltersState } from '../state/filters.state';
import { FiltersActions } from '../types';

export const filtersStateKey = 'filters';
export const filtersReducer = createReducer(
  initialFiltersState,
  on(
    FiltersActions.saveSelectedDatesFilter,
    (state, action): FiltersState => ({
      ...state,
      dateFilter: action.dateFilter,
    })
  ),
  on(
    FiltersActions.setDatesFilterState,
    (state, action): FiltersState => ({
      ...state,
      isDateFilterDisabled: action.isDisabled,
    })
  ),
  on(
    FiltersActions.setCustomDateFilterState,
    (state, action): FiltersState => ({
      ...state,
      isCustomDateOptionDisabled: action.isDisabled,
    })
  ),
  on(
    FiltersActions.resetDateFilterToDefault,
    (state, action): FiltersState => ({
      ...state,
      dateFilter: { ...action.dateFilter },
    })
  ),
  on(
    FiltersActions.setDateFilterByUrlParams,
    (state, action): FiltersState => ({
      ...state,
      dateFilter: { ...action.dateFilter },
    })
  )
);
