import { Injectable, OnDestroy } from '@angular/core';
import {
  Auth,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import { Firestore, getDoc } from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import {
  applyActionCode,
  browserLocalPersistence,
  sendEmailVerification,
  signOut,
  User,
} from '@firebase/auth';
import { doc, onSnapshot } from '@firebase/firestore';
import { Store } from '@ngrx/store';
import { Model } from 'common';
import { isEqual } from 'lodash';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  from,
  interval,
  map,
  Observable,
  Observer,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import { AuthenticationActions } from '../store/types';
import { IsBusyService } from './is-busy.service';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private readonly onLogin!: (
    data: Model.LoginRequest
  ) => Observable<Model.PreliminaryUser>;

  private readonly onSignup!: (
    data: Model.SignUpRequest
  ) => Observable<Model.SignUpRequest>;

  private readonly onAuthenticated!: (
    data: Model.AuthenticatedRequest
  ) => Observable<void>;

  private readonly setCustomClaims!: (
    data: Model.SetClaimsRequest
  ) => Observable<void>;

  private currentFirebaseUser$: Subject<User | null> =
    new Subject<User | null>();
  private currentUserId$!: Observable<string | undefined>;

  currentAuthenticatedUser$!: Observable<Model.User | null>;
  constructor(
    private firestore: Firestore,
    private store: Store,
    private fireAuth: Auth,
    private fireFunctions: Functions,
    private isBusyService: IsBusyService,
    private notificationService: NotificationsService
  ) {
    this.onLogin = httpsCallableData(this.fireFunctions, 'onLogin');
    this.onSignup = httpsCallableData(this.fireFunctions, 'onSignup');
    this.onAuthenticated = httpsCallableData(
      this.fireFunctions,
      'onAuthenticated'
    );
    this.setCustomClaims = httpsCallableData(this.fireFunctions, 'setClaims');

    const obsr: Observer<User | null> = {
      next: firebaseUser => {
        if (firebaseUser == null || firebaseUser.emailVerified === false) {
          this.currentFirebaseUser$.next(null);
        } else {
          this.currentFirebaseUser$.next(firebaseUser);
        }
      },
      error: err => console.log(err),
      complete: () => console.log('completed'),
    };
    this.fireAuth.onIdTokenChanged(obsr);

    this.currentUserId$ = this.currentFirebaseUser$.pipe(
      map(user => user?.uid),
      distinctUntilChanged()
    );

    this.currentAuthenticatedUser$ = this.currentUserId$.pipe(
      switchMap(id =>
        id
          ? from(getDoc(doc(this.firestore, 'users', id))).pipe(
              map(doc => doc.data() as Partial<Model.User>)
            )
          : of(null)
      ),
      map(user => (user ? ({ ...user, token: '' } as Model.User) : null))
    );

    this.fireAuth.setPersistence(browserLocalPersistence);
    this.startStorageEventListener();
  }

  async init(): Promise<Model.User | null> {
    return await firstValueFrom(this.currentAuthenticatedUser$);
  }

  login(loginReq: Model.LoginRequest) {
    return this.isBusyService.add(() => {
      return firstValueFrom(
        this.onLogin(loginReq).pipe(
          catchError(_ =>
            from(
              signInWithEmailAndPassword(
                this.fireAuth,
                loginReq.email,
                loginReq.password
              )
            ).pipe(
              catchError(_ => {
                throw new Error('Wrong email/password');
              }),
              map(cred => {
                if (cred?.user.emailVerified === false) {
                  throw new Error('Email was not verified');
                } else {
                  return null;
                }
              }),
              catchError(err => {
                this.notificationService.error(err.message);
                return of(null);
              })
            )
          )
        )
      );
    });
  }

  async signup(signupReq: Model.SignUpRequest) {
    return await this.isBusyService.add(async () => {
      try {
        const cred = await createUserWithEmailAndPassword(
          this.fireAuth,
          signupReq.email,
          signupReq.password
        );
        await sendEmailVerification(this.fireAuth.currentUser!);
        const userDoc = doc(this.firestore, 'users', cred.user.uid);
        onSnapshot(userDoc, async doc => {
          if (doc.exists()) {
            await firstValueFrom(this.onSignup(signupReq));
          }
        });
        return true;
      } catch {
        this.notificationService.error('something went wrong');
        return false;
      }
    });
  }

  resetPassword(
    resetPasswordReq: Model.ResetPasswordRequest
  ): Promise<boolean> {
    return this.isBusyService.add(async () => {
      try {
        await sendPasswordResetEmail(this.fireAuth, resetPasswordReq.email);
        return true;
      } catch {
        this.notificationService.error(
          'Email address for password recovery was not found'
        );
        return false;
      }
    });
  }

  verifyResetPasswordCode(code: string): Promise<string> {
    return this.isBusyService.add(async () => {
      try {
        return await verifyPasswordResetCode(this.fireAuth, code);
      } catch {
        this.notificationService.error(
          'Invalid or expired code, please try reseting password again'
        );
        return '';
      }
    });
  }

  confirmNewPassword(newPasswordReq: Model.NewPasswordRequest): Promise<void> {
    return this.isBusyService.add(
      () =>
        confirmPasswordReset(
          this.fireAuth,
          newPasswordReq.code,
          newPasswordReq.password
        ),
      undefined,
      'Invalid or expired code, please try reseting password again'
    );
  }

  async confirmEmail(code: string): Promise<boolean> {
    return await this.isBusyService.add(async () => {
      try {
        await applyActionCode(this.fireAuth, code);
        await this.fireAuth.currentUser?.reload();
        this.currentFirebaseUser$.next(this.fireAuth.currentUser);
        return true;
      } catch {
        this.notificationService.error(
          'Invalid or expired code, please contact support'
        );
        return false;
      }
    });
  }

  async setClaimsForOrcaAdmin(claims: Model.SetClaimsRequest): Promise<string> {
    return await this.isBusyService.add(async () => {
      try {
        await this.setCustomClaims(claims);
        return await this.refreshToken(claims.claims);
      } catch {
        this.notificationService.error('Invalid permissions');
        return null;
      }
    });
  }

  async setClaimsForRegularUsers(
    onAuthReq: Model.AuthenticatedRequest,
    authUser: Model.User
  ): Promise<string> {
    return await this.isBusyService.add(async () => {
      try {
        await firstValueFrom(this.onAuthenticated(onAuthReq));
        const claims = {
          fleetId: authUser.fleetId,
          shipId: authUser.shipId,
          subFleetShipsId: authUser.subFleetShipsId ?? null,
          subFleetId: authUser.subFleetId ?? null,
          subFleetIds: authUser.subFleetIds ?? null,
        };
        const token = await this.refreshToken(claims);
        return token;
      } catch {
        this.notificationService.error('Invalid permissions');
        return null;
      }
    });
  }

  async logout(): Promise<void> {
    await signOut(this.fireAuth);
  }

  logoutFromAllOpenTab(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      window.localStorage.setItem('logout-event', Math.random().toString());
      resolve();
    });
  }

  async refreshToken(claims: {
    [key: string]: string | number | number[] | null;
  }): Promise<string> {
    this.fireAuth.currentUser?.refreshToken;
    // the new token isnt auto contains the new claims, one should refresh it, problem is, firebase has its own mech to incoporate the claims into the token and only guarantees
    // the new claims will be after the user re logs, so we need some kind of mech to wait untill they incoporate the new claims into the token before sending any new requests.

    // our mech is take the first value of -
    // every 1 sec try to get the new token, filter out any token that doesnt include all claims that were requested, e.g
    // we requested fleetId: 10 and shipId: 42, all tokens with other data doesnt count, untill we get the correct token
    // success
    return await firstValueFrom(
      interval(1000).pipe(
        concatMap(_ => from(this.fireAuth.currentUser!.getIdTokenResult(true))),
        filter(tokenRes => {
          const allClaimsEqual = Object.keys(claims)
            .map(claim => isEqual(tokenRes.claims[claim], claims[claim]))
            .every(wasClaimFound => wasClaimFound === true);
          return allClaimsEqual;
        }),
        map(tokenRes => tokenRes.token)
      )
    );
  }

  private startStorageEventListener(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event?.key && event.key == 'logout-event') {
        this.store.dispatch(AuthenticationActions.logoutFromAllTab());
      }
    }
  }

  private stopStorageEventListener(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  ngOnDestroy() {
    this.stopStorageEventListener();
  }
}
