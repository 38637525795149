import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { from, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Ship } from 'src/app/core/models/fleet.model';
import {
  SubFleet,
  UpdateSubFleetDto,
} from 'src/app/core/models/subfleet.models';
import { FleetService } from 'src/app/core/services/fleet.service';
import { SubFleetService } from 'src/app/core/services/subfleet.service';
import { FormDialogComponent } from 'src/app/shared/components/form-dialog/form-dialog.component';
import { SelectSearchDirective } from 'src/app/shared/directives/select-search.directive';
@Component({
  selector: 'app-edit-subfleet',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormDialogComponent,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    SelectSearchDirective,
  ],
  templateUrl: './edit-subfleet.component.html',
  styleUrls: ['./edit-subfleet.component.scss'],
})
export class EditSubfleetComponent implements OnInit {
  subFleet: SubFleet;
  subfleetForm: FormGroup;

  ships$!: Observable<Ship[]>;
  searchBoxShipsControl = new FormControl('');

  constructor(
    private fb: FormBuilder,
    private fleetsService: FleetService,
    private subfleetService: SubFleetService,
    @Inject(MAT_DIALOG_DATA) public data: SubFleet
  ) {
    this.subFleet = data;
    this.subfleetForm = this.fb.group({
      subFleetName: [data.subFleetName, Validators.required],
      shipsList: [data.shipsList, [Validators.required]],
    });
  }

  async ngOnInit() {
    this.ships$ = from(this.fleetsService.getShips$(this.subFleet.fleetId));

    this.subfleetForm.patchValue({
      subFleetName: this.subFleet.subFleetName,
      shipsList: this.subFleet.shipsList || [],
    });
  }

  onSubmitSubfleet(updateSubFleetForm: UpdateSubFleetDto): Observable<boolean> {
    return from(
      this.subfleetService.update(this.subFleet.subFleetId, updateSubFleetForm)
    ).pipe(
      map(() => true),
      catchError((error: any) => {
        throw error.message;
      })
    );
  }
}
