import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  concat,
  concatAll,
  concatMap,
  distinctUntilChanged,
  EMPTY,
  filter,
  from,
  map,
  mergeMap,
  of,
  pairwise,
  switchMap,
  toArray,
  withLatestFrom,
} from 'rxjs';
import { DateFilterKeys } from 'src/app/shared/view-models/dates.view.model';
import { DownloadedEvent } from '../../models/events.model';
import { EventsService } from '../../services/events.service';
import { ExportService } from '../../services/export.service';
import { RoutingService } from '../../services/routing.service';
import {
  appliedFiltersComparar,
  calculateJsonDelta,
  mapEventTODownloadedEvent,
  mapEventToEventsType,
} from '../../utils/events';
import { defaultAppliedEventFilters } from '../../view-models/filter.view.model';
import { CoreState } from '../state/core.state';
import {
  AuthenticationActions,
  AuthenticationSelectors,
  EventsActions,
  EventsSelectors,
  FiltersActions,
  FiltersSelectors,
  ShipsSelectors,
} from '../types';

@Injectable()
export class EventsEffects {
  constructor(
    private actions$: Actions,
    private eventsService: EventsService,
    private routingService: RoutingService,
    private exportService: ExportService,
    private store: Store<CoreState>
  ) {}

  onAuthUserGetEventFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateTokenOnRegularUserSetClaims),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([_, dates]) =>
        this.eventsService
          .getEventsFilterValues$(dates)
          .pipe(
            switchMap(eventFilters =>
              of(EventsActions.saveEventFiltersOnAuth({ eventFilters }))
            )
          )
      )
    );
  });

  onUpdateTokenGetEventFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateUserOnAdminSetClaims),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([_, dates]) =>
        this.eventsService
          .getEventsFilterValues$(dates)
          .pipe(
            map(eventFilters =>
              EventsActions.saveEventsFilterOnFleetChange({ eventFilters })
            )
          )
      )
    );
  });

  onDateFilterChangeGetDynamicFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FiltersActions.saveSelectedDatesFilter,
        FiltersActions.resetDateFilterToDefault
      ),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([_, dates]) => {
        return from(this.eventsService.getEventsFilterValues$(dates)).pipe(
          map(eventFilters =>
            EventsActions.saveEventFiltersOnDateFilterChange({ eventFilters })
          )
        );
      })
    );
  });

  resetEventFiltersToDefaultOnAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.saveEventFiltersOnAuth),
      switchMap(action => {
        const defaultAppliedFilters =
          this.eventsService.setAppliedFiltersToDefault(action.eventFilters);
        return of(
          EventsActions.initAllFiltersOnAuth({
            appliedFilters: defaultAppliedFilters,
          })
        );
      })
    );
  });

  resetEventFiltersToDefaultOnFleetChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.saveEventsFilterOnFleetChange),
      switchMap(action => {
        const defaultAppliedFilters =
          this.eventsService.setAppliedFiltersToDefault(action.eventFilters);
        return of(
          EventsActions.resetAllFiltersOnFleetChange({
            appliedFilters: defaultAppliedFilters,
          })
        );
      })
    );
  });

  resetEventFiltersToDefaultOnDateFilterChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.saveEventFiltersOnDateFilterChange),
      switchMap(action => {
        const defaultAppliedFilters =
          this.eventsService.setAppliedFiltersToDefault(action.eventFilters);
        return of(
          EventsActions.resetAllFiltersOnDateFilterChange({
            appliedFilters: defaultAppliedFilters,
          })
        );
      })
    );
  });

  getAllEventsDataOnAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.initAllFiltersOnAuth),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectSortBy),
            this.store.select(EventsSelectors.selectEventsDateFilter)
          )
        )
      ),
      switchMap(([action, sortBy, datesFilter]) =>
        from(
          this.eventsService.getEventsData$(
            action.appliedFilters,
            sortBy,
            datesFilter.dates
          )
        ).pipe(
          map(eventsData => {
            return EventsActions.saveEventsDataOnAuth({ eventsData });
          })
        )
      )
    );
  });

  getAllEventsDataOnFleedIdChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.resetAllFiltersOnFleetChange),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectSortBy),
            this.store.select(EventsSelectors.selectEventsDateFilter)
          )
        )
      ),
      switchMap(([action, sortBy, datesFilter]) =>
        from(
          this.eventsService.getEventsData$(
            action.appliedFilters,
            sortBy,
            datesFilter.dates
          )
        ).pipe(
          map(eventsData => {
            return EventsActions.saveEventsDataOnFleetIdChange({ eventsData });
          })
        )
      )
    );
  });

  getAllEventsDataOnDateFilterChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.resetAllFiltersOnDateFilterChange),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectSortBy),
            this.store.select(EventsSelectors.selectEventsDateFilter)
          )
        )
      ),
      switchMap(([action, sortBy, datesFilter]) =>
        from(
          this.eventsService.getEventsData$(
            action.appliedFilters,
            sortBy,
            datesFilter.dates
          )
        ).pipe(
          map(eventsData => {
            return EventsActions.saveEventsDataOnDateFilterChange({
              eventsData,
            });
          })
        )
      )
    );
  });

  setEventAndDateFiltersUrlParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.setEventAndDateFilterByUrlParams),
      concatMap(action => {
        const dateFilter = action.dateFilter;
        const appliedFiltersToUpdate = action.appliedFiltersToUpdate;

        return of(
          FiltersActions.setDateFilterByUrlParams({ dateFilter }),
          EventsActions.getEventFilterByUrlParams({
            appliedFiltersToUpdate,
            dateFilter,
          })
        );
      })
    );
  });

  getEventAndDateFiltersUrlParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.getEventFilterByUrlParams),
      switchMap(action => {
        const dates = Object.values(action.dateFilter)[0];
        return this.eventsService.getEventsFilterValues$(dates).pipe(
          switchMap(eventFilters => {
            const defaultAppliedFilters =
              this.eventsService.setAppliedFiltersToDefault(eventFilters);
            return of(
              EventsActions.setEventFilterByUrlParams({
                eventFilters: eventFilters,
                appliedEventFilters: {
                  ...defaultAppliedFilters,
                  ...action.appliedFiltersToUpdate,
                },
                dates: dates,
              })
            );
          })
        );
      })
    );
  });

  getEventFilterByInsights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.setEventFilterByUrlParams),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.select(EventsSelectors.selectSortBy))
        )
      ),
      distinctUntilChanged(),
      switchMap(([action, sortBy]) =>
        from(
          this.eventsService.getEventsData$(
            action.appliedEventFilters,
            sortBy,
            action.dates
          )
        ).pipe(
          switchMap(eventsData => {
            const selectedEvent =
              eventsData.events && eventsData.events.length > 0
                ? mapEventToEventsType(eventsData.events[0])
                : null;
            return of(selectedEvent).pipe(
              map(selectedEvent => {
                return EventsActions.saveEventsDataOnNavigateFromInsight({
                  eventsData: eventsData,
                  selectedEvent: selectedEvent,
                });
              })
            );
          })
        )
      )
    );
  });

  getAllEventsDataOnResetToDefault$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.resetAllFiltersOnUserPress),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectSortBy),
            this.store.select(EventsSelectors.selectEventsDateFilter)
          )
        )
      ),
      switchMap(([action, sortBy, datesFilter]) =>
        from(
          this.eventsService.getEventsData$(
            action.appliedFilters,
            sortBy,
            datesFilter.dates
          )
        ).pipe(
          map(eventsData => {
            return EventsActions.saveEventsDataResetToDefaultAppliedFilters({
              eventsData,
            });
          })
        )
      )
    );
  });

  getOverviewEventsOnFleetIdChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateUserOnAdminSetClaims),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([_, dateFilter]) =>
        from(
          this.eventsService.getEvents$(
            defaultAppliedEventFilters,
            'severity',
            dateFilter!,
            1,
            5
          )
        ).pipe(
          map(events =>
            EventsActions.saveOverviewEventsOnFleetIdChange({ events })
          )
        )
      )
    );
  });

  getOverviewEventsOnDateFilterChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FiltersActions.saveSelectedDatesFilter,
        FiltersActions.resetDateFilterToDefault
      ),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectAppliedPresetInOverview)
          )
        )
      ),
      switchMap(([action, preset]) => {
        const dateKey = Object.keys(action.dateFilter)[0] as DateFilterKeys;
        const dates = action.dateFilter[dateKey]!;
        return from(
          this.eventsService.getEvents$(
            preset ? preset.filters : defaultAppliedEventFilters,
            'severity',
            dates,
            1,
            5
          )
        ).pipe(
          map(events =>
            EventsActions.saveOverviewEventsOnDateFilterChange({ events })
          )
        );
      })
    );
  });

  getOverviewEventsOnAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.updateTokenOnRegularUserSetClaims),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectAppliedPresetInOverview),
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([action, preset, datesFilter]) => {
        const filters = preset ? preset.filters : defaultAppliedEventFilters;
        return from(
          this.eventsService.getEvents$(filters, 'date', datesFilter, 1, 5)
        ).pipe(
          map(events =>
            EventsActions.saveOverviewEventsOnPresetChange({ events })
          )
        );
      })
    );
  });

  getAllEventsSortedBy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.saveSortByOptionOnUserChange),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectAppliedEventFilters),
            this.store.select(EventsSelectors.selectEventsDateFilter)
          )
        )
      ),
      switchMap(([action, appliedFilters, eventsDateFilter]) =>
        from(
          this.eventsService.getEvents$(
            appliedFilters,
            action.sortBy,
            eventsDateFilter.dates
          )
        ).pipe(
          map(events =>
            EventsActions.saveSortedEventsOnUserChange({
              events,
            })
          )
        )
      )
    );
  });

  getMoreEventsOnScrolling$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.getMoreEventsOnUserScroll),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectAppliedEventFilters),
            this.store.select(EventsSelectors.selectEventsData)
          )
        )
      ),
      switchMap(([_, appliedFilters, eventsData]) => {
        if (eventsData.totalCount > eventsData.events.length) {
          return from(
            this.eventsService.getEvents$(
              appliedFilters,
              eventsData.sortby,
              eventsData.eventsDateFilter.dates,
              eventsData.pageNumber
            )
          ).pipe(
            map(events => {
              if (events.length > 0) {
                return EventsActions.addMoreEventsAfterScroll({
                  events,
                });
              } else {
                return EventsActions.reachedEndOfEvents();
              }
            })
          );
        } else {
          return of(EventsActions.reachedEndOfEvents());
        }
      })
    );
  });

  resetEventsPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EventsActions.saveSortByOptionOnUserChange,
        EventsActions.resetAllFiltersOnUserPress,
        EventsActions.saveEventsFilterOnFleetChange,
        EventsActions.resetAllFiltersOnDateFilterChange,
        EventsActions.updateEventsDateFilterOnUserClick,
        EventsActions.resetEventsDateFilterOnClear,
        EventsActions.saveEventsOnFilterChange
      ),
      switchMap(_ => of(EventsActions.resetPagination()))
    );
  });

  resetEventsScroll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EventsActions.saveSortByOptionOnUserChange,
        EventsActions.resetAllFiltersOnUserPress,
        EventsActions.saveEventsFilterOnFleetChange,
        EventsActions.resetAllFiltersOnDateFilterChange,
        EventsActions.updateEventsDateFilterOnUserClick,
        EventsActions.resetEventsDateFilterOnClear,
        EventsActions.clearEventsListSubFilters
      ),
      switchMap(_ => of(EventsActions.resetScroll()))
    );
  });

  updateEventsDateFilterOnGeneralDateFilterChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FiltersActions.saveSelectedDatesFilter,
        FiltersActions.resetDateFilterToDefault
      ),
      concatMap(action => {
        const dateKey = Object.keys(action.dateFilter)[0] as DateFilterKeys;
        const dates = action.dateFilter[dateKey]!;
        return of(
          EventsActions.updateEventsDateFilterOnGeneralDateFilterChange({
            dates,
          })
        );
      })
    );
  });

  resetEventsDateFilterOnClear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EventsActions.resetEventsDateFilterOnClear,
        EventsActions.clearEventsListSubFilters
      ),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([action, dates]) =>
        of(
          EventsActions.updateToGeneralDateFilterOnClear({
            dates: dates!,
          })
        )
      )
    );
  });

  getEventsOnEventsDateFilterChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.updateEventsDateFilterOnUserClick),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectAppliedEventFilters),
            this.store.select(EventsSelectors.selectSortBy)
          )
        )
      ),
      switchMap(([action, appliedFilters, sortby]) =>
        from(
          this.eventsService.getEventsAndCount$(
            appliedFilters,
            sortby,
            action.eventDayFilter.dates
          )
        ).pipe(
          map(eventsData =>
            EventsActions.getEventsOnEventsDateFilter({ eventsData })
          )
        )
      )
    );
  });

  getEventsOnResetEventsDateFilter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.updateToGeneralDateFilterOnClear),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectAppliedEventFilters),
            this.store.select(EventsSelectors.selectSortBy)
          )
        )
      ),
      switchMap(([action, appliedFilters, sortby]) =>
        from(
          this.eventsService.getEventsAndCount$(
            appliedFilters,
            sortby,
            action.dates
          )
        ).pipe(
          map(eventsData =>
            EventsActions.getEventsOnResetEventsDateFilter({ eventsData })
          )
        )
      )
    );
  });

  setSelectedMapEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.setSelectedEventsMapEvent),
      distinctUntilChanged(),
      switchMap(action =>
        from(this.eventsService.getEventById(action.eventId)).pipe(
          filter(event => event != null),
          map(event => EventsActions.gotSelectedMapEvent({ event: event! }))
        )
      )
    );
  });

  updateSelectedEventOnMapEventSelected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.gotSelectedMapEvent),
      concatMap(action => {
        const event = mapEventToEventsType(action.event);
        return of(
          EventsActions.updateSelectedEventOnMapSelectedEvent({
            event,
          })
        );
      })
    );
  });

  getEventAndAddToList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.getEventAndAddToList),
      switchMap(action =>
        from(this.eventsService.getEventById(action.eventId)).pipe(
          filter(event => event != null)
        )
      ),
      concatMap(event =>
        of(EventsActions.addSelectedEventToList({ event: event! }))
      )
    );
  });

  updateEventsOnClearMapAndDayFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.clearEventsListSubFilters),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectAppliedEventFilters),
            this.store.select(EventsSelectors.selectSortBy),
            this.store.select(FiltersSelectors.selectFilteredDates)
          )
        )
      ),
      switchMap(([_, appliedFilters, sortby, dateFilters]) =>
        from(
          this.eventsService.getEventsAndCount$(
            appliedFilters,
            sortby,
            dateFilters!
          )
        ).pipe(
          map(eventsData =>
            EventsActions.getEventsOnClearMapAndDayFilters({ eventsData })
          )
        )
      )
    );
  });

  setDefaultSelectedEventOnEventsFiltersChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EventsActions.saveSortedEventsOnUserChange,
        EventsActions.saveEventsDataResetToDefaultAppliedFilters,
        EventsActions.getEventsOnEventsDateFilter,
        EventsActions.getEventsOnResetEventsDateFilter,
        EventsActions.clearEventsListSubFilters,
        EventsActions.saveEventsOnFilterChange
      ),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.select(EventsSelectors.selectEvents))
        )
      ),
      concatMap(([_, events]) => {
        const selectedEvent = events && events.length > 0 ? events[0] : null;
        return of(
          EventsActions.setDefaultSelectedEventOnEventsListChange({
            selectedEvent,
          })
        );
      })
    );
  });

  setDefaultSelectedEventOnDateAndFleetFilterChangeOnOverviewPage$ =
    createEffect(() => {
      return this.actions$.pipe(
        ofType(
          EventsActions.saveOverviewEventsOnDateFilterChange,
          EventsActions.saveOverviewEventsOnFleetIdChange
        ),
        concatMap(action =>
          of(action).pipe(withLatestFrom(this.routingService.getCurrentPage$()))
        ),
        filter(([action, currentPage]) => currentPage === 'Overview'),
        map(([action, currentPage]) => {
          const selectedEvent =
            action.events && action.events.length > 0
              ? mapEventToEventsType(action.events[0])
              : null;
          return EventsActions.setDefaultSelectedEventOnGlobalFiltersChange({
            selectedEvent,
          });
        })
      );
    });

  setDefaultSelectedEventOnDateAndFleetFilterChangeOnShipProfile$ =
    createEffect(() => {
      return this.actions$.pipe(
        ofType(
          EventsActions.saveOverviewEventsOnDateFilterChange,
          EventsActions.saveOverviewEventsOnFleetIdChange
        ),
        concatMap(action =>
          of(action).pipe(
            withLatestFrom(
              this.store.select(EventsSelectors.selectShipPreset),
              this.store.select(ShipsSelectors.selectShips),
              this.routingService.getCurrentShipId$(),
              this.routingService.getCurrentPage$()
            )
          )
        ),
        filter(
          ([action, selectedPreset, ships, currentShipId, currentPage]) =>
            currentPage === 'Ship'
        ),
        concatMap(async ([action, selectedPreset, ships, currentShipId]) => {
          const foundShip = ships.find(ship => ship.shipId === currentShipId);
          return EventsActions.setPresetFilterInShip({
            selectedPresetFilter: selectedPreset,
            shipName: foundShip?.shipName || '',
          });
        })
      );
    });

  setDefaultSelectedEventOnDateAndFleetFilterChangeOnNavigate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          EventsActions.saveOverviewEventsOnDateFilterChange,
          EventsActions.saveOverviewEventsOnFleetIdChange
        ),
        concatMap(action =>
          of(action).pipe(
            withLatestFrom(
              this.store.select(EventsSelectors.selectShipPreset),
              this.store.select(ShipsSelectors.selectShips),
              this.routingService.getCurrentShipId$(),
              this.routingService.getCurrentPage$()
            )
          )
        ),
        filter(
          ([action, selectedPreset, ships, currentShipId, currentPage]) =>
            currentPage === 'Ship'
        ),
        concatMap(async ([action, selectedPreset, ships, currentShipId]) => {
          const foundShip = ships.find(ship => ship.shipId === currentShipId);
          return EventsActions.setPresetFilterInShip({
            selectedPresetFilter: selectedPreset,
            shipName: foundShip?.shipName || '',
          });
        })
      );
    }
  );

  setDefaultSelectedEventOnDateAndFleetFilterChangeOnEventsPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          EventsActions.saveEventsDataOnFleetIdChange,
          EventsActions.saveEventsDataOnDateFilterChange
        ),
        concatMap(action =>
          of(action).pipe(withLatestFrom(this.routingService.getCurrentPage$()))
        ),
        filter(([action, currentPage]) => currentPage === 'Events'),
        map(([action, currentPage]) => {
          const selectedEvent =
            action.eventsData.events && action.eventsData.events.length > 0
              ? mapEventToEventsType(action.eventsData.events[0])
              : null;
          return EventsActions.setDefaultSelectedEventOnGlobalFiltersChange({
            selectedEvent,
          });
        })
      );
    }
  );

  downloadKeplerFile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.downloadKeplerFile),
        switchMap(action =>
          from(this.eventsService.downloadKepler(action.url)).pipe(
            filter(blob => blob !== null),
            switchMap(blob => {
              return of(this.exportService.exportToFile(blob, 'Kepler.html'));
            })
          )
        )
      );
    },
    {
      dispatch: false,
    }
  );

  onExportEvents$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.onExportFile),
        switchMap(action =>
          of(action).pipe(
            withLatestFrom(
              this.store.select(EventsSelectors.selectAppliedEventFilters),
              this.store.select(EventsSelectors.selectEventsDateFilter),
              this.store.select(EventsSelectors.selectSortBy),
              this.store.select(EventsSelectors.selectEventsCount)
            )
          )
        ),
        switchMap(
          ([_, appliedFilters, eventsDateFilter, sortBy, eventsCount]) => {
            const numberOfEventsToFetch = 4000;
            const numberOfPages = Math.ceil(
              eventsCount / numberOfEventsToFetch
            );
            return concat(
              ...Array.from({ length: numberOfPages }, (_, index) =>
                this.eventsService.getEvents$(
                  appliedFilters,
                  sortBy,
                  eventsDateFilter.dates,
                  index + 1,
                  numberOfEventsToFetch
                )
              )
            ).pipe(
              concatAll(),
              toArray(),
              map(events => {
                const modifiedEvents = events.map(
                  (event): DownloadedEvent => mapEventTODownloadedEvent(event)
                );
                const blob = this.exportService.convertToCsv(modifiedEvents);
                return of(this.exportService.exportToFile(blob, 'Events.csv'));
              })
            );
          }
        )
      );
    },
    {
      dispatch: false,
    }
  );

  onEventsFilterChangeGetEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EventsActions.onEventFilterChange,
        EventsActions.applyFiltersOnPresetFilterChange
      ),
      map(action => action.updatedAppliedFilters),
      distinctUntilChanged((prevAppliedFilters, currAppliedFilters) =>
        appliedFiltersComparar(prevAppliedFilters, currAppliedFilters)
      ),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectSortBy),
            this.store.select(EventsSelectors.selectEventsDateFilter),
            this.store.select(EventsSelectors.selectAppliedEventFilters)
          )
        )
      ),
      switchMap(([_, sortBy, datesFilter, appliedFilters]) =>
        this.eventsService
          .getEventsAndCount$(appliedFilters, sortBy, datesFilter.dates)
          .pipe(
            map(eventsData => {
              return EventsActions.saveEventsOnFilterChange({ eventsData });
            })
          )
      )
    );
  });

  onEventsFilterChangeGetEventsLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EventsActions.onEventFilterChange,
        EventsActions.applyFiltersOnPresetFilterChange
      ),
      map(action => action.updatedAppliedFilters),
      distinctUntilChanged((prevAppliedFilters, currAppliedFilters) =>
        appliedFiltersComparar(prevAppliedFilters, currAppliedFilters)
      ),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectAppliedEventFilters),
            this.store.select(EventsSelectors.selectEventsDateFilter)
          )
        )
      ),
      switchMap(([_, appliedFilters, datesFilter]) =>
        this.eventsService
          .getEventsLocation$(appliedFilters, datesFilter.dates)
          .pipe(
            map(eventsLocation =>
              EventsActions.saveEventsLocation({ eventsLocation })
            )
          )
      )
    );
  });

  onEventsFilterChangeReportDelta$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EventsActions.onEventFilterChange,
        EventsActions.applyFiltersOnPresetFilterChange
      ),
      map(action => action.updatedAppliedFilters),
      pairwise(),
      map(([prevFilters, currFilters]) =>
        EventsActions.filterChanged({
          filterChanges: {
            delta: calculateJsonDelta(prevFilters, currFilters),
          },
        })
      )
    );
  });

  onSavePresetFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.savePresetFilters),
      // eslint-disable-next-line @ngrx/prefer-concat-latest-from
      withLatestFrom(
        this.store.select(EventsSelectors.selectAppliedEventFilters),
        this.store.select(AuthenticationSelectors.selectAuthenticatedUser)
      ),
      switchMap(([action, appliedFilters, user]) =>
        from(
          this.eventsService.savePresetFilters(
            user!,
            appliedFilters,
            action.presetName
          )
        ).pipe(
          map(presetFilter =>
            EventsActions.setPresetFilterOnCreation({
              selectedPresetFilter: presetFilter,
            })
          ),
          catchError(err => {
            console.error('Error while saving preset filter', err);
            return EMPTY;
          })
        )
      )
    );
  });

  registerToPresetsOnPresetsDbOnAuth$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.saveAuthUser),
        filter(action => action.authUser != null),
        switchMap(action =>
          of(
            this.eventsService.registerToPresetsInPresetsDb(
              action.authUser!,
              presets =>
                // eslint-disable-next-line @ngrx/no-dispatch-in-effects
                this.store.dispatch(
                  EventsActions.loadPresetsFromDb({ presets })
                )
            )
          )
        )
      );
    },
    {
      dispatch: false,
    }
  );

  registerToSelectedPresetOnPresetsDbOnAuth$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.saveAuthUser),
        filter(action => action.authUser != null),
        switchMap(action =>
          of(
            this.eventsService.registerToSelectedPresetInPresetsDb(
              action.authUser!,
              selectedPresetFilter =>
                // eslint-disable-next-line @ngrx/no-dispatch-in-effects
                this.store.dispatch(
                  EventsActions.loadSelectedPresetFromDb({
                    selectedPresetFilter,
                  })
                )
            )
          )
        )
      );
    },
    {
      dispatch: false,
    }
  );

  applyPresetFilterOnUserChangeInEventsPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.setPresetFilterInEvents),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.select(EventsSelectors.selectEventsFilters))
        )
      ),
      switchMap(([action, eventFilters]) => {
        if (action.selectedPresetFilter !== undefined) {
          return of(
            EventsActions.applyFiltersOnPresetFilterChange({
              updatedAppliedFilters: {
                ...action.selectedPresetFilter!.filters,
              },
            })
          );
        } else {
          const resetedFilters =
            this.eventsService.setAppliedFiltersToDefault(eventFilters);
          return of(
            EventsActions.applyFiltersOnPresetFilterChange({
              updatedAppliedFilters: { ...resetedFilters },
            })
          );
        }
      })
    );
  });

  setPresetFilterToDefault$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EventsActions.resetAllFiltersOnUserPress,
        EventsActions.resetAllFiltersOnFleetChange,
        EventsActions.resetAllFiltersOnDateFilterChange
      ),
      switchMap(_ => of(EventsActions.setPresetFilterToDefault()))
    );
  });

  setOverviewPresetToDefault$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.resetAllFiltersOnFleetChange),
        concatLatestFrom(() =>
          this.store.select(AuthenticationSelectors.selectAuthenticatedUser)
        ),
        switchMap(([action, user]) => {
          return this.eventsService.saveSelectedPreset(user!, undefined);
        })
      );
    },
    { dispatch: false }
  );

  applyPresetFilterOnUserChangeInOverviewPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.setPresetFilterInOverview),
      // eslint-disable-next-line @ngrx/prefer-concat-latest-from
      withLatestFrom(
        this.store.select(AuthenticationSelectors.selectAuthenticatedUser)
      ),
      switchMap(([action, user]) => {
        if (action.selectedPresetFilter !== undefined) {
          return from(
            this.eventsService.saveSelectedPreset(
              user!,
              action.selectedPresetFilter
            )
          ).pipe(
            map(presetFilter => {
              return EventsActions.changeEventsOnOverviewPresetChange({
                updatedAppliedFilters: presetFilter!.filters,
              });
            })
          );
        } else {
          return from(
            this.eventsService.saveSelectedPreset(user!, undefined)
          ).pipe(
            map(() =>
              EventsActions.changeEventsOnOverviewPresetChange({
                updatedAppliedFilters: { ...defaultAppliedEventFilters },
              })
            )
          );
        }
      })
    );
  });

  onEventsFilterChangeGetEventsInOverview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.setPresetFilterInOverview),
      map(action => action.selectedPresetFilter),
      switchMap(preset =>
        of(preset).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectEventsDateFilter)
          )
        )
      ),
      switchMap(([preset, datesFilter]) => {
        const filters = preset ? preset.filters : defaultAppliedEventFilters;
        return from(
          this.eventsService.getEvents$(
            filters,
            'severity',
            datesFilter.dates,
            1,
            5
          )
        ).pipe(
          map(events =>
            EventsActions.saveOverviewEventsOnPresetChange({ events })
          )
        );
      })
    );
  });

  onEventsFilterChangeGetEventsInShip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.setPresetFilterInShip),
      mergeMap(action => {
        const preset = action.selectedPresetFilter;
        const shipName = action.shipName;
        return of({ preset, shipName });
      }),
      switchMap(({ preset, shipName }) =>
        this.store
          .select(EventsSelectors.selectEventsDateFilter)
          .pipe(map(datesFilter => ({ preset, shipName, datesFilter })))
      ),
      switchMap(({ preset, shipName, datesFilter }) => {
        const filters = preset ? preset.filters : defaultAppliedEventFilters;
        return from(
          this.eventsService.getEventsAndCount$(
            {
              ...filters,
              shipName: { value: shipName },
            },
            'severity',
            datesFilter.dates,
            1
          )
        ).pipe(
          map(eventsData =>
            EventsActions.saveShipEventsOnPresetChange({ eventsData })
          )
        );
      })
    );
  });

  getMoreShipEventsOnScrolling$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsActions.getMoreShipEventsOnUserScroll),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(EventsSelectors.selectAppliedPresetInShip),
            this.store.select(EventsSelectors.selectShipEventsData)
          )
        )
      ),
      mergeMap(([action, presetFilter, eventsData]) => {
        if (eventsData.totalCount > eventsData.events.length) {
          const filters = presetFilter
            ? presetFilter.filters
            : defaultAppliedEventFilters;
          return from(
            this.eventsService.getEvents$(
              {
                ...filters,
                shipName: { value: action.shipName },
              },
              eventsData.sortby,
              eventsData.eventsDateFilter.dates,
              eventsData.pageNumber
            )
          ).pipe(
            map(events => {
              if (events.length > 0) {
                return EventsActions.addMoreShipEventsAfterScroll({
                  events,
                });
              } else {
                return EventsActions.reachedEndOfShipEvents();
              }
            })
          );
        } else {
          return of(EventsActions.reachedEndOfShipEvents());
        }
      })
    );
  });

  onDateFilterUpdateSelectedShipEventsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FiltersActions.saveSelectedDatesFilter,
        FiltersActions.resetDateFilterToDefault
      ),
      withLatestFrom(
        this.store.select(FiltersSelectors.selectFilteredDates),
        this.store.select(EventsSelectors.selectShipEventsSortBy),
        this.store.select(EventsSelectors.selectSelectedShipEventsDataFilter),
        this.store.select(ShipsSelectors.selectSelectedShipName)
      ),
      filter(([, , , , shipName]) => !!shipName),
      switchMap(([_, datesFilter, sortBy, shipEventsDataFilter, shipName]) => {
        return this.eventsService
          .getEventsAndCount$(
            {
              ...shipEventsDataFilter,
              shipName: { value: shipName! },
            },
            sortBy,
            datesFilter,
            1
          )
          .pipe(
            map(eventsData =>
              EventsActions.saveShipEventsDataOnDateFilterChange({
                eventsData,
              })
            )
          );
      })
    );
  });
}
