<div class="body">
  <div [ngStyle]="{ height: isDialogChild ? '570px' : '28vh' }" class="stream">
    <ng-container *ngIf="streamingState$ | async as streamingState">
      <div class="camera-selected">
        <div class="camera-boxes-container">
          <span class="name-box live-box">
            <img class="icon" src="./assets/icons/live_stream.svg" />
            <span class="subtitle1">Live</span>
          </span>
          <span
            class="body1-bold name-box quality-box"
            *ngIf="qualityStatus$ | async as qualityStatus">
            <img
              class="icon"
              [src]="'./assets/icons/' + qualityStatus + '_connectivity.svg'" />
            <span class="subtitle1">{{ qualityStatus }}</span></span
          >
          <span class="body1-bold name-box camera-box">{{
            selectedCameraName$ | async | appendCamera
          }}</span>
        </div>
        <app-video-player
          [streamUrl]="streamingState.streamUrl"
          [cameraControl]="cameraControl"
          (establishedYet)="onEstablish($event)"
          (qualityStatus)="onQualityStatusChange($event)">
        </app-video-player>
        <div
          class="loading-camera"
          *ngIf="(establishedYet$ | async) === false"
          [style.backgroundImage]="backgroundImageStyle">
          <div class="background-overlay" *ngIf="hasBackgroundImage"></div>
          <!-- <div
                class="quality-connectivity-indicator"
                *ngIf="connectivityStatus$ | async as connectivityStatus">
                <img
                  [src]="
                    './assets/icons/' + connectivityStatus + '_connectivity.svg'
                  " />
                <span class="connectivity-text"
                  >{{ connectivityStatus }} Connectivity</span
                >
              </div> -->
          <app-loading-indicator color="white"></app-loading-indicator>
        </div>
      </div>
      <ng-template #NoStreamLive>
        <div class="no-stream-live">
          <img class="live-icon icon" src="./assets/icons/binoculars.svg" />
          <span class="subtitle3">Live video isn’t available right now</span>
          <span class="body1 no-stream-live-text"
            >Streaming will begin once we establish a connection.</span
          >
        </div>
      </ng-template>
    </ng-container>
  </div>
  <div class="cameras" *ngIf="isStreamMaster$ | async as isStreamMaster">
    <div class="cameras-selector" *ngIf="isStreamMaster">
      <ng-container *ngIf="viewCamera$ | async as viewCameras">
        <ng-container *ngIf="!getIsCamerasEmpty(viewCameras)">
          <span class="title-buttons-group subtitle1">Views</span>
          <mat-button-toggle-group
            name="day-cameras"
            [formControl]="cameraControl"
            [value]="cameraControl.value">
            <ng-container *ngFor="let camera of viewCameras">
              <mat-button-toggle [value]="camera.cameraId">{{
                camera.cameraName
              }}</mat-button-toggle>
            </ng-container>
          </mat-button-toggle-group>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="dayCameras$ | async as dayCameras">
        <ng-container *ngIf="!getIsCamerasEmpty(dayCameras)">
          <span class="title-buttons-group subtitle1">Day Cameras</span>
          <mat-button-toggle-group
            name="day-cameras"
            [formControl]="cameraControl"
            [value]="cameraControl.value">
            <ng-container *ngFor="let camera of dayCameras">
              <mat-button-toggle [value]="camera.cameraId">{{
                camera.cameraName
              }}</mat-button-toggle>
            </ng-container>
          </mat-button-toggle-group>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="thermalCameras$ | async as thermalCameras">
        <ng-container *ngIf="!getIsCamerasEmpty(thermalCameras)">
          <span class="title-buttons-group subtitle1">Thermal Cameras</span>
          <mat-button-toggle-group
            name="thermal-cameras"
            [formControl]="cameraControl"
            [value]="cameraControl.value">
            <ng-container *ngFor="let camera of thermalCameras">
              <mat-button-toggle [value]="camera.cameraId">{{
                camera.cameraName
              }}</mat-button-toggle>
            </ng-container>
          </mat-button-toggle-group>
        </ng-container>
      </ng-container>
    </div>
    <div class="uncontroll" *ngIf="(isStreamMaster$ | async) === false">
      <img class="icon" src="./assets/icons/exclamation_mark.svg" />
      <span class="subtitle1">
        Another user is currently connected and controlling this video.</span
      >
    </div>
  </div>
</div>
