<app-no-data [dataExists]="dataExists$ | async">
  <div class="container">
    <app-widget-title
      class="header"
      [widgetTitleType]="'trendTitle'"
      [title]="'Ship’s Avg. Safety Score'"
      [avgScore$]="shipAverageSafetyScore$"
      [secendAvgScore$]="fleetAverageSafetyScore$"
      [showEventsDayFilterTitle$]="showEventsDayFilterTitle$"
      [selectedDateRange$]="selectedDateRange$"
      [startDateAvgSeverityScore$]="startDateAvgShipSafetyScore$"
      [endDateAvgSeverityScore$]="endDateAvgShipSafetyScore$"
      [isShipAvg]="true">
    </app-widget-title>
    <div class="graph-container">
      <ngx-charts-line-chart
        class="primary-line-chart"
        [results]="shipSafetyScoreByDateView$ | async"
        [xAxis]="true"
        [yAxis]="true"
        [yScaleMin]="yScaleMin"
        [yScaleMax]="yScaleMax"
        [curve]="linearCurve"
        [tooltipDisabled]="false"
        [xAxisTickFormatting]="formatXLabel"
        [xAxisTicks]="(xAxisTicks$ | async)!">
        <ng-template
          #seriesTooltipTemplate
          #tooltipTemplate
          let-safetyScore="model">
          <div class="tooltip-container">
            <div class="score">
              <h2 class="regular">
                {{
                  safetyScore.value || safetyScore[0].value | number : '1.0-0'
                }}
              </h2>
              <span class="subtitle1">/10</span>
            </div>

            <caption>
              {{
                safetyScore.name || safetyScore[0].date | dateToDayMonthTime
              }}
            </caption>
          </div>
        </ng-template>
      </ngx-charts-line-chart>
    </div>
  </div>
</app-no-data>
