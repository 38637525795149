import {
  DayNamesDto,
  ThermalNamesDto,
  ViewStreamNamesDto,
} from '../view-models/event.view.model';
import { ShipSailDataChange } from '../view-models/ship.view-model';
import { AlertNames, CongestionLevelsNames } from './events-filters.model';
import { EventCountByGroup } from './events.model';
import { ShipsSortBy } from './ships-filters.model';

export type ShipType =
  | 'bulk_carrier'
  | 'container_ship'
  | 'crude_oil_tanker'
  | 'lng_tanker'
  | 'lpg_tanker'
  | 'tug'
  | 'roro_vehicle_carrier';

export type EventSeverity = 'high' | 'medium' | 'low';
export type CrewShifts = '8-12' | '12-16' | '16-20' | '20-0' | '0-4' | '4-8';

export interface ShipConnectionDto {
  readonly online: boolean;
}

export interface ShipSafetyScore {
  readonly date: Date;
  readonly safetyScore: number;
}

export interface ShipSafetyScoreDto {
  readonly date: string;
  readonly score: number;
}

export interface ShipSafetyScores {
  readonly shipId: number;
  readonly safetyScores: ShipSafetyScore[];
}

export interface ShipSeverityScoreDto {
  readonly sum_of_excellent_severity: number;
  readonly sum_of_good_severity: number;
  readonly sum_of_moderate_severity: number;
  readonly sum_of_low_severity: number;
  readonly sum_of_na_severity: number;
  readonly avg_score_per_fleet: number;
  readonly date: string;
  readonly orca_risk_score: number;
}

export interface FleetSeverityScoreDto {
  readonly avg_severities: ShipSeverityScoreDto;
  readonly safety_scores: ShipSeverityScoreDto[];
  readonly avg_fleet_safety_score: number;
  readonly avg_orca_safety_score: number;
}

export interface TotalAvgShipsSeverity {
  readonly excellentSeverityShips: number;
  readonly goodSeverityShips: number;
  readonly moderateSeverityShips: number;
  readonly lowSeverityShips: number;
  readonly naSeverityShips: number;
}

export interface ShipSeverityScore extends TotalAvgShipsSeverity {
  readonly avgScore: number;
  readonly date: Date;
}

export interface ShipsSeverityByDate {
  readonly totalAvgShipsSeverity: TotalAvgShipsSeverity;
  readonly shipsSeverityByDate: ShipSeverityScore[];
  readonly avgFleetScore: number;
  readonly avgOrcaScore: number;
  readonly startDate: Date;
  readonly endDate: Date;
}

export interface ShipSafteyScoreBySorting {
  readonly shipsSafetyScore: ShipSafteyScore[];
  readonly sortby: ShipsSortBy;
}

export interface ShipLiveStream {
  readonly fleetId: number | null;
  readonly shipId: number | null;
  readonly cameras: ShipCamera[];
  readonly isStreamMaster: boolean;
  readonly streamUrl: string;
  readonly connectivityStatus: string | null;
  readonly qualityOnLive: string | null;
  readonly sessionId: string | null;
}

export const defaultShipLiveStream: ShipLiveStream = {
  fleetId: null,
  shipId: null,
  cameras: [],
  isStreamMaster: false,
  streamUrl: '',
  connectivityStatus: null,
  qualityOnLive: null,
  sessionId: null,
};

export type CameraStreamingState = 'live' | 'offline';

export interface shipCamerasDto {
  readonly cameras: ShipCameraDto[];
}
export interface ShipCameraDto {
  readonly camera_id: DayNamesDto | ThermalNamesDto;
  readonly streaming_state: CameraStreamingState;
  readonly streaming_started_at: Date;
}

export type ScoringFactorLabel = 'neutral' | 'well_done' | 'needs_improvement';
export interface ShipScoringFactor {
  readonly value: number;
  readonly label: ScoringFactorLabel;
  readonly description: string;
}
export interface ShipScoringFactorDto {
  readonly value: number;
  readonly label: ScoringFactorLabel;
}

export type ScoringFactorDtoKey =
  | 'days_since_last_event'
  | 'num_of_ce_events_per_k_nm_sailed'
  | 'ce_events_in_open'
  | 'ce_events_in_congested'
  | 'ce_events_in_hyper_congested'
  | 'num_of_high_severity_events';

export const scoringFactorsDisplayOrder: ScoringFactorDtoKey[] = [
  'days_since_last_event',
  'num_of_ce_events_per_k_nm_sailed',
  'ce_events_in_open',
  'ce_events_in_congested',
  'ce_events_in_hyper_congested',
  'num_of_high_severity_events',
];

export const scoringFactorDtoKeyToDescription: {
  [key in ScoringFactorDtoKey]: string;
} = {
  days_since_last_event: 'Days since last event',
  num_of_ce_events_per_k_nm_sailed: 'Events for every 1000 NM sailed',
  ce_events_in_open: 'Close Encounter events in low traffic',
  ce_events_in_congested: 'Close Encounter events in congested waters',
  ce_events_in_hyper_congested:
    'Close Encounter events in hyper-congested waters',
  num_of_high_severity_events: 'High severity events',
};

export type ScoringFactorsStats = {
  [key in ScoringFactorDtoKey]: ShipScoringFactor;
};

export type ScoringFactorsStatsDto = {
  [key in ScoringFactorDtoKey]: ShipScoringFactorDto;
};

export interface ShipCamera {
  readonly cameraId: DayNamesDto | ThermalNamesDto | ViewStreamNamesDto;
  readonly isLive: boolean;
  readonly streamingStartedAt: Date;
}

export interface ShipStreamMasterDto {
  readonly isMaster: boolean;
}

export interface ShipStreamDto {
  readonly streamHLS: string;
  readonly isMaster: boolean;
  readonly session_id: string | null;
}

export const defaultShipsSeverityByDate: ShipsSeverityByDate = {
  endDate: new Date(),
  startDate: new Date(),
  shipsSeverityByDate: [],
  avgFleetScore: 0,
  avgOrcaScore: 0,
  totalAvgShipsSeverity: {
    excellentSeverityShips: 0,
    goodSeverityShips: 0,
    moderateSeverityShips: 0,
    lowSeverityShips: 0,
    naSeverityShips: 0,
  },
};

export interface ShipSafteyScoreDto {
  readonly fleet_id: number;
  readonly ship_name: string;
  readonly own_ship_id: number;
  readonly avg_score: number;
  readonly start_time: Date;
  readonly end_time: Date;
  readonly score_start: number;
  readonly score_end: number;
  readonly score_diff: number;
}

export interface ShipSafteyScore {
  readonly shipName: string;
  readonly shipId: number;
  readonly scoreStart: number;
  readonly scoreEnd: number;
  readonly avg_score: number;
}

export const defaultShipsScoreData: ShipSafteyScoreBySorting = {
  shipsSafetyScore: [],
  sortby: 'low',
};

export const defaultShipEventCount: ShipEventCount = {
  totalCount: 0,
  byAlertName: [],
  bySeverity: [],
  byCrewShift: [],
};

export interface ShipDto {
  readonly ship_id: number;
  readonly fleet_id: number;
  readonly ship_name: string;
  readonly host_name: string;
  readonly fleet_name: string;
  readonly last_connection_datetime: string | null;
  readonly latest_event_added_date: string | null;
  readonly textual_location: string | null;
  readonly last_location_timestamp: string | null;
  readonly sog: number | null;
  readonly cog: number | null;
  readonly max_pitch: number | null;
  readonly max_roll: number | null;
  readonly latest_event_added_id: string | null;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly avg_score: number | null;
  readonly score_trend: number | null;
  readonly ship_type: string;
  readonly total_last_sailed_distance: number;
}

export interface Ship {
  readonly shipId: number;
  readonly shipName: string;
  readonly hostName: string;
  readonly fleetId: number;
  readonly fleetName: string;
  readonly lastConnection: Date | null;
  readonly textualLocation: string | null;
  readonly lastLocationTimestamp: string | null;
  readonly sog: number | null;
  readonly cog: number | null;
  readonly maxPitch: number | null;
  readonly maxRoll: number | null;
  readonly latestEventDateAdded: Date | null;
  readonly latestEventaddedId: string | null;
  readonly latitude: number;
  readonly longitude: number;
  readonly safetyScore: number;
  readonly scoreTrend: number;
  readonly shipType: ShipType;
  readonly totalSailedDistance: number;
}

export interface LiveShipDataDto {
  readonly ship_id: number;
  readonly host_name: string;
  readonly last_location_timestamp: string | null;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly created_at: string;
  readonly data_src: string;
  readonly sog: number | null;
  readonly cog: number | null;
  readonly imu_roll: number | null;
  readonly imu_pitch: number | null;
}

export interface LiveShipData {
  readonly shipId: number;
  readonly hostName?: string;
  readonly lastLocationTimestamp?: string | null;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly createdAt?: string;
  readonly dataSrc?: string;
  readonly sog?: number | null;
  readonly cog?: number | null;
  readonly imuRoll?: number | null;
  readonly imuPitch?: number | null;
}

export enum ConnectivityStatus {
  Good = 'Good',
  Medium = 'Medium',
  Low = 'Low',
  No = 'No',
  Unknown = 'Unknown',
}

export enum QualityStatus {
  Good = 'Good',
  Medium = 'Medium',
  Bad = 'Bad',
  Unknown = 'Unknown',
}

export interface PitchAndRollDataDto {
  ship_id: number;
  timestamp_captured: string;
  imu_roll: number;
  imu_pitch: number;
}

export interface PitchAndRollData {
  date: string;
  roll: number;
  pitch: number;
}

export interface SailDataDto {
  readonly month: number;
  readonly year: number;
  readonly avg_sum_of_events: number;
  readonly total_distance_nm: number;
}

interface CeEventsDataDto {
  month: number;
  year: number;
  avg_min_distance: number;
}

export interface SailData {
  readonly month: number;
  readonly year: number;
  readonly avgNumOfEvents: number;
  readonly totalDistance: number;
}

export interface DistanceData {
  readonly month: number;
  readonly year: number;
  readonly avgMinDistance: number;
}

export interface ShipSailDataDto {
  readonly congestion_level: CongestionLevelsNames | null;
  readonly sailing_data: SailDataDto[];
  readonly ce_events_data: CeEventsDataDto[];
}

export interface ShipSailData {
  readonly congestionLevel: CongestionLevelsNames | null;
  readonly alertType: AlertNames | null;
  readonly sailData: SailData[];
  readonly changesInEventsNum: ShipSailDataChange[];
  readonly ceEventsData: DistanceData[];
  readonly changesInCeNum: ShipSailDataChange[];
}

export interface ShipEventCount {
  readonly totalCount: number;
  readonly bySeverity: EventCountByGroup[];
  readonly byAlertName: EventCountByGroup[];
  readonly byCrewShift: EventCountByGroup[];
}
