<app-no-data [dataExists]="dataExists$ | async">
  <div class="container">
    <span class="body1-bold title">Events by crew shifts</span>
    <ng-container
      *ngIf="
        shipEventCountByCrewShiftView$ | async as shipEventCountByCrewShift
      ">
      <div class="graph-wrapper">
        <div class="graph-container">
          <ngx-charts-bar-vertical
            [results]="shipEventCountByCrewShift"
            [xAxis]="true"
            [yAxis]="true"
            [showXAxisLabel]="true"
            [customColors]="colorScheme"
            [showGridLines]="false"
            [yAxisTickFormatting]="formatYAxisLabel"
            [xAxisLabel]="''">
            <ng-template
              #seriesTooltipTemplate
              #tooltipTemplate
              let-shipEventCountByCrewShift="model">
              <div class="tooltip-container">
                <div class="value">
                  {{ shipEventCountByCrewShift.value | number : '1.0-0' }}
                </div>
              </div>
            </ng-template>
          </ngx-charts-bar-vertical>
        </div>
      </div>
    </ng-container>
  </div>
</app-no-data>
