import _ from 'lodash';
import { ShipMapEntity } from 'src/app/mapbox/models/mapbox.models';
import { isWithinLastHours } from 'src/app/shared/utils/calculators/general';

export function getShipIcon(
  ship: ShipMapEntity,
  isSelected: boolean = false
): string {
  const basePath = `/assets/map/ships`;
  const shipType = _.snakeCase(ship.shipType);
  const isConnected = isWithinLastHours(4, ship.lastConnection);
  let shipIcon = '';
  if (isSelected) {
    shipIcon = `${basePath}/${shipType}/${
      isConnected ? 'ship_icon' : 'faded_ship_icon'
    }.png`;
  } else {
    shipIcon = `${basePath}/${isConnected ? 'default' : 'faded_default'}.png`;
  }
  return shipIcon;
}
