import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { EventsRepository } from 'src/app/core/pages/events/events.repository';
import { CoreState } from 'src/app/core/store/state/core.state';
import { EventsActions, EventsSelectors } from 'src/app/core/store/types';
import { AppliedEventFilters } from 'src/app/core/view-models/filter.view.model';
import { CustomSliderOptions } from 'src/app/shared/view-models/slider.options.model';
@Component({
  selector: 'app-ukc-filter',
  templateUrl: './ukc-filter.component.html',
  styleUrls: ['./ukc-filter.component.scss'],
})
export class UkcFilterComponent implements OnInit {
  private readonly eventsRepository = inject(EventsRepository);
  sliderDepth$!: Observable<CustomSliderOptions>;

  appliedUkcFilter$!: Observable<Partial<AppliedEventFilters>>;
  constructor(private store: Store<CoreState>) {}

  ngOnInit(): void {
    this.sliderDepth$ = this.store.select(EventsSelectors.selectSliderDepth);
  }

  async onEventFiltersChange(value: any, key: string): Promise<void> {
    const appliedFilters = await firstValueFrom(
      this.store.select(EventsSelectors.selectAppliedEventFilters)
    );
    const updatedAppliedFilters: AppliedEventFilters = {
      ...appliedFilters,
      [key]: value,
    };
    this.store.dispatch(
      EventsActions.onEventFilterChange({ updatedAppliedFilters })
    );

    this.eventsRepository.applyFilters(updatedAppliedFilters);
  }
}
