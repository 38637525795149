import { environment } from 'src/environments/environment';

export class LoggerService {
  log(message: string, ...args: any[]): void {
    if (!environment.production) {
      console.log(`[DEBUG] ${message}`, ...args);
    }
  }

  warn(message: string, ...args: any[]): void {
    if (!environment.production) {
      console.warn(`[WARN] ${message}`, ...args);
    }
  }

  error(message: string, ...args: any[]): void {
    if (!environment.production) {
      console.error(`[ERROR] ${message}`, ...args);
    }
  }
}
