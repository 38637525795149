<div class="container">
  <ng-container *ngIf="itemTemplate; else defaultTemplate">
    <ng-container *ngFor="let event of events">
      <ng-container
        *ngTemplateOutlet="
          itemTemplate;
          context: {
            $implicit: event,
            selectedEvent: selectedEvent,
            onSelect: onSelectedEvent,
            onExplore: eventExplorationClicked
          }
        ">
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #defaultTemplate let-event>
    <div
      *ngFor="let event of events"
      class="event-card"
      [class.selected]="selectedEvent?.eventId === event.eventId"
      [ngClass]="{ expanded: showTargetEventData }"
      (click)="onSelectedEvent(event)"
      id="event-{{ event.eventId }}">
      <ng-container [ngSwitch]="true">
        <ng-container
          *ngIf="
            event | typeGuard : isCloseEncounterEvent as closeEncounterEvent
          ">
          <div class="event-header">
            <img
              src="assets/icons/close_encounter_event.svg"
              class="event-icon" />
            <div class="event-title">
              <div class="event-labels">
                <div
                  class="severity-{{
                    closeEncounterEvent.severity | lowercase
                  }}">
                  {{ closeEncounterEvent.severity | capitalizeFirstLetter }}
                </div>
                <div class="event-type-label">
                  {{ closeEncounterEvent.type }}
                </div>
              </div>
              <span class="subtitle1">{{ closeEncounterEvent.shipName }}</span>
            </div>
          </div>
          <span class="separator"></span>
          <div class="location-time">
            <span>Location: </span>
            <span class="location-time-value">
              {{ closeEncounterEvent.location }} |
              {{ closeEncounterEvent.timestamp | date : 'dd MMM yyyy HH:mm' }}
            </span>
          </div>
          <div class="event-details">
            <div class="event-data">
              <div class="event-items">
                <div class="data-item">
                  <span class="label">Min Distance</span>
                  <span class="value">
                    {{ closeEncounterEvent.minDistance | number : '1.0-1' }}
                    <span class="unit">NM</span>
                  </span>
                </div>
                <div class="data-item">
                  <span class="label">SOG</span>
                  <span class="value">
                    {{ closeEncounterEvent.sog | number : '1.0-0' }}
                    <span class="unit">Knts</span>
                  </span>
                </div>
                <div class="data-item" *ngIf="showTargetEventData">
                  <span class="label">Target SOG</span>
                  <span class="value">
                    {{ closeEncounterEvent.targetSog | number : '1.0-0' }}
                    <span class="unit">Knts</span>
                  </span>
                </div>
                <div class="data-item" *ngIf="showTargetEventData">
                  <span class="label">Target Type</span>
                  <span class="value">{{
                    closeEncounterEvent.targetType | targetTypeName
                  }}</span>
                </div>
              </div>
              <div class="footer">
                <button
                  mat-stroked-button
                  color="primary"
                  class="explore-button"
                  (click)="onExploreEvent(event)">
                  Explore
                </button>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="
            event | typeGuard : isHighPitchRollEvent as highPitchRollEvent
          ">
          <div class="event-header">
            <img
              src="assets/icons/high_pitch_roll_event.svg"
              class="event-icon" />
            <div class="event-title">
              <div class="event-labels">
                <div
                  class="severity-{{
                    highPitchRollEvent.severity | lowercase
                  }}">
                  {{ highPitchRollEvent.severity | capitalizeFirstLetter }}
                </div>
                <div class="event-type-label">
                  {{ highPitchRollEvent.type }}
                </div>
              </div>
              <span class="subtitle1">{{ highPitchRollEvent.shipName }}</span>
            </div>
          </div>
          <span class="separator"></span>
          <div class="location-time">
            <span>Location: </span>
            <span class="location-time-value">
              {{ highPitchRollEvent.location }} |
              {{ highPitchRollEvent.timestamp | date : 'dd MMM yyyy HH:mm' }}
            </span>
          </div>
          <div class="event-details">
            <div class="event-data">
              <div class="event-items">
                <div class="data-item">
                  <span class="label">Roll</span>
                  <span class="value">
                    {{ highPitchRollEvent.roll | number : '1.0-1' }}°
                  </span>
                </div>
                <div class="data-item">
                  <span class="label">Pitch</span>
                  <span class="value">
                    {{ highPitchRollEvent.pitch | number : '1.0-1' }}°
                  </span>
                </div>
                <div class="data-item">
                  <span class="label">SOG</span>
                  <span class="value">
                    {{ highPitchRollEvent.sog | number : '1.0-0' }}
                    <span class="unit">Knts</span>
                  </span>
                </div>
              </div>
              <div class="footer">
                <button
                  mat-stroked-button
                  color="primary"
                  class="explore-button"
                  (click)="onExploreEvent(event)">
                  Explore
                </button>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="event | typeGuard : isUKCEvent as ukcEvent">
          <div class="event-header">
            <img src="assets/icons/ukc_event.svg" class="event-icon" />
            <div class="event-title">
              <div class="event-labels">
                <div class="severity-{{ ukcEvent.severity | lowercase }}">
                  {{ ukcEvent.severity | capitalizeFirstLetter }}
                </div>
                <div class="event-type-label">{{ ukcEvent.type }}</div>
              </div>
              <span class="subtitle1">{{ ukcEvent.shipName }}</span>
            </div>
          </div>
          <span class="separator"></span>
          <div class="location-time">
            <span>Location: </span>
            <span class="location-time-value">
              {{ ukcEvent.location }} |
              {{ ukcEvent.timestamp | date : 'dd MMM yyyy HH:mm' }}
            </span>
          </div>
          <div class="event-details">
            <div class="event-data">
              <div class="event-items">
                <div class="data-item">
                  <span class="label">Depth</span>
                  <span class="value">
                    {{ ukcEvent.depth | number : '1.0-1' }}
                    <span class="unit">M</span>
                  </span>
                </div>
                <div class="data-item">
                  <span class="label">SOG</span>
                  <span class="value">
                    {{ ukcEvent.sog | number : '1.0-0' }}
                    <span class="unit">Knts</span>
                  </span>
                </div>
              </div>
              <div class="footer">
                <button
                  mat-stroked-button
                  color="primary"
                  class="explore-button"
                  (click)="onExploreEvent(event)">
                  Explore
                </button>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="event | typeGuard : isSpeedDropEvent as speedDropEvent">
          <div class="event-header">
            <img src="assets/icons/speed_drop_event.svg" class="event-icon" />
            <div class="event-title">
              <div class="event-labels">
                <div class="severity-{{ speedDropEvent.severity | lowercase }}">
                  {{ speedDropEvent.severity | capitalizeFirstLetter }}
                </div>
                <div class="event-type-label">
                  {{ speedDropEvent.type }}
                </div>
              </div>
              <span class="subtitle1">{{ speedDropEvent.shipName }}</span>
            </div>
          </div>
          <span class="separator"></span>
          <div class="location-time">
            <span>Location: </span>
            <span class="location-time-value">
              {{ speedDropEvent.location }} |
              {{ speedDropEvent.timestamp | date : 'dd MMM yyyy HH:mm' }}
            </span>
          </div>
          <div class="event-details">
            <div class="event-data">
              <div class="event-items">
                <div class="data-item">
                  <span class="label">Initial SOG</span>
                  <span class="value">
                    {{ speedDropEvent.sog | number : '1.0-0' }}
                    <span class="unit">Knts</span>
                  </span>
                </div>
                <div class="data-item">
                  <span class="label">Speed Drop</span>
                  <span class="value">
                    {{ speedDropEvent.eSog | number : '1.0-0' }}
                    <span class="unit">Knts</span>
                  </span>
                </div>
              </div>
              <div class="footer">
                <button
                  mat-stroked-button
                  color="primary"
                  class="explore-button"
                  (click)="onExploreEvent(event)">
                  Explore
                </button>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="event | typeGuard : isHighRotEvent as highRotEvent">
          <div class="event-header">
            <img src="assets/icons/high_rot_event.svg" class="event-icon" />
            <div class="event-title">
              <div class="event-labels">
                <div class="severity-{{ highRotEvent.severity | lowercase }}">
                  {{ highRotEvent.severity | capitalizeFirstLetter }}
                </div>
                <div class="event-type-label">
                  {{ highRotEvent.type }}
                </div>
              </div>
              <span class="subtitle1">{{ highRotEvent.shipName }}</span>
            </div>
          </div>
          <span class="separator"></span>
          <div class="location-time">
            <span>Location: </span>
            <span class="location-time-value">
              {{ highRotEvent.location }} |
              {{ highRotEvent.timestamp | date : 'dd MMM yyyy HH:mm' }}
            </span>
          </div>
          <div class="event-details">
            <div class="event-data">
              <div class="event-items">
                <div class="data-item">
                  <span class="label">ROT</span>
                  <span class="value">
                    {{ highRotEvent.rot | number : '1.0-1' }}°
                  </span>
                </div>
                <div class="data-item">
                  <span class="label">SOG</span>
                  <span class="value">
                    {{ highRotEvent.sog | number : '1.0-0' }}
                    <span class="unit">Knts</span>
                  </span>
                </div>
              </div>
              <div class="footer">
                <button
                  mat-stroked-button
                  color="primary"
                  class="explore-button"
                  (click)="onExploreEvent(event)">
                  Explore
                </button>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="event | typeGuard : isNoGoZoneEvent as noGoZoneEvent">
          <div class="event-header">
            <img src="assets/icons/no_go_zone_event.svg" class="event-icon" />
            <div class="event-title">
              <div class="event-labels">
                <div class="severity-{{ noGoZoneEvent.severity | lowercase }}">
                  {{ noGoZoneEvent.severity | capitalizeFirstLetter }}
                </div>
                <div class="event-type-label">
                  {{ noGoZoneEvent.type }}
                  <div class="area-of-interest">
                    - {{ noGoZoneEvent.areaOfInterest }}
                  </div>
                </div>
              </div>
              <span class="subtitle1">{{ noGoZoneEvent.shipName }}</span>
            </div>
            <div
              class="rt-event-box"
              *ngIf="event.isRtEvent && !event.timestampEnd">
              <img src="./assets/icons/blue_live.svg" />
              <span class="body1-bold blue-text">LIVE</span>
              <span class="body1 blue-text">{{
                liveTimeDiffs[event.eventId]
              }}</span>
            </div>
          </div>
          <span class="separator"></span>
          <div class="location-time">
            <span>Location: </span>
            <span class="location-time-value">
              {{ noGoZoneEvent.location }} |
              <ng-container
                *ngIf="
                  noGoZoneEvent.isRtEvent && !noGoZoneEvent.timestampEnd;
                  else nonLiveEventTime
                ">
                {{ noGoZoneEvent.timestamp | dateToHoursMinutesAgo }}
              </ng-container>
              <ng-template #nonLiveEventTime>
                {{ noGoZoneEvent.timestamp | date : 'dd MMM yyyy HH:mm' }}
              </ng-template>
            </span>
          </div>
          <div class="event-details">
            <div class="event-data">
              <div class="event-items">
                <div class="data-item">
                  <span class="label">SOG</span>
                  <span class="value">
                    {{ noGoZoneEvent.sog | number : '1.0-0' }}
                    <span class="unit">Knts</span>
                  </span>
                </div>
                <div class="data-item">
                  <span class="label">COG</span>
                  <span class="value">
                    {{ noGoZoneEvent.cog | number : '1.0-1' }}°
                  </span>
                </div>
                <div class="data-item">
                  <span class="label">Roll</span>
                  <span class="value">
                    {{ noGoZoneEvent.roll | number : '1.0-1' }}°
                  </span>
                </div>
                <div class="data-item">
                  <span class="label">Pitch</span>
                  <span class="value">
                    {{ noGoZoneEvent.pitch | number : '1.0-1' }}°
                  </span>
                </div>
              </div>
              <div class="footer">
                <button
                  mat-stroked-button
                  color="primary"
                  class="explore-button"
                  (click)="onExploreEvent(event)">
                  Explore
                </button>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="event | typeGuard : isComplianceEvent as complianceEvent">
          <div class="event-header">
            <img src="assets/icons/compliance_event.svg" class="event-icon" />
            <div class="event-title">
              <div class="event-labels">
                <div class="severity-{{ event.severity | lowercase }}">
                  {{ event.severity | capitalizeFirstLetter }}
                </div>
                <div class="event-type-label">{{ event.type }} Event</div>
              </div>
              <span class="subtitle1">{{ event.shipName }}</span>
            </div>
            <div
              class="rt-event-box"
              *ngIf="event.isRtEvent && !event.timestampEnd">
              <img src="./assets/icons/blue_live.svg" />
              <span class="body1-bold blue-text">LIVE</span>
              <span class="body1 blue-text">{{
                liveTimeDiffs[event.eventId]
              }}</span>
            </div>
          </div>
          <span class="separator"></span>
          <div class="location-time">
            <span>Location: </span>
            <span class="location-time-value">
              {{ complianceEvent.location }} |
              <ng-container
                *ngIf="
                  complianceEvent.isRtEvent && !complianceEvent.timestampEnd;
                  else nonLiveEventTime
                ">
                {{ complianceEvent.timestamp | dateToHoursMinutesAgo }}
              </ng-container>
              <ng-template #nonLiveEventTime>
                {{ complianceEvent.timestamp | date : 'dd MMM yyyy HH:mm' }}
              </ng-template>
            </span>
          </div>
          <div class="event-details">
            <div class="event-data">
              <div class="event-items">
                <div class="data-item">
                  <span class="label">SOG</span>
                  <div class="data">
                    <div
                      [ngClass]="{
                        violated:
                          complianceEvent.aoiComplianceLimitations &&
                          (complianceEvent.sog
                            | isViolatedLimitation
                              : complianceEvent.aoiComplianceLimitations!.sog)
                      }"
                      class="value">
                      <span class="data-number">{{
                        complianceEvent.sog | number : '1.0-2'
                      }}</span>
                      <span class="unit">Knts</span>
                    </div>
                  </div>
                </div>
                <div class="data-item">
                  <span class="label">COG</span>
                  <div class="data">
                    <div
                      [ngClass]="{
                        violated:
                          complianceEvent.aoiComplianceLimitations &&
                          (complianceEvent.cog ?? 0
                            | isViolatedLimitation
                              : complianceEvent.aoiComplianceLimitations!.cog)
                      }"
                      class="value">
                      <span class="data-number">{{
                        complianceEvent.cog | number : '1.0-1'
                      }}</span>
                      <span class="value">°</span>
                    </div>
                  </div>
                </div>
                <div class="data-item">
                  <span class="label">Roll</span>
                  <span class="value">
                    {{ complianceEvent.roll | number : '1.0-1' }}°
                  </span>
                </div>
                <div class="data-item">
                  <span class="label">Pitch</span>
                  <span class="value">
                    {{ complianceEvent.pitch | number : '1.0-1' }}°
                  </span>
                </div>
              </div>
              <div class="footer">
                <button
                  mat-stroked-button
                  color="primary"
                  class="explore-button"
                  (click)="onExploreEvent(event)">
                  Explore
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</div>
