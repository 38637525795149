import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { OnOffButtonComponent } from './components/on-off-button/on-off-button.component';
import { TreeContentComponent } from './components/tree-select/tree-content/tree-content.component';
import { TreeOptionComponent } from './components/tree-select/tree-option/tree-option.component';
import { TreeSelectComponent } from './components/tree-select/tree-select.component';

const exportables: Type<any>[] = [
  TreeSelectComponent,
  TreeOptionComponent,
  TreeContentComponent,
  OnOffButtonComponent,
];

@NgModule({
  declarations: [...exportables],
  imports: [
    CommonModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRippleModule,
  ],
  exports: [...exportables],
})
export class OrcaLibModule {}
