import { createReducer, on } from '@ngrx/store';
import {
  FuelOptimizationState,
  initialFuelOptimizationState,
} from '../state/fuel-optimization.state';
import { FuelOptimizationAction } from '../types';

export const FuelOptimizationStateKey = 'fuelOptimization';
export const fuelOptimizationReducer = createReducer(
  initialFuelOptimizationState,
  on(
    FuelOptimizationAction.selectPotentialSavingsGraphRangeDate,
    (state, action): FuelOptimizationState => ({
      ...state,
      potentialSavingsGraphSelectedDate:
        action.potentialSavingsGraphSelectedDate,
    })
  ),
  on(
    FuelOptimizationAction.selectAccelerationTimeGraphRangeDate,
    (state, action): FuelOptimizationState => ({
      ...state,
      accelerationTimeGraphSelectedDate:
        action.accelerationTimeGraphSelectedDate,
    })
  )
);
