import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, from, map, Observable, throwError } from 'rxjs';
import { SubFleet } from 'src/app/core/models/subfleet.models';
import { SubFleetService } from 'src/app/core/services/subfleet.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-remove-subfleet',
  standalone: true,
  imports: [CommonModule, ConfirmationDialogComponent],
  templateUrl: './remove-subfleet.component.html',
  styleUrls: ['./remove-subfleet.component.scss'],
})
export class RemoveSubfleetComponent {
  subFleet: SubFleet;

  constructor(
    private subFleetService: SubFleetService,
    @Inject(MAT_DIALOG_DATA) public data: SubFleet
  ) {
    this.subFleet = data;
  }

  onRemoveSubfleet(): Observable<boolean> {
    return from(this.subFleetService.delete(this.subFleet.subFleetId)).pipe(
      map(() => true),
      catchError((error: any) => {
        return throwError(
          () => new Error(error.message || 'Failed to remove subfleet.')
        );
      })
    );
  }
}
