import { GeoJSONFeature } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import {
  MapboxCustomSetting,
  MapboxSettingType,
  MapEntity,
} from 'src/app/mapbox/models/mapbox.models';

export const EVENT_FOCUS_SETTING_ID = 'EventFocus';

export async function CreateEventFocusSetting(
  selectedEvent$: BehaviorSubject<MapEntity | undefined>
): Promise<MapboxCustomSetting> {
  const getEventFeatures = (): GeoJSONFeature[] => {
    const event = selectedEvent$.getValue();
    return !event
      ? []
      : [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [event.long, event.lat],
            },
            source: 'ship-focus',
            properties: {
              id: event.id,
              icon: event.image,
              category: 'event',
              textLabel: event.textLabel,
            },
          },
        ];
  };

  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'ship-focus',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getEventFeatures(),
          },
        },
        updateFeatures: () => getEventFeatures(),
      },
    ],
    layers: [
      {
        id: 'ship-focus',
        type: 'symbol',
        source: 'ship-focus',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      },
    ],
    popups: [],
  };
}
