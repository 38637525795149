export const staticLocationsConfig = [
  {
    parentLocation: {
      id: 'Arctic Ocean',
      location: 'Arctic Ocean',
    },
    subLocations: [
      {
        id: 'Barentsz Sea',
        location: 'Barentsz Sea',
      },
    ],
  },
  {
    parentLocation: {
      id: 'Black Sea',
      location: 'Black Sea',
    },
    subLocations: [
      {
        id: 'Black Sea',
        location: 'Black Sea',
      },
    ],
  },
  {
    parentLocation: {
      id: 'China Sea and Japan',
      location: 'China Sea and Japan',
    },
    subLocations: [
      {
        id: 'Eastern China Sea',
        location: 'Eastern China Sea',
      },
      {
        id: 'Japan Sea',
        location: 'Japan Sea',
      },
      {
        id: 'Ningbo',
        location: 'Ningbo',
      },
      {
        id: 'Seto Naikai or Inland Sea',
        location: 'Seto Naikai or Inland Sea',
      },
      {
        id: 'South China Sea',
        location: 'South China Sea',
      },
      {
        id: 'Yellow Sea',
        location: 'Yellow Sea',
      },
    ],
  },
  {
    parentLocation: {
      id: 'English Channel',
      location: 'English Channel',
    },
    subLocations: [
      {
        id: 'English Channel',
        location: 'English Channel',
      },
    ],
  },
  {
    parentLocation: {
      id: 'Indian Ocean',
      location: 'Indian Ocean',
    },
    subLocations: [
      {
        id: 'Andaman or Burma Sea',
        location: 'Andaman or Burma Sea',
      },
      {
        id: 'Arabian Sea',
        location: 'Arabian Sea',
      },
      {
        id: 'Bay of Bengal',
        location: 'Bay of Bengal',
      },
      {
        id: 'Gulf of Aden',
        location: 'Gulf of Aden',
      },
      {
        id: 'Gulf of Aqaba',
        location: 'Gulf of Aqaba',
      },
      {
        id: 'Gulf of Oman',
        location: 'Gulf of Oman',
      },
      {
        id: 'Gulf of Suez',
        location: 'Gulf of Suez',
      },
      {
        id: 'Gulf of Thailand',
        location: 'Gulf of Thailand',
      },
      {
        id: 'Indian Ocean',
        location: 'Indian Ocean',
      },
      {
        id: 'Laccadive Sea',
        location: 'Laccadive Sea',
      },
      {
        id: 'Mozambique Channel',
        location: 'Mozambique Channel',
      },
      {
        id: 'Persian Gulf',
        location: 'Persian Gulf',
      },
      {
        id: 'Red Sea',
        location: 'Red Sea',
      },
      {
        id: 'Suez Canal',
        location: 'Suez Canal',
      },
    ],
  },
  {
    parentLocation: {
      id: 'Mediterranean Sea',
      location: 'Mediterranean Sea',
    },
    subLocations: [
      {
        id: 'Adriatic Sea',
        location: 'Adriatic Sea',
      },
      {
        id: 'Aegean Sea',
        location: 'Aegean Sea',
      },
      {
        id: 'Alboran Sea',
        location: 'Alboran Sea',
      },
      {
        id: 'Balearic (Iberian Sea)',
        location: 'Balearic (Iberian Sea)',
      },
      {
        id: 'Ionian Sea',
        location: 'Ionian Sea',
      },
      {
        id: 'Ligurian Sea',
        location: 'Ligurian Sea',
      },
      {
        id: 'Mediterranean Sea - Eastern Basin',
        location: 'Mediterranean Sea - Eastern Basin',
      },
      {
        id: 'Mediterranean Sea - Western Basin',
        location: 'Mediterranean Sea - Western Basin',
      },
      {
        id: 'Sea of Marmara',
        location: 'Sea of Marmara',
      },
      {
        id: 'Tyrrhenian Sea',
        location: 'Tyrrhenian Sea',
      },
    ],
  },
  {
    parentLocation: {
      id: 'North and Baltic Sea',
      location: 'North and Baltic Sea',
    },
    subLocations: [
      {
        id: 'Baltic Sea',
        location: 'Baltic Sea',
      },
      {
        id: 'Gulf of Bothnia',
        location: 'Gulf of Bothnia',
      },
      {
        id: 'Gulf of Finland',
        location: 'Gulf of Finland',
      },
      {
        id: 'Gulf of Riga',
        location: 'Gulf of Riga',
      },
      {
        id: 'Kattegat',
        location: 'Kattegat',
      },
      {
        id: 'North Sea',
        location: 'North Sea',
      },
      {
        id: 'Norwegian Sea',
        location: 'Norwegian Sea',
      },
      {
        id: 'Skagerrak',
        location: 'Skagerrak',
      },
    ],
  },
  {
    parentLocation: {
      id: 'North Atlantic',
      location: 'North Atlantic',
    },
    subLocations: [
      {
        id: 'Bay of Biscay',
        location: 'Bay of Biscay',
      },
      {
        id: 'Bay of Fundy',
        location: 'Bay of Fundy',
      },
      {
        id: 'Bristol Channel',
        location: 'Bristol Channel',
      },
      {
        id: 'Caribbean Sea',
        location: 'Caribbean Sea',
      },
      {
        id: 'Celtic Sea',
        location: 'Celtic Sea',
      },
      {
        id: 'Gulf of Guinea',
        location: 'Gulf of Guinea',
      },
      {
        id: 'Gulf of St. Lawrence',
        location: 'Gulf of St. Lawrence',
      },
      {
        id: 'Inner Seas off the West Coast of Scotland',
        location: 'Inner Seas off the West Coast of Scotland',
      },
      {
        id: 'Irish Sea and St. Georges Channel',
        location: 'Irish Sea and St. Georges Channel',
      },
      {
        id: 'Labrador Sea',
        location: 'Labrador Sea',
      },
      {
        id: 'North Atlantic Ocean',
        location: 'North Atlantic Ocean',
      },
    ],
  },
  {
    parentLocation: {
      id: 'Oceania',
      location: 'Oceania',
    },
    subLocations: [
      {
        id: 'Arafura Sea',
        location: 'Arafura Sea',
      },
      {
        id: 'Bali Sea',
        location: 'Bali Sea',
      },
      {
        id: 'Banda Sea',
        location: 'Banda Sea',
      },
      {
        id: 'Bass Strait',
        location: 'Bass Strait',
      },
      {
        id: 'Bismarck Sea',
        location: 'Bismarck Sea',
      },
      {
        id: 'Celebes Sea',
        location: 'Celebes Sea',
      },
      {
        id: 'Ceram Sea',
        location: 'Ceram Sea',
      },
      {
        id: 'Coral Sea',
        location: 'Coral Sea',
      },
      {
        id: 'Flores Sea',
        location: 'Flores Sea',
      },
      {
        id: 'Great Australian Bight',
        location: 'Great Australian Bight',
      },
      {
        id: 'Halmahera Sea',
        location: 'Halmahera Sea',
      },
      {
        id: 'Java Sea',
        location: 'Java Sea',
      },
      {
        id: 'Makassar Strait',
        location: 'Makassar Strait',
      },
      {
        id: 'Molukka Sea',
        location: 'Molukka Sea',
      },
      {
        id: 'Savu Sea',
        location: 'Savu Sea',
      },
      {
        id: 'Solomon Sea',
        location: 'Solomon Sea',
      },
      {
        id: 'Sulu Sea',
        location: 'Sulu Sea',
      },
      {
        id: 'Tasman Sea',
        location: 'Tasman Sea',
      },
      {
        id: 'Timor Sea',
        location: 'Timor Sea',
      },
    ],
  },
  {
    parentLocation: {
      id: 'Other',
      location: 'Other',
    },
    subLocations: [
      {
        id: 'Other',
        location: 'Other',
      },
    ],
  },
  {
    parentLocation: {
      id: 'Pacific Ocean',
      location: 'Pacific Ocean',
    },
    subLocations: [
      {
        id: 'Bering Sea',
        location: 'Bering Sea',
      },
      {
        id: 'Gulf of California',
        location: 'Gulf of California',
      },
      {
        id: 'North Pacific Ocean',
        location: 'North Pacific Ocean',
      },
      {
        id: 'Philippine Sea',
        location: 'Philippine Sea',
      },
      {
        id: 'Sea of Okhotsk',
        location: 'Sea of Okhotsk',
      },
      {
        id: 'South Pacific Ocean',
        location: 'South Pacific Ocean',
      },
      {
        id: 'The Coastal Waters of Southeast Alaska and British Columbia',
        location: 'The Coastal Waters of Southeast Alaska and British Columbia',
      },
    ],
  },
  {
    parentLocation: {
      id: 'Panama Canal',
      location: 'Panama Canal',
    },
    subLocations: [
      {
        id: 'Panama Canal',
        location: 'Panama Canal',
      },
    ],
  },
  {
    parentLocation: {
      id: 'Singapore and Malacca Strait',
      location: 'Singapore and Malacca Strait',
    },
    subLocations: [
      {
        id: 'Malacca Strait',
        location: 'Malacca Strait',
      },
      {
        id: 'Singapore Strait',
        location: 'Singapore Strait',
      },
    ],
  },
  {
    parentLocation: {
      id: 'South Atlantic',
      location: 'South Atlantic',
    },
    subLocations: [
      {
        id: 'Rio de La Plata',
        location: 'Rio de La Plata',
      },
      {
        id: 'South Atlantic Ocean',
        location: 'South Atlantic Ocean',
      },
    ],
  },
  {
    parentLocation: {
      id: 'Strait of Gibraltar',
      location: 'Strait of Gibraltar',
    },
    subLocations: [
      {
        id: 'Strait of Gibraltar',
        location: 'Strait of Gibraltar',
      },
    ],
  },
  {
    parentLocation: {
      id: 'USA',
      location: 'USA',
    },
    subLocations: [
      {
        id: 'East Coast EEZ 1',
        location: 'East Coast EEZ 1',
      },
      {
        id: 'Gulf of Mexico',
        location: 'Gulf of Mexico',
      },
      {
        id: 'West Coast EEZ 1',
        location: 'West Coast EEZ 1',
      },
    ],
  },
];
