import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MonthlyFuelSavingData } from 'src/app/core/view-models/fuel-optimization.view.models';
import { AbbreviateNumberPipe } from '../../../../shared/pipes/abbreviate-number.pipe';

@Component({
  selector: 'app-fuel-saving-tooltip',
  standalone: true,
  imports: [AsyncPipe, DatePipe, CommonModule, AbbreviateNumberPipe],
  templateUrl: './fuel-saving-tooltip.component.html',
  styleUrls: ['./fuel-saving-tooltip.component.scss'],
})
export class FuelSavingTooltipComponent {
  @Input() data$!: Observable<MonthlyFuelSavingData>;
}
