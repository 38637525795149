import {
  GeoJSONFeature,
  LayerSpecification,
  Popup,
  SourceSpecification,
} from 'mapbox-gl';
import { BehaviorSubject, Observable } from 'rxjs';

export interface MapboxSettingOption {
  id: string;
  setting: MapboxCustomSetting | MapboxStyleSetting;
  update$: Observable<void>;
  show$: BehaviorSubject<boolean>;
}

export enum MapboxSettingType {
  Custom = 'Custom',
  FromStyle = 'FromStyle',
}

export interface MapboxCustomSetting {
  type: MapboxSettingType.Custom;
  sources: MapSource[];
  layers: LayerSpecification[];
  popups: Popup[];
  maxZoomForPopups?: number;
  updatePopups?: () => Popup[];
}

export interface MapboxStyleSetting {
  type: MapboxSettingType.FromStyle;
  layerIds: string[];
}

export interface MapSource {
  sourceId: string;
  source: SourceSpecification;
  updateFeatures?: () => GeoJSONFeature[];
  updateTilesSource?: () => string[];
}

export interface MapboxEntityConfiguration {
  image: string;
}

export interface MapboxEntityClickEvent {
  longtitude: number;
  latitude: number;
  entities: GeoJSONFeature[];
}

export interface MapEntity {
  readonly id: string;
  readonly image?: string;
  readonly lat: number;
  readonly long: number;
  readonly eyeOffset: [number, number, number];
  readonly textLabel?: string;
  readonly labelType?: LabelType;
}

export type LabelType = 'white' | 'large-white' | 'red';

export interface PolygonEntity {
  readonly id: string;
  readonly textLabel: string;
  readonly labelType: LabelType;
  readonly coordinates: number[][];
  readonly color: string;
  readonly borderColor: string;
  readonly labelSource: string;
}

export interface MapLabel {
  readonly iconSrc?: string;
  readonly lat: number;
  readonly long: number;
  readonly textLabel: string;
  readonly labelType: LabelType;
  readonly offset: number;
}
