<div class="tooltip" *ngIf="data$ | async as data">
  <div class="container">
    <div class="header">
      <div class="right-side">
        <img class="ship-icon" src="./assets/icons/ship.svg" />
        <span class="ship-count"> Ships {{ data.ships }}</span>
      </div>
      <div class="left-side">{{ data.date | date : 'MMM YYYY' }}</div>
    </div>
    <div class="content">
      <div class="text-with-image">
        <span class="circle fuel-cost"></span>
        <span class="text-x-small">Fuel Cost $</span>
        <span class="text-x-small"
          >{{ (data.fuel | abbreviateNumber).value | number : '1.0-1'
          }}{{ (data.fuel | abbreviateNumber).abbreviation }}</span
        >
      </div>
      <div class="text-with-image">
        <span class="circle safety-events"></span>
        <span class="text-x-small">Events With Acceleration</span>
        <span class="text-x-small bold">
          {{ data.eventsWithAcceleration }}
        </span>
      </div>
    </div>
  </div>
</div>
