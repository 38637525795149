import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { DestroyRef } from 'projects/orca-lib-main/projects/orca-lib/src/lib/services/destroy-ref.service';
import { BehaviorSubject, fromEvent, map, Observable, takeUntil } from 'rxjs';
import { EventsSortBy } from 'src/app/core/models/events-filters.model';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  EventsActions,
  EventsSelectors,
  FiltersSelectors,
} from 'src/app/core/store/types';
import { Events } from 'src/app/core/view-models/event.view.model';
import { eventsSortList } from 'src/app/core/view-models/events.sort.view.model';
import { DateFilterKeys } from 'src/app/shared/view-models/dates.view.model';

const SCROLL_DEBOUNCE_TIME_MS = 200;

@Component({
  selector: 'app-filtered-events',
  templateUrl: './filtered-events.component.html',
  styleUrls: ['./filtered-events.component.scss'],
  providers: [DestroyRef],
})
export class FilteredEventsComponent implements OnInit, AfterViewInit {
  events$!: Observable<Events[]>;
  eventsSortList = eventsSortList;
  eventsCount$!: Observable<number>;
  dataExists$!: Observable<boolean>;
  selectedSortOption$!: Observable<EventsSortBy>;
  eventToScrollTo$ = new BehaviorSubject<Events | null>(null);
  eventsDayFilter$!: Observable<Date>;
  currentDateFilter$!: Observable<DateFilterKeys>;
  @ViewChild('eventsContainer') eventsContainer!: ElementRef;
  selectedEvent: Events | null = null;
  events: Events[] = [];
  updateEventToScrollTo = _.debounce((event: Events | null): void => {
    this.eventToScrollTo$.next(event);
  }, SCROLL_DEBOUNCE_TIME_MS);

  constructor(
    private dr: DestroyRef,
    private store: Store<CoreState>,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.events$ = this.store.select(EventsSelectors.selectEvents).pipe(
      map(events => {
        this.events = events;
        return events;
      })
    );

    this.eventsCount$ = this.store.select(EventsSelectors.selectEventsCount);

    this.dataExists$ = this.eventsCount$.pipe(map(count => count > 0));

    this.selectedSortOption$ = this.store.select(EventsSelectors.selectSortBy);

    this.store
      .select(EventsSelectors.selectSelectedEvent)
      .pipe(takeUntil(this.dr.destroy$))
      .subscribe(selectedEvent => {
        this.selectedEvent = selectedEvent;
        if (selectedEvent?.eventId) {
          if (
            !this.events.find(
              event => event?.eventId === selectedEvent?.eventId
            )
          ) {
            this.store.dispatch(
              EventsActions.getEventAndAddToList({
                eventId: selectedEvent!.eventId,
              })
            );
          } else {
            this.updateEventToScrollTo(selectedEvent);
          }
        } else {
          this.resetScroll();
        }
      });

    this.events$.pipe(takeUntil(this.dr.destroy$)).subscribe(events => {
      this.updateEventToScrollTo(this.selectedEvent);
    });

    this.eventsDayFilter$ = this.store.select(
      EventsSelectors.selectEventsDateFilterStartDate
    );

    this.currentDateFilter$ = this.store.select(
      FiltersSelectors.selectFilterdDatesKey
    );
  }

  ngAfterViewInit(): void {
    this.updateScrollToTopOnUserScroll();
  }

  updateScrollToTopOnUserScroll() {
    fromEvent(this.eventsContainer.nativeElement, 'scroll')
      .pipe(takeUntil(this.dr.destroy$))
      .subscribe(() => {
        this.store.dispatch(EventsActions.updateScrollToTopByUser());
      });
  }

  resetScroll() {
    if (this.eventsContainer && this.eventsContainer.nativeElement) {
      this.eventsContainer.nativeElement.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  }

  onSortByChange(sortBy: EventsSortBy): void {
    this.store.dispatch(EventsActions.saveSortByOptionOnUserChange({ sortBy }));
  }

  onScroll() {
    this.store.dispatch(EventsActions.getMoreEventsOnUserScroll());
  }

  onSelectedEvent(event: Events): void {
    this.store.dispatch(EventsActions.setSelectedFilteredEvent({ event }));
  }

  onEventExploration(event: Events): void {
    if (event.isRtEvent) {
      this.router.navigate(['rt-event-exploration', event.eventId], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this.router.navigate(['event-exploration', event.eventId], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  clearEventsList(): void {
    this.store.dispatch(EventsActions.clearEventsListSubFilters());
  }

  onExportClick(): void {
    this.store.dispatch(EventsActions.onExportFile());
  }
}
