import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { BehaviorSubject } from 'rxjs';
import { MapboxMenuOption } from 'src/app/mapbox/models/mapbox-menu.models';

@Component({
  selector: 'app-weather-menu-form',
  standalone: true,
  imports: [MatRadioModule, CommonModule],
  templateUrl: './weather-menu-form.component.html',
  styleUrls: ['./weather-menu-form.component.scss'],
})
export class WeatherMenuFormComponent {
  @Input() weatherSettingOptions: MapboxMenuOption[] = [];
  @Input() weatherSelection$!: BehaviorSubject<MapboxMenuOption | undefined>;
  isEnabled: boolean = true;

  constructor() {}

  ngOnInit(): void {
    this.weatherSelection$.subscribe(selection =>
      this.weatherSettingOptions.forEach(
        option => (option.isChecked = option.id === selection?.id)
      )
    );
  }

  onSelect = (selectedOptionId: string) =>
    this.weatherSelection$.next(
      this.weatherSettingOptions.find(option => option.id === selectedOptionId)
    );
}
