import {
  MapboxSettingType,
  MapboxStyleSetting,
} from 'src/app/mapbox/models/mapbox.models';

export const LAND_SETTING_ID = 'land';

export const GetLandSetting = (): MapboxStyleSetting => ({
  type: MapboxSettingType.FromStyle,
  layerIds: ['country-boundaries', 'waterway'],
});
