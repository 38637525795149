import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';
import { CoreState } from '../store/state/core.state';
import { AuthenticationSelectors } from '../store/types';

@Injectable({
  providedIn: 'root',
})
export class ShipCaptainGuard implements CanActivate {
  constructor(private store: Store<CoreState>, private router: Router) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const isCaptain = await firstValueFrom(
      this.store
        .select(AuthenticationSelectors.selectIsUserShipCaptain)
        .pipe(filter(user => user != null))
    );

    if (isCaptain === false) {
      return true;
    }

    const shipLink = `${state.url}/ship/`;
    const shipId = await firstValueFrom(
      this.store
        .select(AuthenticationSelectors.selectAuthenticatedUserShipId)
        .pipe(filter(shipId => shipId != null))
    );

    if (shipId) this.router.navigateByUrl(`${shipLink}${shipId}`);
    else this.router.navigateByUrl('/private/overview');

    return false;
  }
}
