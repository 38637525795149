import { BehaviorSubject } from 'rxjs';
import {
  MapboxLegendConfigurations,
  MapboxLegendTick,
} from 'src/app/mapbox/models/mapbox-legend.models';
import {
  MapboxCustomSetting,
  MapboxSettingType,
} from 'src/app/mapbox/models/mapbox.models';
import { environment } from 'src/environments/environment';

export const PERCIPITATION_LEGEND_TICKS: MapboxLegendTick[] = [
  { value: 0.25, color: '#85F7F4' },
  { value: 0.5, color: '#6994FC' },
  { value: 1.0, color: '#5A7BF8' },
  { value: 1.5, color: '#017CFE' },
  { value: 2.0, color: '#0268D5' },
  { value: 3.0, color: '#039787' },
  { value: 5.0, color: '#02C621' },
  { value: 7.0, color: '#AEFF03' },
  { value: 10.0, color: '#DAFF35' },
  { value: 15.0, color: '#FFAD02' },
  { value: 20.0, color: '#FF6101' },
  { value: 25.0, color: '#FC3C03' },
  { value: 30.0, color: '#FB1403' },
];

export const PERCIPITATION_LEGEND_CONFIG: MapboxLegendConfigurations = {
  id: 'cloud',
  unitsName: 'mm',
  legendTicks: PERCIPITATION_LEGEND_TICKS,
};

export const PERCIPITATION_SETTING_ID = 'Clouds and Percipitation';

export const GetPercipitationSetting = (
  sampleDateString$: BehaviorSubject<string>
): MapboxCustomSetting => {
  const getTileSource = () => [
    `${
      environment.meteoblue.tilesUrl
    }/raster/ICONAUTO/${sampleDateString$.getValue()}/75~high%20cld%20lay~hourly~none~contourSteps~0.0~rgba(255,255,255,0.0)~20.0~rgba(255,255,255,0.09803922)~40.0~rgba(255,255,255,0.2)~60.0~rgba(255,255,255,0.29803923)~80.0~rgba(255,255,255,0.4)~100.0~rgba(255,255,255,0.49803922)_2006~sfc~hourly~none~contourSteps~0.0~rgba(255,255,255,0.0)~20.0~rgba(255,255,255,0.1764706)~40.0~rgba(255,255,255,0.29803923)~60.0~rgba(255,255,255,0.54901963)~80.0~rgba(255,255,255,0.8)~95.0~rgba(255,255,255,0.8980392)_61~sfc~hourly~none~contourSteps~0.1~rgba(133,247,244,0.49803922)~0.25~rgba(133,247,244,1.0)~0.5~rgba(105,148,252,1.0)~1.0~rgba(90,123,248,1.0)~1.5~rgba(1,124,254,1.0)~2.0~rgba(2,104,213,1.0)~3.0~rgba(3,151,135,1.0)~5.0~rgba(2,198,33,1.0)~7.0~rgba(174,255,3,1.0)~10.0~rgba(218,255,53,1.0)~15.0~rgba(255,173,2,1.0)~20.0~rgba(255,97,1,1.0)~25.0~rgba(252,60,3,1.0)~30.0~rgba(251,20,3,1.0)/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=km%2Fh&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${
      environment.meteoblue.apiKey
    }`,
  ];
  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'clouds-vector-tile-source',
        source: {
          type: 'vector',
          tiles: getTileSource(),
        },
        updateTilesSource: getTileSource,
      },
      {
        sourceId: 'clouds-raster-tile-source',
        source: {
          type: 'raster',
          tiles: getTileSource(),
          tileSize: 256,
        },
        updateTilesSource: getTileSource,
      },
    ],
    layers: [
      {
        id: 'clouds-height-fill',
        source: 'clouds-vector-tile-source',
        'source-layer': 'layerSnowInternal',
        type: 'fill',
        paint: {
          'fill-antialias': false,
          'fill-opacity': 0.75,
          'fill-color': [
            'interpolate',
            ['linear'],
            ['get', 'minValue'],
            ...PERCIPITATION_LEGEND_TICKS.flatMap(tick => [
              tick.value,
              tick.color,
            ]),
          ],
        },
        filter: ['all', ['==', '$type', 'Polygon']],
      },
      {
        id: 'clouds-png',
        type: 'raster',
        source: 'clouds-raster-tile-source',
        paint: {
          'raster-opacity': 0.7,
        },
      },
    ],
    popups: [],
  };
};
