import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CreateSubFleetDto,
  SubFleet,
  UpdateSubFleetDto,
} from '../models/subfleet.models';

@Injectable({
  providedIn: 'root',
})
export class SubFleetService {
  private readonly apiUrl = `${environment.api.subfleets}`;

  constructor(private readonly http: HttpClient) {}

  create(createSubFleet: CreateSubFleetDto): Observable<SubFleet> {
    const createSubFleetDto = instanceToPlain(createSubFleet);
    return this.http
      .post<SubFleet>(this.apiUrl, createSubFleetDto)
      .pipe(map(response => plainToInstance(SubFleet, response)));
  }

  getAll(): Observable<SubFleet[]> {
    return this.http
      .get<SubFleet[]>(this.apiUrl)
      .pipe(map(response => plainToInstance(SubFleet, response)));
  }

  getById(id: number): Observable<SubFleet> {
    return this.http
      .get<SubFleet>(`${this.apiUrl}/${id}`)
      .pipe(map(response => plainToInstance(SubFleet, response)));
  }

  update(id: number, updateSubFleet: UpdateSubFleetDto): Observable<SubFleet> {
    const updateSubFleetDto = instanceToPlain(updateSubFleet);
    return this.http
      .put<SubFleet>(`${this.apiUrl}/${id}`, updateSubFleetDto)
      .pipe(map(response => plainToInstance(SubFleet, response)));
  }

  delete(id: number): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(`${this.apiUrl}/${id}`);
  }
}
