import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';

import { EventsRepository } from '../pages/events/events.repository';
import { EventsStore } from '../pages/events/events.store';
import { CoreState } from '../store/state/core.state';
import {
  AuthenticationSelectors,
  EventsActions,
  FiltersActions,
  ShipsSelectors,
} from '../store/types';
import { AppliedEventFilters } from '../view-models/filter.view.model';

@Injectable({
  providedIn: 'root',
})
export class EventsResolver implements Resolve<void> {
  private store: Store<CoreState> = inject(Store);
  private router: Router = inject(Router);
  private eventsRepository = inject(EventsRepository);
  private eventsStore = inject(EventsStore);

  async resolve(_route: ActivatedRouteSnapshot): Promise<void> {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { queryParams: any } | undefined;

    if (state) {
      const queryParams = state.queryParams;
      const appliedFiltersToUpdate = {
        ...queryParams,
      };

      const dateFilter = queryParams['dateFilter'];

      this.store.dispatch(
        FiltersActions.setDateFilterByUrlParams({
          dateFilter,
        })
      );

      // If the user navigates to the events page with filters, we need to set the flag to true
      // to stop the filters from being reset when the user navigates to the events page
      this.eventsStore.isNavigationWithFilters$.next(true);

      // Get default filters and apply the filters from the URL
      await this.eventsRepository.getFilters();
      const appliedFilters = this.eventsStore.appliedEventFilters$.value;
      const updatedAppliedFilters: AppliedEventFilters = {
        ...appliedFilters,
        ...appliedFiltersToUpdate,
      };

      // Apply the filters on both ngrx (old) store and new service store
      // This is needed until we refactor old filters component to only use the new service store
      this.store.dispatch(
        EventsActions.onEventFilterChange({ updatedAppliedFilters })
      );
      this.eventsRepository.applyFilters(updatedAppliedFilters);
    } else {
      this.eventsStore.isNavigationWithFilters$.next(false);
    }

    await firstValueFrom(
      this.store
        .select(AuthenticationSelectors.selectAuthenticatedUser)
        .pipe(filter(authUser => authUser?.token != ''))
    );

    await firstValueFrom(
      this.store
        .select(ShipsSelectors.selectShips)
        .pipe(filter(ships => ships.length > 0))
    );
  }
}
