import { NgElement, WithProperties } from '@angular/elements';
import { Anchor, Popup } from 'mapbox-gl';

// Note: Ensure that the component used is registered in "app/shared/custom-elements"
export function CreatePopupFromComponent<Inputs extends Object>(
  selector: string,
  inputs: Inputs,
  anchor: Anchor = 'top'
): Popup {
  const popupElement = document.createElement(selector) as NgElement &
    WithProperties<Inputs>;
  Object.entries(inputs).forEach(
    ([key, value]) => ((popupElement as any)[key] = value)
  );
  const popup = new Popup({
    closeButton: false,
    closeOnClick: false,
    anchor,
  });
  popup.setDOMContent(popupElement);
  return popup;
}

export function GetCenterOfPolygon(
  polygonCoordinates: number[][]
): [longtitude: number, latitude: number] {
  const bounds = polygonCoordinates.reduce(
    (bounds, coord) => {
      return [
        Math.min(bounds[0], coord[0]),
        Math.min(bounds[1], coord[1]),
        Math.max(bounds[2], coord[0]),
        Math.max(bounds[3], coord[1]),
      ];
    },
    [Infinity, Infinity, -Infinity, -Infinity]
  );

  return [(bounds[0] + bounds[2]) / 2, (bounds[1] + bounds[3]) / 2];
}
