<app-form-dialog
  class="form-fields-container"
  [title]="'Edit Subfleet'"
  [formGroup]="subfleetForm"
  [submitButtonText]="'Save Changes'"
  [onSubmitAction]="onSubmitSubfleet.bind(this)">
  <mat-form-field appearance="outline" class="form-field">
    <mat-label>Sub fleet name</mat-label>
    <input matInput formControlName="subFleetName" required />
    <mat-error *ngIf="subfleetForm.get('subFleetName')?.hasError('required')">
      This field is required
    </mat-error>
  </mat-form-field>

  <mat-form-field
    appearance="fill"
    class="form-field custom-form-field-selection selection">
    <mat-label>Associated Ships</mat-label>
    <mat-select
      formControlName="shipsList"
      panelClass="custom-select"
      multiple="true">
      <div class="search-container">
        <input
          appSelectSearch
          matInput
          placeholder="Type ship's name"
          [formControl]="searchBoxShipsControl"
          class="search-input" />
      </div>
      <mat-option *ngFor="let ship of ships$ | async" [value]="ship.shipId">
        {{ ship.shipName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</app-form-dialog>
