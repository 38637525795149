import { CommonModule, DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  Events,
  isCloseEncounterEvent,
  isHighPitchRollEvent,
  isHighRot,
  isRTComplianceEvent,
  isRTNoGoZoneEvent,
  isSpeedDropEvent,
  isUKCEvent,
} from 'src/app/core/view-models/event.view.model';
import { CapitalizeFirstLetterPipe } from 'src/app/shared/pipes/capitalize-first-letter.pipe';
import { DateToHoursMinutesAgoPipe } from 'src/app/shared/pipes/date-to-hours-minutes-ago.pipe';
import { TargetTypeNamePipe } from 'src/app/shared/pipes/target-type-name.pipe';
import { TypeGuardPipe } from 'src/app/shared/pipes/type-guard.pipe';
import { getTimeDiffWithSeconds } from 'src/app/shared/utils/date-transforms/date-diff';
import { IsViolatedLimitationPipe } from '../../pipes/is-violated-limitation.pipe';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    DatePipe,
    CapitalizeFirstLetterPipe,
    DateToHoursMinutesAgoPipe,
    TargetTypeNamePipe,
    TypeGuardPipe,
    IsViolatedLimitationPipe,
  ],
})
export class EventsListComponent implements OnChanges, AfterViewInit {
  @Input() events!: Events[] | null;
  @Input() selectedEvent!: Events | null;
  @Input() showTargetEventData: boolean = true;
  @Input() itemTemplate?: TemplateRef<any>;

  @Output() eventClicked = new EventEmitter<Events>();
  @Output() eventExplorationClicked = new EventEmitter<Events>();

  isCloseEncounterEvent = isCloseEncounterEvent;
  isHighPitchRollEvent = isHighPitchRollEvent;
  isSpeedDropEvent = isSpeedDropEvent;
  isUKCEvent = isUKCEvent;
  isHighRotEvent = isHighRot;
  isComplianceEvent = isRTComplianceEvent;
  isNoGoZoneEvent = isRTNoGoZoneEvent;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['selectedEvent'] &&
      changes['selectedEvent'].currentValue &&
      changes['selectedEvent'].currentValue !==
        changes['selectedEvent'].previousValue
    ) {
      this.scrollToSelectedEvent();
    }
  }

  ngAfterViewInit(): void {
    this.scrollToSelectedEvent();
  }

  scrollToSelectedEvent() {
    const eventCard = document.getElementById(
      `event-${this.selectedEvent?.eventId}`
    );
    eventCard?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }

  onSelectedEvent(event: Events) {
    this.selectedEvent = { ...event };
    this.scrollToSelectedEvent();
    this.eventClicked.emit(this.selectedEvent);
  }

  onExploreEvent(event: Events) {
    this.eventExplorationClicked.emit(event);
  }

  isSelectedEvent(event: Events): boolean {
    if (this.selectedEvent) {
      return this.selectedEvent.eventId === event.eventId;
    }
    return false;
  }

  getTimeDiff(event: Events): string {
    const endTime = new Date(
      event.timestampEnd ??
        new Date().getTime() + new Date().getTimezoneOffset() * 60000
    );
    const startTime = new Date(event.timestamp);
    return getTimeDiffWithSeconds(endTime, startTime);
  }
}
