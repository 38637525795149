import { GeoJSONFeature, LngLat, Popup } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { VoyageModel } from 'src/app/core/pages/voyages/models/voyages.types';
import {
  MapboxCustomSetting,
  MapboxSettingType,
} from 'src/app/mapbox/models/mapbox.models';
import { CreatePopupFromComponent } from 'src/app/mapbox/utils/mapbox.utility';

export const SHIP_VOYAGE_SETTING_ID = 'ShipVoyage';

export type VoyageData = { [key: string]: VoyageModel };

export async function CreateVoyageSetting(
  voyages$: BehaviorSubject<VoyageModel[] | null>
): Promise<MapboxCustomSetting> {
  const openPopup$ = new BehaviorSubject<string | null>(null);

  const getVoyageLineFeatures = (voyageType?: string): GeoJSONFeature[] => {
    const voyagesData = voyages$.value;
    if (!voyagesData) return [];
    const features: GeoJSONFeature[] = [];
    Object.entries(voyagesData).forEach(([key, voyage]) => {
      if (voyage.geometry && voyage.geometry.length > 1) {
        if (voyageType && voyage.key !== voyageType) return;
        features.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: voyage.geometry,
          },
          properties: {
            id: voyage.key,
            voyageType: voyage.key,
            start_time: voyage.startTime,
            end_time: voyage.endTime,
            ship_type: voyage.shipType,
            start_port: voyage.startPort.split(';')[0],
            end_port: voyage.endPort.split(';')[0],
            length_nm: voyage.lengthNm,
            sog: voyage.sog,
            duration_m: voyage.durationMin,
            total_acc_time_m: voyage.totalAccTimeMin,
            total_encounter_events: voyage.totalEncounterEvents,
          },
          source: 'voyage_lines_' + voyage.key,
        });
      }
    });
    return features;
  };

  const getVoyagePointFeatures = (): GeoJSONFeature[] => {
    const voyagesData = voyages$.value;
    if (!voyagesData) return [];
    const features: GeoJSONFeature[] = [];
    Object.entries(voyagesData).forEach(([key, voyage]) => {
      if (voyage.geometry && voyage.geometry.length > 0) {
        features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: voyage.geometry[0],
          },
          properties: {
            id: `${key}_start`,
            voyageType: key,
            position: 'start',
            port: voyage.startPort.split(';')[0],
            icon: '/assets/map/voyages/start.png',
          },
          source: 'voyage_points',
        });
        features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: voyage.geometry[voyage.geometry.length - 1],
          },
          properties: {
            id: `${key}_end`,
            voyageType: key,
            position: 'end',
            port: voyage.endPort.split(';')[0],
            icon: '/assets/map/voyages/end.png',
          },
          source: 'voyage_points',
        });
      }
    });
    return features;
  };

  const getPopups = () => {
    const voyagesData = voyages$.value;

    if (!voyagesData) return [];
    const popups: Popup[] = [];
    Object.entries(voyagesData).forEach(([key, voyage]) => {
      const startLabel = {
        long: voyage.geometry[0][0],
        lat: voyage.geometry[0][1],
        textLabel: voyage.startPort.split(';')[0],
      };
      const endLabel = {
        long: voyage.geometry[voyage.geometry.length - 1][0],
        lat: voyage.geometry[voyage.geometry.length - 1][1],
        textLabel: voyage.endPort.split(';')[0],
      };
      const startPopup = CreatePopupFromComponent('app-map-label', {
        label: startLabel,
      })
        .setOffset([-53, 0])
        .setLngLat(new LngLat(startLabel.long, startLabel.lat).wrap());

      const endPopup = CreatePopupFromComponent('app-map-label', {
        label: endLabel,
      })
        .setOffset([-53, 0])
        .setLngLat(new LngLat(endLabel.long, endLabel.lat).wrap());

      startPopup.addClassName('dark-blue');
      endPopup.addClassName('dark-blue');
      popups.push(startPopup);
      popups.push(endPopup);
    });
    return popups;
  };

  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'voyage_lines_safest',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getVoyageLineFeatures('safest'),
          },
        },
        updateFeatures: () => getVoyageLineFeatures('safest'),
      },
      {
        sourceId: 'voyage_lines_most_efficient',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getVoyageLineFeatures('most_efficient'),
          },
        },
        updateFeatures: () => getVoyageLineFeatures('most_efficient'),
      },
      {
        sourceId: 'voyage_lines_shortest',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getVoyageLineFeatures('shortest'),
          },
        },
        updateFeatures: () => getVoyageLineFeatures('shortest'),
      },
      {
        sourceId: 'voyage_lines_fastest',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getVoyageLineFeatures('fastest'),
          },
        },
        updateFeatures: () => getVoyageLineFeatures('fastest'),
      },
      {
        sourceId: 'voyage_points',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getVoyagePointFeatures(),
          },
        },
        updateFeatures: getVoyagePointFeatures,
      },
    ],
    layers: [
      {
        id: 'voyage_lines_safest_border',
        type: 'line',
        source: 'voyage_lines_safest',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#FFFFFF',
          'line-width': 6,
        },
      },
      {
        id: 'voyage_lines_safest',
        type: 'line',
        source: 'voyage_lines_safest',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#55B1F2',
          'line-width': 3,
        },
      },
      {
        id: 'voyage_lines_most_efficient_border',
        type: 'line',
        source: 'voyage_lines_most_efficient',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#FFFFFF',
          'line-width': 6,
        },
      },
      {
        id: 'voyage_lines_most_efficient',
        type: 'line',
        source: 'voyage_lines_most_efficient',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#AD99FF',
          'line-width': 3,
        },
      },
      {
        id: 'voyage_lines_shortest_border',
        type: 'line',
        source: 'voyage_lines_shortest',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#FFFFFF',
          'line-width': 6,
        },
      },
      {
        id: 'voyage_lines_shortest',
        type: 'line',
        source: 'voyage_lines_shortest',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#F79E31',
          'line-width': 3,
        },
      },
      {
        id: 'voyage_lines_fastest_border',
        type: 'line',
        source: 'voyage_lines_fastest',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#FFFFFF',
          'line-width': 6,
        },
      },
      {
        id: 'voyage_lines_fastest',
        type: 'line',
        source: 'voyage_lines_fastest',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#26C754',
          'line-width': 3,
        },
      },
      {
        id: 'voyage_points',
        type: 'symbol',
        source: 'voyage_points',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      },
    ],
    popups: getPopups(),
    updatePopups: getPopups,
    showPopup$: openPopup$,
  };
}
