export type VoyageFilterDropdownType = 'Port' | 'Time';

export interface VoyageFilter {
  label: string;
  value: string;
  description: string;
  requestValue: string;
  isFocused: boolean;
  placeholder: string;
  defaultPlaceholder: string;
  dropdown: VoyageFilterDropdown;
}

export interface VoyageFilterDropdown {
  type: VoyageFilterDropdownType;
  options: VoyageFilterDropdownOption[];
  filteredOptions: VoyageFilterDropdownOption[];
}

export interface VoyageFilterDropdownOption {
  value: string;
  description: string;
  img: string;
}

export interface OriginsAndDestinationsResponse {
  ports: string[];
}

export enum VoyagesSeasonMap {
  'All' = 0,
  'Jan-Mar' = 1,
  'Apr-Jun' = 2,
  'Jul-Sep' = 3,
  'Oct-Dec' = 4,
}

export interface VoyageDataResponse {
  start_time: string;
  end_time: string;
  ship_type: string;
  season: number;
  start_port: string;
  end_port: string;
  length_nm: number;
  duration_m: number;
  total_acc_time_m: number;
  total_encounter_events: number;
  geometry: number[][];
  sog?: number;
}

export interface VoyagesResponse {
  safest: VoyageDataResponse;
  most_efficient: VoyageDataResponse;
  shortest: VoyageDataResponse;
  fastest: VoyageDataResponse;
}

export enum VoyageType {
  SAFEST = 'safest',
  MOST_EFFICIENT = 'most_efficient',
  SHORTEST = 'shortest',
  FASTEST = 'fastest',
}

export enum VoyageTypeTitle {
  SAFEST = 'Safest Alternative',
  MOST_EFFICIENT = 'Most Efficient',
  SHORTEST = 'Shortest Alternative',
  FASTEST = 'Fastest Alternative',
}

export interface VoyageModel {
  startTime: string;
  endTime: string;
  shipType: string;
  season: VoyagesSeasonMap;
  startPort: string;
  endPort: string;
  lengthNm: number;
  durationMin: number;
  totalAccTimeMin: number;
  totalEncounterEvents: number;
  geometry: number[][];
  sog?: number;
  key: VoyageType;
  title: string;
  className: string;
  isAccTimeImportant: boolean;
  isDistanceImportant: boolean;
  isCloseEncountersImportant: boolean;
  isDurationImportant: boolean;
}
