import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  allFleetsFleet,
  Fleet,
  FleetDto,
  PolygonDto,
  Ship,
  ShipDto,
  ShipLastLocationDto,
  ShipLastLocationsDto,
} from '../models/fleet.model';
import { mapPolygonDtoToPolygon } from '../utils/fleets';
import { EMPTY_STATE_IMAGE_URL } from '../utils/ship';
import { Polygon, ShipLastLocation } from '../view-models/event.view.model';
import { Screenshot, ScreenshotDto } from '../view-models/gallery.view.model';
import { FleetReport } from '../view-models/reports.view.model';
import { IsBusyService } from './is-busy.service';

@Injectable({
  providedIn: 'root',
})
export class FleetService {
  constructor(private http: HttpClient, private isBusy: IsBusyService) {}

  getFleets$() {
    return this.http.get<FleetDto[]>(environment.api.admin_fleets).pipe(
      map(fleetsDto => {
        const fleets = fleetsDto
          .map(fleetDto => {
            const fleet: Fleet = {
              companyName: fleetDto.fleet_name,
              fleetId: fleetDto.fleet_id,
            };
            return fleet;
          })
          .sort((fleetNameA, fleetNameB) =>
            fleetNameA.companyName.localeCompare(fleetNameB.companyName)
          );
        return [allFleetsFleet, ...fleets];
      })
    );
  }

  getShips$(fleetId: number): Promise<Ship[]> {
    return this.isBusy.add(() =>
      firstValueFrom(
        this.http
          .get<ShipDto[]>(environment.api.admin_ships, {
            params: { fleet_id: fleetId },
          })
          .pipe(
            map((ships): Ship[] =>
              ships.map(ship => ({
                shipId: ship.ship_id,
                shipName: ship.ship_name,
              }))
            ),
            catchError(_ => of([]))
          )
      )
    );
  }

  getGeoPolygons$(): Promise<Polygon[]> {
    return this.isBusy.add(() =>
      firstValueFrom(
        this.http.get<PolygonDto[]>(environment.api.shipsGeoPolygons).pipe(
          map(dtos => dtos.map(dto => mapPolygonDtoToPolygon(dto))),
          catchError(_ => of([]))
        )
      )
    );
  }

  getGeoPolygonByFleetIdAndPolygonId$(
    fleetId: number,
    polygonId: string
  ): Promise<Polygon | undefined> {
    return this.isBusy.add(() =>
      firstValueFrom(
        this.http
          .get<PolygonDto>(
            `${environment.api.polygonByFleetId}${fleetId}/polygon/${polygonId}`
          )
          .pipe(
            map(dto => mapPolygonDtoToPolygon(dto)),
            catchError(_ => of(undefined))
          )
      )
    );
  }

  getShipLastLocations$(
    shipId: number | undefined,
    endDate: Date
  ): Promise<ShipLastLocation[]> {
    return this.isBusy.add(() =>
      firstValueFrom(
        this.http
          .get<ShipLastLocationsDto>(
            `${environment.api.ships}/${shipId}/last_locations`,
            {
              params: {
                end_date: endDate.toISOString(),
                start_date: new Date(
                  endDate.getTime() - 24 * 60 * 60 * 1000
                ).toISOString(),
              },
            }
          )
          .pipe(
            map((shipLastLocations: ShipLastLocationsDto): ShipLastLocation[] =>
              shipLastLocations.locations.map(
                (lastLocation: ShipLastLocationDto) => {
                  const date = new Date(lastLocation.time.split('+')[0]);
                  const hours = date.getHours().toString().padStart(2, '0');
                  const minutes = date.getMinutes().toString().padStart(2, '0');
                  return {
                    latitude: lastLocation.latitude,
                    longitude: lastLocation.longitude,
                    sog: Math.floor(lastLocation.sog),
                    time: `${hours}:${minutes}`,
                  };
                }
              )
            ),
            catchError(_ => of([]))
          )
      )
    );
  }

  async getScreenshots(): Promise<Screenshot[]> {
    try {
      const screenshotDtos = await firstValueFrom(
        this.http.get<ScreenshotDto[]>(`${environment.api.shipsGallery}`)
      );

      const screenshots = screenshotDtos
        .map((dto: ScreenshotDto) => ({
          shipId: dto.ship_id,
          shipName: dto.ship_name,
          timestamp: dto.timestamp,
          fleetId: dto.fleet_id,
          fleetName: dto.fleet_name,
          cdnUrl:
            dto.cdn_url && dto.cdn_url !== 'null'
              ? dto.cdn_url
              : EMPTY_STATE_IMAGE_URL,
          location: dto.textual_location,
          hostName: dto.host_name,
        }))
        .sort((a, b) => a.shipName.localeCompare(b.shipName));
      return screenshots;
    } catch (error) {
      console.error('Error fetching screenshots:', error);
      return [];
    }
  }

  getShipsOfSubFleetManager$(): Promise<number[]> {
    return this.isBusy.add(() =>
      firstValueFrom(
        this.http
          .get<number[]>(environment.api.subFleetManagerShips)
          .pipe(map((ships): number[] => ships))
          .pipe(catchError(_ => of([])))
      )
    );
  }

  getFleetReports(): Promise<FleetReport[]> {
    return firstValueFrom(
      this.http.get<FleetReport[]>(`${environment.api.fleetReports}`)
    );
  }

  uploadFleetReport(file: File, year: number) {
    const formData = new FormData();
    formData.append('pdf', file, file.name);
    formData.append('year', year.toString());

    return this.http.post(`${environment.api.fleetReports}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
