import { createAction, props } from '@ngrx/store';
import { DateFilter } from 'src/app/shared/view-models/dates.view.model';

export const saveSelectedDatesFilter = createAction(
  '[Filters Effects] save selected dates filter',
  props<{ dateFilter: Partial<DateFilter> }>()
);

export const setDatesFilterState = createAction(
  '[Filters Effects] Set dates filters state',
  props<{ isDisabled: boolean }>()
);

export const setCustomDateFilterState = createAction(
  '[Filters Effects] Set custom dates filters state',
  props<{ isDisabled: boolean }>()
);

export const resetDateFilterToDefault = createAction(
  '[Filters Effects] Reset date filter to default',
  props<{ dateFilter: Partial<DateFilter> }>()
);

export const setDateFilterByUrlParams = createAction(
  '[Filters Effects] Set date filter by url params',
  props<{ dateFilter: Partial<DateFilter> }>()
);
