import dayjs from 'dayjs';

export type DateFilterKeys =
  | 'Last 7 days'
  | 'Last 30 days'
  | 'Last 90 days'
  | 'Last Year'
  | 'Custom dates average';
export type DateFilter = {
  [key in DateFilterKeys]: Dates | undefined;
};

export const dateFilters: DateFilter = {
  'Last 7 days': {
    startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
    endDate: new Date(),
  },
  'Last 30 days': {
    startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
    endDate: new Date(),
  },
  'Last 90 days': {
    startDate: new Date(new Date().setDate(new Date().getDate() - 89)),
    endDate: new Date(),
  },
  'Last Year': {
    startDate: new Date(new Date().setDate(new Date().getDate() - 364)),
    endDate: new Date(),
  },
  // eslint-disable-next-line prettier/prettier
  'Custom dates average': undefined,
};

export enum DateGraphFilterKeys {
  ThreeMonths = '3 M',
  SixMonths = '6 M',
  TwelveMonths = '12 M',
}

export type DateGraphFilter = Record<DateGraphFilterKeys, Dates>;

export const getInsightCompletedMonthDates = (fromDate: string) => {
  if (!dayjs(fromDate).isValid()) {
    return undefined;
  }

  const now = dayjs(fromDate);
  let year = now.year();
  let month = now.month();
  const day = now.date();

  // If today is before the 5th, select month - 2 else month - 1
  if (day < 5) {
    if (month === 0) {
      year--;
      month = 10;
    } else if (month === 1) {
      year--;
      month = 11;
    } else {
      month -= 2;
    }
  } else {
    if (month === 0) {
      year--;
      month = 11;
    } else {
      month--;
    }
  }

  const startDate = dayjs(new Date(year, month, 1)).startOf('day').toDate();
  const endDate = dayjs(new Date(year, month + 1, 0))
    .endOf('day')
    .toDate();

  const dates: Dates = {
    startDate,
    endDate,
  };

  return dates;
};

export const getInsightCompletedMonthName = (fromDate: string) => {
  const dates = getInsightCompletedMonthDates(fromDate);

  return dates ? getMonthName(new Date(dates.startDate)) : '';
};

export const getMonthName = (date: Date) => {
  return new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
};

const getStartOfMonth = (monthsAgo: number) => {
  const date = new Date();
  date.setMonth(date.getMonth() - monthsAgo, 1);
  return date;
};

export const dateGraphFilters: DateGraphFilter = {
  [DateGraphFilterKeys.ThreeMonths]: {
    startDate: getStartOfMonth(3),
    endDate: new Date(),
  },
  [DateGraphFilterKeys.SixMonths]: {
    startDate: getStartOfMonth(6),
    endDate: new Date(),
  },
  [DateGraphFilterKeys.TwelveMonths]: {
    startDate: getStartOfMonth(12),
    endDate: new Date(),
  },
};

export interface Dates {
  readonly startDate: Date;
  readonly endDate: Date;
}
