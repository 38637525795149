import { GeoJSONFeature } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import {
  MapboxCustomSetting,
  MapboxSettingType,
  ShipMapEntity,
} from 'src/app/mapbox/models/mapbox.models';

export const SHIP_SETTING_ID = 'ship';

export const GetShipSetting = (
  ships$: BehaviorSubject<ShipMapEntity[]>
): MapboxCustomSetting => {
  const getShipFeatures = () => {
    return ships$.getValue().map(
      (ship): GeoJSONFeature => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [ship.long, ship.lat],
        },
        source: 'ship',
        properties: {
          id: ship.shipId,
          icon: ship.image,
          category: 'ship',
        },
      })
    );
  };

  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'ship',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getShipFeatures(),
          },
        },
        updateFeatures: getShipFeatures,
      },
    ],
    layers: [
      {
        id: 'ship',
        type: 'symbol',
        source: 'ship',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      },
    ],
    popups: [],
  };
};
