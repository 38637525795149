import { Model } from 'common';
import { MetricEvents } from 'src/app/core/models/metrics.model';

export function initMixpanel(): void {}

export function addUserIdentity(user: Model.User): void {}

export function optOutTrackingWhenUserIsTester(user: Model.User): void {}

export function sendMetricEvent(eventName: MetricEvents, data?: any): void {}
export {};
