import { GeoJSONFeature } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import {
  MapboxCustomSetting,
  MapboxSettingType,
  MapEntity,
} from 'src/app/mapbox/models/mapbox.models';

export const EVENT_SETTING_ID = 'event';

export const GetEventSetting = (
  events$: BehaviorSubject<MapEntity[]>
): MapboxCustomSetting => {
  const getEventFeatures = () =>
    events$.getValue().map(
      (event): GeoJSONFeature => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [event.long, event.lat],
        },
        source: 'event',
        properties: {
          id: event.id,
          icon: event.image,
          category: 'event',
          textLabel: event.textLabel,
          labelType: event.labelType,
        },
      })
    );
  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'event',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getEventFeatures(),
          },
        },
        updateFeatures: getEventFeatures,
      },
    ],
    layers: [
      {
        id: 'event',
        type: 'symbol',
        source: 'event',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      },
    ],
    popups: [],
  };
};
