import {
  getTitleAndLinkDetails,
  insightTitleByType,
} from 'src/app/shared/models/InsightData.model';
import { InsightCard } from 'src/app/shared/view-models/insight.view.model';
import {
  HighDuringLastWeekInsight,
  InsightDataDto,
  InsightType,
  ShipWithLeastEventsInsight,
  ShipWithMostEventsInsight,
} from '../models/insights.models';

export function mapInsightsDtoToInsights(
  insights: InsightDataDto[]
): InsightCard[] {
  return insights.map((insight: InsightDataDto) => {
    return {
      insightId: insight.insightId,
      insightType: insight.insightType,
      title: insightTitleByType[insight.insightType],
      content: InsightContentTemplates[insight.insightType](
        insight.insightData
      ),
      dateUpdated: new Date(insight.lastUpdatedAt),
      link: getTitleAndLinkDetails(insight.insightType, insight.insightData),
      likeVote: insight.likeVote,
      readStatus: insight.readStatus,
    };
  });
}
export const InsightContentTemplates: Record<
  InsightType,
  (content: any) => string
> = {
  HighDuringLastWeekInsight: (content: HighDuringLastWeekInsight) => `
    <span>In the last 7 days, your ships had an average of 
      <strong>
        ${content.formattedAvgNumOfHighEventsPerShip} high severity events
      </strong> 
      per ship, totaling ${content.numOfSeverityEvents} events.
    </span>
    <br />
    <span>For comparison, Orca benchmark indicates
      <strong>
        ${content.formattedBenchmarkAvgNumOfHighEventsPerShip}
        high severity events per ship.
      </strong>
    </span>
  `,
  ShipWithMostEventsInsight: (content: ShipWithMostEventsInsight) => `
    <strong>
      ${content.outsandShipName} had 
      ${content.formattedOutstandShipNumOfEventsPer1000Nm}
    </strong>
    events per 1000 NM of sailing 
    (${content.formattedOutstandShipTotalNumOfEvents}
    events in total) during the last completed month,
    which is the highest in your fleet.
    <br />
    On average, your ships had 
    ${content.formattedFleetAvgNumOfEventPer1000Nm}
    events per every 1000 NM.
  `,
  ShipWithLeastEventsInsight: (content: ShipWithLeastEventsInsight) => `
    <strong>
      ${content.outsandShipName} had 
      ${content.formattedOutstandShipNumOfEventsPer1000Nm}
    </strong>
    events per 1000 NM of sailing 
    (${content.formattedOutstandShipTotalNumOfEvents}
    events in total) during the last completed month,
    which is the lowest in your fleet.
    <br />
    On average, your ships had 
    ${content.formattedFleetAvgNumOfEventPer1000Nm}
    events per every 1000 NM.
  `,
};
