import { GeoJSONFeature, LngLat, Popup } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { ShipSafteyScore } from 'src/app/core/models/ship.model';
import {
  EventMapEntity,
  MapboxCustomSetting,
  MapboxSettingType,
  ShipMapEntity,
} from 'src/app/mapbox/models/mapbox.models';
import { CreatePopupFromComponent } from 'src/app/mapbox/utils/mapbox.utility';
import { ShipCardOuputs, ShipScreenshot } from '../models/ship.models';
import { getShipIcon } from '../utils/icons';

export const SHIP_FOCUS_SETTING_ID = 'ShipFocus';

export async function CreateShipFocusSetting(
  isShipCaptain: boolean,
  outputs: ShipCardOuputs,
  ships$: BehaviorSubject<ShipMapEntity[]>,
  screenshot$: BehaviorSubject<ShipScreenshot | null>,
  focusedShip$: BehaviorSubject<ShipMapEntity | null>,
  shipSaftyScores$: BehaviorSubject<ShipSafteyScore | null>,
  events$: BehaviorSubject<EventMapEntity[]>
): Promise<MapboxCustomSetting> {
  const getFocusedShip = () =>
    ships$
      .getValue()
      .find(ship => ship.shipId === focusedShip$.getValue()?.shipId);
  const getPopups = () => {
    const focusedShip = getFocusedShip();
    return focusedShip
      ? [
          CreateShipStatusPopup(
            focusedShip,
            screenshot$.getValue(),
            shipSaftyScores$.getValue()?.scoreStart ?? NaN,
            shipSaftyScores$.getValue()?.scoreEnd ?? NaN,
            outputs,
            isShipCaptain
          ),
        ]
      : [];
  };
  const getEventFeatures = (): GeoJSONFeature[] =>
    events$
      .getValue()
      .filter(event => event.shipId === getFocusedShip()?.shipId)
      .map(
        (event): GeoJSONFeature => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [event.long, event.lat],
          },
          source: 'ship-focus',
          properties: {
            id: event.id,
            icon: event.image,
            category: 'event',
          },
        })
      );
  const getShipFeature = (): GeoJSONFeature[] => {
    const focusedShip = getFocusedShip();
    return focusedShip
      ? [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [focusedShip.long, focusedShip.lat],
            },
            source: 'ship-focus',
            properties: {
              id: focusedShip.shipId,
              icon: getShipIcon(focusedShip, true),
              category: 'ship',
            },
          },
        ]
      : [];
  };

  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'ship-focus',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: getEventFeatures().concat(getShipFeature()),
          },
        },
        updateFeatures: () => getEventFeatures().concat(getShipFeature()),
      },
    ],
    layers: [
      {
        id: 'ship-focus',
        type: 'symbol',
        source: 'ship-focus',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      },
    ],
    popups: getPopups(),
    updatePopups: getPopups,
  };
}

function CreateShipStatusPopup(
  ship: ShipMapEntity,
  screenshot: ShipScreenshot | null,
  startDateAvg: number,
  endDateAvg: number,
  shipCardOutputs: ShipCardOuputs,
  isShipCaptain: boolean
): Popup {
  const popup = CreatePopupFromComponent<{
    shipData: ShipMapEntity | null;
    screenshot: ShipScreenshot | null;
    endDateAvgShipSafetyScore: number;
    startDateAvgShipSafetyScore: number;
    showLiveStreamFlag: boolean;
    isLiveStreamOpen: boolean;
    shipCardOutputs: ShipCardOuputs;
    isShipCaptain: boolean;
  }>('app-ship-profile-card', {
    shipData: ship,
    screenshot: screenshot,
    endDateAvgShipSafetyScore: endDateAvg,
    startDateAvgShipSafetyScore: startDateAvg,
    showLiveStreamFlag: true,
    isLiveStreamOpen: false,
    shipCardOutputs: shipCardOutputs,
    isShipCaptain,
  });
  popup.setLngLat(new LngLat(ship.long, ship.lat).wrap());
  popup.setOffset(-460);
  return popup;
}
