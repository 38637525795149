<div class="container">
  <div class="header">
    <app-widget-title
      [widgetTitleType]="'basic'"
      [title]="'Filters'"></app-widget-title>
    <span class="button-text primary" (click)="resetToDefaultAllFilters()"
      >Reset to default</span
    >
  </div>
  <div class="scrollable">
    <div class="general-filters">
      <div class="preset-filters-container">
        <mat-accordion>
          <mat-expansion-panel
            [disabled]="true"
            [expanded]="true"
            hideToggle
            class="custom-expansion no-padding">
            <mat-expansion-panel-header class="preset-accordion">
              <mat-panel-title class="title">
                <mat-icon class="icon">expand_more</mat-icon>
                <span class="body1">My Saved Presets</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-presets
              label="None"
              defaultOption="None"
              [selectedPreset]="appliedPresetFilter$ | async"
              (filterChanged)="onFilterChanged($event)"></app-presets>
          </mat-expansion-panel>
        </mat-accordion>
        <hr />
      </div>

      <app-selection-dropdown
        [options]="(shipNamesDropdown$ | async)!"
        [selectedValue]="(appliedShipNameFilter$ | async)!"
        [title]="'Ships'"
        [isMultipleChoice]="true"
        (valueChange)="
          onEventFiltersChange($event, 'shipName')
        "></app-selection-dropdown>

      <div class="port-checkbox" *ngIf="(isShipCaptain$ | async) === false">
        <mat-checkbox
          color="primary"
          class="primary-outline"
          [checked]="(appliedShowOnlyRt$ | async)!"
          (change)="
            onEventFiltersChange($event.checked ? true : false, 'showOnlyRt')
          ">
        </mat-checkbox>
        <div class="only-live-container">
          <img src="./assets/icons/only_live_icon.svg" />
          <span class="body1">Show Only Live Events</span>
        </div>
      </div>

      <app-severity-filter></app-severity-filter>
      <app-selection-dropdown
        [options]="(eventTypeDropdown$ | async)!"
        [selectedValue]="(appliedEventTypeFilter$ | async)!"
        [title]="'Event Type'"
        [isMultipleChoice]="true"
        (valueChange)="
          onEventFiltersChange($event, 'eventType')
        "></app-selection-dropdown>
      <app-selection-dropdown
        [options]="(congestionLevelDropdown$ | async)!"
        [selectedValue]="(appliedCongestionLevelFilter$ | async)!"
        [title]="'Congestion Level'"
        [isMultipleChoice]="true"
        (valueChange)="
          onEventFiltersChange($event, 'congestionLevel')
        "></app-selection-dropdown>

      <app-locations></app-locations>

      <!-- <app-selection-dropdown
        #locationsFilter
        [options]="(locationsDropdown$ | async)!"
        [title]="'Location'"
        [selectedValue]="(appliedLocationsFilter$ | async)!"
        [isMultipleChoice]="true"></app-selection-dropdown> -->

      <app-range-slider
        (rangeChanged)="onEventFiltersChange($event, 'ownSog')"
        [title]="'Own SOG'"
        [customSliderOptions]="(sliderOwnSog$ | async)!"></app-range-slider>
      <div class="port-checkbox">
        <mat-checkbox
          color="primary"
          class="primary-outline"
          [checked]="appliedInPort$ | async"
          (change)="
            onEventFiltersChange($event.checked ? null : false, 'inPort')
          ">
        </mat-checkbox>
        <span class="body1">Show events in ports</span>
      </div>
    </div>
    <hr />
    <mat-accordion #eventFiltersAccordion="matAccordion" [multi]="true">
      <mat-expansion-panel
        #closeEncounterPanel
        hideToggle
        class="custom-expansion"
        [disabled]="(isCloseEncounterEvent$ | async) === false"
        [expanded]="
          (closeEncounterPanel.expanded && (isCloseEncounterEvent$ | async)) ===
          true
        ">
        <mat-expansion-panel-header>
          <mat-panel-title class="title">
            <mat-icon class="icon">expand_more</mat-icon>
            <span class="body1">Close Encounter</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-close-encounter-filter></app-close-encounter-filter>
      </mat-expansion-panel>
      <hr />
      <mat-expansion-panel
        #ukcPanel
        hideToggle
        class="custom-expansion"
        [disabled]="(isUKCEvent$ | async) === false"
        [expanded]="(ukcPanel.expanded && (isUKCEvent$ | async)) === true">
        <mat-expansion-panel-header>
          <mat-panel-title class="title">
            <mat-icon class="icon">expand_more</mat-icon>
            <span class="body1">UKC</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-ukc-filter></app-ukc-filter>
      </mat-expansion-panel>
      <hr />
      <mat-expansion-panel
        #highPitchRollPanel
        hideToggle
        class="custom-expansion"
        [disabled]="(isHighPitchRollEvent$ | async) === false"
        [expanded]="
          (highPitchRollPanel.expanded && (isHighPitchRollEvent$ | async)) ===
          true
        ">
        <mat-expansion-panel-header>
          <mat-panel-title class="title">
            <mat-icon class="icon">expand_more</mat-icon>
            <span class="body1">High Pitch/Roll</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-high-pitch-roll-filter></app-high-pitch-roll-filter>
      </mat-expansion-panel>
      <hr />
      <mat-expansion-panel
        #speedDropPanel
        hideToggle
        class="custom-expansion"
        [disabled]="(isSpeedDropEvent$ | async) === false"
        [expanded]="
          (speedDropPanel.expanded && (isSpeedDropEvent$ | async)) === true
        ">
        <mat-expansion-panel-header>
          <mat-panel-title class="title">
            <mat-icon class="icon">expand_more</mat-icon>
            <span class="body1">Speed Drop</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-speed-drop-filter></app-speed-drop-filter>
      </mat-expansion-panel>
      <hr />
      <mat-expansion-panel
        #highRotPanel
        hideToggle
        class="custom-expansion"
        [disabled]="(isHighRotEvent$ | async) === false"
        [expanded]="
          (highRotPanel.expanded && (isHighRotEvent$ | async)) === true
        ">
        <mat-expansion-panel-header>
          <mat-panel-title class="title">
            <mat-icon class="icon">expand_more</mat-icon>
            <span class="body1">High ROT</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-high-rot-filter></app-high-rot-filter>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="footer">
    <button
      mat-stroked-button
      color="primary"
      class="primary-border"
      [disabled]="isSavePresetDisabled$ | async"
      (click)="saveFilters()">
      Save as Preset
    </button>
  </div>
</div>
