import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DestroyRef } from 'projects/orca-lib-main/projects/orca-lib/src/lib/services/destroy-ref.service';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  Observable,
  of,
  startWith,
  takeUntil,
} from 'rxjs';
import { ShipMapEntity } from 'src/app/mapbox/models/mapbox.models';
import { ShipScreenshot } from 'src/app/maritime-map/models/ship.models';
import { ColumnDefinition } from 'src/app/shared/components/table/models/column-definition.model';
import { RowKey } from 'src/app/shared/components/table/models/row-key.model';
import { TrackBySelector } from 'src/app/shared/components/table/models/track-by-selector.model';
import { SortDetails } from '../../../shared/components/table/models/sort-details.model';
import { ReportsDialogComponent } from '../../components/dialogs/reports-dialog/reports-dialog.component';
import { ShipPosition } from '../../models/map.models';
import { Ship, ShipSafteyScore } from '../../models/ship.model';
import { FeatureFlagService } from '../../services/feature-toggle.service';
import { CoreState } from '../../store/state/core.state';
import {
  AuthenticationSelectors,
  ShipsActions,
  ShipsSelectors,
} from '../../store/types';
import { Screenshot } from '../../view-models/gallery.view.model';

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.scss'],
  providers: [DestroyRef],
})
export class FleetComponent implements OnInit {
  ships$!: Observable<Ship[]>;
  isFleetManagerOrAdminWithFleet$!: Observable<boolean | null>;
  token$: Observable<string | undefined> = of(undefined);
  allMapShips$ = new BehaviorSubject<ShipMapEntity[]>([]);
  shipsOnMap$ = new BehaviorSubject<ShipMapEntity[]>([]);
  screenshots$ = new BehaviorSubject<ShipScreenshot[]>([]);
  shipsSafetyScores$ = new BehaviorSubject<ShipSafteyScore[]>([]);
  selectedShipPosition$ = new BehaviorSubject<ShipPosition | undefined>(
    undefined
  );

  columns: ColumnDefinition<Ship>[] = [
    { id: 'name', header: 'Ship Name', value: s => s.shipName, width: 160 },
    {
      id: 'safety',
      header: 'Safety Score',
      value: s => s.safetyScore,
      width: 86,
    },
    {
      id: 'score-trend',
      header: 'Score Trend',
      value: s => s.scoreTrend,
      width: 80,
    },
    {
      id: 'last-connection',
      header: 'Last Connection',
      value: s => s.lastConnection?.getTime() ?? 0,
      width: 100,
    },
    { id: 'view', header: '', width: 70 },
  ];

  trackBy: TrackBySelector<Ship> = ship => ship.shipId;
  sortBy: SortDetails | null = null;
  selectedShipId$ = new BehaviorSubject<number | null>(null);
  fleetName$!: Observable<string | undefined>;
  isAdmin$!: Observable<boolean | null>;

  searchBoxControl = new FormControl<string>('');
  searchBox$!: Observable<string | null>;
  startDateAvgShipSafetyScore$!: Observable<number>;
  endDateAvgShipSafetyScore$!: Observable<number>;
  shipData$!: Observable<Ship | null>;
  screenshot$!: Observable<Screenshot | null>;
  showFlag$!: Observable<boolean>;

  isVisible = false;
  constructor(
    private store: Store<CoreState>,
    private featureFlag: FeatureFlagService,
    private router: Router,
    private destroyRef: DestroyRef,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.isFleetManagerOrAdminWithFleet$ = this.store.select(
      AuthenticationSelectors.selectIsFleetManagerOrAdminWithFleet
    );

    this.token$ = this.store.select(AuthenticationSelectors.selectToken);
    this.store
      .select(ShipsSelectors.selectMapShips)
      .pipe(takeUntil(this.destroyRef.destroy$))
      .subscribe(ships => {
        this.allMapShips$.next(ships);
        this.showMapShips(null);
      });
    this.store
      .select(ShipsSelectors.selectSelectedShipPosition)
      .pipe(takeUntil(this.destroyRef.destroy$))
      .subscribe(position =>
        this.selectedShipPosition$.next({
          height: 20000000,
          ...position,
          shipId: position.shipId,
        })
      );
    this.store
      .select(ShipsSelectors.selectScreenshots)
      .pipe(takeUntil(this.destroyRef.destroy$))
      .subscribe(screenshots => this.screenshots$.next(screenshots));

    this.store
      .select(ShipsSelectors.selectSafteyScoreShips)
      .pipe(takeUntil(this.destroyRef.destroy$))
      .subscribe(scores => this.shipsSafetyScores$.next(scores));

    this.showFlag$ = this.featureFlag.getFeatureFlag$('LiveView');

    this.endDateAvgShipSafetyScore$ = this.store.select(
      ShipsSelectors.selectEndDateAvgShipSafetyScore
    );

    this.startDateAvgShipSafetyScore$ = this.store.select(
      ShipsSelectors.selectStartDateAvgShipSafetyScore
    );

    this.shipData$ = this.store.select(ShipsSelectors.selectShipRealTimeData);
    this.screenshot$ = this.store.select(ShipsSelectors.selectShipScreenshot);
    this.showFlag$ = this.featureFlag.getFeatureFlag$('LiveView');

    this.sortBy = history.state['sortBySafetyLevel'];

    this.store
      .select(ShipsSelectors.selectSelectedShipId)
      .pipe(takeUntil(this.destroyRef.destroy$))
      .subscribe(shipId => this.selectedShipId$.next(shipId));

    this.fleetName$ = this.store.select(
      AuthenticationSelectors.selectFleetName
    );

    this.isAdmin$ = this.store.select(
      AuthenticationSelectors.selectIsUserOrcaAdmin
    );

    this.searchBox$ = this.searchBoxControl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      map(v => v!.toLowerCase())
    );

    this.ships$ = combineLatest([
      this.store.select(ShipsSelectors.selectShips),
      this.searchBox$,
    ]).pipe(
      map(([ships, shipNameToSearch]) => {
        if (shipNameToSearch === '') {
          return [...ships];
        } else {
          return ships.filter(ship =>
            ship.shipName.toLowerCase().includes(shipNameToSearch!)
          );
        }
      })
    );
  }

  onShipListSelection(key: RowKey | null) {
    if (key != null) {
      this.store.dispatch(
        ShipsActions.fetchAndSaveShipDataOnSelectListShip({
          shipId: key as number,
        })
      );
      this.showMapShips(key as number);
    }
  }
  shipTooltipButtonClicked(navigate: string) {
    this.router.navigateByUrl(navigate);
  }

  onShipMapSelection(entityParams: string[]): void {
    const shipId = entityParams[0];
    this.store.dispatch(
      ShipsActions.fetchAndSaveShipDataOnSelectMapShip({
        shipId: Number(shipId),
      })
    );
    this.showMapShips(Number(shipId));
  }
  onCloseTooltip() {
    this.showMapShips(null);
    this.selectedShipId$.next(null);
  }

  onReportsClick() {
    this.dialog.open(ReportsDialogComponent, {
      panelClass: 'reports-dialog-container',
    });
  }

  private showMapShips(selectedShipId: number | null) {
    this.shipsOnMap$.next(
      selectedShipId
        ? this.allMapShips$
            .getValue()
            .filter(ship => ship.shipId === selectedShipId)
        : this.allMapShips$.getValue()
    );
  }
}
