import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
} from '@angular/core';
import { TooltipComponent } from '../components/tooltip/tooltip.component';
import { TooltipService } from '../services/tooltip.service';

@Directive({
  selector: '[appTooltip]',
  standalone: true,
})
export class TooltipDirective {
  private readonly MINIMUM_SPACE_FOR_TOOLTIP = 250; // pixels
  @Input('appTooltip') templateContent!: TemplateRef<any>;
  @Input() title?: string;
  @Input() showCloseButton = true;
  @Input() tooltipContext: any;
  private overlayRef: OverlayRef | null = null;
  private tooltipRef: ComponentRef<TooltipComponent> | null = null;
  private intersectionObserver: IntersectionObserver | null = null;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private tooltipService: TooltipService
  ) {
    this.setupIntersectionObserver();
  }

  private setupIntersectionObserver(): void {
    this.intersectionObserver = new IntersectionObserver(
      entries => {
        if (!entries[0].isIntersecting) {
          this.hideTooltip();
        }
      },
      { threshold: 0.1 }
    );

    this.intersectionObserver.observe(this.elementRef.nativeElement);
  }

  @HostListener('click', ['$event'])
  showTooltip(event: Event): void {
    event.stopPropagation();

    const activeTooltip = this.tooltipService.getActiveTooltip();
    if (activeTooltip && activeTooltip !== this) {
      activeTooltip.hideTooltip();
    }

    if (this.overlayRef) {
      this.hideTooltip();
      return;
    }

    if (this.templateContent) {
      const elementRect = this.elementRef.nativeElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const spaceBelow = viewportHeight - elementRect.bottom;
      const isAbove = spaceBelow < this.MINIMUM_SPACE_FOR_TOOLTIP;

      const position: ConnectedPosition = isAbove
        ? {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
            offsetX: 25,
            offsetY: -10,
          }
        : {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetX: 25,
            offsetY: 20,
          };

      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([position])
        .withPush(true)
        .withViewportMargin(10)
        .withLockedPosition(false);

      this.overlayRef = this.overlay.create({
        positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
        hasBackdrop: false,
        panelClass: isAbove ? 'tooltip-above' : 'tooltip-below',
      });

      const tooltipPortal = new ComponentPortal(TooltipComponent);
      this.tooltipRef = this.overlayRef.attach(tooltipPortal);

      Object.assign(this.tooltipRef.instance, {
        templateContent: this.templateContent,
        context: this.tooltipContext,
        title: this.title,
        showCloseButton: this.showCloseButton,
        isAbove,
      });

      this.tooltipService.setActiveTooltip(this);
    }
  }

  @HostListener('document:click', ['$event'])
  hideTooltip(event?: Event): void {
    if (event) {
      const clickTarget = event.target as HTMLElement;
      const isClickOnTrigger =
        this.elementRef.nativeElement.contains(clickTarget);
      const isClickInTooltip =
        this.overlayRef?.overlayElement.contains(clickTarget);

      if (
        isClickOnTrigger ||
        (isClickInTooltip && !clickTarget.closest('.close-tooltip'))
      ) {
        return;
      }
    }

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.tooltipRef = null;
      this.tooltipService.setActiveTooltip(null);
    }
  }

  ngOnDestroy(): void {
    this.hideTooltip();
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
      this.intersectionObserver = null;
    }
  }
}
