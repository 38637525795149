<app-mapbox
  [settings]="mapSettings"
  [entitiesConfiguration]="entitiesConfiguration"
  [mapOptions]="mapOptions"
  [flyTo$]="flyTo$"
  (entityClicked)="HandleEntityClicked($event)">
  <ng-template #mapMenu>
    <app-outside-click-detector (outsideClick)="OnOutOfMenuClick()">
      <app-map-menu-plugin
        [showMenu$]="menuToShow$"
        [buttons]="menuButtons"
        (menuButtonClick)="OnMenuButtonClick($event)">
        <ng-template #menuTitle>
          <app-weather-menu-title
            class="weather-menu-title-integration-id"
            *ngIf="menuToShow$.getValue() === 'weather'"
            [lastUpdateTime$]="lastUpdateTime$"
            [toggleState$]="weatherMenuToggle$"></app-weather-menu-title>
          <app-layers-menu-title
            class="layers-menu-title-integration-id"
            *ngIf="menuToShow$.getValue() === 'layers'"></app-layers-menu-title>
        </ng-template>
        <ng-template #menuContent>
          <app-layers-menu-form
            class="layers-menu-integration-id"
            *ngIf="menuToShow$.getValue() === 'layers'"
            [optionGroups$]="layersFormOptionGroups$"></app-layers-menu-form>
          <app-weather-menu-form
            class="weather-menu-integration-id"
            *ngIf="menuToShow$.getValue() === 'weather'"
            [weatherSettingOptions]="weatherMenuOptions"
            [weatherSelection$]="weatherSelection$"></app-weather-menu-form>
        </ng-template>
      </app-map-menu-plugin>
    </app-outside-click-detector>
  </ng-template>
  <ng-template #mapLegend>
    <app-map-legend-plugin
      *ngIf="showLegend$ | async"
      [configurations$]="legendConfig$"></app-map-legend-plugin>
  </ng-template>
</app-mapbox>
