import { NgModule } from '@angular/core';
import { MapLegendPluginComponent } from './components/map-legend-plugin/map-legend-plugin.component';
import { MapMenuPluginComponent } from './components/map-menu-plugin/map-menu-plugin.component';
import { MapboxComponent } from './components/mapbox/mapbox.component';

@NgModule({
  imports: [MapboxComponent, MapMenuPluginComponent, MapLegendPluginComponent],
  exports: [MapboxComponent, MapMenuPluginComponent, MapLegendPluginComponent],
})
export class MapboxModule {}
