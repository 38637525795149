<div class="container">
  <div class="subfleet-table">
    <div class="header">
      <div class="search-box">
        <mat-form-field
          appearance="outline"
          data-test="subfleets-search-box"
          class="search-form">
          <mat-label>Search</mat-label>
          <input
            matInput
            [formControl]="searchBoxControl"
            autocomplete="off"
            class="search-form" />
          <mat-icon matSuffix class="search-icon">search</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <orc-table
      *ngIf="subFleets.length > 0; else NoSubFleets"
      [data]="subFleets"
      [columns]="columns"
      [sortBy]="sortBy"
      [trackBy]="trackBy">
      <orc-column id="subFleetId" data-test="subfleet-id-col">
        <div *orcTableHeader class="header-text text-x-small text-bold">
          <div>Subfleet Id</div>
        </div>
        <div *orcTableCell="let item = item" class="cell text-base">
          <div class="text-bold text-base">
            {{ item.subFleetId }}
          </div>
        </div>
      </orc-column>

      <orc-column id="fleetId">
        <div *orcTableHeader class="header-text text-x-small text-bold">
          <div>Fleet Id</div>
        </div>
        <div *orcTableCell="let item = item" class="cell text-base">
          <div class="text-x-small text-bold">{{ item.fleetId }}</div>
        </div>
      </orc-column>

      <orc-column id="subFleetName">
        <div *orcTableHeader class="header-text text-x-small text-bold">
          <div>Subfleet Name</div>
        </div>
        <div *orcTableCell="let item = item" class="cell text-base">
          <div class="text-x-small text-bold">{{ item.subFleetName }}</div>
        </div>
      </orc-column>

      <orc-column id="shipsList">
        <div *orcTableHeader class="header-text text-x-small text-bold">
          <div>Associated Ships</div>
        </div>
        <div *orcTableCell="let item = item" class="cell text-base">
          <div class="text-x-small text-bold">{{ item.shipsList.length }}</div>
        </div>
      </orc-column>

      <orc-column id="actions">
        <div *orcTableCell="let item = item" class="cell">
          <div class="action-buttons">
            <button mat-icon-button aria-label="Edit subfleet">
              <div data-svg-wrapper>
                <img src="assets/icons/edit.svg" alt="edit" />
              </div>
            </button>

            <button mat-icon-button aria-label="Delete subfleet">
              <div data-svg-wrapper>
                <img src="assets/icons/trash.svg" alt="delete" />
              </div>
            </button>
          </div>
        </div>
      </orc-column>
    </orc-table>

    <ng-template #NoSubFleets>
      <div class="no-subfleets-container">
        <div class="no-subfleets-text">No subfleets found</div>
      </div>
    </ng-template>
  </div>
</div>
