import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviateNumber',
  standalone: true,
})
export class AbbreviateNumberPipe implements PipeTransform {
  transform(
    value: number,
    abbreviation: string = 'k'
  ): { value: number; abbreviation: string } {
    const positiveValue = Math.abs(value);
    let abbreviatedValue: { value: number; abbreviation: string } = {
      value: positiveValue,
      abbreviation: '',
    };
    if (positiveValue >= 1000) {
      abbreviatedValue = {
        value: positiveValue / 1000,
        abbreviation: abbreviation,
      };
    }
    return abbreviatedValue;
  }
}
