import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { EventsRepository } from 'src/app/core/pages/events/events.repository';
import { CoreState } from 'src/app/core/store/state/core.state';
import { EventsActions, EventsSelectors } from 'src/app/core/store/types';
import {
  AppliedEventFilters,
  ViewColregClassificationNames,
  ViewTargetTypes,
} from 'src/app/core/view-models/filter.view.model';
import {
  DropdownViewModel,
  ViewValue,
} from 'src/app/shared/view-models/dropdown.options.view.model';
import { CustomSliderOptions } from 'src/app/shared/view-models/slider.options.model';

@Component({
  selector: 'app-close-encounter-filter',
  templateUrl: './close-encounter-filter.component.html',
  styleUrls: ['./close-encounter-filter.component.scss'],
})
export class CloseEncounterFilterComponent implements OnInit {
  private readonly eventsRepository = inject(EventsRepository);
  colregList$!: Observable<DropdownViewModel[] | null>;
  targetTypeList$!: Observable<DropdownViewModel[] | null>;
  sliderTargetSog$!: Observable<CustomSliderOptions>;
  sliderTargetDistance$!: Observable<CustomSliderOptions>;

  appliedColregClassification$!: Observable<
    ViewValue<ViewColregClassificationNames>
  >;
  appliedTargetType$!: Observable<ViewValue<ViewTargetTypes>>;

  constructor(private store: Store<CoreState>) {}

  ngOnInit(): void {
    this.colregList$ = this.store.select(
      EventsSelectors.selectColregClassificationDropdown
    );
    this.targetTypeList$ = this.store.select(
      EventsSelectors.selectTargetTypeDropdown
    );

    this.sliderTargetSog$ = this.store.select(
      EventsSelectors.selectSliderTargetSog
    );
    this.sliderTargetDistance$ = this.store.select(
      EventsSelectors.selectSliderTargetDistance
    );

    this.appliedColregClassification$ = this.store.select(
      EventsSelectors.selectAppliedColregClassification
    );
    this.appliedTargetType$ = this.store.select(
      EventsSelectors.selectAppliedTargetType
    );
  }

  async onEventFiltersChange(value: any, key: string): Promise<void> {
    const appliedFilters = await firstValueFrom(
      this.store.select(EventsSelectors.selectAppliedEventFilters)
    );
    const updatedAppliedFilters: AppliedEventFilters = {
      ...appliedFilters,
      [key]: value,
    };
    this.store.dispatch(
      EventsActions.onEventFilterChange({ updatedAppliedFilters })
    );

    this.eventsRepository.applyFilters(updatedAppliedFilters);
  }
}
