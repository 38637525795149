import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, of, Subject, switchMap, tap } from 'rxjs';
import { IsBusyService } from '../../services/is-busy.service';
import { VoyagesSeasonMap } from './models/voyages.types';
import { VoyagesService } from './voyages.service';
import { VoyagesStore } from './voyages.store';

export class VoyagesRepository {
  // DI
  private voyagesStore = inject(VoyagesStore);
  private voyagesService = inject(VoyagesService);
  private destroyRef = inject(DestroyRef);
  private isBusyService = inject(IsBusyService);
  // Http Request Subjects
  private originsAndDestinations$ = new Subject<void>();
  private voyages$ = new Subject<void>();

  constructor() {
    this.setUpSubscriptions();
  }

  setUpSubscriptions() {
    this.originsAndDestinations$
      .pipe(
        switchMap(() => this.voyagesService.getOriginsAndDestinations()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(data => {
        this.voyagesStore.setOriginsAndDestinations(data);
      });

    this.voyages$
      .pipe(
        tap(() => this.isBusyService.start()),
        switchMap(() => {
          if (this.voyagesStore.filters().length < 3) {
            return of(null);
          }

          const originFilter = this.voyagesStore.filters()[0];
          const origin = originFilter.requestValue;

          const destinationFilter = this.voyagesStore.filters()[1];
          const destination = destinationFilter.requestValue;

          const timeValue = this.voyagesStore.filters()[2].value;
          const season =
            VoyagesSeasonMap[timeValue as keyof typeof VoyagesSeasonMap];

          return this.voyagesService
            .getVoyages(origin, destination, season)
            .pipe(
              catchError(err => {
                if (err.status !== 404) {
                  this.isBusyService.error(
                    'Something went wrong. Please try again or contact support.'
                  );
                }

                this.voyagesStore.setAlternativeVoyages(null);
                return of(null);
              })
            );
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(data => {
        this.voyagesStore.setAlternativeVoyages(data);
        this.isBusyService.stop();
        this.voyagesStore.setFiltersPopup(false);
      });
  }

  getOriginsAndDestinations() {
    this.originsAndDestinations$.next();
  }

  getVoyages() {
    if (this.voyagesStore.filters().some(filter => !filter.value)) {
      return;
    }

    this.voyages$.next();
  }

  toggleFiltersPopup(value: boolean) {
    this.voyagesStore.setFiltersPopup(value);
  }
}
