import { HttpEventType } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { FleetService } from 'src/app/core/services/fleet.service';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  AuthenticationSelectors,
  ShipsActions,
} from 'src/app/core/store/types';
import { FleetReport } from 'src/app/core/view-models/reports.view.model';

@Component({
  selector: 'app-reports-dialog',
  templateUrl: './reports-dialog.component.html',
  styleUrls: ['./reports-dialog.component.scss'],
})
export class ReportsDialogComponent {
  isUserOrcaAdmin$!: Observable<boolean | null>;
  fleetName$!: Observable<string | undefined>;
  selectedFilter: string = 'all';
  list: FleetReport[] = [];
  filteredList: FleetReport[] = [];
  filters: string[] = [];
  selectedFile: File | null = null;
  selectedYear: number | null = null;
  availableYears: number[] = [];

  constructor(
    private store: Store<CoreState>,
    private fleetService: FleetService,
    public dialogRef: MatDialogRef<ReportsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fetchList();
    this.isUserOrcaAdmin$ = this.store.select(
      AuthenticationSelectors.selectIsUserOrcaAdmin
    );
    this.fleetName$ = this.store.select(
      AuthenticationSelectors.selectFleetNameOfSelectedFleetId
    );
    this.initializeYears();
  }

  async fetchList(): Promise<void> {
    try {
      const response = await this.fleetService.getFleetReports();
      this.list = response;
      this.filteredList = response;
      this.filters = _.uniq(response.map(item => item.subfolder));
      this.store.dispatch(
        ShipsActions.reportsClicked({ displayedReports: response.length })
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  initializeYears(): void {
    // shows the last 7 years + the current year + the next 2 years
    const currentYear = new Date().getFullYear();
    this.availableYears = Array.from(
      { length: 10 },
      (_, i) => currentYear - i + 2
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFilterChange(filter: string): void {
    if (filter === 'new') {
      this.filteredList = [];
    } else {
      this.filteredList =
        filter === 'all'
          ? this.list
          : this.list.filter(item => item.subfolder === filter);
    }
    this.selectedFilter = filter;
  }

  onUploadReport(): void {
    if (this.selectedFile && this.selectedYear) {
      this.fleetService
        .uploadFleetReport(this.selectedFile, this.selectedYear)
        .subscribe({
          next: event => {
            if (event.type === HttpEventType.Response) {
              this.fetchList();
              this.selectedFilter = 'all';
            }
          },
          error: error => console.error('Upload failed:', error),
        });
    } else {
      console.error('File or Year not selected');
    }
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      this.selectedFile = file;
    } else {
      console.error('Invalid file, must be valid PDF file.');
      this.selectedFile = null;
    }
  }
}
