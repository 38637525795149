import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  Observable,
  startWith,
  Subject,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import { MapEntity } from 'src/app/mapbox/models/mapbox.models';
import { ColumnDefinition } from 'src/app/shared/components/table/models/column-definition.model';
import { RowKey } from 'src/app/shared/components/table/models/row-key.model';
import { TrackBySelector } from 'src/app/shared/components/table/models/track-by-selector.model';
import { SortDetails } from '../../../shared/components/table/models/sort-details.model';
import { MapTooltip, ShipPosition } from '../../models/map.models';
import { Ship } from '../../models/ship.model';
import { FeatureFlagService } from '../../services/feature-toggle.service';
import { fetchShipDetails } from '../../store/actions/ships.actions';
import { CoreState } from '../../store/state/core.state';
import {
  AuthenticationSelectors,
  ShipsActions,
  ShipsSelectors,
} from '../../store/types';
import { Screenshot } from '../../view-models/gallery.view.model';

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.scss'],
})
export class FleetComponent implements OnInit {
  ships$!: Observable<Ship[]>;

  shipsOnMap$ = new BehaviorSubject<MapEntity[]>([]);

  selectedShipPosition$ = new BehaviorSubject<ShipPosition | undefined>(
    undefined
  );

  columns: ColumnDefinition<Ship>[] = [
    { id: 'name', header: 'Ship Name', value: s => s.shipName, width: 160 },
    {
      id: 'safety',
      header: 'Safety Score',
      value: s => s.safetyScore,
      width: 86,
    },
    {
      id: 'score-trend',
      header: 'Score Trend',
      value: s => s.scoreTrend,
      width: 80,
    },
    {
      id: 'last-connection',
      header: 'Last Connection',
      value: s => s.lastConnection?.getTime() ?? 0,
      width: 100,
    },
    { id: 'view', header: '', width: 70 },
  ];

  trackBy: TrackBySelector<Ship> = ship => ship.shipId;
  sortBy: SortDetails | null = null;
  selectedShipId$ = new BehaviorSubject<number | null>(null);
  fleetName$!: Observable<string | undefined>;
  isAdmin$!: Observable<boolean | null>;

  searchBoxControl = new FormControl<string>('');
  searchBox$!: Observable<string | null>;
  startDateAvgShipSafetyScore$!: Observable<number>;
  endDateAvgShipSafetyScore$!: Observable<number>;
  shipData$!: Observable<Ship | null>;
  screenshot$!: Observable<Screenshot | null>;
  showFlag$!: Observable<boolean>;
  mapTooltip$!: Observable<MapTooltip>;
  private destroy$ = new Subject<void>();

  isVisible = false;
  constructor(
    private store: Store<CoreState>,
    private featureFlag: FeatureFlagService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.store
      .select(ShipsSelectors.selectMapShips)
      .subscribe(ships => this.shipsOnMap$.next(ships));
    this.store
      .select(ShipsSelectors.selectSelectedShipPosition)
      .subscribe(position =>
        this.selectedShipPosition$.next({
          height: 20000000,
          ...position,
          shipId: this.selectedShipId$.getValue() ?? undefined,
        })
      );

    this.showFlag$ = this.featureFlag.getFeatureFlag$('LiveView');

    this.mapTooltip$ = this.store.select(ShipsSelectors.selectMapTooltip).pipe(
      withLatestFrom(this.showFlag$.pipe(startWith(null))),
      takeUntil(this.destroy$),
      map(([tooltip, showFlag]) => ({
        ...tooltip,
        isVisible: true,
        showLiveStreamFlag: showFlag,
      }))
    );

    this.endDateAvgShipSafetyScore$ = this.store.select(
      ShipsSelectors.selectEndDateAvgShipSafetyScore
    );

    this.startDateAvgShipSafetyScore$ = this.store.select(
      ShipsSelectors.selectStartDateAvgShipSafetyScore
    );

    this.shipData$ = this.store.select(ShipsSelectors.selectShipRealTimeData);
    this.screenshot$ = this.store.select(ShipsSelectors.selectShipScreenshot);
    this.showFlag$ = this.featureFlag.getFeatureFlag$('LiveView');

    this.sortBy = history.state['sortBySafetyLevel'];

    this.store
      .select(ShipsSelectors.selectSelectedShipId)
      .subscribe(shipId => this.selectedShipId$.next(shipId));

    this.fleetName$ = this.store.select(
      AuthenticationSelectors.selectFleetName
    );

    this.isAdmin$ = this.store.select(
      AuthenticationSelectors.selectIsUserOrcaAdmin
    );

    this.searchBox$ = this.searchBoxControl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      map(v => v!.toLowerCase())
    );

    this.ships$ = combineLatest([
      this.store.select(ShipsSelectors.selectShips),
      this.searchBox$,
    ]).pipe(
      map(([ships, shipNameToSearch]) => {
        if (shipNameToSearch === '') {
          return [...ships];
        } else {
          return ships.filter(ship =>
            ship.shipName.toLowerCase().includes(shipNameToSearch!)
          );
        }
      })
    );
  }

  onShipListSelection(key: RowKey | null) {
    if (key != null) {
      this.store.dispatch(
        ShipsActions.setSelectedShipIdOnShipListClick({ shipId: key as number })
      );
      this.store.dispatch(fetchShipDetails({ shipId: key as number }));
      this.store.dispatch(
        ShipsActions.setSelectedShipIdOnTooltipShipProfile({
          shipId: key as number,
        })
      );
    }
  }
  shipTooltipButtonClicked(navigate: string) {
    this.router.navigateByUrl(navigate);
  }

  onShipMapSelection(entityParams: string[]): void {
    const shipId = entityParams[0];
    this.store.dispatch(
      ShipsActions.setSelectedShipIdOnShipMapClick({ shipId: Number(shipId) })
    );
  }
}
