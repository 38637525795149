<div class="report-item">
  <div class="report-item-content">
    <h4 class="report-item-title">
      {{ formattedFileName }}
    </h4>
    <p class="report-item-date">
      Updated: {{ last_updated | date : 'MMM d, y' }}
    </p>
  </div>
  <div class="report-item-actions">
    <button class="report-item-button" (click)="downloadReport()">
      <img src="./assets/icons/retrieve.svg" />
    </button>
    <a
      class="report-item-view"
      [href]="url + '?jwt=' + (token$ | async)"
      target="_blank"
      rel="noopener noreferrer"
      (click)="viewReport()"
      >View</a
    >
  </div>
</div>
