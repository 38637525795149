import { CommonModule } from '@angular/common';
import { Component, computed, OnInit, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { Store } from '@ngrx/store';
import { User } from 'dashboard-frontend-library/models/auth/user.model';
import { DestroyRef } from 'projects/orca-lib-main/projects/orca-lib/src/lib/services/destroy-ref.service';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import { Ship } from 'src/app/core/models/ship.model';
import { SubFleetWithUsers } from 'src/app/core/models/subfleet.models';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { SubFleetService } from 'src/app/core/services/subfleet.service';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  AuthenticationSelectors,
  ShipsSelectors,
} from 'src/app/core/store/types';
import { ColumnDefinition } from 'src/app/shared/components/table/models/column-definition.model';
import { SortDetails } from 'src/app/shared/components/table/models/sort-details.model';
import { TrackBySelector } from 'src/app/shared/components/table/models/track-by-selector.model';
import { TagListComponent } from 'src/app/shared/components/tag-list/tag-list.component';
import { TagComponent } from 'src/app/shared/components/tag/tag.component';
import { TooltipDirective } from 'src/app/shared/directives/tooltip.directive';
import { SharedModule } from 'src/app/shared/shared.module';
import { EditSubfleetComponent } from '../edit-subfleet/edit-subfleet.component';
import { NewSubfleetComponent } from '../new-subfleet/new-subfleet.component';
import { RemoveSubfleetComponent } from '../remove-subfleet/remove-subfleet.component';
@Component({
  selector: 'app-subfleet-list',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatButtonModule,
    TooltipDirective,
    TagListComponent,
    TagComponent,
  ],
  templateUrl: './subfleet-list.component.html',
  styleUrls: ['./subfleet-list.component.scss'],
  providers: [DestroyRef],
})
export class SubfleetListComponent implements OnInit {
  subFleets: SubFleetWithUsers[] = [];
  trackBy: TrackBySelector<SubFleetWithUsers> = subFleet => subFleet.subFleetId;
  sortBy: SortDetails | null = {
    column: 'subFleetName',
    direction: 'asc',
  };

  columns: ColumnDefinition<SubFleetWithUsers>[] = [
    {
      id: 'fleetId',
      header: 'Fleet Id',
      value: s => s.fleetId,
    },
    {
      id: 'subFleetName',
      header: 'Subfleet Name',
      value: s => s.subFleetName,
      width: 200,
    },
    {
      id: 'assignedUsers',
      header: 'Users',
      value: s => s.assignedUsers.length,
      width: 200,
    },
    {
      id: 'shipsList',
      header: 'Ships',
      value: s => s.shipsList.length,
      width: 200,
    },
    {
      id: 'actions',
      header: '',
      width: 200,
    },
  ];

  searchBoxControl = new FormControl('');

  private ships = signal<Ship[]>([]);
  private selectedFleetId = signal<number | undefined>(undefined);

  constructor(
    private subFleetService: SubFleetService,
    private store: Store<CoreState>,
    private dialog: MatDialog,
    private notifications: NotificationsService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.initializeSearchBox();
    this.initializeShips();
    this.initializeFleetFilter();
  }

  private initializeSearchBox(): void {
    this.searchBoxControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroyRef.destroy$)
      )
      .subscribe(searchTerm => {
        this.loadSubFleets(searchTerm ?? undefined);
      });
  }

  private initializeShips(): void {
    this.store
      .select(ShipsSelectors.selectShips)
      .pipe(takeUntil(this.destroyRef.destroy$))
      .subscribe((ships: Ship[]) => this.ships.set(ships));
  }

  private initializeFleetFilter(): void {
    this.store
      .select(AuthenticationSelectors.selectSelectedFleetId)
      .pipe(
        map(fleetId => fleetId ?? null),
        takeUntil(this.destroyRef.destroy$)
      )
      .subscribe(fleetId => {
        this.selectedFleetId.set(fleetId ?? undefined);
        this.loadSubFleets(this.searchBoxControl.value ?? undefined);
      });
  }

  private loadSubFleets(searchTerm?: string): void {
    const fleetId = this.selectedFleetId();
    this.subFleetService
      .getAllWithUsers(fleetId, searchTerm || undefined)
      .then(subFleets => {
        this.subFleets = subFleets;
      })
      .catch(error => {
        console.error('Error loading subfleets:', error);
        this.subFleets = [];
      });
  }

  private handleSuccessfulSubfleetAction(
    success: boolean,
    successMessage: string
  ): void {
    if (success) {
      this.loadSubFleets();
      this.notifications.success(successMessage);
    }
  }

  openEditDialog(subFleet: SubFleetWithUsers): void {
    const dialogRef = this.dialog.open(EditSubfleetComponent, {
      data: subFleet,
      restoreFocus: false,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyRef.destroy$))
      .subscribe(result =>
        this.handleSuccessfulSubfleetAction(
          result,
          'Subfleet updated successfully'
        )
      );
  }

  openCreateDialog(): void {
    const dialogRef = this.dialog.open(NewSubfleetComponent, {
      restoreFocus: false,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyRef.destroy$))
      .subscribe(result =>
        this.handleSuccessfulSubfleetAction(
          result,
          'Subfleet created successfully'
        )
      );
  }

  confirmDelete(subFleet: SubFleetWithUsers): void {
    const dialogRef = this.dialog.open(RemoveSubfleetComponent, {
      data: subFleet,
      restoreFocus: false,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyRef.destroy$))
      .subscribe(result => {
        if (result) {
          this.handleSuccessfulSubfleetAction(
            result,
            'Subfleet deleted successfully'
          );
        }
      });
  }

  getShipNames(shipsList: string[]) {
    return computed(() => {
      return shipsList.map(shipId => {
        const ship = this.ships().find(s => s.shipId === Number(shipId));
        return ship ? ship.shipName : shipId;
      });
    })();
  }

  getUserNames(assignedUsers: User[]) {
    return computed(() => {
      return assignedUsers.map(user => user.firstName + ' ' + user.lastName);
    })();
  }
}
