import { Screenshot } from '../view-models/gallery.view.model';

export interface ShipPosition {
  readonly long: number;
  readonly lat: number;
  readonly height?: number;
  readonly shipId?: number;
}

export const defaultMapHeight = 40000000;

export const defaultPosition: ShipPosition = {
  long: -17.743711,
  lat: 34.903953,
  height: defaultMapHeight,
};

export interface MapTooltip {
  readonly shipData?: any | null;
  readonly endDateAvgShipSafetyScore?: number | null;
  readonly startDateAvgShipSafetyScore?: number | null;
  readonly showLiveStreamFlag?: boolean | null;
  readonly isLiveStreamOpen?: boolean;
  readonly screenshot?: Screenshot;
  readonly isVisible?: boolean;
  readonly isShipCaptain?: boolean;
  readonly visibleByLoading?: boolean;
}

export enum MapSettingsOption {
  Events = 'Events',
  LiveEvents = 'Live Events',
  NoGoAreas = 'No go Areas',
  ComplianceAreas = 'Compliance Areas',
  Wind = 'Wind',
  WaveSwell = 'Waves (Swell)',
  WaveSignificant = 'Waves (Significant)',
  Currents = 'Ocean Currents',
  Humidity = 'Humidity',
  Temperature = 'Temperature',
  Clouds = 'Clouds and Precipitation',
}
