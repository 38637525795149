<div class="flex-column-base">
  <div class="header">
    <h5 class="link" (click)="navigateToShip(ship.shipId)">
      {{ ship.shipName }}
    </h5>
    <div class="rt-status-box" *ngIf="rtEventName">
      <img class="icon" src="./assets/icons/rt_event.svg" />
      {{ rtEventName }} Duration: {{ rtEventTime }} Hrs
    </div>
  </div>
  <ng-container *ngIf="realTimeData$ | async as realTimeData">
    <span class="subtitle1 top-marginer"
      >Location: {{ realTimeData.textualLocation }}</span
    >
    <div class="padding-reverter general-data">
      <div class="info-container">
        <div class="separator"></div>
        <div class="info">
          <span class="caption">SOG</span>
          <div class="date">
            <span class="subtitle3">
              {{
                realTimeData.sog !== null
                  ? (realTimeData.sog | number : '1.0-0')
                  : 'N/A'
              }}
              <span *ngIf="realTimeData.sog !== null" class="caption">
                Knts</span
              >
            </span>
          </div>
        </div>
      </div>
      <div class="info-container">
        <div class="separator"></div>
        <div class="info">
          <span class="caption">COG</span>
          <div class="date">
            <span class="subtitle3">
              {{
                realTimeData.cog !== null
                  ? (realTimeData.cog | number : '1.0-0') + '°'
                  : 'N/A'
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="info-container">
        <div class="separator"></div>
        <div class="info">
          <span class="caption">Max Pitch</span>
          <div class="date">
            <span class="subtitle3">
              {{
                realTimeData.maxPitch !== null
                  ? (realTimeData.maxPitch | number : '1.0-0') + '°'
                  : 'N/A'
              }}
            </span>
          </div>
          <div *ngIf="realTimeData.maxPitch !== null" class="caption gray">
            Last 10 minutes
          </div>
        </div>
      </div>
      <div class="info-container">
        <div class="separator"></div>
        <div class="info">
          <span class="caption">Max Roll</span>
          <div class="date">
            <span class="subtitle3">
              {{
                realTimeData.maxRoll !== null
                  ? (realTimeData.maxRoll | number : '1.0-0') + '°'
                  : 'N/A'
              }}
            </span>
          </div>
          <div *ngIf="realTimeData.maxRoll !== null" class="caption gray">
            Last 10 minutes
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
