import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CoreState } from 'src/app/core/store/state/core.state';
import { EventsSelectors } from 'src/app/core/store/types';
import { appliedFiltersComparar } from 'src/app/core/utils/events';
import { PresetFilter } from 'src/app/core/view-models/filter.view.model';

@Component({
  selector: 'app-presets',
  templateUrl: './presets.component.html',
  styleUrls: ['./presets.component.scss'],
})
export class PresetsComponent implements OnInit, OnChanges {
  @Input()
  label: string = '';
  @Input()
  defaultOption: string = '';
  @Input()
  selectedPreset: PresetFilter | undefined | null = undefined;
  @Output()
  filterChanged: EventEmitter<PresetFilter | undefined> = new EventEmitter();

  presetFilters$!: Observable<PresetFilter[]>;
  isPresetsDiabled$!: Observable<boolean>;
  showLabelCondition!: boolean;
  constructor(private store: Store<CoreState>) {}

  ngOnInit(): void {
    this.presetFilters$ = this.store.select(
      EventsSelectors.selectPresetFilters
    );

    this.isPresetsDiabled$ = this.store.select(
      EventsSelectors.selectIsPresetFiltersEmpty
    );
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedPreset']) {
      this.showLabelCondition = this.selectedPreset === undefined;
    }
  }

  setPresetFitler(change: MatSelectChange): void {
    this.filterChanged.emit(change.value);
  }

  compareFilterValues(f1: PresetFilter, f2: PresetFilter): boolean {
    return appliedFiltersComparar(f1?.filters, f2?.filters);
  }
}
