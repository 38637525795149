import { Component, inject, OnInit } from '@angular/core';
import { DestroyRef } from 'projects/orca-lib-main/projects/orca-lib/src/lib/services/destroy-ref.service';
import { LoggerService } from '../../services/logger.service';
import { EventsRepository } from './events.repository';
import { EventsStore } from './events.store';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [DestroyRef],
})
export class EventsComponent implements OnInit {
  public readonly eventsStore = inject(EventsStore);
  private readonly eventsRepository = inject(EventsRepository);
  private readonly logger = new LoggerService();

  ngOnInit() {
    this.eventsRepository.initLoad();
  }

  async onSelectedMapEvent(entityParams: string[]): Promise<void> {
    const eventId = entityParams[0];
    await this.eventsRepository.updateSelectedEventById(eventId);
  }
}
